import { Component, ComponentFactoryResolver, OnInit, Type, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { HeaderService } from 'src/app/core/service';
import { MidHeader } from './mid-header.directive';

@Component({
    selector: 'eudamed-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
  })
export class HeaderComponent implements OnInit {

    @ViewChild(MidHeader, {static: true}) midHeader: MidHeader ;
    title = '';
    constructor(private router: Router,
                private componentFactoryResolver: ComponentFactoryResolver,
                private headerService: HeaderService,
                private route: ActivatedRoute
      ) { }

    ngOnInit(): void {
      this.headerService.getCurrentComponent().subscribe((component) => {
        this.loadComponent(component);
      });


      this.router.events.pipe(
          filter(event => event instanceof NavigationEnd),
              map(() => this.route),
              map(route => {
              while (route.firstChild) { route = route.firstChild; }
              return route;
          }),
      ).subscribe(data => {
          this.title = data.snapshot.data.title;
      });

    }

    loadComponent(component: Type<any>): void {
      const viewContainerRef = this.midHeader.viewContainerRef;
      if (Object.keys(component).length === 0 && component.constructor === Object) {
        viewContainerRef.clear();
      } else {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        viewContainerRef.clear();
        viewContainerRef.createComponent(componentFactory);
      }
    }
}
