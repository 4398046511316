import {animate, animation, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Platform} from 'src/app/core/service/platform.service';
import {BaseLayoutComponent} from '../base-layout.component';

export const siderAnim = animation([
    state('open', style({
        width: '400px',
    }), {
    params: {
        width: '400px'
        }
    }),
    state('closed', style({
        width: '0px',
    })),
    transition('open => closed', [
        animate('0.3s')
    ]),
    transition('closed => open', [
        animate('0.3s')
    ]),
]);

@Component({
    selector: 'layout-mobile',
    templateUrl: './layout-mobile.component.html',
    styleUrls: ['./layout-mobile.component.scss'],
    animations: [
        trigger('openCloseSider', [
            state('open', style({
                width: '248px',
            })),
            state('closed', style({
                width: '0px',
            })),
            transition('open => closed', [
                animate('0.1s')
            ]),
            transition('closed => open', [
                animate('0.1s')
            ]),
        ]),
    ],
})
export class LayoutMobileComponent extends BaseLayoutComponent implements OnInit {
    faTimes = faTimes;
    faBars = faBars;
    firstAndLastName = '';
    Platform = Platform;

    ngOnInit(): void {
        super.ngOnInit();
        this.loginService.getUserData().then(result => {
            const fn = result.firstName ? result.firstName : '';
            const ln = result.lastName ? result.lastName : '';
            this.firstAndLastName = fn + ' ' + ln;
        });
    }

    openInNewTab(url: string): void {
        const win = window.open(url, '_blank');
        win?.focus();
    }

    routeTo(route: string): void{
        this.router.navigateByUrl(route);
    }

    logout(): void {
        this.loginService.logout();
    }

    changeLanguage(lang: string): void {
        this.selectedLanguage = lang;
        this.translationService.use(this.selectedLanguage);
        this.eudamedCookieService.changeLanguageCookie(this.selectedLanguage);
    }
}
