export default class EnrichingDeviceDTO{
    basicDiCode: string;
    basicIssuingEntityCode: string;
    deviceType: string;
    diCode: string;
    compound: boolean;
    basicDeviceType: string;
    id: string;
    issuingEntityCode: string;
    mfActorCode: string;
    prActorCode: string;
    payload: any;
    basicPayload: any;
    valid: boolean;
    version: number | undefined;

    // for device enrichment component
    payloadTradeName: string;
    payloadModel: string;
    payloadModelName: string;
    payloadDeviceType: string;
    payloadReferenceNumber: string;
    payloadBasicUDIIdentifier: string;
    payloadBasicUDIIdentifierDICode: string;
    payloadRiskClass: string;
    isBasic: boolean;
    isLegacy: boolean;
    isUpdate: boolean;

  constructor(enrichingDevice: Partial<EnrichingDeviceDTO>) {
    this.basicDiCode = enrichingDevice.basicDiCode || '';
    this.basicIssuingEntityCode = enrichingDevice.basicIssuingEntityCode || '';
    this.deviceType = enrichingDevice.deviceType || '';
    this.diCode = enrichingDevice.diCode || '';
    this.id = enrichingDevice.id || '';
    this.issuingEntityCode = enrichingDevice.issuingEntityCode || '';
    this.mfActorCode = enrichingDevice.mfActorCode || '';
    this.prActorCode = enrichingDevice.prActorCode || '';
    this.payload = enrichingDevice.payload || {};
    this.basicDeviceType = enrichingDevice.basicDeviceType || '';
    this.valid = enrichingDevice.valid || false;
    this.basicPayload = enrichingDevice.basicPayload || {};
    this.compound = enrichingDevice.compound || false;
    if (enrichingDevice.version != null && enrichingDevice.version > 0){
        this.isUpdate = true;
    }else{
        this.isUpdate = false;
    }


    this.setHeaderFields();
  }

  setHeaderFields(): void{
    this.payloadTradeName = this.payload.tradeName?.length > 0 ? this.payload.tradeName[0].tradeNameText : '';
    this.payloadModel = this.payload.model || '';
    this.payloadModelName = this.payload.modelName?.modelNameTerm || '';
    this.payloadDeviceType = this.payload.deviceType;
    this.payloadReferenceNumber = this.payload.referenceNumber;
    this.payloadBasicUDIIdentifier = this.payload.basicUDIIdentifier;
    this.payloadBasicUDIIdentifierDICode = this.payload.basicUDIIdentifier?.basicUDIIdentifierDICode;
    this.payloadRiskClass = this.payload.riskClass || this.basicPayload.riskClass;
    this.isBasic = this.payload.deviceType.includes('BASIC');
    this.isLegacy = this.payload.deviceType.includes('MDE') || this.payload.deviceType.includes('IVDE') || this.payload.deviceType.includes('ADEUDI');
  }
}
