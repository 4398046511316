import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { CoreModule } from './core/core.module';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { HeaderComponent } from './shared/layout/header.component';
import { SharedModule } from './shared/shared.module';
import {LoginService} from './core/service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MidHeader } from './shared/layout/mid-header.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NotificationTemplateComponent} from './core/service/notifications/notification-template-component';
import { CookieService} from 'ngx-cookie-service';
import {ApplicationConfigurationService} from './core/service/application-configuration.service';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {BaseLayoutComponent} from './shared/layout/base-layout.component';
import {LayoutComponent} from './shared/layout/browser/layout.component';
import {LayoutMobileComponent} from './shared/layout/mobile/layout-mobile.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {EudamedCookieService} from './core/service/eudamed-cookie.service';
import {EudamedCookie} from './core/constants/cookie.constants';
import {BrandingService} from './core/service/branding-service.service';
import { EnvService } from './core/service/env.service';


registerLocaleData(en);

function initializeEnv(appConfigurationService: ApplicationConfigurationService, envService: EnvService): any {
  return async () => {
    const envConfiguration = await appConfigurationService.getEnvConfiguration().toPromise();
    envService.environment = envService.getEnvironmentForEnv(envConfiguration);
  };
}

function initializeBrand(router: Router, appConfigurationService: ApplicationConfigurationService, brandingService: BrandingService) {
  const urlhost = window.location.host;
  return async () => {
    const brandingConfiguration = await appConfigurationService.getBrandingConfiguration().toPromise();
    brandingService.sidebarLogoPath = brandingService.getSidebarLogoPath(brandingConfiguration, urlhost);
    brandingService.sidebarLogoImgClass = brandingService.getSidebarLogoCssClass(brandingConfiguration, urlhost);
    brandingService.logoPath = brandingService.getLogoPath(brandingConfiguration, urlhost);
    brandingService.logoClass = brandingService.getLogoCssClass(brandingConfiguration, urlhost);
    brandingService.logoClassTablet = brandingService.getLogoTabletCssClass(brandingConfiguration, urlhost);
    brandingService.impressumText = brandingService.getImpressumTextForBrand(brandingConfiguration, urlhost);
  };
}

function initializeKeycloak(keycloak: KeycloakService, router: Router, loginService: LoginService, appConfigurationService: ApplicationConfigurationService): any {

  return async () => {

    from(keycloak.keycloakEvents$).subscribe(event => {
      if (event.type === KeycloakEventType.OnAuthSuccess) {
        loginService
          .login({
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            keycloakToken: keycloak.getKeycloakInstance().token
          })
          .subscribe(
            () => {
              router.navigate(['']);
            }
          );
      }
    });

    const keycloakConfiguration = await appConfigurationService.getKeycloakConfiguration().toPromise();

    return keycloak.init({
      config: keycloakConfiguration,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false
      },
      enableBearerInterceptor: true,
      loadUserProfileAtStartUp: true
      // bearerExcludedUrls: ['/welcome']
    });
  };
}

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    BaseLayoutComponent,
    LayoutMobileComponent,
    HeaderComponent,
    MidHeader,
    NotificationTemplateComponent
  ],
  imports: [
      CoreModule,
      SharedModule,
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      KeycloakAngularModule,
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),

      // Needed for layout as its declared in AppModule instead of shared since it shouldn't be shared
      NzLayoutModule,
      NzGridModule,
      NzMenuModule,
      NzIconModule,
      NzButtonModule,

      FontAwesomeModule
  ],
  providers: [
      {
          provide: NZ_I18N, useValue: en_US
      }, {
          provide: APP_INITIALIZER,
          useFactory: initializeKeycloak,
          multi: true,
          deps: [KeycloakService, Router, LoginService, ApplicationConfigurationService]
      }, {
          provide: APP_INITIALIZER,
          useFactory: initializeBrand,
          multi: true,
          deps: [Router, ApplicationConfigurationService, BrandingService]
      }, {
        provide: APP_INITIALIZER,
        useFactory: initializeEnv,
        multi: true,
        deps: [ApplicationConfigurationService, EnvService]
      },  CookieService,
      {
          provide: 'googleTagManagerId', useValue: 'GTM-NVDV8W6'
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(translatorService: TranslateService, cookieService: EudamedCookieService) {
    translatorService.addLangs(['en', 'de']);
    translatorService.setDefaultLang('en');

    const languageFromCookie = cookieService.getLanguageCookie();
    if (languageFromCookie === '') {
      const browserLang = translatorService.getBrowserLang();
      translatorService.use(browserLang.match(/en|de/) ? browserLang : 'en');
      cookieService.changeLanguageCookie(translatorService.currentLang);
    } else {
      translatorService.use(languageFromCookie);
    }
  }

}

declare module '@angular/forms' {
    interface AbstractControl {
        onChangeFunction: any;
        updateable: boolean;
        updating: boolean;
        hidden: boolean | ((formGroup: FormGroup) => boolean);
        errorMessage: string | any;
        asterisk: boolean;
    }
}



