<div class="user-component">
  <div style="display:flex; align-items:center;">
    <a nz-dropdown [(nzVisible)]="userMenuOpen" nzTrigger="click"
       [nzDropdownMenu]="menu" nzPlacement="bottomRight">
      {{userData.name}}
      <i nz-icon [nzType]="userMenuOpen ? 'caret-up' : 'caret-down'"></i>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul style="border-radius:5px;" nz-menu nzSelectable>
        <li nz-menu-item (click)="openAccount()">{{ 'USER.account' | translate }}</li>
        <li nz-menu-item (click)="openSettings()">{{ 'USER.settings' | translate }}</li>
        <li nz-menu-item *eudRole="[Authority.ADMIN]"
            (click)="routeTo('/admin/users')">{{ 'USER.admin' | translate }}</li>
        <li nz-menu-item *eudRole="[Authority.TOKEN_MANAGER]"
            (click)="routeTo('/account/tokens')">{{ 'USER.tokenManagement' | translate }}</li>
        <li nz-menu-item (click)="logout()">{{ 'USER.logout' | translate }}</li>
      </ul>
    </nz-dropdown-menu>
    <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="languages"
         class="circled" nzPlacement="bottomRight">
      {{ selectedLanguage | uppercase }}
    </div>
    <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="supportMenu"
       class="circled" nzPlacement="bottomRight">
      ?
    </a>
    <div *ngIf="getCurrentEnv() !== 'PROD'" nzTrigger="click" class="info" nzPlacement="bottomRight">
      <div
        nz-tooltip
        nzTooltipTrigger="hover"
        nzTooltipColor="#243746"
        [nzTooltipTitle]="'USER.info' | translate: {env: getCurrentEnv()}">
        <span>{{ getCurrentEnv() === 'LOCALHOST' ? 'LOCAL' : getCurrentEnv() }}</span>
      </div>
    </div>
    <nz-dropdown-menu #supportMenu="nzDropdownMenu">
      <ng-template #self [ngTemplateOutlet]="self"
                   let-userManual="userManualLink"
                   let-support="supportLink"
                   let-releaseNotes="releaseNotesLink"
                   [ngTemplateOutletContext]="{
                      userManualLink: (selectedLanguage === 'en') ? 'current_V.1.5_UDIManager-User_Manual_EN.pdf' : 'aktuell_V.1.5_UDIManager_Benutzerhandbuch_DE.pdf',
                      supportLink: ('USER.supportMenu.supportLink' | translate),
                      releaseNotesLink: ('USER.supportMenu.releaseNotesLink' | translate) }">
        <ul nz-menu>
          <li nz-menu-item (click)="openFileInNewTab(userManual)">
            {{ 'USER.supportMenu.userManual' | translate }}
          </li>
          <li nz-menu-item (click)="openInNewTab(support)">
            {{ 'USER.supportMenu.support' | translate }}
          </li>
          <li nz-menu-item (click)="openInSameTab('download')">
            {{ 'USER.supportMenu.technicalDocuments' | translate }}
          </li>
          <li nz-menu-item (click)="openInNewTab(releaseNotes)">
            {{ 'USER.supportMenu.releaseNotes' | translate }}
          </li>
        </ul>
      </ng-template>
    </nz-dropdown-menu>
  </div>

  <nz-dropdown-menu #languages="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="changeLanguage('en')">English</li>
      <li nz-menu-item (click)="changeLanguage('de')">German</li>
      <li nz-menu-item (click)="changeLanguage('it')">Italian</li>
      <li nz-menu-item (click)="changeLanguage('jp')">Japanese</li>
    </ul>
  </nz-dropdown-menu>
</div>
