import { NgModule } from '@angular/core';
import { HeaderService, LoginService } from './service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AuthServerProvider } from './auth/auth-jwt.service';
import { DeviceService } from './service/device.store.service';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { ApiService } from './service/api.service';
import { NotificationService } from './service/notifications/notification.service';
import { PlatformService } from './service/platform.service';
import {DeviceEnrichmentService} from './service/device-initialization/device-enrichment.service';
import {FilterService} from './service/filters/filter.service';
import {ActorCodesService} from './service/actor-codes.service';
import {ValidationService} from './service/validation.service';
import {UserManagementService} from './service/user-management.service';
import {FormService} from './service/forms/form.service';
import {SelectionService} from './service/selection.service';
import {EudamedActionService} from './service/eudamed-action.service';
import ReportsService from './service/reports/reports.service';
import {DetailsDownloadService} from './service/details-download.service';
import {UserStoreService} from './service/user.store.service';

@NgModule({
    imports: [
      NgxWebstorageModule.forRoot({}),
      NzNotificationModule
    ],
    providers: [
        CanDeactivateGuard,
        LoginService,
        AuthServerProvider,
        FilterService,
        DeviceService,
        DetailsDownloadService,
        HeaderService,
        ApiService,
        NotificationService,
        PlatformService,
        ActorCodesService,
        DeviceEnrichmentService,
        ValidationService,
        UserManagementService,
        FormService,
        SelectionService,
        EudamedActionService,
        ReportsService,
        UserStoreService
    ],
})
export class CoreModule { }
