<ng-container>
    <h3 class="groupedAttributes-title">
        {{formBuildingInput.name || '' | translate}}
    </h3>
    <ng-container [formGroup]="formGroup">
        <div [class]="setClass(formBuildingInput)">
            <ng-container *ngFor="let child of formBuildingInput.children">
                <form-root-render
                    [formBuildingInput]="child"
                    [formGroup]="getByName(formGroup, formBuildingInput.propertyName)"
                    [lastVisitedPath]="lastVisitedPath">
                </form-root-render>
                <div *ngIf="formBuildingInput.children[formBuildingInput.children.length - 1] === child" style="margin-top: -10px"></div>
                <!-- <ng-container *ngTemplateOutlet="renderer; context: {formBuildingInput: child, formGroup: getByName(formGroup, formBuildingInput.propertyName), lastVisitedPath: lastVisitedPath}"></ng-container> -->
            </ng-container>
        </div>
    </ng-container>
</ng-container>
