import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Device from '../model/device.model';
import { Filter } from '../model/filter.model';
import RegistrationHistoryModel from '../model/registration-history.model';
import { DeviceTypeToRegisterType } from '../util/device-type-to-register-type.util';
import { determineUploadToEudamedStorageUrl } from '../util/upload.util';
import { ApiService } from './api.service';
import { EudamedCookieService } from './eudamed-cookie.service';
import { FilterService } from './filters/filter.service';
import { XmlError } from '../model/xml-error.model';
import { KeycloakService } from 'keycloak-angular';
import DeviceColumn from '../model/device-column.enum';

@Injectable()
export class DeviceService {
  private readonly draftDevice = new BehaviorSubject<any>(null);
  private readonly devices = new BehaviorSubject<any>([]);
  private readonly history = new BehaviorSubject<any>([]);
  private readonly selectedLegislation = new BehaviorSubject<string>('mdr');

  private readonly isLoading = new BehaviorSubject<boolean>(false);
  private readonly currentPage = new BehaviorSubject<number>(1);
  private readonly devicesPerPage = new BehaviorSubject<number>(20);

  // Expanded BUDI children
  private readonly currentChildren = new BehaviorSubject<Device[]>([]);
  private readonly childrenPage = new BehaviorSubject<number>(1);
  private readonly innerLoading = new BehaviorSubject<boolean>(false);
  private readonly expandedRow = new BehaviorSubject<Device | null>(null);

  private readonly showDetails = new Subject<Device | null>();
  private readonly totalNumber = new BehaviorSubject<number>(0);

  private readonly statusHistoryLoading = new BehaviorSubject<boolean>(false);
  private readonly statusHistory = new BehaviorSubject<
    RegistrationHistoryModel[]
  >([]);
  private readonly detailsStateTransactions = new BehaviorSubject<boolean>(
    false
  );

  readonly draftDevice$ = this.draftDevice.asObservable();
  readonly devices$ = this.devices.asObservable();
  readonly selectedLegislation$ = this.selectedLegislation.asObservable();
  readonly isLoading$ = this.isLoading.asObservable();
  readonly currentPage$ = this.currentPage.asObservable();
  readonly devicesPerPage$ = this.devicesPerPage.asObservable();
  readonly history$ = this.history.asObservable();

  readonly currentChildren$ = this.currentChildren.asObservable();
  readonly innerLoading$ = this.innerLoading.asObservable();
  readonly showDetails$ = this.showDetails.asObservable();
  readonly totalNumber$ = this.totalNumber.asObservable();
  readonly currentlyExpandedRow$ = this.expandedRow.asObservable();
  readonly childrenPage$ = this.childrenPage.asObservable();
  readonly detailsStateTransactions$ =
    this.detailsStateTransactions.asObservable();

  readonly statusHistory$ = this.statusHistory.asObservable();
  readonly statusHistoryLoading$ = this.statusHistoryLoading.asObservable();

  constructor(
    private router: Router,
    private apiService: ApiService,
    private filterService: FilterService,
    private cookieService: EudamedCookieService,
    private keycloakService: KeycloakService
  ) {}

  set currentlyExpandedRow(value: Device | null) {
    this.expandedRow.next(value);
  }

  set childrenDevicesPage(value: number) {
    this.childrenPage.next(value);
  }

  get loadedStatusHistory(): any | null {
    return this.statusHistory.value;
  }

  getRegistrationHistory(
    issuingEntity: string,
    diCode: string,
    basic: boolean
  ): Observable<any> {
    this.statusHistoryLoading.next(true);
    if (basic) {
      return this.apiService
        .get(
          `/eudamed/v1/devices/${
            this.selectedLegislation.value
          }/basic-udi-dis/${issuingEntity}/${encodeURIComponent(
            diCode
          )}/registration/history`
        )
        .pipe(
          map((history) => {
            this.statusHistoryLoading.next(false);
            if (history === null) {
              this.statusHistory.next([]);
            } else {
              this.statusHistory.next(history);
            }
          }),
          catchError(() => {
            this.statusHistoryLoading.next(false);
            this.statusHistory.next([]);
            return of(null);
          })
        );
    }
    return this.apiService
      .get(
        `/eudamed/v1/devices/${
          this.selectedLegislation.value
        }/udi-dis/${issuingEntity}/${encodeURIComponent(
          diCode
        )}/registration/history`
      )
      .pipe(
        map((history) => {
          this.statusHistoryLoading.next(false);
          if (history === null) {
            this.statusHistory.next([]);
          } else {
            this.statusHistory.next(history);
          }
        }),
        catchError(() => {
          this.statusHistoryLoading.next(false);
          this.statusHistory.next([]);
          return of(null);
        })
      );
  }

  set perPage(value: number) {
    this.devicesPerPage.next(value);
  }

  set page(value: number) {
    this.currentPage.next(value);
  }

  showDetailsForDevice(device: Device | null): void {
    this.showDetails.next(device);

    if (
      this.keycloakService.getUserRoles().includes('eudamed-editor') &&
      device !== null
    ) {
      this.apiService
        .get(
          `/udimanager/v1/editor/draft/${
            device.fullPayload.deviceType
          }/${device.getIssuingEntity()}/${encodeURIComponent(
            device[DeviceColumn.PublicID]
          )}`
        )
        .subscribe((responseDraft) => {
          if (responseDraft !== null) {
            this.draftDevice.next(responseDraft);
          }
        });
    }
  }

  setDraftDeviceToNull(): void {
    this.draftDevice.next(null);
  }

  toggleTransactions(state: boolean): void {
    this.detailsStateTransactions.next(state);
  }

  getXMLForMessageID(
    issuingEntityCode: string,
    diCode: string,
    messageID: string
  ): Observable<any> {
    return this.apiService.get(
      `/eudamed/v1/registration/${issuingEntityCode}/${encodeURIComponent(
        diCode
      )}/${messageID}/xml`,
      { responseType: 'text' }
    );
  }

  getErrorsFromXMLDocument(
    issuingEntityCode: string,
    diCode: string,
    messageID: string
  ): Observable<XmlError[]> {
    return this.apiService.get(
      `/eudamed/v1/registration/${issuingEntityCode}/${encodeURIComponent(
        diCode
      )}/${messageID}/xml/errors`,
      {}
    );
  }

  getChildrenFor(parentDevice: Device): any {
    this.innerLoading.next(true);
    this.apiService
      .get(
        `/eudamed/v1/devices/${this.selectedLegislation.getValue()}/basic-udi-di/${
          parentDevice.fullPayload.basicUDIIdentifierIssuingEntityCode
        }/${encodeURIComponent(
          parentDevice.fullPayload.basicUDIIdentifierDICode
        )}/link`
      )
      .pipe(
        map((allChildren: Partial<Device>[]) => {
          return allChildren.map((child) => new Device(child));
        })
      )
      .pipe(
        catchError(() => {
          return of([]);
        })
      )
      .subscribe((children) => {
        this.currentChildren.next(children);
        this.innerLoading.next(false);
      });
  }

  toggleLoading(state: boolean): void {
    if (this.isLoading.getValue() !== state) {
      this.isLoading.next(state);
    }
  }

  getSingleBasicDeviceById(
    deviceId: string,
    issuingEntity: string
  ): Observable<any> {
    return this.apiService
      .get(
        `/eudamed/v1/devices/${this.selectedLegislation.getValue()}/basic-udi-dis/${issuingEntity}/${deviceId}/metadata`
      )
      .pipe(map((basicDevice) => new Device(basicDevice)));
  }

  changeLegislation(legislation: string): void {
    this.selectedLegislation.next(legislation);
  }

  fetchLegislation(legislation: string): void {
    this.router.navigate(['/devices', legislation]);
    this.selectedLegislation.next(legislation);
  }

  fetchCsv(): void {
    let params: HttpParams = new HttpParams();

    this.filterService.getFilters().forEach((filter: Filter) => {
      filter.isApplied = true;
      if (filter.isSort) {
        params = params.set('direction', filter.valueToString());
        params = params.set('column', filter.fieldToString());
      } else {
        params = params.set(filter.fieldToString(), filter.valueToString());
      }
    });
    const legislation = this.selectedLegislation.getValue();

    this.apiService
      .get(`/udimanager/v1/reports/csv/${legislation}`, {
        headers: { 'accept-language': this.cookieService.getLanguageCookie() },
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          return of(false);
        })
      )
      .subscribe((response: any) => {
        if (response !== false) {
          const blob = new Blob([response.body], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.download = Date.now().toString() + 'csv';
          link.href = url;
          link.click();
        }
      });
  }

  getDevicesPage(): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('page', (this.currentPage.getValue() - 1).toString())
      .set('perPage', this.devicesPerPage.getValue().toString());

    this.filterService.getFilters().forEach((filter: Filter) => {
      filter.isApplied = true;
      if (filter.isSort) {
        params = params.set('direction', filter.valueToString());
        params = params.set('column', filter.fieldToString());
      } else {
        params = params.set(filter.fieldToString(), filter.valueToString());
      }
    });

    return this.apiService
      .get(
        `/eudamed/v1/devices/${this.selectedLegislation.getValue()}/search`,
        { params, observe: 'response' as 'body' }
      )
      .pipe(
        catchError(() => {
          this.toggleLoading(false);
          return of([]);
        })
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          const totalNumberInHeader = response.headers?.get('x-total-count');

          let totalNumber = 0;
          if (totalNumberInHeader) {
            totalNumber = parseInt(totalNumberInHeader, 10);
          }

          if (totalNumber) {
            this.totalNumber.next(totalNumber);
          }

          if (!response.body) {
            return response;
          } else {
            return this.devices.next(
              response.body.map(
                (fullDevice: Partial<Device>) => new Device(fullDevice)
              )
            );
          }
        })
      );
  }

  getCurrentDevicesPage(): Array<any> {
    return this.devices.getValue();
  }

  sendRegistrationRequest(
    diCode: string,
    issuingEntity: string,
    deviceType: string,
    actorCode: string,
    eudamedToken: string
  ): Observable<any> {
    let registrationRequestDto: any = {};
    if (!deviceType.includes('BASIC')) {
      registrationRequestDto = {
        deviceType: DeviceTypeToRegisterType[deviceType],
        UDIDIIdentifierDICode: diCode,
        UDIDIIdentifierIssuingEntityCode: issuingEntity,
        customerServiceToken: eudamedToken,
        MFActorCode: actorCode,
      };
    } else {
      registrationRequestDto = {
        deviceType: DeviceTypeToRegisterType[deviceType],
        basicUDIIdentifierDICode: diCode,
        basicUDIIdentifierIssuingEntityCode: issuingEntity,
        customerServiceToken: eudamedToken,
        MFActorCode: actorCode,
      };
    }
    if (deviceType.toLowerCase().includes('spp')) {
      registrationRequestDto.PRActorCode = actorCode;
    }
    return this.apiService.post(
      `${determineUploadToEudamedStorageUrl(
        deviceType,
        false
      )}/${issuingEntity}/${encodeURIComponent(diCode)}/registration`,
      registrationRequestDto,
      { 'Content-Type': 'application/json' }
    );
  }

  sendRegistrationDownloadRequest(
    diCode: string,
    issuingEntity: string,
    deviceType: string,
    actorCode: string,
    eudamedToken: string
  ): Observable<any> {
    const registrationRequestDto: any = {
      deviceType: DeviceTypeToRegisterType[deviceType],
      UDIDIIdentifierDICode: diCode,
      UDIDIIdentifierIssuingEntityCode: issuingEntity,
      customerServiceToken: eudamedToken,
      MFActorCode: actorCode,
    };
    if (deviceType.toLowerCase().includes('spp')) {
      registrationRequestDto.PRActorCode = actorCode;
    }
    return this.apiService.post(
      `${determineUploadToEudamedStorageUrl(
        deviceType,
        false
      )}/${issuingEntity}/${encodeURIComponent(diCode)}/registration/update`,
      registrationRequestDto,
      { 'Content-Type': 'application/json' }
    );
  }

  getCurrentLegislation(): string {
    return this.selectedLegislation.getValue();
  }

  getNumberOfAllDevices(): number {
    return this.totalNumber.getValue();
  }

  getRegistrationForDevice(
    diCode: string,
    issuingEntity: string
  ): Observable<any> {
    return this.apiService.get(
      `/eudamed/v1/devices/${this.selectedLegislation.getValue()}/udi-dis/${issuingEntity}/${diCode}/registration`,
      { observe: 'response' as 'body' }
    );
  }
}
