import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseFormFieldType} from '../base-form-field-type.class';

@Component({
    selector: 'textarea-render',
    templateUrl: './textarea-rendering.component.html',
    styleUrls: ['./textarea-rendering.component.scss']
})
export class TextareaRenderingComponent extends BaseFormFieldType implements OnInit {
    optionalClass = ['non-empty-input', 'label'];
    Validators = Validators;

    ngOnInit(): void{
        // reset 'null' to null, to show empty field to user in editor
        if (this.formBuildingInput.propertyName === 'storageHandlingConditionCommentText') {
            if (this.formGroup.get('storageHandlingConditionCommentText').value === 'null') {
                this.formGroup.get('storageHandlingConditionCommentText').setValue(null);
            }
        }
        if (this.formBuildingInput.propertyName === 'criticalWarningCommentText') {
            if (this.formGroup.get('criticalWarningCommentText').value === 'null') {
                this.formGroup.get('criticalWarningCommentText').setValue(null);
            }
        }
    }
}
