<nz-layout class="app-layout">
    <nz-sider class="menu-sidebar"
              #sider
              nzCollapsible
              [nzCollapsedWidth]="40"
              [nzWidth]="216"
              [nzCollapsed]="isCollapsed"
              [nzTrigger]="null"
              >
            <div class="sidebar-logo">
<!--                <img class="sidebar-expanded-logo" (click)="goTo('/')" src="assets/manager-logo-dark-no.svg">-->
                <img class="{{getSidebarLogoImgClass()}}" (click)="goTo('/')" src="{{getSidebarLogo()}}">
            </div>
        <ul nz-menu nzMode="inline" class="list-of-links" nzType="dark">
            <!-- Menu links -->
            <li id="devicesMenuButton" nz-menu-item [routerLink]="['/devices']" [nzMatchRouter]="true" class="menu-item">
                <fa-icon [styles]="{'color':'#f2f2f2', 'width':'18px', 'height':'18px'}"
                         class="icon" nz-icon [icon]="faStethoscope"></fa-icon>
                <span>{{ 'LAYOUT.title' | translate }}</span>
            </li>
            <li *eudRole="[Authority.EDITOR]" id="importDevicesMenuButton" nz-menu-item [routerLink]="['/add-devices']" nz-menu-item [nzMatchRouter]="true" class="menu-item">
                <fa-icon [styles]="{'color':'#f2f2f2', 'width':'18px', 'height':'18px', 'margin-left':'0px'}"
                         class="icon" nz-icon [icon]="faPlus"></fa-icon>
                <span>{{ 'LAYOUT.import' | translate }}</span>
            </li>
            <li id="reportsMenuButton" nz-menu-item [routerLink]="['/report']" nz-menu-item [nzMatchRouter]="true" class="menu-item">
                <fa-icon [styles]="{'color':'#f2f2f2', 'width':'18px', 'height':'18px', 'margin-left':'0px'}"
                    class="icon" nz-icon [icon]="faFileDownload"></fa-icon>
                <span>{{ 'LAYOUT.reports' | translate }}</span>
            </li>
            <div class="version" *ngIf="!isCollapsed">
                {{ 'LAYOUT.version' | translate }} {{applicationVersion}}
                <a *eudRole="[Authority.ADMIN]" (click)="goTo('/admin')">
                  {{ 'LAYOUT.more' | translate }}
                </a>
            </div>
            <div class="privacy" *ngIf="!isCollapsed" style="bottom: 79px;">
                {{ getImpressum() }}
            </div>
            <div class="privacy" *ngIf="!isCollapsed" style="bottom: 21px;">
                <span style="font-weight:300;">
                    <ng-template #self [ngTemplateOutlet]="self"
                                 let-privacy="privacyLink" let-disclaimer="disclaimerLink"
                                 [ngTemplateOutletContext]="{
                                   privacyLink: ('LAYOUT.privacyLink' | translate),
                                   disclaimerLink : ('LAYOUT.disclaimerLink' | translate)
                                 }">
                        <span (click)="openInNewTab(disclaimer)" class="sidebar-links">
                            {{ 'LAYOUT.disclaimer' | translate }}
                        </span> |
                        <span (click)="openInNewTab(privacy)" class="sidebar-links">
                            {{ 'LAYOUT.privacy' | translate }}
                        </span>
                    </ng-template>
                </span>
            </div>
        </ul>
    </nz-sider>
    <nz-layout>
        <nz-header style="height:83px;" *ngIf="showHeader">
            <ng-content select=".app-header"></ng-content>
        </nz-header>
        <nz-content class="inner-content">
            <div *ngIf="!isCollapsed" id="overlay"></div>
            <ng-content select="[content]">
            </ng-content>
            <app-cookie-banner *ngIf="!necessaryCookieExists" [modalOpen]="!necessaryCookieExists"></app-cookie-banner>
        </nz-content>
    </nz-layout>
</nz-layout>
