import {DeviceConstants} from '.';
import { BUDIGroups } from './attribute-groups.enum';
import {parseSimpleField, parseSimpleArray, parseComplexField} from '../../util/device-to-details.util';
import FormBuildingInput from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {FormControlType} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-control-type.enum';
import {FormGroup, Validators} from '@angular/forms';
import {actorCodePattern, optionalWithRequired} from './validators/complex-validators.util';

export const SPPBASICDEVICE: Array<DeviceConstants & FormBuildingInput> = [
    {
        propertyName: 'deviceType',
        name: 'DEVICECONSTANTS.spp.basic.deviceType.name',
        description: 'DEVICECONSTANTS.spp.basic.deviceType.description',
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        validators: [Validators.required],
        placeholder: 'FLD-ATRIFY-01',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        hidden: true
    },
    {
        propertyName: 'basicUDIIdentifierDICode',
        name: 'DEVICECONSTANTS.spp.basic.basicUDIIdentifierDICode.name',
        description: 'DEVICECONSTANTS.spp.basic.basicUDIIdentifierDICode.description',
        controlType: FormControlType.INPUT,
        validators: [Validators.required, Validators.minLength(1), Validators.maxLength(120)],
        placeholder: 'FLD-UDID-14',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        hidden: true,
        style: {
            width: '50%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea120'
        }
    },
    {
        propertyName: 'basicUDIIdentifierIssuingEntityCode',
        name: 'DEVICECONSTANTS.spp.basic.basicUDIIdentifierIssuingEntityCode.name',
        description: 'DEVICECONSTANTS.spp.basic.basicUDIIdentifierIssuingEntityCode.description',
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.DROPDOWN,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-01',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        hidden: true
    },
    {
        propertyName: 'customerMessageID',
        name: 'DEVICECONSTANTS.spp.basic.customerMessageID.name',
        description: 'DEVICECONSTANTS.spp.basic.customerMessageID.description',
        parsingFunction: parseSimpleField,
        placeholder: 'FLD-ATRIFY-02',
        controlType: FormControlType.INPUT,
        group: BUDIGroups.KEYDATA,
        hidden: true
    },
    {
        propertyName: 'customerTransactionID',
        name: 'DEVICECONSTANTS.spp.basic.customerTransactionID.name',
        description: 'DEVICECONSTANTS.spp.basic.customerTransactionID.description',
        parsingFunction: parseSimpleField,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-ATRIFY-03',
        group: BUDIGroups.KEYDATA,
        hidden: true
    },
    {
        propertyName: 'riskClass',
        name: 'DEVICECONSTANTS.spp.basic.riskClass.name',
        description: 'DEVICECONSTANTS.spp.basic.riskClass.description',
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-16',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA
    },
    {
        propertyName: 'model',
        name: 'DEVICECONSTANTS.spp.basic.model.name',
        description: 'DEVICECONSTANTS.spp.basic.model.description',
        updateable: true,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-20',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        hidden: (form: FormGroup) => {
            if (form.get('modelName')?.get('modelNameTerm')?.value == null || form.get('modelName')?.get('modelNameTerm')?.value === ''){
                return true;
            }else{
                return false;
            }
        },
        style: {
            width: '50%',
            marginBottom: '48px'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea255'
        },
        validators: [Validators.minLength(1), Validators.maxLength(255)],
    },
    {
        propertyName: 'modelName',
        name: 'DEVICECONSTANTS.spp.basic.modelName.name',
        parsingFunction: parseComplexField,
        validators: [optionalWithRequired(['modelNameTerm'])],
        controlType: FormControlType.NON_SHOWING,
        placeholder: 'FLD-EUD-017',
        group: BUDIGroups.KEYDATA,
        children: [
            {
                propertyName: 'modelNameModel',
                name: 'DEVICECONSTANTS.spp.basic.modelNameModel.name',
                description: 'DEVICECONSTANTS.spp.basic.modelNameModel.description',
                controlType: FormControlType.INPUT,
                updateable: true,
                placeholder: 'FLD-UDID-20',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                style: {
                    width: '47%',
                    'margin-right': '24px'
                },
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea255'
                },
                validators: [Validators.minLength(1), Validators.maxLength(255)],
            },
            {
                propertyName: 'modelNameTerm',
                name: 'DEVICECONSTANTS.spp.basic.modelNameTerm.name',
                controlType: FormControlType.INPUT,
                updateable: true,
                placeholder: 'FLD-UDID-22',
                description: 'DEVICECONSTANTS.spp.basic.modelNameTerm.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                style: {
                    width: '47%'
                },
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea255'
                },
                validators: [Validators.minLength(1), Validators.maxLength(255)],
            }
        ],
        hidden: (form: FormGroup) => {
            if (form.get('model')?.value == null || form.get('model')?.value === ''){
                return true;
            }else{
                return false;
            }
        }
    },
    {
        propertyName: 'PRActorCode',
        name: 'DEVICECONSTANTS.spp.basic.PRActorCode.name',
        description: 'DEVICECONSTANTS.spp.basic.PRActorCode.description',
        errorMessage: {
            required: 'ERRORS.notFilled',
            pattern: 'ERRORS.actorCode'
        },
        validators: [Validators.required, Validators.pattern(actorCodePattern)],
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-44',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        hidden: true,
        style: {
            width: '50%'
        }
    },
    {
        propertyName: 'applicableLegislation',
        name: 'DEVICECONSTANTS.spp.basic.applicableLegislation.name',
        description: 'DEVICECONSTANTS.spp.basic.applicableLegislation.description',
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.DROPDOWN,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-11',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        hidden: true
    },
    {
        propertyName: 'typeOfSystemOrProcedurePack',
        name: 'DEVICECONSTANTS.spp.basic.typeOfSystemOrProcedurePack.name',
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        controlType: FormControlType.DROPDOWN,
        jsonPath: '$.typeOfSystemOrProcedurePack',
        placeholder: 'FLD-UDID-261',
        description: 'DEVICECONSTANTS.spp.basic.typeOfSystemOrProcedurePack.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'medicinalPurpose',
        name: 'DEVICECONSTANTS.spp.basic.medicinalPurpose.name',
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.REPEATABLE,
        placeholder: 'FLD-EUD-017',
        parsingFunction: parseSimpleArray,
        children: [
            {
                propertyName: 'medicinalPurposeText',
                name: 'DEVICECONSTANTS.spp.basic.medicinalPurposeText.name',
                description: 'DEVICECONSTANTS.spp.basic.medicinalPurposeText.description',
                validators: [Validators.required, Validators.minLength(1), Validators.maxLength(2000)],
                updateable: true,
                jsonPath: '$.medicinalPurpose[*].medicinalPurposeText',
                controlType: FormControlType.TEXTAREA,
                textareaRows: 6,
                placeholder: 'FLD-UDID-260',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            },
            {
                propertyName: 'medicinalPurposeLanguage',
                name: 'DEVICECONSTANTS.spp.basic.medicinalPurposeLanguage.name',
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                validators: [Validators.required],
                jsonPath: '$.medicinalPurpose[*].medicinalPurposeLanguage',
                placeholder: 'FLD-EUD-010',
                description: 'DEVICECONSTANTS.spp.basic.medicinalPurposeLanguage.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA
            }
        ]
    },
];
