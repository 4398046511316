import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {COOKIE_DURATION, EudamedCookie} from '../constants/cookie.constants';

@Injectable({
  providedIn: 'root'
})
export class EudamedCookieService {

  constructor(private cookieService: CookieService) {}

  getCookie = (name: string) => {
    return this.cookieService.check(name);
  }

  analyticsEnabled = () => {
    return this.cookieService.get(EudamedCookie.ANALYTICS) === 'true';
  }

  createCookie = (name: string, value: boolean) => {
    this.cookieService.delete(name);
    this.cookieService.set(name, value.toString(), COOKIE_DURATION, '/');
  }

  deleteCookie = (name: string) => {
    this.cookieService.delete(name, '/');
  };

  getLanguageCookie = () => {
    return this.cookieService.get(EudamedCookie.LANGUAGE);
  }

  changeLanguageCookie = (selectedLanguage: string) => {
    this.cookieService.delete(EudamedCookie.LANGUAGE);
    this.cookieService.set(EudamedCookie.LANGUAGE, selectedLanguage, COOKIE_DURATION, '/');
  }

}
