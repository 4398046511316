import STATUS_UI_MAPPINGS from '../constants/status-ui.mappings.constants';
import DeviceColumn from '../model/device-column.enum';

function nameToString(device: any): string{
    if (device.tradeName){
        // Gets the trade name object with the lowest tradeNameSequenceNumber
        const res = device.tradeName.reduce((prev: any, curr: any) => {
            return prev.tradeNameSequenceNumber < curr.tradeNameSequenceNumber ? prev : curr;
        });
        return res.tradeNameText;
    } else {
        if (device.model){
            return device.model;
        } else {
            return device.modelName?.modelNameTerm || '';
        }
    }
}

function internalIdToString(device: any): string {
    if (device.model){
        return device.model;
    } else {
        return device.referenceNumber || '';
    }
}

function publicIdToString(device: any): string {
    if (device.basicUDIIdentifierDICode){
       return device.basicUDIIdentifierDICode;
    } else {
        return device.UDIDIIdentifierDICode;
    }
}

function riskClassToString(device: any): string {
    return device.riskClass || '';
}

function typeToString(device: any): string {
    switch (device.deviceType){
         case 'MDRBASICDEVICE':
             return 'MDR Basic UDI';
         case 'IVDRBASICDEVICE':
             return 'IVDR Basic UDI';
         case 'SPPBASICDEVICE':
             return 'SPP Basic UDI';
         case 'IVDEUDIBASICDEVICE':
             return 'IVD Basic UDI';
         case 'ADEUDIBASICDEVICE':
             return 'AIMDD Basic UDI';
         case 'MDEUDIBASICDEVICE':
             return 'MDD Basic UDI';
         case 'MDRUDIDEVICE':
             return 'MDR UDI-DI';
         case 'IVDRUDIDEVICE':
             return 'IVDR UDI-DI';
         case 'SPPUDIDEVICE':
             return 'SPP UDI-DI';
         case 'IVDEUDIUDIDEVICE':
             return 'IVD UDI-DI';
         case 'ADEUDIUDIDEVICE':
             return 'AIMDD UDI-DI';
         case 'MDEUDIUDIDEVICE':
             return 'MDD UDI-DI';
         case 'LINKMDR':
             return 'MDR LINK';
         case 'LINKIVDR':
             return 'IVDR LINK';
         case 'LINKSPP':
             return 'SPP LINK';
         case 'REGISTERMDR':
             return 'MDR REGISTER';
         case 'REGISTERIVDR':
             return 'IVDR REGISTER';
         case 'REGISTERSPP':
             return 'SPP REGISTER';
         case 'PACKLINKMDR':
             return 'MDR Packaging Link';
         case 'PACKLINKIVDR':
             return 'IVDR Packaging Link';
         case 'PACKLINKSPP':
             return 'SPP Packaging Link';

         default:
             return '';
    }
}

function srnToString(device: any): string{
    return device.MFActorCode || device.PRActorCode || '';
}

function statusToString(device: any): string{
    if (device.metadata?.registerStatus){
        if (STATUS_UI_MAPPINGS[device.metadata.registerStatus]){
            return STATUS_UI_MAPPINGS[device.metadata.registerStatus];
        }else{
            return device.metadata.registerStatus;
        }
    }else if (device.metadata?.uiStatus){
        return device.metadata.uiStatus;
    }else{
        return '';
    }
}

function lastMessagedToString(device: any): Date | null{
    try{
        const d = new Date(Date.parse(device.metadata?.lastModifiedAt));
        if (d.toString() === 'Invalid Date'){
            throw new Error();
        }
        return d;
    } catch {
        return null;
    }
}

function lastMessageIDToString(device: any): string{
    return device.metadata?.messageRequestId.requestId || '';
}

const DeviceFieldToStringUtil: {[key in DeviceColumn]: any} = {
    [DeviceColumn.Name]: nameToString,
    [DeviceColumn.SRN]: srnToString,
    [DeviceColumn.Type]: typeToString,
    [DeviceColumn.Status]: statusToString,
    [DeviceColumn.PublicID]: publicIdToString,
    [DeviceColumn.InternalID]: internalIdToString,
    [DeviceColumn.RiskClass]: riskClassToString,
    [DeviceColumn.LastMessaged]: lastMessagedToString,
    [DeviceColumn.LastMessageID]: lastMessageIDToString,
};

export default DeviceFieldToStringUtil;
