import {Component} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseFormFieldType} from '../base-form-field-type.class';

@Component({
    selector: 'datetime-render',
    templateUrl: './datetime-render.component.html',
    styleUrls: ['./datetime-render.component.scss']
})
export class DatetimeRenderingComponent extends BaseFormFieldType {
    optionalClass = ['non-empty-input', 'label'];
    Validators = Validators;
}
