import {Component, forwardRef, Input} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseFormFieldType} from '../base-form-field-type.class';
import {RootFormComponent} from '../root-form-component/root-form.component';

@Component({
    selector: 'basic-async-render',
    templateUrl: './basic-async-select.component.html',
    styleUrls: ['./basic-async-select.component.scss'],
    providers: [{ provide: RootFormComponent , useExisting: forwardRef(() => RootFormComponent) }],
})
export class BasicAsyncRenderingComponent extends BaseFormFieldType {
    @Input()
    editingObject: any;

    optionalClass = ['non-empty-input', 'label'];
    Validators = Validators;
}
