import {Injector} from '@angular/core';
import {AbstractControl, FormArray} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Codelist} from '../../codelists.constants';

export function conditionalDescriptionWithLanguage(injector: Injector, control: AbstractControl, mainControlName: string, languageControlName: string, descriptionControlName: string, codeList: Codelist): void {
  const translationService = injector.get<TranslateService>(TranslateService);

  const mainControl = control.parent?.get(mainControlName);
  const conditionType = control.value;

  if (conditionType) {
    const labelValuePair = codeList.values.find((val) => val.value === conditionType);
    if (labelValuePair) {
      const translatedLabel = translationService.instant(labelValuePair.label);
      if (translatedLabel.includes('**')) {
        if (mainControl && mainControl instanceof FormArray) {
          mainControl.controls.forEach(x => {
            const language = x.get(languageControlName);
            if (language) {
              if (language.value === 'ANY') {
                language.setValue(undefined);
              }
              language.enable();
            }
            const comment = x.get(descriptionControlName);
            if (comment) {
              comment.asterisk = true;
            }
          });
        }
      } else if (translatedLabel.includes('*') && (translatedLabel.indexOf('*') === translatedLabel.lastIndexOf('*'))) {
        if (mainControl && mainControl instanceof FormArray) {
          mainControl.controls.forEach(x => {
            const language = x.get(languageControlName);
            if (language) {
              language.setValue('ANY');
              language.disable();
            }
            const comment = x.get(descriptionControlName);
            if (comment) {
              comment.asterisk = true;
            }
          });
        }
      } else {
        if (mainControl && mainControl instanceof FormArray) {
          mainControl.controls.forEach(x => {
            const language = x.get(languageControlName);
            if (language) {
              language.setValue('ANY');
              language.disable();
            }
            const comment = x.get(descriptionControlName);
            if (comment) {
              comment.asterisk = false;
            }
          });
        }
      }
    }
  }
}
