export default class User {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    roles: Array<string>;
    temporaryPassword?: string;
    mfActorCodes: string[];
    prActorCodes: string[];

    constructor(
        user: Partial<User>
    ){
        this.firstName = user.firstName || '';
        this.lastName = user.lastName || '';
        this.username = user.username || '';
        this.email = user.email || '';
        this.roles = user.roles || [];
        this.roles.push('viewer');
        this.temporaryPassword = user.temporaryPassword || '';
        this.mfActorCodes = user.mfActorCodes || [];
        this.prActorCodes = user.prActorCodes || [];
    }
}


export interface ActorCode {
    typeOfActorCode: TypeOfActorCode;
    actorCode: string;
    isActorCodeLinked?: boolean;
}

export enum TypeOfActorCode {
    PR= 'PR',
    MF= 'MF'
}
