<ng-container [formGroup]="formGroup">
    <nz-form-item
        [style]="formBuildingInput.style? formBuildingInput.style : 'flex-basis:100%;'"
        >
        <nz-form-control>
            <app-eudamed-basic-select 
                [label]="formBuildingInput.name"
                [placeholder]="formBuildingInput.placeholder || ''  | translate"
                [editingDevice]="editingObject"
                [formControlName]="formBuildingInput.propertyName">
            </app-eudamed-basic-select>
        </nz-form-control>
    </nz-form-item>
</ng-container>
