import {Directive, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import FormBuildingInput from '../form-interfaces/form-building-input.interface';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class BaseFormFieldType implements OnInit {
    @Input()
    formGroup: any;

    @Input()
    formBuildingInput: FormBuildingInput;

    ngOnInit(): void {
        if (this.formBuildingInput.placeholder?.includes('FLD-UDID-252')){
            this.formGroup.controls.marketInfoOriginalPlacedOnTheMarket.enable();
            this.formGroup.controls.marketInfoMadeAvailableCountry.enable();
            this.formGroup.controls.marketInfoMadeAvailableEndDate.enable();
            this.formGroup.controls.marketInfoMadeAvailableStartDate.enable();
        }
        if (this.formBuildingInput.placeholder?.includes('FLD-UDID-137')){
            this.formGroup.controls.marketInfoPlacingOnTheEUCountry.enable();
            this.formGroup.controls.marketInfoPlacingOnTheEUEndDate.enable();
            this.formGroup.controls.marketInfoPlacingOnTheEUStartDate.enable();
        }
    }

    getByName(currentFormGroup: AbstractControl, name: string): any{
        return currentFormGroup.get(name) as FormGroup;
    }
}
