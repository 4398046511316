<div [@openClose]="isOpen ? 'open' : 'closed'" (@openClose.done)="animEnd($event)" class="filter-component" >
    <div style="margin:16px;">
        <span style="cursor:pointer; margin:8px; float:right;" (click)="triggerExitAnimation()">
            {{ 'DEVICES.close' | translate }}
            <i
                style="margin-left:4px;margin-top:4px;"
                nzTooltipTitle="{{ 'DEVICES.close' | translate }}"
                nz-tooltip
                nz-icon>
                <fa-icon [icon]="faTimes" style="margin-bottom: -4px; font-size: 1.125rem;"></fa-icon>
            </i>
        </span>
        <div nz-row style="margin:8px;" nzJustify="space-between">
            <h2>{{ 'DEVICES.filters.add' | translate }}</h2>
        </div>
        <div nz-row nzJustify="center">
            <div nz-col >
                <div style="width:100%" nz-row *ngIf="filters.length === 0">
                    <p>{{ 'DEVICES.filters.noFilter' | translate }}</p>
                </div>
                <div *ngFor="let filter of filters; let i = index" nz-row>
                    <div style="width:100%;" *ngIf="filters.length > 1 && i != 0">
                        <nz-divider nzText="{{ 'DEVICES.filters.and' | translate }}"></nz-divider>
                    </div>
                    <ng-container *ngIf="!filter.isSearch">
                        <div style="width:100%">
                            <nz-radio-group (ngModelChange)="filter.isApplied = false;" nz-row nzJustify="space-between" style="margin-bottom:16px;" ngSize="large" [(ngModel)]="filter.include">
                                <label nz-radio [nzValue]="true">{{ 'DEVICES.filters.include' | translate }}</label>
                                <label nz-radio [nzValue]="false">{{ 'DEVICES.filters.exclude' | translate }}</label>
                            </nz-radio-group>
                            <fa-icon
                                [styles]="{ cursor: 'pointer', 'font-size': '1rem' }"
                                nz-icon
                                [icon]="faTrash"
                                (click)="removeFilter(filter)"
                                style="float: right;"
                            ></fa-icon>
                            <div style="clear: both;"></div>
                        </div>
                        <div nz-row style="width:100%; margin-bottom: 16px;">
                            <nz-select (ngModelChange)="fieldChanged($event, filter)" [(ngModel)]="filter.field" style="width:100%">
                                <nz-option *ngFor="let deviceField of columnFilterOptions | keyvalue" [nzValue]="deviceField.key" [nzLabel]="deviceField.value.name | translate"></nz-option>
                            </nz-select>
                        </div>
                        <div nz-row style="width:100%; margin-bottom:16px;">
                            <nz-select (ngModelChange)="filter.isApplied = false;" *ngIf="columnFilterOptions[filter.field].type === FilterInputType.Input; else selectFilter" [(ngModel)]="filter.typeOfFilter" style="width:100%;">
                                <nz-option [nzValue]="FilterType.Contains" nzLabel="{{ 'DEVICES.filters.contains' | translate }}"></nz-option>
                                <nz-option [nzValue]="FilterType.StartsWith" nzLabel="{{ 'DEVICES.filters.starts' | translate }}"></nz-option>
                                <nz-option [nzValue]="FilterType.EndsWith" nzLabel="{{ 'DEVICES.filters.ends' | translate }}"></nz-option>
                            </nz-select>
                            <ng-template #selectFilter>
                                <nz-select style="width:100%" [(ngModel)]="filter.typeOfFilter" [nzDisabled]="true">
                                    <nz-option [nzValue]="FilterType.Contains" nzLabel="{{ 'DEVICES.filters.contains' | translate }}"></nz-option>
                                </nz-select>
                            </ng-template>
                        </div>
                        <input (ngModelChange)="filter.isApplied = false;" *ngIf="columnFilterOptions[filter.field].type === FilterInputType.Input" nz-input style="margin-bottom:16px;" placeholder="{{ 'DEVICES.filters.inputPlaceholder' | translate }}" [(ngModel)]="filter.value" />
                        <nz-select (ngModelChange)="filter.isApplied = false;" nzPlaceHolder="{{ 'DEVICES.filters.selectPlaceholder' | translate }}" *ngIf="columnFilterOptions[filter.field].type === FilterInputType.Select"  [(ngModel)]="filter.value" style="margin-bottom:16px; width:100%">
                            <nz-option *ngFor="let option of columnFilterOptions[filter.field].options" [nzValue]="option.value" [nzLabel]="filter.field === 'status' ? ('STATUS.'+option.label | translate) : (filter.field === 'relatedlegacy' ? (option.label | translate) : option.label)"></nz-option>
                        </nz-select>
                    </ng-container>
                </div>
            </div>
        </div>
        <div style="width: 100%;">
          <button style="width: 100%; margin-bottom: 16px; margin-top: 20px; overflow-wrap: break-word; height: fit-content;" (click)="addFilter()" nzType="default" nz-button>
            <fa-icon [ngStyle]="{'font-size': '1rem'}"
                     nz-icon
                     [icon]="faPlus">
            </fa-icon>
            <span style="font-weight: 600;">
              {{ 'DEVICES.filters.add' | translate }}
            </span>
          </button>
        </div>
        <div nz-row nzJustify="space-between" style="width: 100%;">
          <a class="cancel-button" (click)="triggerExitAnimation()">{{ 'DEVICES.filters.cancel' | translate }}</a>
          <button style="width: 50%; overflow-wrap: break-word; height: fit-content; font-weight: 600;" (click)="applyFilters()" nzType="primary" nz-button>{{ 'DEVICES.filters.apply' | translate }}</button>
        </div>
    </div>
</div>
