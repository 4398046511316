import {ActorCodeType} from '../../pages/account/token-management/token.model';

export default class SimplifiedDevice {
  diCode: string;
  issuingEntityCode: string;
  deviceType: string;
  actorCode: string;
  actorCodeType: ActorCodeType;

  constructor(diCode: string, issuingEntityCode: string, deviceType: string, actorCode: string, actorCodeType: ActorCodeType) {
    this.diCode = diCode;
    this.issuingEntityCode = issuingEntityCode;
    this.deviceType = deviceType;
    this.actorCode = actorCode;
    this.actorCodeType = actorCodeType;
  }
}
