import {Injectable, Injector} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import FormBuildingInput from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {FormControlType} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-control-type.enum';
import {ValidationService} from '../validation.service';

interface ControlWithName {
    name: string;
    control: AbstractControl;
}

@Injectable()
export class FormService {

    private updating: boolean;

    private afterFormCreatedQueue: Array<() => void> = [];

    constructor(private validationService: ValidationService, private injector: Injector) {}

    generateFormGroupFromBuilderData(formBuildingData: FormBuildingInput[], updating: boolean): {[key: string]: AbstractControl} {
        this.updating = updating;
        const controls: {[key: string]: AbstractControl} = {};
        formBuildingData.forEach((formControlData: FormBuildingInput) => {
            const control = this.formBuildingToAbstractControl(formControlData);
            control.forEach((cntrl: any) => {
                controls[cntrl.name] = cntrl.control;
            });
        });
        return controls;
    }

    formBuildingToAbstractControl(formControlData: FormBuildingInput): ControlWithName[] {
        if (formControlData.controlType === FormControlType.REPEATABLE && formControlData.children && formControlData.children?.length !== 0) {
            return this.repeatableBuildingToAbstractControl(formControlData);
        } else if (formControlData.controlType === FormControlType.NON_SHOWING) {
            return this.nonShowingBuildingToAbstractControl(formControlData);
        } else if (formControlData.controlType === FormControlType.DROPDOWN) {
            return this.dropdownBuildingToAbstractControl(formControlData);
        } else if (formControlData.controlType === FormControlType.SEARCHABLE_DROPDOWN) {
            return this.searchableDropdownBuildingToAbstractControl(formControlData);
        } else if (formControlData.controlType === FormControlType.SEARCHABLE_MULTISELECT) {
            return this.searchableMultiselectDropdownBuildingToAbstractControl(formControlData);
        } else if (formControlData.controlType === FormControlType.MDN_SELECT) {
            return this.mdnBuildingToAbstractControl(formControlData);
        } else if (formControlData.controlType === FormControlType.BASIC_ASYNC_SELECT) {
            return this.basicAsyncSelectToAbstractControl(formControlData);
        } else if (formControlData.controlType === FormControlType.CHECKBOX) {
            // TODO: Remove initialValue from the constants. And add here this !== undefined check to every formcontrol type
            const contrl = new FormControl({value: formControlData.initialValue !== undefined ? formControlData.initialValue : null, disabled: formControlData.disabled}, formControlData.validators);
            this.checkIfRequiredAndCount(contrl, formControlData);

            this.bindOnChangeToAbstractControl(contrl, formControlData);
            this.disableIfUpdatingAndNonUpdateable(contrl, formControlData);
            contrl.updating = this.updating;

            contrl.hidden = formControlData.hidden || false;

            return [{
                name: formControlData.propertyName,
                control: contrl,
            }];
        } else {
            const contrl = new FormControl({value: formControlData.initialValue ? formControlData.initialValue : null, disabled: formControlData.disabled}, formControlData.validators);
            this.checkIfRequiredAndCount(contrl, formControlData);

            this.bindOnChangeToAbstractControl(contrl, formControlData);
            this.disableIfUpdatingAndNonUpdateable(contrl, formControlData);
            contrl.updating = this.updating;

            contrl.hidden = formControlData.hidden || false;

            return [{
                name: formControlData.propertyName,
                control: contrl,
            }];
        }
    }


    cloneAbstractControl<T extends AbstractControl>(control: T): T {
        let newControl: T;

        if (control instanceof FormGroup) {
            const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
            const controls = control.controls;

            Object.keys(controls).forEach(key => {
                formGroup.addControl(key, this.cloneAbstractControl(controls[key]));
            });

            newControl = formGroup as any;
        }
        else if (control instanceof FormArray) {
            const formArray = new FormArray([], control.validator, control.asyncValidator);

            formArray.push(this.cloneAbstractControl(control.at(0)));

            newControl = formArray as any;
        }
        else if (control instanceof FormControl) {
            newControl = new FormControl(control.disabled ? control.value : null, control.validator, control.asyncValidator) as any;
        }

        else {
            throw new Error('Error: unexpected control value');
        }

        if (control.disabled) {
            newControl.disable({emitEvent: false});
        }

        if (control.onChangeFunction){
            newControl.valueChanges.subscribe(() => {
                control.onChangeFunction(newControl);
            });
        }

        newControl.hidden = control.hidden;

        return newControl;
    }

    dropdownBuildingToAbstractControl(formControlData: FormBuildingInput): ControlWithName[] {
        const contrl = new FormControl({value: formControlData.initialValue ? formControlData.initialValue : null, disabled: formControlData.disabled}, formControlData.validators);
        this.checkIfRequiredAndCount(contrl, formControlData);

        contrl.hidden = formControlData.hidden || false;
        contrl.updating = this.updating;
        this.bindOnChangeToAbstractControl(contrl, formControlData);
        this.disableIfUpdatingAndNonUpdateable(contrl, formControlData);


        return [{
          name: formControlData.propertyName,
          control: contrl,
        }];
    }

    checkIfRequiredAndCount(cntrl: AbstractControl, formControlData: FormBuildingInput): void {
        if (formControlData.validators?.includes(Validators.required)){
            this.validationService.addOneToTotalNumberOfMandatories();
            if (cntrl.status === 'VALID' || cntrl.status === 'DISABLED'){
                this.validationService.addOneValidMandatoryField(cntrl);
            }
            cntrl.statusChanges.subscribe((status) => {
                if (status === 'VALID' || status === 'DISABLED'){
                    this.validationService.addOneValidMandatoryField(cntrl);
                }else{
                    this.validationService.removeMandatoryFieldAsValid(cntrl);
                }
            });
        }
    }

    nonShowingBuildingToAbstractControl(formControlData: FormBuildingInput): ControlWithName[] {
        const nonShowingFormGroup = new FormGroup({}, formControlData.validators || []);

        formControlData.children.forEach((child: FormBuildingInput) => {
            const oneProcessedChild = this.formBuildingToAbstractControl(child);
            oneProcessedChild.forEach((processedChild) => {
                nonShowingFormGroup.addControl(processedChild.name, processedChild.control);
            });
        });

        this.bindOnChangeToAbstractControl(nonShowingFormGroup, formControlData);

        nonShowingFormGroup.hidden = formControlData.hidden || false;
        nonShowingFormGroup.updating = this.updating;


        return [{
            name: formControlData.propertyName,
            control: nonShowingFormGroup
        }];
    }

    repeatableBuildingToAbstractControl(formControlData: FormBuildingInput): ControlWithName[] {
        const allChildControls: Array<ControlWithName> = [];

        formControlData.children.forEach((child: FormBuildingInput) => {
            allChildControls.push(...this.formBuildingToAbstractControl(child));
        });

        const objectForFormGroup: {[key: string]: AbstractControl} = {};

        allChildControls.forEach((control: ControlWithName) => {
            objectForFormGroup[control.name] = control.control;
        });

        const formGroup = new FormGroup(objectForFormGroup, formControlData.validators || []);

        formGroup.hidden = formControlData.hidden || false;
        formGroup.updating = this.updating;

        const formArray = new FormArray([formGroup]);

        this.bindOnChangeToAbstractControl(formArray, formControlData);

        formArray.hidden = formControlData.hidden || false;
        formArray.updating = this.updating;

        return [{
            name: formControlData.propertyName,
            control: formArray,
        }];
    }

    searchableDropdownBuildingToAbstractControl(formControlData: FormBuildingInput): ControlWithName[] {
        const contrl = new FormControl({value: formControlData.initialValue ? formControlData.initialValue : null, disabled: formControlData.disabled}, formControlData.validators);
        this.checkIfRequiredAndCount(contrl, formControlData);

        contrl.hidden = formControlData.hidden || false;
        contrl.updating = this.updating;
        this.bindOnChangeToAbstractControl(contrl, formControlData);
        this.disableIfUpdatingAndNonUpdateable(contrl, formControlData);

        return [{
            name: formControlData.propertyName,
            control: contrl,
        }];
    }


    searchableMultiselectDropdownBuildingToAbstractControl(formControlData: FormBuildingInput): ControlWithName[] {
        const contrl = new FormControl({value: formControlData.initialValue ? formControlData.initialValue : null, disabled: formControlData.disabled}, formControlData.validators);
        this.checkIfRequiredAndCount(contrl, formControlData);

        this.bindOnChangeToAbstractControl(contrl, formControlData);
        this.disableIfUpdatingAndNonUpdateable(contrl, formControlData);

        contrl.hidden = formControlData.hidden || false;
        contrl.updating = this.updating;

        return [{
            name: formControlData.propertyName,
            control: contrl,
        }];
    }

    mdnBuildingToAbstractControl(formControlData: FormBuildingInput): ControlWithName[] {
        const contrl = new FormControl({value: formControlData.initialValue ? formControlData.initialValue : null, disabled: formControlData.disabled}, formControlData.validators);
        this.checkIfRequiredAndCount(contrl, formControlData);


        this.bindOnChangeToAbstractControl(contrl, formControlData);
        this.disableIfUpdatingAndNonUpdateable(contrl, formControlData);
        contrl.hidden = formControlData.hidden || false;
        contrl.updating = this.updating;

        return [{
            name: formControlData.propertyName,
            control: contrl
        }];
    }

    basicAsyncSelectToAbstractControl(formControlData: FormBuildingInput): ControlWithName[] {
        const contrl = new FormControl({value: formControlData.initialValue ? formControlData.initialValue : null, disabled: formControlData.disabled}, formControlData.validators);
        this.checkIfRequiredAndCount(contrl, formControlData);

        this.bindOnChangeToAbstractControl(contrl, formControlData);
        this.disableIfUpdatingAndNonUpdateable(contrl, formControlData);

        contrl.hidden = formControlData.hidden || false;
        contrl.updating = this.updating;

        return [{
            name: formControlData.propertyName,
            control: contrl
        }];
    }


    private bindOnChangeToAbstractControl(cntrl: AbstractControl, formData: FormBuildingInput): void{
        if (formData.errorMessage) {
          cntrl.errorMessage = formData.errorMessage;
        }
        if (formData.onChange){
            cntrl.onChangeFunction = (control: AbstractControl) => {
                if (formData.onChange){
                    formData.onChange(control, formData, this.injector);
                }
            };
            cntrl.valueChanges.subscribe(() => {
                if (formData.onChange){
                    formData.onChange(cntrl, formData, this.injector);
                }
            });
        }
    }

    private disableIfUpdatingAndNonUpdateable(control: AbstractControl, formData: FormBuildingInput): void{
        control.updateable = formData.updateable !== undefined && formData.updateable === true;
        if ((this.updating && formData.updateable !== true) && formData.propertyName !== 'marketInfoPlacingOnTheEUCountry'){
            const disableCallback = () => {
                if (control.value !== null){
                    control.disable();
                }
            };
            this.afterFormCreatedQueue.push(disableCallback);
        }
    }

    public flushQueue(): void{
        this.afterFormCreatedQueue.forEach((callback) => {
            callback();
        });
        this.afterFormCreatedQueue = [];
    }
}
