import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {NotificationService} from './notifications/notification.service';

@Injectable()
export class ApiService {

    public templateRefForNotifications: TemplateRef<any>;

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService,
    ){}

    errorHandler(error: HttpErrorResponse): ObservableInput<any> {
       if (error.status > 404) {
        const cookieNotif = this.notificationService.showCustomNotification({
            nzDuration: 5000,
            nzStyle: {backgroundColor: '#243746', width: '600px'},
            nzPlacement: 'bottomRight',
            nzData: {
                style: { margin: '16px'},
                content: {
                    title: 'Lost server connection',
                    titleStyle: {fontWeight: 'bold', marginLeft: '32px', fontSize: '32px', color: 'white'},
                    titleIcon: 'warning',
                    iconStyle: {fontSize: '32px', color: 'white'},
                    text: 'The system currently has a communication problem. Please try again in a few moments or contact our support.',
                    contentStyle: {marginTop: '32px', fontSize: '17px', color: 'white'}
                },
                buttons: [
                    {
                        text: 'Got it!',
                        // tslint:disable-next-line: variable-name
                        onClick: () => {
                            this.notificationService.deleteNotification(cookieNotif.messageId);
                        },
                        size: 'large',
                        style: { width: '30%', marginTop: '32px', backgroundColor: 'white', color: '#0071BD', fontWeight: 'bold', borderRadius: '6px'}
                    }
                ]
            }
        });
       }

       return throwError(error);
    }

    get(path: string, options: any = {}): Observable <any> {
        return this.http.get(path, options).pipe(catchError((error: HttpErrorResponse): ObservableInput<any> => {
            return this.errorHandler(error);
        }));
    }

    put(path: string, body: object = {}, headers: any = {}, options: any = {}): Observable<any> {
        return this.http.put(
            path,
            JSON.stringify(body),
          {
              headers,
              ...options
          }
        ).pipe(catchError((error: HttpErrorResponse): ObservableInput<any> => {
            return this.errorHandler(error);
        }));
    }

    post(path: string, body: object = {}, headers: any = {}, options: any = {}): Observable<any> {
        return this.http.post(
            path,
            JSON.stringify(body),
            {
                headers,
                ...options
            }
        ).pipe(catchError((error: HttpErrorResponse): ObservableInput<any> => {
            return this.errorHandler(error);
        }));
    }

    delete(path: string, body: object = {}, headers: any = {}, options: any = {}): Observable<any> {
        const jsonBody = JSON.stringify(body);
        return this.http.delete(
            path,
            {
              headers,
              body: jsonBody,
              ...options
            }
        ).pipe(catchError((error: HttpErrorResponse): ObservableInput<any> => {
            return this.errorHandler(error);
        }));
    }
}
