<div class="cookie-banner-content">
  <nz-modal
    [(nzVisible)]="modalOpen"
    [nzTitle]="modalTitle"
    [nzClosable]="false"
    [nzContent]="modalContent"
    [nzFooter]="null"
    [nzWidth]="620"
  >
    <ng-template #modalTitle>
      <h2><fa-icon [icon]="icon" class="alignedIcon"></fa-icon> {{ 'COOKIE.title' | translate }}</h2>
    </ng-template>

    <ng-template #modalContent>
      <p>
        <ng-template #self [ngTemplateOutlet]="self"
                     let-privacy="privacyLink"
                     [ngTemplateOutletContext]="{ privacyLink: ('COOKIE.privacyLink' | translate) }">
          <span [innerHTML]="'COOKIE.disclaimer' | translate"></span>
          <a href="{{ 'COOKIE.privacyLinkNew' | translate }}" target="_blank">
            {{ 'COOKIE.privacy' | translate }}
          </a>
        </ng-template>
      </p>

      <div *ngIf="!showOptions" style="float: right;">
        <button nz-button nzType="default" style="margin-right: 12px;" (click)="showOptions = !showOptions">
          {{ 'COOKIE.cookieSettings' | translate }}
        </button>
        <button nz-button nzType="primary" (click)="allowAllCookies()">
          {{ 'COOKIE.allowAll' | translate }}
        </button>
      </div>
      <div style="clear: both;"></div>
      <div *ngIf="showOptions">
        <p>
          {{ 'COOKIE.choice' | translate }}
        </p>
        <div class="cookie-info" style="border-top: 1px solid #404248;">
          <div class="cookie-name" style="float: left;">{{ 'COOKIE.necessary' | translate }}</div>
          <div class="cookie-action" style="float: right;">
            <nz-switch [(ngModel)]="necessaryCookie" [nzDisabled]="true" nzSize="small"></nz-switch>&nbsp;&nbsp;{{ 'COOKIE.on' | translate }}
          </div>
          <div style="clear: both;"></div>
        </div>
        <div class="cookie-info">
          <div class="cookie-name"><strong>{{ 'COOKIE.statistics.title' | translate }}</strong>{{ 'COOKIE.statistics.explanation' | translate }}</div>
          <div class="cookie-action">
            <nz-switch [(ngModel)]="analyticsCookie" nzSize="small"></nz-switch>&nbsp;&nbsp;{{ analyticsCookie ? ('COOKIE.on' | translate) : ('COOKIE.off' | translate) }}
          </div>
          <div style="clear: both;"></div>
        </div>
        <div class="footer">
          <button nz-button nzType="primary" (click)="handleOk()">{{ 'COOKIE.save' | translate }}</button>
        </div>
      </div>
    </ng-template>

  </nz-modal>
</div>
