<ng-container [formGroup]="formGroup">
    <nz-form-item
        [style]="formBuildingInput.style? formBuildingInput.style : 'flex-basis:100%;'"
        >
        <nz-form-control nzHasFeedback 
        [nzErrorTip]="(formBuildingInput.errorMessage | typeof) == 'string' ? (formBuildingInput.errorMessage | translate) : errorHandlingTemplate">
            <nz-input-group>
                <eudamed-number-input-wrapper [label]="(formBuildingInput.name | translate) + ((formBuildingInput.validators && formBuildingInput.validators.includes(Validators.required)) ? '*' : '')">
                    <nz-input-number
                        nzPopoverTrigger="focus"
                        nzPopoverPlacement="right"
                        [nzMax]="formBuildingInput.maxLength? formBuildingInput.maxLength : Infinity"
                        [nzMin]="formBuildingInput.minLength? formBuildingInput.minLength : -Infinity"
                        nz-popover
                        [nzPopoverContent]="popoverContent"
                        [nzInputMode]="formBuildingInput.decimal ? 'decimal' : 'numeric'"
                        [nzStep]="formBuildingInput.decimal ? 0.1 : 1"
                        [nzPrecision]="formBuildingInput.decimal ? 1 : 0"
                        [nzPopoverOverlayStyle]="{maxWidth:'200px'}"
                        nzPopoverColor="white"
                        style="width:100%;"
                        nz-input
                        [formControlName]="formBuildingInput.propertyName"
                        [nzPlaceHolder]="formBuildingInput.placeholder || '' | translate"
                        ></nz-input-number>
                </eudamed-number-input-wrapper>
            </nz-input-group>
        </nz-form-control>
    </nz-form-item>
</ng-container>

<ng-template #popoverContent>
    <div style="display:block;">
        <span style="color:white;">
            {{formBuildingInput.description || '' | translate}}
        </span>
    </div>
</ng-template>

<ng-template #errorHandlingTemplate let-formControl>
    <div *ngFor="let error of (formControl.errors | keyvalue)">
      <span>
        {{ error.key | handleErrorTranslationPipe : formControl }}
      </span>
    </div>
</ng-template>
