import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ApiService} from 'src/app/core/service/api.service';
import User from 'src/app/pages/admin/user-management/user.model';
import {UserStoreService} from './user.store.service';

@Injectable()
export class UserManagementService {

    constructor(private api: ApiService, private userStoreService: UserStoreService){}

    getAllUsers(): void {
        this.userStoreService.setLoading(true);
        this.api.get('/eudamed/v1/admin/users').pipe(catchError(() => {
          this.userStoreService.setLoading(false);
          return of([]);
        })).subscribe((allUsers) => {
            const mapUsers = (user: any) => {
                return new User(user);
            };

            this.userStoreService.setAllUsers(allUsers.map(mapUsers));
            this.userStoreService.setLoading(false);
            this.userStoreService.searchHasChanged();
        });
    }

  getAllUsersPaginated(
    pageSize: number,
    pageIndex: number
  ): void {
    this.userStoreService.setLoading(true);
    this.api.get(
      `/eudamed/v1/admin/users/${pageSize}/${pageIndex}`,
      {observe: 'response' as 'body'}
    ).pipe(
      catchError(() => {
        this.userStoreService.setLoading(false);
        return of([]);
      })
    ).subscribe((allUsers) => {
      const totalNumberInHeader = allUsers.headers?.get('x-total-count');
      this.userStoreService.setTotalNumber(totalNumberInHeader);
      this.userStoreService.setAllUsers(allUsers.body);
      this.userStoreService.setLoading(false);

      return allUsers.body.map(
        (singleUser: Partial<User>) => new User(singleUser)
      );
    });
  }

  searchAllUsersPaginated(
    pageSize: number,
    pageIndex: number,
    searchValue: string | undefined
  ): void {
    this.userStoreService.setLoading(true);
    this.api.get(
      `/eudamed/v1/admin/users/${pageSize}/${pageIndex}/${searchValue}`,
      {observe: 'response' as 'body'}
    ).pipe(
      catchError(() => {
        this.userStoreService.setLoading(false);
        return of([]);
      })
    ).subscribe((allUsers) => {
      const totalNumberInHeader = allUsers.headers?.get('x-total-count');
      this.userStoreService.setTotalNumber(totalNumberInHeader);
      this.userStoreService.setAllUsers(allUsers.body);
      this.userStoreService.setLoading(false);

      return allUsers.body.map(
        (singleUser: Partial<User>) => new User(singleUser)
      );
    });
  }


}
