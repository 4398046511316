import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import {WebSocketService} from './core/service/web-socket-service.service';
import {ApplicationConfigurationService} from './core/service/application-configuration.service';
import {KeycloakService} from 'keycloak-angular';
import {NotificationService} from './core/service/notifications/notification.service';
import {Router} from '@angular/router';

const constructNotification = (body: string) => {
  // return body + `<br/>` + `<a routerLink='/excel/history'>Check the status</a>`;
  return body;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{


  constructor(private webSocketService: WebSocketService,
              private appConfigurationService: ApplicationConfigurationService,
              private keycloakService: KeycloakService,
              private notificationService: NotificationService,
              private router: Router) { }

  ngOnInit(): void {
    console.log('Environment: ' + environment.environment);
    console.log('Application version: ' + environment.version);

    this.appConfigurationService.getWebsocketConfiguration().subscribe(result => {
      const username = this.keycloakService.getUsername();
      const stompClient = this.webSocketService.connect(result);
      // @ts-ignore
      stompClient.connect({}, frame => { //
        // @ts-ignore
        stompClient.subscribe('/user/' + username + result.destination, response => { //
          if (!this.router.url.includes('/excel/history')) {
            // tslint:disable-next-line:no-debugger
            this.notificationService.showInfoNotification('', constructNotification(response.body), {});
          }
        });
      });
    });
  }

}
