<div class="mdn-code-wizard" *ngFor="let mdnCode of mdnCodesArray; let i = index">
  <nz-collapse>
    <nz-collapse-panel
      #mdnCollapsePanel
      [nzExtra]="removeMdn"
      [nzHeader]="(formControlInput.name | translate) + ' ' + (i+1)"
      [nzActive]="true"
      [nzExpandedIcon]="expandedIcon"
    >
      <ng-template #removeMdn>
        <fa-icon
          [icon]="faTrash"
          *ngIf="mdnCodesArray.length > 1"
          (click)="removeSingleMdn(i)"
          [class.remove-mdn-icon-active]="mdnCollapsePanel.nzActive"
          [class.remove-mdn-icon-inactive]="!mdnCollapsePanel.nzActive"
          class="repeatable-headline-delete-icon"
        ></fa-icon>
      </ng-template>
      <ng-template #expandedIcon>
        <i nz-icon
           [class.expanded-mdn-icon]="mdnCollapsePanel.nzActive"
           [class.collapsed-mdn-icon]="!mdnCollapsePanel.nzActive"
           [nzType]="mdnCollapsePanel.nzActive ? 'minus' : 'plus'"
           class="repeatable-headline-icon ant-collapse-arrow"
           [nzRotate]="mdnCollapsePanel.nzActive ? -180 : 90"
        ></i>
      </ng-template>
      <ng-container>
        <p>
          {{ "SHARED.components.wizard.remark.firstPart" | translate }}
          <a style="text-decoration: underline" (click)="showMdnWizardModal(i)">
            {{ "SHARED.components.wizard.remark.secondPart" | translate }}
          </a> {{ "SHARED.components.wizard.remark.thirdPart" | translate }}
        </p>
        <nz-modal [nzVisible]="isMdnWizardModalVisible !== null"
                  nzCentered
                  (nzOnCancel)="handleCancelMiddle()"
                  [nzCancelText]="'SHARED.components.wizard.cancel' | translate"
                  (nzOnOk)="handleOkMiddle()"
                  [nzOkText]="'SHARED.components.wizard.save' | translate"
                  [nzOkDisabled]="!isBottomLevelSelected">
          <ng-container *nzModalContent>
            <h2>{{ "SHARED.components.wizard.title" | translate }}</h2>
            <div *ngFor="let wizardStep of wizardStepsArray; let j = index">
              <nz-input-group>
                <eudamed-dropdown-searchable-wrapper [label]="'EMDN Level ' + (j+1)">
                  <nz-select nzShowSearch nzAllowClear
                             class="mdn-code-wizard-select"
                             nzMode="default"
                             [style]="getDropdownStyle(formControlInput.propertyName)"
                             nz-input
                             [nzPlaceHolder]="formControlInput.placeholder || ''"
                             [(ngModel)]="wizardStepsArray[j]"
                             (ngModelChange)="isBottomLevel($event, j)">
                    <nz-option *ngFor="let codelist of codelists | filter: filterByPreviousValueAndLevel((j === 0) ? '' : wizardStepsArray[j-1].value, j+1)"
                               [nzValue]="codelist" [nzLabel]="codelist.label | codelistMDNTranslation">
                    </nz-option>
                  </nz-select>
                </eudamed-dropdown-searchable-wrapper>
              </nz-input-group>
              <br>
            </div>

          </ng-container>
        </nz-modal>
        <nz-input-group>
          <eudamed-dropdown-searchable-wrapper [label]="(formControlInput.name | translate) + ((formControlInput.validators && formControlInput.validators.includes(Validators.required)) ? '*' : '')">
            <nz-select nzShowSearch
                       nzMode="default"
                       class="mdnSelect"
                       [style]="getDropdownStyle(formControlInput.propertyName)"
                       nzPopoverTrigger="click"
                       nzPopoverPlacement="right"
                       nz-popover
                       [nzPopoverContent]="popoverContent"
                       [nzPopoverOverlayStyle]="{maxWidth:'200px'}"
                       nzPopoverColor="white"
                       nz-input
                       (ngModelChange)="updateSingleMdn($event, i)"
                       [ngModel]="mdnCodesArray[i]"
                       [nzPlaceHolder]="formControlInput.placeholder || ''"
                       [nzOptions]="codelists | filter: bottomLevelFilter">
              <nz-option *ngFor="let codelist of codelists | filter: bottomLevelFilter"
                        [nzValue]="codelist" [nzLabel]="codelist.label | codelistMDNTranslation">
              </nz-option>
            </nz-select>
          </eudamed-dropdown-searchable-wrapper>
        </nz-input-group>

        <ng-template #popoverContent>
          <span style="color:white;">
              {{formControlInput.description || '' | translate}}
            <p>
              <a class="show-mdn-wizard-link" (click)="showMdnWizardModal(i)">
                {{ "SHARED.components.wizard.link" | translate }}
              </a>
            </p>
          </span>
        </ng-template>
      </ng-container>
    </nz-collapse-panel>
  </nz-collapse>

</div>
<button id="mdn-code-button" class="add-another add-another-root"
        nz-button type="button" (click)="addNewMdnCode()"
        [disabled]="mdnCodesArray[mdnCodesArray.length-1] === null">
  <h4>
    <fa-icon [icon]="faPlus"></fa-icon>
    {{ "SHARED.components.wizard.button" | translate }}
  </h4>
</button>

