import { Injectable, TemplateRef } from '@angular/core';
import { NzNotificationDataOptions, NzNotificationRef, NzNotificationService } from 'ng-zorro-antd/notification';
import {NzConfigService} from 'ng-zorro-antd/core/config';

@Injectable()
export class NotificationService {

    public notificationTemplate: TemplateRef<any>;

    constructor(private nzNotificationService: NzNotificationService, private nzConfigService: NzConfigService) {
      this.nzConfigService.set('notification', { nzMaxStack: 1 });
    }

    showCustomNotification(notificationData: NzNotificationDataOptions): NzNotificationRef {
      return this.nzNotificationService.template(this.notificationTemplate, notificationData);
    }

    showBlankNotification(title: string, content: string, notificationData: NzNotificationDataOptions | undefined): NzNotificationRef {
        return this.nzNotificationService.blank(title, content, notificationData);
    }

    showSuccessNotification(title: string, content: string, notificationData: NzNotificationDataOptions | undefined): NzNotificationRef {
        return this.nzNotificationService.success(title, content, notificationData);
    }

    showErrorNotification(title: string, content: string, notificationData: NzNotificationDataOptions | undefined): NzNotificationRef {
        return this.nzNotificationService.error(title, content, notificationData);
    }

    showInfoNotification(title: string, content: string, notificationData: NzNotificationDataOptions | undefined): NzNotificationRef {
        return this.nzNotificationService.info(title, content, notificationData);
    }

    showWarningNotification(title: string, content: string, notificationData: NzNotificationDataOptions | undefined): NzNotificationRef {
        return this.nzNotificationService.warning(title, content, notificationData);
    }

    deleteNotification(notificationId: string): void{
        return this.nzNotificationService.remove(notificationId);
    }
}
