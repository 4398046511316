import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import ActorCodes from '../model/actor-codes.model';
import {ApiService} from './api.service';


@Injectable()
export class ActorCodesService {
    private readonly allActorCodes = new BehaviorSubject<ActorCodes | null>(null);
    private readonly actorCodesLoading = new BehaviorSubject<boolean>(false);

    readonly allActorCodes$ = this.allActorCodes.asObservable();
    readonly actorCodesLoading$ = this.actorCodesLoading.asObservable();

    constructor(
        private apiService: ApiService,
        private keycloakService: KeycloakService
    ){}

    retrieveAllActorCodes(): Observable<any> {
        const username = this.keycloakService.getUsername();
        this.actorCodesLoading.next(true);
        return this.apiService.get(`/eudamed/v1/users/${username}/actor-codes`).pipe(catchError(() => {
            this.actorCodesLoading.next(false);
            return of(new ActorCodes({}));
        }))
        .pipe(map((allActorCodes: ActorCodes) => {
            this.allActorCodes.next(allActorCodes);
            this.actorCodesLoading.next(false);
        }));
    }

    get AllCodes(): Array<string> {
      const arr: string[] = [];
      arr.push(... this.allActorCodes.getValue()?.MFActorCodes || []);
      arr.push(... this.allActorCodes.getValue()?.PRActorCodes || []);
      return arr.filter((item, pos) => arr.indexOf(item) === pos);
    }

    get PRActorCodes(): Array<string> {
        return this.allActorCodes.getValue()?.PRActorCodes || [];
    }

    get MFActorCodes(): Array<string> {
        return this.allActorCodes.getValue()?.MFActorCodes || [];
    }
}
