import {Component, Input} from '@angular/core';
import {Validators} from '@angular/forms';
import {returnCodelistStyle} from 'src/app/core/constants/codelist.style.constants';
import {BaseFormFieldType} from '../base-form-field-type.class';
import { CodelistValuesPipe } from '../../../../../pipes/code-list-values.pipe';
import { CodelistValue } from '../../../../../../../core/constants/codelists.constants';

@Component({
    selector: 'searchable-multiselect-render',
    templateUrl: './searchable-multiselect.component.html',
    styleUrls: ['./searchable-multiselect.component.scss']
})
export class SearchableMultiselectRenderingComponent extends BaseFormFieldType {
    @Input()
    errorForm: boolean;

    @Input()
    editingObject: any;

    codeList: CodelistValue[] = [];
    invalidCodes: string[] = [];

    optionalClass = ['non-empty-input', 'label'];
    Validators = Validators;

    getDropdownStyle(propertyName: string): any {
      return returnCodelistStyle(propertyName);
    }

    ngOnInit() {
      super.ngOnInit();
      this.codeList = new CodelistValuesPipe().transform(this.formBuildingInput.propertyName, this.editingObject);
      this.formGroup.get(this.formBuildingInput.propertyName).value.forEach((formInputCodeValue: string) => {
        if (this.codeList.findIndex(code => code.value === formInputCodeValue) === -1) {
          this.invalidCodes.push(formInputCodeValue);
        }
      });
    }
}
