<div style="display:flex; flex-wrap:wrap; width:100%; margin-bottom:32px;margin-bottom:48px">
    <!-- <h3> -->
    <!-- {{formBuildingInput.name | translate}} -->
    <!-- </h3> -->
    <ng-container [formGroup]="formGroup">
        <nz-collapse nzAccordion [class]="rootNested ? 'nz-collapse-headline-root' : 'nz-collapse-headline-child'"
            [style]="!rootNested ? 'box-shadow: 1px 1px 4px var(--gray2);' : ''">
            <nz-collapse-panel
                #collapsePanel
                [nzExtra]="removeArray"
                [nzHeader]="(formBuildingInput.name | translate) + ' ' + (i+1)"
                [nzActive]="true"
                *ngFor="let innerFormGroups of getByName(formGroup, formBuildingInput.propertyName).controls; let i = index"
                [class.rootRepeatable]="rootNested"
                [class.nestedChild]="!rootNested"
                [class.invalidGroupError]="!innerFormGroups.valid && innerFormGroups.status !== 'DISABLED'"
                [nzExpandedIcon]="expandedIcon"
                [style]="style"
                [class.last-collapsed]="lastCollapsed"
                [class.last-collapsed-child]="lastCollapsedChild"
                >
                <ng-template #removeArray >
                    <fa-icon
                        *ngIf="getByName(formGroup, formBuildingInput.propertyName).controls.length > 1"
                        (click)="removeSingleItem(i, getByName(formGroup, formBuildingInput.propertyName))"
                        [icon]="faTrash"
                        class="repeatable-headline-delete-icon"
                        [class.remove-repeatable-icon-active]="collapsePanel.nzActive"
                        [class.remove-repeatable-icon-inactive]="!collapsePanel.nzActive"
                        ></fa-icon>
                </ng-template>
                    <ng-template #expandedIcon>
                        <i nz-icon
                            [style]="collapsePanel.nzActive ? 'background-color: var(--gray3);' : 'background-color: var(--accent1);'"
                            [nzType]="collapsePanel.nzActive ? 'minus' : 'plus'"
                            class="repeatable-headline-icon ant-collapse-arrow"
                            [nzRotate]="collapsePanel.nzActive ? -180 : 90"
                            ></i>
                    </ng-template>
                <div *ngIf="collapsePanel.nzActive">

                    <ng-container [formArrayName]="formBuildingInput.propertyName" *ngFor="let child of formBuildingInput.children">
                        <!-- <renderer-for-forms [formBuildingInput]="child" [formGroup]="innerFormGroups" [rootNested]="false" [index]="i" [lastVisitedPath]="lastVisitedPath+'.'+i"></renderer-for-forms> -->
                        <form-root-render
                            [formBuildingInput]="child"
                            [formGroup]="innerFormGroups"
                            [rootNested]="false"
                            [index]="i"
                            [editingObject]="editingObject"
                            [lastVisitedPath]="lastVisitedPath+'.'+i">
                        </form-root-render>
                        <!-- <ng-container *ngTemplateOutlet="renderer; context: {formBuildingInput: child, rootNested: false, index: i, formGroup: innerFormGroups, lastVisitedPath: lastVisitedPath+'.'+i }"></ng-container> -->
                    </ng-container>

                    <div 
                      [class.disabled-adding]="formGroup.disabled"
                      *ngIf="(formBuildingInput.maxLength === undefined && !rootNested && innerFormGroups === getByName(formGroup, formBuildingInput.propertyName).controls[getByName(formGroup, formBuildingInput.propertyName).controls.length-1]) ? true : formGroup.controls.length < (formBuildingInput.maxLength || 0)"
                        (click)="addRepeatable(formBuildingInput, formGroup, collapsePanel, innerFormGroups)" class="add-another add-another-child">
                        <h4 style="font-weight:600; margin-left: 10px; margin-bottom:0px; color:var(--accent1)">
                            <fa-icon [icon]="faPlus" style="margin-right: 4px;"></fa-icon>
                            {{ 'ENRICHMENT.body.button.addAttributeSet' | translate }}
                        </h4>
                    </div>
                    <div 
                      [class.disabled-adding]="formGroup.disabled"
                      *ngIf="(formBuildingInput.maxLength === undefined && rootNested && innerFormGroups === getByName(formGroup, formBuildingInput.propertyName).controls[getByName(formGroup, formBuildingInput.propertyName).controls.length-1]) ? true : formGroup.controls.length < (formBuildingInput.maxLength || 0)"
                        (click)="addRepeatable(formBuildingInput, formGroup, collapsePanel, innerFormGroups)" class="add-another add-another-root">
                        <h4 style="font-weight:600; margin-left: 10px; margin-bottom:0px; color:var(--accent1)">
                            <fa-icon [icon]="faPlus" style="margin-right: 4px;"></fa-icon>
                            {{ 'ENRICHMENT.body.button.addAttributeSet' | translate }}
                        </h4>
                    </div>
                </div>

                <!-- When container is collapsed -->
                <div *ngIf="!collapsePanel.nzActive" [ngStyle]="{'height': '50px !important;'}">
                    <div [class.disabled-adding]="formGroup.disabled" 
                      *ngIf="(formBuildingInput.maxLength === undefined && !rootNested && innerFormGroups === getByName(formGroup, formBuildingInput.propertyName).controls[getByName(formGroup, formBuildingInput.propertyName).controls.length-1]) ? true : formGroup.controls.length < (formBuildingInput.maxLength || 0)"
                        (click)="addRepeatable(formBuildingInput, formGroup, collapsePanel, innerFormGroups)" class="add-another add-another-child">
                        <h4 style="font-weight:600; margin-left: 10px; margin-bottom:0px; color:var(--accent1)">
                            <fa-icon [icon]="faPlus" style="margin-right: 4px;"></fa-icon>
                            {{ 'ENRICHMENT.body.button.addAttributeSet' | translate }}
                        </h4>
                    </div>
                    <div 
                      [class.disabled-adding]="formGroup.disabled"
                      *ngIf="(formBuildingInput.maxLength === undefined && rootNested && innerFormGroups === getByName(formGroup, formBuildingInput.propertyName).controls[getByName(formGroup, formBuildingInput.propertyName).controls.length-1]) ? true : formGroup.controls.length < (formBuildingInput.maxLength || 0)"
                        (click)="addRepeatable(formBuildingInput, formGroup, collapsePanel, innerFormGroups)" class="add-another add-another-root">
                        <h4 style="font-weight:600; margin-left: 10px; margin-bottom:0px; color:var(--accent1)">
                            <fa-icon [icon]="faPlus" style="margin-right: 4px;"></fa-icon>
                            {{ 'ENRICHMENT.body.button.addAttributeSet' | translate }}
                        </h4>
                    </div>
                </div>
            </nz-collapse-panel>
        </nz-collapse>
    </ng-container>
    <!-- <a (click)="addRepeatable(formBuildingInput, formGroup)"> -->
    <!-- + Add another {{formBuildingInput.name | translate}} -->
    <!-- </a> -->
    <br>
</div>

