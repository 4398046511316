import {Component} from '@angular/core';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import {BaseDeviceFilterComponent} from '../device-filter.component';

@Component({
    selector: 'device-filter-web',
    templateUrl: './device-filter-web.component.html',
    styleUrls: ['./device-filter-web.component.scss']
})
export class DeviceFilterWebComponent extends BaseDeviceFilterComponent {
  faPlus = faPlus;
  faTimes = faTimes;
  faTrash = faTrashAlt;
}
