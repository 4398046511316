import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './core/auth';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/devices' },
    { path: 'devices', loadChildren: () => import('./pages/devices/devices.module').then(m => m.DevicesModule), canActivate: [AuthGuard], data: { title: 'Devices'} },
        { path: 'add-devices', loadChildren: () => import('./pages/add-devices/add-devices.module').then(m => m.AddDevicesModule), canActivate: [AuthGuard], data: { title: 'Add product' } },
    { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard], data: {title: 'Account settings'} },
        { path: 'admin', loadChildren: () => import('./pages//admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard], data: {title: 'Account settings'} },
  { path: 'account', loadChildren: () => import('./pages//account/account.module').then(m => m.AccountModule), canActivate: [AuthGuard], data: {title: 'Account settings'} },
  { path: 'download', loadChildren: () => import('./pages//download-center/download.module').then(m => m.DownloadModule), canActivate: [AuthGuard], data: {title: 'Download Center'} },
  { path: 'report', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard], data: {title: 'Reports'} },
];

@NgModule({
        imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
        exports: [RouterModule]
    })
export class AppRoutingModule { }
