import {Injector} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormGroup, ValidatorFn} from '@angular/forms';
import {BUDIGroups, UDIGroups} from 'src/app/core/constants/device-attribute-details/attribute-groups.enum';
import {FormControlType} from './form-control-type.enum';

export default interface FormBuildingInput {
    propertyName: string;
    controlType: FormControlType;
    initialValue?: any;
    errorMessage?: string | any;
    name: string;
    description?: string;
    placeholder?: string;
    group: BUDIGroups | UDIGroups;
    parsingFunction?: any;
    disabled?: boolean;
    style?: any;
    minLength?: number;
    maxLength?: number;
    textareaRows?: number;
    decimal?: boolean;
    stringArray?: boolean;
    validators?: Array<ValidatorFn | AsyncValidatorFn>;
    numericType?: NumericTypes;
    children?: any;
    blankSeparator?: boolean;
    hidden?: boolean | ((formGroup: FormGroup) => boolean);
    updateable?: boolean;
    onChange?: ((control: AbstractControl, formBuildingInput: FormBuildingInput, injector?: Injector) => void);
}

export enum NumericTypes {
    INTEGER,
    DECIMAL
}
