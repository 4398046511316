import {Component, Input} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseFormFieldType} from '../base-form-field-type.class';

@Component({
    selector: 'repeatable-select-render',
    templateUrl: './repeatable-select.component.html',
    styleUrls: ['./repeatable-select.component.scss']
})
export class RepeatableSelectRenderingComponent extends BaseFormFieldType {
    @Input()
    editingObject: any;

    optionalClass = ['non-empty-input', 'label'];
    Validators = Validators;
    Infinity = Infinity;
}
