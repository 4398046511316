import {Component, OnInit, OnDestroy} from '@angular/core';
import {COLUMN_FILTER_OPTIONS, FilterInputType, FilterOptions} from 'src/app/core/constants/column-filter-options.constants';
import {DeviceService} from 'src/app/core/service';
import {Subject} from 'rxjs';
import {animate, state, style, transition, trigger, AnimationEvent} from '@angular/animations';
import {Filter, FilterType} from 'src/app/core/model/filter.model';
import {takeUntil} from 'rxjs/operators';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {Platform, PlatformService} from 'src/app/core/service/platform.service';
import {FilterService} from 'src/app/core/service/filters/filter.service';



@Component({
    selector: 'device-filter',
    template: `
    <ng-container>
        <device-filter-web *ngIf="currentPlatform == 0"></device-filter-web>
        <device-filter-mobile *ngIf="currentPlatform == 2 || currentPlatform == 1"></device-filter-mobile>
    </ng-container>`,
    styleUrls: ['./device-filter.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                transform: 'none',
                opacity: 1,
            })),
            state('closed', style({
                transform: 'translateX(2500px)',
                opacity: 0.5,
            })),
            transition('open => closed', [
                animate('250ms 0.2ms ease-in-out')
            ]),
            transition('closed => open', [
                animate('250ms 0.2ms ease-in-out')
            ]),
        ])
    ]
})

export class BaseDeviceFilterComponent implements OnInit, OnDestroy{

    unsubscribeNotifier = new Subject();
    columnFilterOptions: FilterOptions = COLUMN_FILTER_OPTIONS;
    isOpen = false;
    faTrashAlt = faTrashAlt;

    filters: Filter[] = [];

    currentPlatform: Platform;

    FilterType = FilterType;
    FilterInputType = FilterInputType;

    constructor(
        public deviceService: DeviceService,
        protected filterService: FilterService,
        platform: PlatformService
    ) {
        platform.currentPlatform$.subscribe((currentPlatform: Platform) => {
            this.currentPlatform = currentPlatform;
        });
    }

    ngOnInit(): void{
         setTimeout(() => {
             this.isOpen = true;
         }, 0);

         this.filterService.filters$.pipe(takeUntil(this.unsubscribeNotifier)).subscribe((filters: Filter[]) => {
             this.filters = filters.filter((filter) => {
                return filter.isSort === false;
             });
         });


         if (
             this.filterService.getFilters().length === 0
             || this.filterService.getFilters().length === 1
             && this.filterService.getFilters()[0].isSearch === true
         ) {
             this.addFilter();
         }

    }

    removeFilter(filter: Filter): void {
        this.filterService.removeFilter(filter);
        if (filter.isApplied){
            this.deviceService.toggleLoading(true);
            this.deviceService.getDevicesPage().subscribe(() => {
                this.deviceService.toggleLoading(false);
            });
        }
    }

    addFilter(): void{
        const defaultEmptyFilter = new Filter();
        defaultEmptyFilter.field = 'name';
        defaultEmptyFilter.value = '';
        defaultEmptyFilter.include = true;
        defaultEmptyFilter.typeOfFilter = FilterType.Contains;
        this.filterService.addFilter(defaultEmptyFilter);
    }

    applyFilters(): void {
        this.deviceService.toggleLoading(true);
        this.deviceService.getDevicesPage().subscribe(() => {
            this.deviceService.toggleLoading(false);
        });
        this.filterService.toggleFiltering(false);
    }

    animEnd(ev: AnimationEvent): void {
        if (ev.fromState === 'open' && ev.toState === 'closed'){
            this.closeComponent();
        }
    }

    fieldChanged(newField: string, filter: Filter): void{
        if (this.columnFilterOptions[newField].type === FilterInputType.Select) {
            filter.typeOfFilter = FilterType.Contains;
        }
        filter.isApplied = false;
        filter.value = '';
    }


    triggerExitAnimation(): void{
        this.isOpen = false;
    }

    closeComponent(): void{
        this.filters.forEach((filter) => {
            if (!filter.isApplied) {
                this.filterService.removeFilter(filter);
            }
        });
        this.filterService.toggleFiltering(false);
    }

    ngOnDestroy(): void {
        this.unsubscribeNotifier.next();
        this.unsubscribeNotifier.complete();
    }
}
