import { Injectable } from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {AuthServerProvider} from '../auth/auth-jwt.service';
import {Observable} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {KeycloakProfile} from 'keycloak-js';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private keycloak: KeycloakService, private authServerProvider: AuthServerProvider) {}

  // If needed add account service for fetching information about account
  getToken(): string {
    return this.authServerProvider.getToken();
  }

  getUserData(): Promise<KeycloakProfile> {
    return this.keycloak.loadUserProfile(false);
  }

  getRole(): string{
    if (this.keycloak.isUserInRole('admin')) {
      return 'admin';
    } else if (this.keycloak.isUserInRole('eudamed-editor')) {
      return 'eudamed-editor';
    } else if (this.keycloak.isUserInRole('visitor')) {
      return 'visitor';
    } else if (this.keycloak.isUserInRole('token-manager')) {
      return 'token-manager';
    } else {
      return '';
    }
  }

  login(token: { keycloakToken: string }): Observable<void> {
    return this.authServerProvider.login(token.keycloakToken);
  }

  logout(): void {
    this.keycloak.logout().then(() => {
      this.authServerProvider.logout();
    });
  }
}
