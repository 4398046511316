<div style="margin-bottom:48px">
    <ng-container [formGroup]="formGroup">
        <nz-form-item
            class="nz-collapse-headline-root"
            [style]="formBuildingInput.style? formBuildingInput.style : 'flex-basis:100%;'"
            >
            <nz-form-control>
                <app-eudamed-mdn-wizard [formControlInput]="formBuildingInput" [formControlName]="formBuildingInput.propertyName"></app-eudamed-mdn-wizard>
            </nz-form-control>
        </nz-form-item>
    </ng-container>
</div>

