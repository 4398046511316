import { Injectable } from '@angular/core';

import * as SockJs from 'sockjs-client';
import * as Stomp from 'stompjs';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  public connect(result: any) {
    const socket = new SockJs(result.url);
    const stompClient = Stomp.over(socket);
    return stompClient;
  }


}
