import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Input, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IconsProviderModule } from '../icons-provider.module';
import { UserComponent } from './user/user.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {EudRoleDirective} from '../core/auth/hide-per-role.directive';
import {TranslateModule} from '@ngx-translate/core';
import {StatusColorPipe} from './common/pipes/status-color.pipe';
import {DateAgoPipe} from './common/pipes/time-ago.pipe';
import {FilterPipe} from './common/pipes/filter.pipe';
import {EudamedInputComponent} from './common/components/eudamed-input/eudamed-input.component';
import { ReversePipe } from './common/pipes/reverse.pipe';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import { EudamedFormComponent } from './common/components/eudamed-forms/eudamed-form/eudamed-form.component';
import {NzFormModule} from 'ng-zorro-antd/form';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import { EudamedDatePickerComponent } from './common/components/eudamed-date-picker/eudamed-date-picker/eudamed-date-picker.component';
import { EudamedDropdownComponent } from './common/components/eudamed-dropdown/eudamed-dropdown.component';
import { EudamedDropdownSearchableComponent } from './common/components/eudamed-dropdown-searchable/eudamed-dropdown-searchable.component';
import { EudamedMultiselectSearchableComponent } from './common/components/eudamed-multiselect-searchable/eudamed-multiselect-searchable.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import {EudamedNumberInputComponent} from './common/components/eudamed-number-input/eudamed-number-input.component';
import {EudamedTextareaComponent} from './common/components/eudamed-textarea/eudamed-textarea.component';
import {EudamedMdnWizardComponent} from './common/components/eudamed-mdn-wizard/eudamed-mdn-wizard.component';
import {EudInputComponent} from './common/components/eud-input/eud-input.component';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {CheckIfRegistrable} from './common/pipes/check-if-registrable.pipe';
import {CodelistValuesPipe} from './common/pipes/code-list-values.pipe';
import {MergePipe} from './common/pipes/merge.pipe';
import {EudamedBasicSelectComponent} from './common/components/eudamed-basic-select/eudamed-basic-select.component';
import { EudamedTableComponent } from './common/components/eudamed-table/eudamed-table.component';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import {EudamedRepeatableSelectComponent} from './common/components/eudamed-repeatable/eudamed-repeatable.component';
import {TypeofPipe} from './common/pipes/typeof.pipe';
import {IncludesPipe} from './common/pipes/includes.pipe';
import {HandleErrorTranslationPipe} from './common/pipes/handle-error-translation.pipe';
import { CodelistTranslation } from './common/pipes/code-list-translation.pipe';
import { CodelistMDNTranslation } from './common/pipes/code-list-MDN-translation.pipe';
import {IsBasicPipe} from './common/pipes/is-basic.pipe';
import {RootFormComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/root-form-component/root-form.component';
import {InputRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/input-rendering-component/input-rendering.component';
import {NumberInputRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/number-input/number-input.component';
import {TextareaRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/textarea-rendering/textarea-rendering.component';
import {RepeatableRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/repeatable-render/repeatable-render.component';
import {NonShowingRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/non-showing-render/non-showing-render.component';
import {RadioRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/radio-rendering/radio-rendering.component';
import {DateRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/date-render/date-render.component';
import {DatetimeRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/datetime-render/datetime-render.component';
import {DropdownRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/dropdown-render/dropdown-render.component';
import {SearchableDropdownRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/searchable-dropdown-render/searchable-dropdown-render.component';
import {SearchableMultiselectRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/searchable-multiselect-render/searchable-multiselect.component';
import {MdnRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/mdn-select-render/mdn-render.component';
import {RepeatableSelectRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/repeatable-select-render/repeatable-select.component';
import {BasicAsyncRenderingComponent} from './common/components/eudamed-forms/eudamed-form/form-rendering-components/basic-async-select/basic-async-select.component';
import {BaseDeviceFilterComponent} from './common/components/device-filter/device-filter.component';
import {DeviceFilterWebComponent} from './common/components/device-filter/web/device-filter-web.component';
import {DeviceFilterMobileComponent} from './common/components/device-filter/mobile/device-filter-mobile.component';

@NgModule({
    imports: [
        NzPaginationModule,
        NzDropDownModule,
        NzIconModule,
        NzAvatarModule,
        NzModalModule,
        FontAwesomeModule,
        NzButtonModule,
        NzSwitchModule,
        FormsModule,
        NzToolTipModule,
        NzPopoverModule,
        NzInputModule,
        NzTableModule,
        NzSelectModule,
        NzRadioModule,
        NzGridModule,
        TranslateModule,
        NzDividerModule,
        NzFormModule,
        NzDatePickerModule,
        NzCheckboxModule,
        NzCollapseModule,
        NzTypographyModule,
        NzSpinModule,
        CommonModule,
        NzInputNumberModule,
        // FormsModule,
        ReactiveFormsModule,
        // HttpClientModule,
        // RouterModule


    ],
    declarations: [
        EudamedTableComponent,
        EudamedInputComponent,
        UserComponent,
        CookieBannerComponent,
        EudRoleDirective,
        CodelistValuesPipe,
        StatusColorPipe,
        DateAgoPipe,
        MergePipe,
        FilterPipe,
        ReversePipe,
        EudamedFormComponent,
        EudamedDatePickerComponent,
        EudamedDropdownComponent,
        EudamedBasicSelectComponent,
        EudamedDropdownSearchableComponent,
        EudamedMultiselectSearchableComponent,
        EudamedMdnWizardComponent,
        EudamedRepeatableSelectComponent,
        EudamedNumberInputComponent,
        EudamedTextareaComponent,
        EudInputComponent,
        CheckIfRegistrable,
        BaseDeviceFilterComponent,
        DeviceFilterWebComponent,
        DeviceFilterMobileComponent,
        TypeofPipe,
        IncludesPipe,
        HandleErrorTranslationPipe,
        CodelistTranslation,
        CodelistMDNTranslation,
        IsBasicPipe,
        RootFormComponent,
        InputRenderingComponent,
        NumberInputRenderingComponent,
        TextareaRenderingComponent,
        RepeatableRenderingComponent,
        NonShowingRenderingComponent,
        RadioRenderingComponent,
        DateRenderingComponent,
        DatetimeRenderingComponent,
        DropdownRenderingComponent,
        SearchableDropdownRenderingComponent,
        SearchableMultiselectRenderingComponent,
        MdnRenderingComponent,
        RepeatableSelectRenderingComponent,
        BasicAsyncRenderingComponent
    ],
    exports: [
        EudamedTableComponent,
        CommonModule,
        FilterPipe,
        ReversePipe,
        DateAgoPipe,
        StatusColorPipe,
        MergePipe,
        CodelistValuesPipe,
        EudRoleDirective,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        IconsProviderModule,
        BaseDeviceFilterComponent,
        DeviceFilterWebComponent,
        DeviceFilterMobileComponent,
        UserComponent,
        NzSpinModule,
        CookieBannerComponent,
        NzDropDownModule,
        NzIconModule,
        NzAvatarModule,
        NzModalModule,
        NzButtonModule,
        NzSwitchModule,
        NzToolTipModule,
        NzInputModule,
        NzFormModule,
        NzTableModule,
        NzSelectModule,
        NzRadioModule,
        FontAwesomeModule,
        NzPopoverModule,
        NzTypographyModule,
        NzGridModule,
        EudamedInputComponent,
        NzDividerModule,
        EudamedFormComponent,
        CheckIfRegistrable,
        TypeofPipe,
        IncludesPipe,
        HandleErrorTranslationPipe,
        CodelistTranslation,
        CodelistMDNTranslation,
        IsBasicPipe,
        RootFormComponent,
        InputRenderingComponent,
        EudamedDropdownSearchableComponent,
        NumberInputRenderingComponent,
        TextareaRenderingComponent,
        RepeatableRenderingComponent,
        NonShowingRenderingComponent,
        RadioRenderingComponent,
        DateRenderingComponent,
        DatetimeRenderingComponent,
        DropdownRenderingComponent,
        SearchableDropdownRenderingComponent,
        SearchableMultiselectRenderingComponent,
        MdnRenderingComponent,
        RepeatableSelectRenderingComponent,
        BasicAsyncRenderingComponent
    ]
})
export class SharedModule{}
