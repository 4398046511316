import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import {LoginService} from '../service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly loginService: LoginService
  ) {
    super(router, keycloak);

  }

  protected authenticated = this.loginService.getToken() !== '';
  protected roles: string[];

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
      this.roles = this.keycloak.getUserRoles(true);
      return this.isAccessAllowed(route, state);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean>{
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    const requiredRoles = [];
    let currRoute = route;
    if (currRoute.data.roles){
        requiredRoles.push(...currRoute.data.roles);
    }

    while (currRoute.firstChild) {
        currRoute = currRoute.firstChild;
        if (currRoute.data.roles){
            requiredRoles.push(...currRoute.data.roles);
        }
    }

    // Get the roles required from the route.

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      this.authenticated = true;
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}
