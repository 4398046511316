import {Component, forwardRef, Input, OnInit, Optional, Self} from '@angular/core';
import {CodelistValue} from '../../../../core/constants/codelists.constants';
import {returnCodelistStyle} from '../../../../core/constants/codelist.style.constants';
import FormBuildingInput from '../eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {DeviceConstants} from '../../../../core/constants/device-attribute-details';
import {MdnEditorCodelist} from '../../../../core/constants/codelistsmdn.constants';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-eudamed-mdn-wizard',
    templateUrl: './eudamed-mdn-wizard.component.html',
    styleUrls: ['./eudamed-mdn-wizard.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EudamedMdnWizardComponent),
                multi: true
        }
    ]
})
export class EudamedMdnWizardComponent implements OnInit, ControlValueAccessor {

    constructor(private translateService: TranslateService) {}
    set value(value: any) {
        this.onChangeCallback(value);
        this.mdnCodes = value;
    }

    @Input()
    formControlInput!: FormBuildingInput;

    codelists: CodelistValue[];

    Validators = Validators;

    mdnCodes = '';
    mdnCodesArray: any[] = [''];
    wizardStepsArray: any[] = [''];
    isMdnWizardModalVisible: number | null = null;
    isBottomLevelSelected = false;

    faTrash = faTrashAlt;
    faPlus = faPlus;

    ngOnInit(): void {
        this.codelists = MdnEditorCodelist.values.map((x) => {
            return {
                label: x.value + ' ' +  this.translateService.instant(x.label),
                value: x.value,
                description: x.description,
                level: x.level,
                bottomLevel: x.bottomLevel
            };
        });
    }

    onChangeCallback = (value: any) => {};

    filterByPreviousValueAndLevel(previousValue: string, neededLevel: number): any {
        return (cl: CodelistValue) => {
            return cl.value.startsWith(previousValue) && cl.level === neededLevel;
        };
    }

    isBottomLevel($event: any, j: number) {
        if (this.wizardStepsArray.length - 1 === j) {
            if (!$event.bottomLevel) {
                setTimeout(() => {
                    this.wizardStepsArray.push(null);
                }, 0);
            } else {
                this.isBottomLevelSelected = true;
            }
        } else {
            this.wizardStepsArray.length = j + 1;
            this.wizardStepsArray.push(null);
            this.isBottomLevelSelected = false;
        }
    }

    bottomLevelFilter(cl: CodelistValue) {
        return cl.bottomLevel === true;
    }

    getDropdownStyle(propertyName: string): any {
        return returnCodelistStyle(propertyName);
    }

    showMdnWizardModal(i: any): void {
        this.isMdnWizardModalVisible = i;
    }

    handleOkMiddle(): void {
        if (!this.mdnCodesArray.includes(this.wizardStepsArray[this.wizardStepsArray.length - 1].value)) {
            this.updateSingleMdn(this.wizardStepsArray[this.wizardStepsArray.length - 1].value, this.isMdnWizardModalVisible || 0);
        }
        this.isMdnWizardModalVisible = null;
        this.wizardStepsArray = [''];
    }

    handleCancelMiddle(): void {
        this.isMdnWizardModalVisible = null;
        this.isBottomLevelSelected = false;
        this.wizardStepsArray = [''];
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: any): void {
        if (obj) {
            this.mdnCodes = obj;
            this.mdnCodesArray = obj.split(' ');
        }
    }

    addNewMdnCode() {
        this.mdnCodesArray.push(null);
    }

    updateSingleMdn($event: any, i: number) {
        if (!this.mdnCodesArray.includes($event)) {
            this.mdnCodesArray[i] = $event;
            this.value = this.mdnCodesArray.join(' ');
        } else {
            this.mdnCodesArray[i] = null;
            this.value = this.mdnCodesArray.join(' ');
        }
        console.log(this.mdnCodes, $event, i);
    }

    removeSingleMdn(i: number) {
        this.mdnCodesArray.splice(i, 1);
        this.value = this.mdnCodesArray.join(' ');
    }
}
