import {AbstractControl, FormArray, Validators} from '@angular/forms';
import FormBuildingInput, {NumericTypes} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {FormControlType} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-control-type.enum';
import {DeviceConstants} from '.';
import {
  parseArrayField,
  parseComplexField,
  parseSimpleArray,
  parseSimpleField
} from '../../util/device-to-details.util';
import {UDIGroups} from './attribute-groups.enum';
import {
  actorCodePattern,
  cascodePattern,
  decimalPattern,
  eccodePattern,
  emailPattern,
  optionalWithRequired,
  optionalWithRequiredParticular,
  positiveIntegerPattern
} from './validators/complex-validators.util';
import {Injector} from '@angular/core';
import {conditionalDescriptionWithLanguage} from './services/eudamed-editor.util';
import {CriticalWarningValueCodeList, StorageHandlingConditionTypeCodeList} from '../codelists.constants';


export const MDRUDIDEVICE: Array<DeviceConstants & FormBuildingInput> = [
    {
        propertyName: 'secondaryIdentifier',
        name: 'DEVICECONSTANTS.mdr.udi.secondaryIdentifier.name',
        parsingFunction: parseComplexField,
        description: '',
        initialValue: '',
        validators: [optionalWithRequired(['secondaryIdentifierDICode', 'secondaryIdentifierIssuingEntityCode'])],
        placeholder: 'FLD-UDID-136',
        disabled: false,
        controlType: FormControlType.NON_SHOWING,
        group: UDIGroups.IDENTIFICATION,
        children: [
            {
                propertyName: 'secondaryIdentifierDICode',
                name: 'DEVICECONSTANTS.mdr.udi.secondaryIdentifierDICode.name',
                description: 'DEVICECONSTANTS.mdr.udi.secondaryIdentifierDICode.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                placeholder: 'FLD-UDID-136',
                jsonPath: '$.secondaryIdentifier.secondaryIdentifierDICode',
                disabled: false,
                controlType: FormControlType.INPUT,
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '47%',
                    'margin-right': '24px'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'secondaryIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.mdr.udi.secondaryIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.mdr.udi.secondaryIdentifierIssuingEntityCode.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                placeholder: 'FLD-UDID-293',
                disabled: false,
                jsonPath: '$.secondaryIdentifier.secondaryIdentifierIssuingEntityCode',
                controlType: FormControlType.DROPDOWN,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'deviceStatusCode',
        name: 'DEVICECONSTANTS.mdr.udi.deviceStatusCode.name',
        description: 'DEVICECONSTANTS.mdr.udi.deviceStatusCode.description',
        initialValue: '',
        placeholder: 'FLD-UDID-130',
        validators: [Validators.required],
        updateable: true,
        jsonPath: '$.deviceStatusCode',
        disabled: false,
        controlType: FormControlType.DROPDOWN,
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'directMarkingDI',
        name: 'DEVICECONSTANTS.mdr.udi.directMarkingDI.name',
        description: '',
        group: UDIGroups.IDENTIFICATION,
        initialValue: '',
        placeholder: 'DEVICECONSTANTS.mdr.udi.directMarkingDI.placeholder',
        validators: [optionalWithRequired(['directMarkingDIDICode', 'directMarkingDIIssuingEntityCode'])],
        disabled: false,
        controlType: FormControlType.NON_SHOWING,
        parsingFunction: parseComplexField,
        children: [
            {
                propertyName: 'directMarkingDIDICode',
                name: 'DEVICECONSTANTS.mdr.udi.directMarkingDIDICode.name',
                description: 'DEVICECONSTANTS.mdr.udi.directMarkingDIDICode.description',
                updateable: true,
                initialValue: '',
                placeholder: 'FLD-UDID-138',
                disabled: false,
                controlType: FormControlType.INPUT,
                jsonPath: '$.directMarkingDI.directMarkingDIDICode',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '47%',
                    'margin-right': '24px'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'directMarkingDIIssuingEntityCode',
                name: 'DEVICECONSTANTS.mdr.udi.directMarkingDIIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.mdr.udi.directMarkingDIIssuingEntityCode.description',
                initialValue: '',
                updateable: true,
                placeholder: 'FLD-UDID-294',
                disabled: false,
                controlType: FormControlType.DROPDOWN,
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled',
                jsonPath: '$.directMarkingDI.directMarkingDIIssuingEntityCode'
            }
        ]
    },
    {
        propertyName: 'baseQuantity',
        name: 'DEVICECONSTANTS.mdr.udi.baseQuantity.name',
        description: 'DEVICECONSTANTS.mdr.udi.baseQuantity.description',
        parsingFunction: parseSimpleField,
        initialValue: 1,
        jsonPath: '$.baseQuantity',
        placeholder: 'FLD-UDID-151',
        validators: [Validators.required, Validators.pattern(positiveIntegerPattern)],
        numericType: NumericTypes.INTEGER,
        disabled: false,
        controlType: FormControlType.INPUT,
        group: UDIGroups.IDENTIFICATION,
        errorMessage: {
          required: 'ERRORS.notFilled',
          pattern: 'ERRORS.numbersOnly'
        },
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'unitOfUseIdentifier',
        name: 'DEVICECONSTANTS.mdr.udi.unitOfUseIdentifier.name',
        description: '',
        parsingFunction: parseComplexField,
        initialValue: '',
        placeholder: 'FLD-UDID-135',
        validators: [optionalWithRequired(['unitOfUseIdentifierDICode', 'unitOfUseIdentifierIssuingEntityCode'])],
        disabled: false,
        controlType: FormControlType.NON_SHOWING,
        group: UDIGroups.IDENTIFICATION,
        children: [
            {
                propertyName: 'unitOfUseIdentifierDICode',
                name: 'DEVICECONSTANTS.mdr.udi.unitOfUseIdentifierDICode.name',
                description: 'DEVICECONSTANTS.mdr.udi.unitOfUseIdentifierDICode.description',
                initialValue: '',
                placeholder: 'FLD-UDID-135',
                disabled: false,
                updateable: true,
                controlType: FormControlType.INPUT,
                jsonPath: '$.unitOfUseIdentifier.unitOfUseIdentifierDICode',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '47%',
                    'margin-right': '24px'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'unitOfUseIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.mdr.udi.unitOfUseIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.mdr.udi.unitOfUseIdentifierIssuingEntityCode.description',
                initialValue: '',
                placeholder: 'FLD-UDID-292',
                updateable: true,
                disabled: false,
                controlType: FormControlType.DROPDOWN,
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled',
                jsonPath: '$.unitOfUseIdentifier.unitOfUseIdentifierIssuingEntityCode'
            }
        ]
    },
    {
        propertyName: 'deviceType',
        name: 'DEVICECONSTANTS.mdr.udi.deviceType.name',
        description: 'DEVICECONSTANTS.mdr.udi.deviceType.description',
        parsingFunction: parseSimpleField,
        initialValue: '',
        placeholder: 'FLD-ATRIFY-01',
        validators: [Validators.required],
        disabled: false,
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        errorMessage: 'ERRORS.notFilled',
        jsonPath: '$.deviceType'
    },
    {
        propertyName: 'UDIDIIdentifierDICode',
        name: 'DEVICECONSTANTS.mdr.udi.UDIDIIdentifierDICode.name',
        description: 'DEVICECONSTANTS.mdr.udi.UDIDIIdentifierDICode.description',
        parsingFunction: parseSimpleField,
        initialValue: '',
        validators: [Validators.required, Validators.minLength(1), Validators.maxLength(120)],
        placeholder: 'FLD-UDID-178',
        disabled: false,
        jsonPath: '$.UDIDIIdentifierDICode',
        controlType: FormControlType.INPUT,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        style: {
            width: '50%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea120'
        }
    },
    {
        propertyName: 'UDIDIIdentifierIssuingEntityCode',
        name: 'DEVICECONSTANTS.mdr.udi.UDIDIIdentifierIssuingEntityCode.name',
        description: 'DEVICECONSTANTS.mdr.udi.UDIDIIdentifierIssuingEntityCode.description',
        parsingFunction: parseSimpleField,
        initialValue: '',
        validators: [Validators.required],
        placeholder: 'FLD-UDID-291',
        disabled: false,
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        errorMessage: 'ERRORS.notFilled',
        jsonPath: '$.UDIDIIdentifierIssuingEntityCode'
    },
    {
        propertyName: 'customerMessageID',
        name: 'DEVICECONSTANTS.mdr.basic.customerMessageID.name',
        description: 'DEVICECONSTANTS.mdr.basic.customerMessageID.description',
        parsingFunction: parseSimpleField,
        initialValue: '',
        placeholder: 'FLD-ATRIFY-02',
        disabled: false,
        controlType: FormControlType.INPUT,
        group: UDIGroups.IDENTIFICATION,
        hidden: true
    },
    {
        propertyName: 'customerTransactionID',
        name: 'DEVICECONSTANTS.mdr.basic.customerTransactionID.name',
        description: 'DEVICECONSTANTS.mdr.basic.customerTransactionID.description',
        initialValue: '',
        placeholder: 'FLD-ATRIFY-03',
        disabled: false,
        controlType: FormControlType.INPUT,
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true
    },
    {
        propertyName: 'MFActorCode',
        name: 'DEVICECONSTANTS.mdr.udi.MFActorCode.name',
        description: 'DEVICECONSTANTS.mdr.udi.MFActorCode.description',
        initialValue: '',
        placeholder: 'FLD-UDID-10',
        validators: [Validators.required, Validators.pattern(actorCodePattern)],
        disabled: false,
        controlType: FormControlType.INPUT,
        parsingFunction: parseSimpleField,
        jsonPath: '$.MFActorCode',
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        style: {
            width: '50%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            pattern: 'ERRORS.actorCode'
        }
    },
    {
        propertyName: 'additionalDescription',
        name: 'DEVICECONSTANTS.mdr.udi.additionalDescription.name',
        description: '',
        parsingFunction: parseSimpleArray,
        initialValue: '',
        placeholder: 'DEVICECONSTANTS.mdr.udi.additionalDescription.placeholder',
        validators: [optionalWithRequired(['additionalProductDescriptionLanguage', 'additionalProductDescriptionText'])],
        disabled: false,
        controlType: FormControlType.REPEATABLE,
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'additionalProductDescriptionLanguage',
                name: 'DEVICECONSTANTS.mdr.udi.additionalProductDescriptionLanguage.name',
                description: 'DEVICECONSTANTS.mdr.udi.additionalProductDescriptionLanguage.description',
                initialValue: '',
                placeholder: 'FLD-EUD-010',
                disabled: false,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled',
                jsonPath: '$.additionalDescription[*].additionalProductDescriptionLanguage'
            },
            {
                propertyName: 'additionalProductDescriptionText',
                name: 'DEVICECONSTANTS.mdr.udi.additionalProductDescriptionText.name',
                description: 'DEVICECONSTANTS.mdr.udi.additionalProductDescriptionText.description',
                initialValue: '',
                placeholder: 'FLD-UDID-175',
                updateable: true,
                disabled: false,
                minLength: 1,
                maxLength: 2000,
                jsonPath: '$.additionalDescription[*].additionalProductDescriptionText',
                textareaRows: 6,
                controlType: FormControlType.TEXTAREA,
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            }
        ]
    },
    {
        propertyName: 'website',
        name: 'DEVICECONSTANTS.mdr.udi.website.name',
        description: 'DEVICECONSTANTS.mdr.udi.website.description',
        parsingFunction: parseSimpleField,
        validators: [Validators.maxLength(255)],
        initialValue: '',
        updateable: true,
        placeholder: 'FLD-UDID-174',
        disabled: false,
        controlType: FormControlType.INPUT,
        group: UDIGroups.DEVICE_INFORMATION,
        style: {
            width: '100%',
            marginBottom: '48px'
        },
        errorMessage: 'ERRORS.tooLong255',
        jsonPath: '$.website'
    },
    {
        propertyName: 'labeledAsSingleUse',
        name: 'DEVICECONSTANTS.mdr.udi.labeledAsSingleUse.name',
        description: 'DEVICECONSTANTS.mdr.udi.labeledAsSingleUse.description',
        validators: [Validators.required],
        initialValue: null,
        placeholder: 'FLD-UDID-167',
        disabled: false,
        jsonPath: '$.labeledAsSingleUse',
        controlType: FormControlType.CHECKBOX,
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'numberOfReuses',
        name: 'DEVICECONSTANTS.mdr.udi.numberOfReuses.name',
        description: 'DEVICECONSTANTS.mdr.udi.numberOfReuses.description',
        initialValue: '',
        placeholder: 'FLD-UDID-157',
        validationError: 'EUD-item0012',
        disabled: false,
        controlType: FormControlType.INPUT,
        jsonPath: '$.numberOfReuses',
        validators: [Validators.pattern(positiveIntegerPattern), Validators.minLength(1), Validators.maxLength(32767)],
        numericType: NumericTypes.INTEGER,
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        style: {
            marginBottom: '48px'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea32767',
            pattern: 'ERRORS.numbersOnly'
        }
    },
    {
        propertyName: 'reprocessed',
        name: 'DEVICECONSTANTS.mdr.udi.reprocessed.name',
        initialValue: null,
        placeholder: 'FLD-UDID-164',
        disabled: false,
        controlType: FormControlType.CHECKBOX,
        description: 'DEVICECONSTANTS.mdr.udi.reprocessed.description',
        jsonPath: '$.reprocessed',
        validators: [Validators.required],
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'sterile',
        name: 'DEVICECONSTANTS.mdr.udi.sterile.name',
        description: 'DEVICECONSTANTS.mdr.udi.sterile.description',
        parsingFunction: parseSimpleField,
        initialValue: null,
        placeholder: 'FLD-UDID-169',
        validators: [Validators.required],
        jsonPath: '$.sterile',
        disabled: false,
        controlType: FormControlType.CHECKBOX,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'sterilization',
        name: 'DEVICECONSTANTS.mdr.udi.sterilization.name',
        description: 'DEVICECONSTANTS.mdr.udi.sterilization.description',
        parsingFunction: parseSimpleField,
        initialValue: null,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-170',
        disabled: false,
        controlType: FormControlType.CHECKBOX,
        jsonPath: '$.sterilization',
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'latex',
        name: 'DEVICECONSTANTS.mdr.udi.latex.name',
        initialValue: null,
        placeholder: 'FLD-UDID-156',
        disabled: false,
        validators: [Validators.required],
        jsonPath: '$.latex',
        controlType: FormControlType.CHECKBOX,
        description: 'DEVICECONSTANTS.mdr.udi.latex.description',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'clinicalSize',
        name: 'DEVICECONSTANTS.mdr.udi.clinicalSize.name',
        initialValue: '', placeholder: 'DEVICECONSTANTS.mdr.udi.clinicalSize.placeholder',
        disabled: false,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['clinicalSizeType', 'clinicalSizePrecision'])],
        parsingFunction: parseArrayField,
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'clinicalSizeType',
                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeType.name',
                description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeType.description',
                initialValue: '',
                placeholder: 'FLD-UDID-190',
                disabled: false,
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                  const clinicalSizeTypeDescriptionControl = control.parent?.get('clinicalSizeTypeDescription');
                  const conditionType = control.value;

                  if (clinicalSizeTypeDescriptionControl && clinicalSizeTypeDescriptionControl instanceof FormArray) {
                    if (conditionType && conditionType === 'CST999') {
                      clinicalSizeTypeDescriptionControl.hidden = false;
                    } else {
                      clinicalSizeTypeDescriptionControl.hidden = true;
                      clinicalSizeTypeDescriptionControl.controls.forEach(x => {
                        x.get('clinicalSizeTypeDescriptionLanguage')?.setValue('');
                        x.get('clinicalSizeTypeDescriptionText')?.setValue('');
                      });
                    }
                  }
                },
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled',
                jsonPath: '$.clinicalSize[*].clinicalSizeType'
            },
            {
                propertyName: 'clinicalSizeTypeDescription',
                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypDescription.name',
                description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypDescription.description',
                initialValue: '',
                placeholder: 'FLD-UDID-195',
                disabled: false,
                hidden: true,
                validators: [optionalWithRequired(['clinicalSizeTypeDescriptionLanguage', 'clinicalSizeTypeDescriptionText'])],
                controlType: FormControlType.REPEATABLE,
                parsingFunction: parseSimpleArray,
                group: UDIGroups.DEVICE_INFORMATION,
                children: [
                    {
                        propertyName: 'clinicalSizeTypeDescriptionLanguage',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypeDescriptionLanguage.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypeDescriptionLanguage.description',
                        initialValue: '',
                        placeholder: 'FLD-EUD-010',
                        disabled: false,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled',
                        jsonPath: '$.clinicalSize[*].clinicalSizeTypeDescription[*].clinicalSizeTypeDescriptionLanguage'
                    },
                    {
                        propertyName: 'clinicalSizeTypeDescriptionText',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypeDescriptionText.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypeDescriptionText.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-358',
                        disabled: false,
                        minLength: 1,
                        maxLength: 2000,
                        jsonPath: '$.clinicalSize[*].clinicalSizeTypeDescription[*].clinicalSizeTypeDescriptionText',
                        textareaRows: 6,
                        controlType: FormControlType.TEXTAREA,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    },

                ]
            },
            {
                propertyName: 'clinicalSizePrecision',
                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizePrecision.name',
                description: 'DEVICECONSTANTS.mdr.udi.clinicalSizePrecision.description',
                initialValue: '',
                placeholder: 'FLD-UDID-191',
                disabled: false,
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                    const clinicalSizeValueControl = control.parent?.get('clinicalSizeValue');
                    const clinicalSizeTextControl = control.parent?.get('clinicalSizeText');
                    const clinicalSizeRangeControl = control.parent?.get('clinicalSizeRange');
                    const conditionType = control.value;

                    if (conditionType) {
                        if (conditionType === 'RANGE') {
                            clinicalSizeTextControl?.get('clinicalSizeTextValue')?.setValue('');
                            clinicalSizeValueControl?.get('clinicalSizeValueData')?.setValue('');
                            clinicalSizeValueControl?.get('clinicalSizeValueUnitOfMeasure')?.setValue('');

                            if (clinicalSizeValueControl && clinicalSizeTextControl && clinicalSizeRangeControl){
                                clinicalSizeValueControl.hidden = true;
                                clinicalSizeTextControl.hidden = true;
                                clinicalSizeRangeControl.hidden = false;
                            }

                        } else if (conditionType === 'TEXT') {
                            clinicalSizeRangeControl?.get('clinicalSizeMinimumValue')?.setValue('');
                            clinicalSizeRangeControl?.get('clinicalSizeMaximumValue')?.setValue('');
                            clinicalSizeRangeControl?.get('clinicalSizeRangeValueUnitOfMeasure')?.setValue('');
                            clinicalSizeValueControl?.get('clinicalSizeValueData')?.setValue('');
                            clinicalSizeValueControl?.get('clinicalSizeValueUnitOfMeasure')?.setValue('');

                            if (clinicalSizeValueControl && clinicalSizeTextControl && clinicalSizeRangeControl){
                                clinicalSizeValueControl.hidden = true;
                                clinicalSizeTextControl.hidden = false;
                                clinicalSizeRangeControl.hidden = true;
                            }
                        } else if (conditionType === 'VALUE') {
                            clinicalSizeRangeControl?.get('clinicalSizeMinimumValue')?.setValue('');
                            clinicalSizeRangeControl?.get('clinicalSizeMaximumValue')?.setValue('');
                            clinicalSizeRangeControl?.get('clinicalSizeRangeValueUnitOfMeasure')?.setValue('');
                            clinicalSizeTextControl?.get('clinicalSizeTextValue')?.setValue('');

                            if (clinicalSizeValueControl && clinicalSizeTextControl && clinicalSizeRangeControl){
                                clinicalSizeValueControl.hidden = false;
                                clinicalSizeTextControl.hidden = true;
                                clinicalSizeRangeControl.hidden = true;
                            }
                        }
                    } else {
                        if (clinicalSizeValueControl && clinicalSizeTextControl && clinicalSizeRangeControl){
                            clinicalSizeValueControl.hidden = true;
                            clinicalSizeTextControl.hidden = true;
                            clinicalSizeRangeControl.hidden = true;
                        }
                    }

                },
                controlType: FormControlType.DROPDOWN,
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled',
                jsonPath: '$.clinicalSize[*].clinicalSizePrecision'
            },
            {
                propertyName: 'clinicalSizeValue',
                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValue.name',
                initialValue: '',
                placeholder: '',
                disabled: false,
                hidden: true,
                controlType: FormControlType.NON_SHOWING,
                group: UDIGroups.DEVICE_INFORMATION,
                validators: [optionalWithRequired(['clinicalSizeValueData', 'clinicalSizeValueUnitOfMeasure'])],
                parsingFunction: parseComplexField,
                children: [
                    {
                        propertyName: 'clinicalSizeValueData',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueData.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueData.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-196',
                        disabled: false,
                        validators: [Validators.pattern(decimalPattern)],
                        numericType: NumericTypes.DECIMAL,
                        controlType: FormControlType.INPUT,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.decimalNumbersOnly',
                        jsonPath: '$.clinicalSize[*].clinicalSizeValue.clinicalSizeValueData'
                    },
                    {
                        propertyName: 'clinicalSizeValueUnitOfMeasure',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasure.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasure.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-195-VAL',
                        disabled: false,
                        onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                          const clinicalSizeTypeDescriptionControl = control.parent?.get('clinicalSizeValueUnitOfMeasureDescription');
                          const conditionType = control.value;

                          if (clinicalSizeTypeDescriptionControl && clinicalSizeTypeDescriptionControl instanceof FormArray) {
                            if (conditionType && conditionType === 'MU999') {
                              clinicalSizeTypeDescriptionControl.hidden = false;
                            } else {
                              clinicalSizeTypeDescriptionControl.hidden = true;
                              clinicalSizeTypeDescriptionControl.controls.forEach(x => {
                                x.get('clinicalSizeValueUnitOfMeasureDescriptionLanguage')?.setValue('');
                                x.get('clinicalSizeValueUnitOfMeasureDescriptionText')?.setValue('');
                              });
                            }
                          }
                        },
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled',
                        jsonPath: '$.clinicalSize[*].clinicalSizeValue.clinicalSizeValueUnitOfMeasure'
                    },
                    {
                        propertyName: 'clinicalSizeValueUnitOfMeasureDescription',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescription.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescription.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-195',
                        disabled: false,
                        hidden: true,
                        validators: [optionalWithRequired(['clinicalSizeValueUnitOfMeasureDescriptionLanguage',
                                                          'clinicalSizeValueUnitOfMeasureDescriptionText'])],
                        controlType: FormControlType.REPEATABLE,
                        parsingFunction: parseSimpleArray,
                        group: UDIGroups.DEVICE_INFORMATION,
                        children: [
                            {
                                propertyName: 'clinicalSizeValueUnitOfMeasureDescriptionLanguage',
                                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescriptionLanguage.name',
                              description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescriptionLanguage.description',
                              initialValue: '',
                              placeholder: 'FLD-EUD-010',
                              disabled: false,
                              controlType: FormControlType.SEARCHABLE_DROPDOWN,
                              parsingFunction: parseSimpleField,
                              group: UDIGroups.DEVICE_INFORMATION,
                              errorMessage: 'ERRORS.notFilled',
                              jsonPath: '$.clinicalSize[*].clinicalSizeValue.clinicalSizeValueUnitOfMeasureDescription[*].clinicalSizeValueUnitOfMeasureDescriptionLanguage'
                          },
                          {
                              propertyName: 'clinicalSizeValueUnitOfMeasureDescriptionText',
                              name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescriptionText.name',
                              description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescriptionText.description',
                              initialValue: '',
                              placeholder: 'FLD-UDID-359',
                              disabled: false,
                              minLength: 1,
                              maxLength: 2000,
                              jsonPath: '$.clinicalSize[*].clinicalSizeValue.clinicalSizeValueUnitOfMeasureDescription[*].clinicalSizeValueUnitOfMeasureDescriptionText',
                              textareaRows: 6,
                              controlType: FormControlType.TEXTAREA,
                              parsingFunction: parseSimpleField,
                              group: UDIGroups.DEVICE_INFORMATION,
                              errorMessage: {
                                required: 'ERRORS.notFilled',
                                maxlength: 'ERRORS.textArea2000'
                                }
                          },

                      ]
                    },

                ]
            },
            {
                propertyName: 'clinicalSizeRange',
                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRange.name',
                initialValue: '',
                placeholder: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRange.placeholder',
                disabled: false,
                hidden: true,
                controlType: FormControlType.NON_SHOWING,
                validators: [optionalWithRequired(['clinicalSizeMinimumValue', 'clinicalSizeMaximumValue', 'clinicalSizeRangeValueUnitOfMeasure'])],
                group: UDIGroups.DEVICE_INFORMATION,
                parsingFunction: parseComplexField,
                children: [
                    {
                        propertyName: 'clinicalSizeMinimumValue',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeMinimumValue.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeMinimumValue.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-193',
                        disabled: false,
                        controlType: FormControlType.INPUT,
                        validators: [Validators.pattern(decimalPattern)],
                        numericType: NumericTypes.DECIMAL,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.decimalNumbersOnly',
                        jsonPath: '$.clinicalSize[*].clinicalSizeRange.clinicalSizeMinimumValue'
                    },
                    {
                        propertyName: 'clinicalSizeMaximumValue',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeMaximumValue.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeMaximumValue.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-192',
                        disabled: false,
                        controlType: FormControlType.INPUT,
                        validators: [Validators.pattern(decimalPattern)],
                        numericType: NumericTypes.DECIMAL,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.decimalNumbersOnly',
                        jsonPath: '$.clinicalSize[*].clinicalSizeRange.clinicalSizeMaximumValue'
                    },
                    {
                        propertyName: 'clinicalSizeRangeValueUnitOfMeasure',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasure.name',
                        initialValue: '',
                        placeholder: 'FLD-UDID-195',
                        disabled: false,
                        onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                          const clinicalSizeTypeDescriptionControl = control.parent?.get('clinicalSizeRangeValueUnitOfMeasureDescription');
                          const conditionType = control.value;

                          if (clinicalSizeTypeDescriptionControl && clinicalSizeTypeDescriptionControl instanceof FormArray) {
                            if (conditionType && conditionType === 'MU999') {
                              clinicalSizeTypeDescriptionControl.hidden = false;
                            } else {
                              clinicalSizeTypeDescriptionControl.hidden = true;
                              clinicalSizeTypeDescriptionControl.controls.forEach(x => {
                                x.get('clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage')?.setValue('');
                                x.get('clinicalSizeRangeValueUnitOfMeasureDescriptionText')?.setValue('');
                              });
                            }
                          }
                        },
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasure.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled',
                        jsonPath: '$.clinicalSize[*].clinicalSizeRange.clinicalSizeRangeValueUnitOfMeasure'
                    },
                    {
                        propertyName: 'clinicalSizeRangeValueUnitOfMeasureDescription',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescription.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescription.description',
                        initialValue: '',
                        hidden: true,
                        placeholder: 'FLD-UDID-195',
                        validators: [optionalWithRequired(['clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage',
                                                          'clinicalSizeRangeValueUnitOfMeasureDescriptionText'])],
                                                          disabled: false,
                                                          controlType: FormControlType.REPEATABLE,
                                                          parsingFunction: parseSimpleArray,
                                                          group: UDIGroups.DEVICE_INFORMATION,
                                                          children: [
                                                              {
                                                                  propertyName: 'clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage',
                                                                  name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage.name',
                                                                  description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage.description',
                                                                  initialValue: '',
                                                                  placeholder: 'FLD-EUD-010',
                                                                  disabled: false,
                                                                  controlType: FormControlType.SEARCHABLE_DROPDOWN,
                                                                  parsingFunction: parseSimpleField,
                                                                  group: UDIGroups.DEVICE_INFORMATION,
                                                                  errorMessage: 'ERRORS.notFilled',
                                                                  jsonPath: '$.clinicalSize[*].clinicalSizeRange.clinicalSizeRangeValueUnitOfMeasureDescription[*].clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage'
                                                              },
                                                              {
                                                                  propertyName: 'clinicalSizeRangeValueUnitOfMeasureDescriptionText',
                                                                  name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescriptionText.name',
                                                                  description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescriptionText.description',
                                                                  initialValue: '',
                                                                  placeholder: 'FLD-UDID-359',
                                                                  disabled: false,
                                                                  minLength: 1,
                                                                  maxLength: 2000,
                                                                  textareaRows: 6,
                                                                  controlType: FormControlType.TEXTAREA,
                                                                  jsonPath: '$.clinicalSize[*].clinicalSizeRange.clinicalSizeRangeValueUnitOfMeasureDescription[*].clinicalSizeRangeValueUnitOfMeasureDescriptionText',
                                                                  parsingFunction: parseSimpleField,
                                                                  group: UDIGroups.DEVICE_INFORMATION,
                                                                  errorMessage: {
                                                                    required: 'ERRORS.notFilled',
                                                                    maxlength: 'ERRORS.textArea2000'
                                                                    }
                                                              },

                                                          ]
                    },
                ],
            },
            {
                propertyName: 'clinicalSizeText',
                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeText.name',
                initialValue: '', placeholder: 'DEVICECONSTANTS.mdr.udi.clinicalSizeText.placeholder',
                disabled: false,
                hidden: true,
                controlType: FormControlType.NON_SHOWING,
                validators: [optionalWithRequired(['clinicalSizeTextValue'])],
                parsingFunction: parseComplexField,
                group: UDIGroups.DEVICE_INFORMATION,
                children: [
                    {
                        propertyName: 'clinicalSizeTextValue',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTextValue.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTextValue.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-194',
                        disabled: false,
                        controlType: FormControlType.INPUT,
                        jsonPath: '$.clinicalSize[*].clinicalSizeText.clinicalSizeTextValue',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        style: {
                            width: '100%'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(255)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.tooLong255'
                        }
                    }
                ]
            }
        ]
    },
    {
        propertyName: 'storageHandlingCondition',
        name: 'DEVICECONSTANTS.mdr.udi.storageHandlingCondition.name',
        description: '',
        group: UDIGroups.DEVICE_INFORMATION,
        initialValue: '',
        placeholder: '',
        disabled: false,
        validators: [optionalWithRequired(['storageHandlingConditionType'])],
        controlType: FormControlType.REPEATABLE,
        parsingFunction: parseArrayField,
        children: [
            {
                propertyName: 'storageHandlingConditionType',
                name: 'DEVICECONSTANTS.mdr.udi.storageHandlingConditionType.name',
                description: 'DEVICECONSTANTS.mdr.udi.storageHandlingConditionType.description',
                initialValue: '',
                updateable: true,
                placeholder: 'FLD-UDID-211',
                disabled: false,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                errorMessage: 'ERRORS.notFilled',
                parsingFunction: parseSimpleField,
                jsonPath: '$.storageHandlingCondition[*].storageHandlingConditionType',
                group: UDIGroups.DEVICE_INFORMATION,
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput, injector: Injector) => {
                    conditionalDescriptionWithLanguage(injector, control, 'storageHandlingConditionComment', 'storageHandlingConditionCommentLanguage', 'storageHandlingConditionCommentText', StorageHandlingConditionTypeCodeList);
                },
            },
            {
                propertyName: 'storageHandlingConditionComment',
                name: 'DEVICECONSTANTS.mdr.udi.storageHandlingConditionComment.name',
                description: 'DEVICECONSTANTS.mdr.udi.storageHandlingConditionComment.description',
                group: UDIGroups.DEVICE_INFORMATION,
                initialValue: '',
                validators: [optionalWithRequiredParticular(['storageHandlingConditionCommentLanguage', 'storageHandlingConditionCommentText'], 'storageHandlingConditionType', 'SHC099')],
                placeholder: '',
                disabled: false,
                controlType: FormControlType.REPEATABLE,
                parsingFunction: parseSimpleArray,
                children: [
                    {
                        propertyName: 'storageHandlingConditionCommentLanguage',
                        name: 'DEVICECONSTANTS.mdr.udi.storageHandlingConditionCommentLanguage.name',
                        description: 'DEVICECONSTANTS.mdr.udi.storageHandlingConditionCommentLanguage.description',
                        initialValue: '',
                        placeholder: 'FLD-EUD-010',
                        disabled: false,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled',
                        jsonPath: '$.storageHandlingConditionComment[*].storageHandlingConditionCommentLanguage',
                    },
                    {
                        propertyName: 'storageHandlingConditionCommentText',
                        name: 'DEVICECONSTANTS.mdr.udi.storageHandlingConditionCommentText.name',
                        description: 'DEVICECONSTANTS.mdr.udi.storageHandlingConditionCommentText.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-213',
                        disabled: false,
                        updateable: true,
                        controlType: FormControlType.TEXTAREA,
                        jsonPath: '$.storageHandlingConditionComment[*].storageHandlingConditionCommentText',
                        textareaRows: 6,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            maxlength: 'ERRORS.textArea2000',
                            required: 'ERRORS.notFilled'
                        }
                    }
                ]
            },
        ]
    },
    {
        propertyName: 'criticalWarning',
        name: 'DEVICECONSTANTS.mdr.udi.criticalWarning.name',
        group: UDIGroups.DEVICE_INFORMATION,
        description: '',
        initialValue: '',
        validators: [optionalWithRequired(['criticalWarningValue'])],
        placeholder: 'DEVICECONSTANTS.mdr.udi.criticalWarning.placeholder',
        disabled: false,
        controlType: FormControlType.REPEATABLE,
        parsingFunction: parseArrayField,
        children: [
            {
                propertyName: 'criticalWarningValue',
                name: 'DEVICECONSTANTS.mdr.udi.criticalWarningValue.name',
                description: 'DEVICECONSTANTS.mdr.udi.criticalWarningValue.description',
                updateable: true,
                parsingFunction: parseSimpleField,
                initialValue: '',
                jsonPath: '$.criticalWarning[*].criticalWarningValue',
                placeholder: 'FLD-UDID-212',
                disabled: false,
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput, injector: Injector) => {
                    conditionalDescriptionWithLanguage(injector, control, 'criticalWarningComment', 'criticalWarningCommentLanguage', 'criticalWarningCommentText', CriticalWarningValueCodeList);
                },
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'criticalWarningComment',
                name: 'DEVICECONSTANTS.mdr.udi.criticalWarningComment.name',
                description: '',
                group: UDIGroups.DEVICE_INFORMATION,
                initialValue: '',
                placeholder: 'DEVICECONSTANTS.mdr.udi.criticalWarningComment.placeholder',
                validators: [optionalWithRequiredParticular(['criticalWarningCommentLanguage', 'criticalWarningCommentText'], 'criticalWarningValue', 'CW999')],
                disabled: false,
                controlType: FormControlType.REPEATABLE,
                parsingFunction: parseSimpleArray,
                children: [
                    {
                        propertyName: 'criticalWarningCommentLanguage',
                        name: 'DEVICECONSTANTS.mdr.udi.criticalWarningCommentLanguage.name',
                        description: 'DEVICECONSTANTS.mdr.udi.criticalWarningCommentLanguage.description',
                        initialValue: '',
                        placeholder: 'FLD-EUD-010',
                        disabled: false,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        jsonPath: '$.criticalWarning[*].criticalWarningComment[*].criticalWarningCommentLanguage',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'criticalWarningCommentText',
                        name: 'DEVICECONSTANTS.mdr.udi.criticalWarningCommentText.name',
                        description: 'DEVICECONSTANTS.mdr.udi.criticalWarningCommentText.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-319',
                        updateable: true,
                        jsonPath: '$.criticalWarning[*].criticalWarningComment[*].criticalWarningCommentText',
                        disabled: false,
                        controlType: FormControlType.TEXTAREA,
                        textareaRows: 6,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            maxlength: 'ERRORS.textArea2000',
                            required: 'ERRORS.notFilled'
                        }
                    }
                ]
            }
        ]
    },
    {
        propertyName: 'CMRSubstance',
        name: 'DEVICECONSTANTS.mdr.udi.CMRSubstance.name',
        description: '',
        initialValue: '',
        placeholder: '',
        disabled: false,
        validators: [optionalWithRequired(['CMRSubstanceCategory'])],
        controlType: FormControlType.REPEATABLE,
        parsingFunction: parseArrayField,
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'CMRSubstanceName',
                name: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceName.name',
                group: UDIGroups.DEVICE_INFORMATION,
                description: '',
                initialValue: '', placeholder: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceName.placeholder',
                validators: [optionalWithRequired(['CMRSubstanceNameLanguage', 'CMRSubstanceNameText'])],
                disabled: false,
                controlType: FormControlType.REPEATABLE,
                parsingFunction: parseSimpleArray,
                children: [
                    {
                        propertyName: 'CMRSubstanceNameLanguage',
                        name: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceNameLanguage.name',
                        description: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceNameLanguage.description',
                        initialValue: '',
                        placeholder: 'FLD-EUD-010',
                        disabled: false,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        jsonPath: '$.CMRSubstance[*].CMRSubstanceName[*].CMRSubstanceNameLanguage',
                        errorMessage: 'ERRORS.notFilled',
                    },
                    {
                        propertyName: 'CMRSubstanceNameText',
                        name: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceNameText.name',
                        description: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceNameText.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-201',
                        updateable: true,
                        disabled: false,
                        controlType: FormControlType.INPUT,
                        jsonPath: '$.CMRSubstance[*].CMRSubstanceName[*].CMRSubstanceNameText',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    },
                ]
            },
            {
                propertyName: 'CMRSubstanceCASCode',
                name: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceCASCode.name',
                description: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceCASCode.description',
                initialValue: '',
                updateable: true,
                placeholder: 'FLD-UDID-202',
                disabled: false,
                controlType: FormControlType.INPUT,
                jsonPath: '$.CMRSubstance[*].CMRSubstanceCASCode',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(12), Validators.pattern(cascodePattern)],
                errorMessage: {
                    pattern: 'ERRORS.cascode',
                    maxlength: 'ERRORS.mustBeBetween1And12'
                }
            },
            {
                propertyName: 'CMRSubstanceECCode',
                name: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceECCode.name',
                description: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceECCode.description',
                initialValue: '',
                placeholder: 'FLD-UDID-203',
                jsonPath: '$.CMRSubstance[*].CMRSubstanceECCode',
                disabled: false,
                updateable: true,
                controlType: FormControlType.INPUT,
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                style: {
                    width: '50%'
                },
                validators: [Validators.maxLength(9), Validators.minLength(9), Validators.pattern(eccodePattern)],
                errorMessage: {
                    pattern: 'ERRORS.eccode',
                    maxlength: 'ERRORS.stringWith9'
                }
            },
            {
                propertyName: 'CMRSubstanceCategory',
                name: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceCategory.name',
                initialValue: '',
                placeholder: 'FLD-UDID-200',
                updateable: true,
                disabled: false,
                controlType: FormControlType.DROPDOWN,
                jsonPath: '$.CMRSubstance[*].CMRSubstanceCategory',
                description: 'DEVICECONSTANTS.mdr.udi.CMRSubstanceCategory.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                style: {
                    width: '50%'
                },
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'endocrineSubstance',
        description: '',
        initialValue: '', placeholder: 'DEVICECONSTANTS.mdr.udi.endocrineSubstance.placeholder',
        disabled: false,
        controlType: FormControlType.REPEATABLE,
        name: 'DEVICECONSTANTS.mdr.udi.endocrineSubstance.name',
        group: UDIGroups.DEVICE_INFORMATION,
        parsingFunction: parseArrayField,
        children: [
            {
                propertyName: 'endocrineSubstanceName',
                name: 'DEVICECONSTANTS.mdr.udi.endocrineSubstanceName.name',
                description: '',
                initialValue: '', placeholder: 'DEVICECONSTANTS.mdr.udi.endocrineSubstanceName.placeholder',
                disabled: false,
                controlType: FormControlType.REPEATABLE,
                validators: [optionalWithRequired(['endocrineSubstanceNameLanguage', 'endocrineSubstanceNameText'])],
                parsingFunction: parseSimpleArray,
                group: UDIGroups.DEVICE_INFORMATION,
                children: [
                    {
                        propertyName: 'endocrineSubstanceNameLanguage',
                        name: 'DEVICECONSTANTS.mdr.udi.endocrineSubstanceNameLanguage.name',
                        description: 'DEVICECONSTANTS.mdr.udi.endocrineSubstanceNameLanguage.description',
                        initialValue: '',
                        placeholder: 'FLD-EUD-010',
                        disabled: false,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        parsingFunction: parseSimpleField,
                        jsonPath: '$.endocrineSubstance[*].endocrineSubstanceName[*].endocrineSubstanceNameLanguage',
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'endocrineSubstanceNameText',
                        name: 'DEVICECONSTANTS.mdr.udi.endocrineSubstanceNameText.name',
                        description: 'DEVICECONSTANTS.mdr.udi.endocrineSubstanceNameText.description',
                        initialValue: '',
                        updateable: true,
                        placeholder: 'FLD-UDID-313',
                        disabled: false,
                        controlType: FormControlType.INPUT,
                        parsingFunction: parseSimpleField,
                        jsonPath: '$.endocrineSubstance[*].endocrineSubstanceName[*].endocrineSubstanceNameText',
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    },
                ]
            },
            {
                propertyName: 'endocrineSubstanceCASCode',
                name: 'DEVICECONSTANTS.mdr.udi.endocrineSubstanceCASCode.name',
                description: 'DEVICECONSTANTS.mdr.udi.endocrineSubstanceCASCode.description',
                initialValue: '',
                placeholder: 'FLD-UDID-314',
                disabled: false,
                updateable: true,
                jsonPath: '$.endocrineSubstance[*].endocrineSubstanceCASCode',
                controlType: FormControlType.INPUT,
                validators: [Validators.minLength(1), Validators.maxLength(12)],
                errorMessage: 'ERRORS.mustBeBetween1And12',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                style: {
                    width: '50%'
                }
            },
            {
                propertyName: 'endocrineSubstanceECCode',
                name: 'DEVICECONSTANTS.mdr.udi.endocrineSubstanceECCode.name',
                description: 'DEVICECONSTANTS.mdr.udi.endocrineSubstanceECCode.description',
                initialValue: '',
                placeholder: 'FLD-UDID-315',
                updateable: true,
                disabled: false,
                jsonPath: '$.endocrineSubstance[*].endocrineSubstanceECCode',
                controlType: FormControlType.INPUT,
                validators: [Validators.maxLength(9), Validators.minLength(9)],
                errorMessage: 'ERRORS.stringWith9',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                style: {
                    width: '50%'
                }
            }
        ]
    },
    {
        propertyName: 'referenceNumber',
        name: 'DEVICECONSTANTS.mdr.udi.referenceNumber.name',
        description: 'DEVICECONSTANTS.mdr.udi.referenceNumber.description',
        parsingFunction: parseSimpleField,
        validators: [Validators.required, Validators.maxLength(255)],
        initialValue: '',
        placeholder: 'FLD-UDID-163',
        disabled: false,
        controlType: FormControlType.INPUT,
        jsonPath: '$.referenceNumber',
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        style: {
            width: '100%'
        },
        errorMessage: {
          required: 'ERRORS.notFilled',
          maxlength: 'ERRORS.tooLong255'
        }
    },
    {
        propertyName: 'basicUDIIdentifier',
        name: 'DEVICECONSTANTS.mdr.udi.basicUDIIdentifier.name',
        group: UDIGroups.IDENTIFICATION,
        description: '',
        initialValue: '',
        placeholder: 'FLD-UDID-145',
        validators: [optionalWithRequired(['basicUDIIdentifierDICode', 'basicUDIIdentifierIssuingEntityCode'])],
        disabled: false,
        controlType: FormControlType.BASIC_ASYNC_SELECT,
        parsingFunction: parseComplexField,
        style: {
            marginBottom: '48px'
        },
        children: [
            {
                propertyName: 'basicUDIIdentifierDICode',
                name: 'DEVICECONSTANTS.mdr.udi.basicUDIIdentifierDICode.name',
                description: 'DEVICECONSTANTS.mdr.udi.basicUDIIdentifierDICode.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                placeholder: 'FLD-UDID-145',
                disabled: false,
                jsonPath: '$.basicUDIIdentifier.basicUDIIdentifierDICode',
                controlType: FormControlType.INPUT,
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '50%'
                },
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)]
            },
            {
                propertyName: 'basicUDIIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.mdr.udi.basicUDIIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.mdr.udi.basicUDIIdentifierIssuingEntityCode.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                jsonPath: '$.basicUDIIdentifier.basicUDIIdentifierIssuingEntityCode',
                placeholder: 'FLD-UDID-01',
                disabled: false,
                controlType: FormControlType.DROPDOWN,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'productionIdentifier',
        name: 'DEVICECONSTANTS.mdr.udi.productionIdentifier.name',
        description: 'DEVICECONSTANTS.mdr.udi.productionIdentifier.description',
        parsingFunction: parseSimpleField,
        initialValue: '',
        placeholder: 'FLD-UDID-148',
        validators: [Validators.required],
        disabled: false,
        jsonPath: '$.productionIdentifier',
        controlType: FormControlType.REPEATABLE_SELECT,
        updateable: true,
        group: UDIGroups.IDENTIFICATION,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'tradeName',
        name: 'DEVICECONSTANTS.mdr.udi.tradeName.name',
        description: '',
        initialValue: '',
        placeholder: 'DEVICECONSTANTS.mdr.udi.tradeName.placeholder',
        disabled: false,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['tradeNameLanguage', 'tradeNameText', 'tradeNameSequenceNumber'])],
        group: UDIGroups.DEVICE_INFORMATION,
        parsingFunction: parseSimpleArray,
        children: [
            {
                propertyName: 'tradeNameLanguage',
                name: 'DEVICECONSTANTS.mdr.udi.tradeNameLanguage.name',
                description: 'DEVICECONSTANTS.mdr.udi.tradeNameLanguage.description',
                initialValue: '',
                placeholder: 'FLD-EUD-010',
                disabled: false,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                parsingFunction: parseSimpleField,
                jsonPath: '$.tradeName[*].tradeNameLanguage',
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled',
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                    const tradeNameSequenceNumber = control.parent?.get('tradeNameSequenceNumber');

                    if (control.value) {
                        tradeNameSequenceNumber?.setValue('1');
                    }else{
                        tradeNameSequenceNumber?.setValue('');
                    }
                }
            },
            {
                propertyName: 'tradeNameText',
                name: 'DEVICECONSTANTS.mdr.udi.tradeNameText.name',
                description: 'DEVICECONSTANTS.mdr.udi.tradeNameText.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                updateable: true,
                placeholder: 'FLD-UDID-176',
                disabled: false,
                controlType: FormControlType.TEXTAREA,
                jsonPath: '$.tradeName[*].tradeNameText',
                textareaRows: 6,
                group: UDIGroups.DEVICE_INFORMATION,
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            },
            {
                propertyName: 'tradeNameSequenceNumber',
                name: 'DEVICECONSTANTS.mdr.udi.tradeNameSequenceNumber.name',
                description: 'DEVICECONSTANTS.mdr.udi.tradeNameSequenceNumber.description',
                initialValue: '',
                placeholder: 'DEVICECONSTANTS.mdr.udi.tradeNameSequenceNumber.placeholder',
                controlType: FormControlType.INPUT,
                validators: [Validators.pattern(positiveIntegerPattern), Validators.minLength(1), Validators.maxLength(32767)],
                numericType: NumericTypes.INTEGER,
                jsonPath: '$.tradeName[*].tradeNameSequenceNumber',
                disabled: true,
                maxLength: 32767,
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                validationError: 'EUD-item0026',
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea32767',
                    pattern: 'ERRORS.numbersOnly'
                }
            },
        ]
    },
    {
        propertyName: 'package',
        name: 'DEVICECONSTANTS.mdr.udi.package.name',
        group: UDIGroups.PACKAGE_ITEM,
        description: '',
        initialValue: '',
        placeholder: '',
        validators: [optionalWithRequired([
            'packageIdentifierDICode',
            'packageIdentifierIssuingEntityCode',
            'packageStatusCode',
            'childIdentifierDICode',
            'childIdentifierIssuingEntityCode',
            'numberOfItems'
        ])],
        disabled: false,
        controlType: FormControlType.REPEATABLE,
        parsingFunction: parseArrayField,
        children: [
            {
                propertyName: 'packageIdentifierDICode',
                name: 'DEVICECONSTANTS.mdr.udi.packageIdentifierDICode.name',
                description: 'DEVICECONSTANTS.mdr.udi.packageIdentifierDICode.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                placeholder: 'FLD-UDID-120',
                jsonPath: '$.tradeName[*].tradeNameSequenceNumber',
                disabled: false,
                controlType: FormControlType.INPUT,
                updateable: true,
                group: UDIGroups.PACKAGE_ITEM,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'packageIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.mdr.udi.packageIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.mdr.udi.packageIdentifierIssuingEntityCode.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                placeholder: 'FLD-UDID-297',
                disabled: false,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                updateable: true,
                group: UDIGroups.PACKAGE_ITEM,
                style: {
                  width: '100%'
                },
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'packageStatusCode',
                name: 'DEVICECONSTANTS.mdr.udi.packageStatusCode.name',
                description: 'DEVICECONSTANTS.mdr.udi.packageStatusCode.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                // updateable: true,
                placeholder: 'FLD-UDID-130',
                disabled: false,
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                group: UDIGroups.PACKAGE_ITEM,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'childIdentifierDICode',
                name: 'DEVICECONSTANTS.mdr.udi.childIdentifierDICode.name',
                description: 'DEVICECONSTANTS.mdr.udi.childIdentifierDICode.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                placeholder: 'FLD-UDID-124',
                disabled: false,
                controlType: FormControlType.INPUT,
                updateable: true,
                group: UDIGroups.PACKAGE_ITEM,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'childIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.mdr.udi.childIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.mdr.udi.childIdentifierIssuingEntityCode.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                placeholder: 'FLD-UDID-01',
                disabled: false,
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                group: UDIGroups.PACKAGE_ITEM,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'numberOfItems',
                name: 'DEVICECONSTANTS.mdr.udi.numberOfItems.name',
                description: 'DEVICECONSTANTS.mdr.udi.numberOfItems.description',
                initialValue: '',
                placeholder: 'FLD-UDID-121',
                validators: [Validators.pattern(positiveIntegerPattern)],
                numericType: NumericTypes.INTEGER,
                disabled: false,
                controlType: FormControlType.INPUT,
                updateable: true,
                parsingFunction: parseSimpleField,
                group: UDIGroups.PACKAGE_ITEM,
                errorMessage: 'ERRORS.numbersOnly'
             }
        ]
    },
     {
         propertyName: 'substatus',
         name: 'DEVICECONSTANTS.mdr.udi.substatus.name',
         description: '',
         parsingFunction: parseArrayField,
         initialValue: '',
         placeholder: 'DEVICECONSTANTS.mdr.udi.substatus.placeholder',
         disabled: false,
         validators: [optionalWithRequired(['substatusCode', 'substatusStartDate'])],
         controlType: FormControlType.REPEATABLE,
         group: UDIGroups.IDENTIFICATION,
         children: [
             {
                 propertyName: 'substatusCode',
                 name: 'DEVICECONSTANTS.mdr.udi.substatusCode.name',
                 description: 'DEVICECONSTANTS.mdr.udi.substatusCode.description',
                 parsingFunction: parseSimpleField,
                 initialValue: '',
                 updateable: true,
                 placeholder: 'FLD-UDID-131',
                 jsonPath: '$.package[*].substatusCode',
                 disabled: false,
                 controlType: FormControlType.DROPDOWN,
                 group: UDIGroups.IDENTIFICATION,
                 errorMessage: 'ERRORS.notFilled'
             },
             {
                 propertyName: 'substatusStartDate',
                 name: 'DEVICECONSTANTS.mdr.udi.substatusStartDate.name',
                 description: 'DEVICECONSTANTS.mdr.udi.substatusStartDate.description',
                 parsingFunction: parseSimpleField,
                 updateable: true,
                 initialValue: '',
                 placeholder: 'FLD-UDID-126',
                 jsonPath: '$.package[*].substatusStartDate',
                 disabled: false,
                 controlType: FormControlType.DATE,
                 group: UDIGroups.IDENTIFICATION,
                 errorMessage: 'ERRORS.notFilled'
             },
             {
                 propertyName: 'substatusEndDate',
                 name: 'DEVICECONSTANTS.mdr.udi.substatusEndDate.name',
                 description: 'DEVICECONSTANTS.mdr.udi.substatusEndDate.description',
                 parsingFunction: parseSimpleField,
                 updateable: true,
                 initialValue: '',
                 placeholder: 'FLD-UDID-127',
                 jsonPath: '$.package[*].substatusEndDate',
                 disabled: false,
                 controlType: FormControlType.DATE,
                 group: UDIGroups.IDENTIFICATION
            },
             {
                propertyName: 'substatusRecalledPrecision',
                name: 'DEVICECONSTANTS.mdr.udi.substatusRecalledPrecision.name',
                description: '',
                parsingFunction: parseSimpleArray,
                initialValue: '',
                placeholder: '',
                validators: [optionalWithRequired(['substatusRecalledPrecisionLanguage', 'substatusRecalledPrecisionText'])],
                disabled: false,
                controlType: FormControlType.REPEATABLE,
                group: UDIGroups.IDENTIFICATION,
                children: [
                    {
                        propertyName: 'substatusRecalledPrecisionLanguage',
                        name: 'DEVICECONSTANTS.mdr.udi.substatusRecalledPrecisionLanguage.name',
                        description: 'DEVICECONSTANTS.mdr.udi.substatusRecalledPrecisionLanguage.description',
                        parsingFunction: parseSimpleField,
                        initialValue: '',
                        placeholder: 'FLD-EUD-010',
                        disabled: false,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        jsonPath: '$.package[*].substatusRecalledPrecision[*].substatusRecalledPrecisionLanguage',
                        group: UDIGroups.IDENTIFICATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'substatusRecalledPrecisionText',
                        name: 'DEVICECONSTANTS.mdr.udi.substatusRecalledPrecisionText.name',
                        description: 'DEVICECONSTANTS.mdr.udi.substatusRecalledPrecisionText.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-122',
                        updateable: true,
                        disabled: false,
                        textareaRows: 6,
                        controlType: FormControlType.TEXTAREA,
                        parsingFunction: parseSimpleField,
                        jsonPath: '$.package[*].substatusRecalledPrecision[*].substatusRecalledPrecisionText',
                        group: UDIGroups.IDENTIFICATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    }
                ]
            },
             {
                propertyName: 'substatusRecallScope',
                name: 'DEVICECONSTANTS.mdr.udi.substatusRecallScope.name',
                description: 'DEVICECONSTANTS.mdr.udi.substatusRecallScope.description',
                initialValue: '',
                placeholder: 'FLD-UDID-123',
                jsonPath: '$.package[*].substatusRecallScope',
                disabled: false,
                updateable: true,
                controlType: FormControlType.DROPDOWN,
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION
            }
        ]
    },
    {
        propertyName: 'relatedUDILink',
        name: 'DEVICECONSTANTS.mdr.udi.relatedUDILink.name',
        description: '',
        parsingFunction: parseComplexField,
        initialValue: '',
        placeholder: 'DEVICECONSTANTS.mdr.udi.relatedUDILink.placeholder',
        validators: [optionalWithRequired(['relatedUDILinkDICode', 'relatedUDILinkIssuingEntityCode', 'relatedUDILinkType'])],
        disabled: false,
        hidden: true,
        controlType: FormControlType.NON_SHOWING,
        group: UDIGroups.REFERENCED_DEVICE,
        children: [
            {
                propertyName: 'relatedUDILinkDICode',
                name: 'DEVICECONSTANTS.mdr.udi.relatedUDILinkDICode.name',
                description: 'DEVICECONSTANTS.mdr.udi.relatedUDILinkDICode.description',
                initialValue: '',
                updateable: true,
                placeholder: 'FLD-UDID-180',
                jsonPath: '$.relatedUDILink.relatedUDILinkDICode',
                disabled: false,
                controlType: FormControlType.INPUT,
                parsingFunction: parseSimpleField,
                group: UDIGroups.REFERENCED_DEVICE,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'relatedUDILinkIssuingEntityCode',
                name: 'DEVICECONSTANTS.mdr.udi.relatedUDILinkIssuingEntityCode.name',
                initialValue: '',
                placeholder: 'FLD-UDID-341',
                disabled: false,
                jsonPath: '$.relatedUDILink.relatedUDILinkDICode',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                description: 'DEVICECONSTANTS.mdr.udi.relatedUDILinkIssuingEntityCode.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.REFERENCED_DEVICE,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'relatedUDILinkType',
                name: 'DEVICECONSTANTS.mdr.udi.relatedUDILinkType.name',
                initialValue: '',
                placeholder: 'FLD-UDID-181',
                disabled: false,
                updateable: true,
                jsonPath: '$.relatedUDILink.relatedUDILinkType',
                controlType: FormControlType.DROPDOWN,
                description: 'DEVICECONSTANTS.mdr.udi.relatedUDILinkType.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.REFERENCED_DEVICE,
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'marketInfoPlacingOnTheEU',
        name: 'DEVICECONSTANTS.mdr.udi.marketInfoPlacingOnTheEU.name',
        description: '',
        group: UDIGroups.MARKET_INFO,
        initialValue: '',
        placeholder: '',
        disabled: false,
        validators: [optionalWithRequired(['marketInfoPlacingOnTheEUCountry', 'marketInfoOriginalPlacedOnTheMarket'])],
        controlType: FormControlType.NON_SHOWING,
        parsingFunction: parseComplexField,
        children: [
            {
                propertyName: 'marketInfoPlacingOnTheEUCountry',
                name: 'DEVICECONSTANTS.mdr.udi.marketInfoPlacingOnTheEUCountry.name',
                description: 'DEVICECONSTANTS.mdr.udi.marketInfoPlacingOnTheEUCountry.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                jsonPath: '$.marketInfoPlacingOnTheEU.marketInfoPlacingOnTheEUCountry',
                placeholder: 'FLD-UDID-137',
                disabled: false,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                group: UDIGroups.MARKET_INFO,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'marketInfoPlacingOnTheEUStartDate',
                name: 'DEVICECONSTANTS.mdr.udi.marketInfoPlacingOnTheEUStartDate.name',
                description: 'DEVICECONSTANTS.mdr.udi.marketInfoPlacingOnTheEUStartDate.description',
                initialValue: '',
                placeholder: 'FLD-UDID-250',
                jsonPath: '$.marketInfoPlacingOnTheEU.marketInfoPlacingOnTheEUStartDate',
                disabled: false,
                controlType: FormControlType.DATE,
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoPlacingOnTheEUEndDate',
                name: 'DEVICECONSTANTS.mdr.udi.marketInfoPlacingOnTheEUEndDate.name',
                description: 'DEVICECONSTANTS.mdr.udi.marketInfoPlacingOnTheEUEndDate.description',
                initialValue: '',
                jsonPath: '$.marketInfoPlacingOnTheEU.marketInfoPlacingOnTheEUEndDate',
                placeholder: 'FLD-UDID-251',
                disabled: false,
                controlType: FormControlType.DATE,
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoOriginalPlacedOnTheMarket',
                name: 'DEVICECONSTANTS.mdr.udi.marketInfoOriginalPlacedOnTheMarket.name',
                placeholder: 'FLD-UDID-137',
                disabled: false,
                controlType: FormControlType.CHECKBOX,
                jsonPath: '$.marketInfoPlacingOnTheEU.marketInfoOriginalPlacedOnTheMarket',
                description: 'DEVICECONSTANTS.mdr.udi.marketInfoOriginalPlacedOnTheMarket.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO,
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    yesValidator: 'ERRORS.yesValidator'
                }
            },
        ]
    },
    {
        propertyName: 'marketInfoMadeAvailable',
        name: 'DEVICECONSTANTS.mdr.udi.marketInfoMadeAvailable.name',
        description: '',
        group: UDIGroups.MARKET_INFO,
        initialValue: '',
        placeholder: '',
        validators: [optionalWithRequired(['marketInfoMadeAvailableCountry', 'marketInfoOriginalPlacedOnTheMarket'])],
        disabled: false,
        controlType: FormControlType.REPEATABLE,
        parsingFunction: parseArrayField,
        children: [
            {
                propertyName: 'marketInfoMadeAvailableCountry',
                name: 'DEVICECONSTANTS.mdr.udi.marketInfoMadeAvailableCountry.name',
                description: 'DEVICECONSTANTS.mdr.udi.marketInfoMadeAvailableCountry.description',
                initialValue: '',
                placeholder: 'FLD-UDID-252',
                jsonPath: '$.marketInfoMadeAvailable[*].marketInfoMadeAvailableCountry',
                disabled: false,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'marketInfoMadeAvailableStartDate',
                name: 'DEVICECONSTANTS.mdr.udi.marketInfoMadeAvailableStartDate.name',
                description: 'DEVICECONSTANTS.mdr.udi.marketInfoMadeAvailableStartDate.description',
                parsingFunction: parseSimpleField,
                initialValue: '',
                placeholder: 'FLD-UDID-250',
                jsonPath: '$.marketInfoMadeAvailable[*].marketInfoMadeAvailableStartDate',
                disabled: false,
                controlType: FormControlType.DATE,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoMadeAvailableEndDate',
                name: 'DEVICECONSTANTS.mdr.udi.marketInfoMadeAvailableEndDate.name',
                description: 'DEVICECONSTANTS.mdr.udi.marketInfoMadeAvailableEndDate.description',
                initialValue: '',
                placeholder: 'FLD-UDID-251',
                disabled: false,
                controlType: FormControlType.DATE,
                jsonPath: '$.marketInfoMadeAvailable[*].marketInfoMadeAvailableEndDate',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoOriginalPlacedOnTheMarket',
                name: 'DEVICECONSTANTS.mdr.udi.marketInfoOriginalPlacedOnTheMarket.name',
                description: 'DEVICECONSTANTS.mdr.udi.marketInfoOriginalPlacedOnTheMarket.description',
                placeholder: 'FLD-UDID-252',
                disabled: false,
                jsonPath: '$.marketInfoMadeAvailable[*].marketInfoOriginalPlacedOnTheMarket',
                controlType: FormControlType.CHECKBOX,
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO,
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    noValidator: 'ERRORS.noValidator'
                }
            },
        ]
    },
    {
        propertyName: 'productDesignerActorCode',
        name: 'DEVICECONSTANTS.mdr.udi.productDesignerActorCode.name',
        description: 'DEVICECONSTANTS.mdr.udi.productDesignerActorCode.description',
        initialValue: '',
        placeholder: 'FLD-UDID-221',
        updateable: true,
        jsonPath: '$.productDesignerActorCode',
        disabled: false,
        controlType: FormControlType.INPUT,
        errorMessage: 'ERRORS.actorCode',
        validators: [Validators.pattern(actorCodePattern)],
        parsingFunction: parseSimpleField,
        group: UDIGroups.PRODUCT_DESIGNER,
        style: {
            width: '50%',
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'productDesignerOrganisation',
        name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisation.name',
        description : '',
        initialValue: '',
        placeholder: '',
        disabled: false,
        controlType: FormControlType.NON_SHOWING,
        validators: [optionalWithRequired([
            'productDesignerOrganisationNameLanguage',
            'productDesignerOrganisationNameText'
        ])],
        parsingFunction: parseComplexField,
        group: UDIGroups.PRODUCT_DESIGNER,
        children: [
            {
                propertyName: 'productDesignerOrganisationNameText',
                name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationNameText.name',
                description: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationNameText.description',
                initialValue: '',
                placeholder: 'FLD-UDID-353',
                disabled: false,
                updateable: true,
                jsonPath: '$.productDesignerOrganisation.productDesignerOrganisationNameText',
                textareaRows: 6,
                controlType: FormControlType.TEXTAREA,
                parsingFunction: parseSimpleField,
                group: UDIGroups.PRODUCT_DESIGNER,
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            },
            {
                propertyName: 'productDesignerOrganisationNameLanguage',
                name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationNameLanguage.name',
                description: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationNameLanguage.description',
                initialValue: '',
                placeholder: 'FLD-EUD-010',
                jsonPath: '$.productDesignerOrganisation.productDesignerOrganisationNameLanguage',
                disabled: false,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                parsingFunction: parseSimpleField,
                group: UDIGroups.PRODUCT_DESIGNER,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'productDesignerOrganisationGeographicAddress',
                name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationGeographicAddress.name',
                description: '',
                initialValue: '',
                placeholder: '',
                disabled: false,
                controlType: FormControlType.NON_SHOWING,
                validators: [optionalWithRequired(['productDesignerOrganisationCountry', 'productDesignerOrganisationPostalCode'])],
                parsingFunction: parseComplexField,
                group: UDIGroups.PRODUCT_DESIGNER,
                children: [
                    {
                        propertyName: 'productDesignerOrganisationAddressComplement',
                        name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationAddressComplement.name',
                        description: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationAddressComplement.description',
                        initialValue: '',
                        placeholder: 'FLD-EUD-040',
                        disabled: false,
                        controlType: FormControlType.TEXTAREA,
                        jsonPath: '$.productDesignerOrganisation.productDesignerOrganisationGeographicAddress.productDesignerOrganisationAddressComplement',
                        updateable: true,
                        textareaRows: 4,
                        maxLength: 500,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        validators: [Validators.minLength(1), Validators.maxLength(500)],
                        errorMessage: 'ERRORS.tooLong500'
                    },
                    {
                        propertyName: 'productDesignerOrganisationCity',
                        initialValue: '',
                        placeholder: 'FLD-EUD-036',
                        disabled: false,
                        controlType: FormControlType.TEXTAREA,
                        textareaRows: 4,
                        jsonPath: '$.productDesignerOrganisation.productDesignerOrganisationGeographicAddress.productDesignerOrganisationCity',
                        maxLength: 500,
                        updateable: true,
                        name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationCity.name',
                        description: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationCity.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        validators: [Validators.minLength(1), Validators.maxLength(500)],
                        errorMessage: 'ERRORS.tooLong500'
                    },
                    {
                        propertyName: 'productDesignerOrganisationCountry',
                        name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationCountry.name',
                        initialValue: '',
                        placeholder: 'FLD-EUD-042',
                        updateable: true,
                        jsonPath: '$.productDesignerOrganisation.productDesignerOrganisationGeographicAddress.productDesignerOrganisationCountry',
                        disabled: false,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        description: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationCountry.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'productDesignerOrganisationPOBox',
                        name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationPOBox.name',
                        initialValue: '',
                        placeholder: 'FLD-EUD-041',
                        disabled: false,
                        jsonPath: '$.productDesignerOrganisation.productDesignerOrganisationGeographicAddress.productDesignerOrganisationPOBox',
                        updateable: true,
                        controlType: FormControlType.INPUT,
                        description: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationPOBox.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '47%',
                            'margin-right': '24px'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: 'ERRORS.textArea120'
                    },
                    {
                        propertyName: 'productDesignerOrganisationPostalCode',
                        name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationPostalCode.name',
                        description: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationPostalCode.description',
                        initialValue: '',
                        jsonPath: '$.productDesignerOrganisation.productDesignerOrganisationGeographicAddress.productDesignerOrganisationPostalCode',
                        placeholder: 'FLD-EUD-039',
                        disabled: false,
                        updateable: true,
                        controlType: FormControlType.INPUT,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '47%'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea120'
                        }
                    },
                    {
                        propertyName: 'productDesignerOrganisationStreet',
                        name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationStreet.name',
                        initialValue: '',
                        jsonPath: '$.productDesignerOrganisation.productDesignerOrganisationGeographicAddress.productDesignerOrganisationStreet',
                        placeholder: 'FLD-EUD-034',
                        disabled: false,
                        controlType: FormControlType.TEXTAREA,
                        textareaRows: 4,
                        updateable: true,
                        maxLength: 500,
                        description: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationStreet.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        validators: [Validators.minLength(1), Validators.maxLength(500)],
                        errorMessage: 'ERRORS.tooLong500'
                    },
                    {
                        propertyName: 'productDesignerOrganisationStreetNum',
                        name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationStreetNum.name',
                        jsonPath: '$.productDesignerOrganisation.productDesignerOrganisationGeographicAddress.productDesignerOrganisationStreetNum',
                        initialValue: '',
                        placeholder: 'FLD-EUD-035',
                        updateable: true,
                        disabled: false,
                        controlType: FormControlType.INPUT,
                        description: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationStreetNum.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '100%'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: 'ERRORS.textArea120'
                    },
                ]
            },
            {
                propertyName: 'productDesignerOrganisationContactDetail',
                name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationContactDetail.name',
                description: '',
                group: UDIGroups.PRODUCT_DESIGNER,
                initialValue: '',
                placeholder: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationContactDetail.placeholder',
                validators: [optionalWithRequired(['productDesignerOrganisationContactDetaileMail'])],
                disabled: false,
                controlType: FormControlType.REPEATABLE,
                parsingFunction: parseSimpleArray,
                children: [
                    {
                        propertyName: 'productDesignerOrganisationContactDetaileMail',
                        name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationContactDetaileMail.name',
                        description: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationContactDetaileMail.description',
                        parsingFunction: parseSimpleField,
                        initialValue: '',
                        jsonPath: '$.productDesignerOrganisation.productDesignerOrganisationContactDetail[*].productDesignerOrganisationContactDetaileMail',
                        updateable: true,
                        placeholder: 'FLD-EUD-018',
                        disabled: false,
                        controlType: FormControlType.INPUT,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '100%'
                        },
                        validators: [Validators.pattern(emailPattern), Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: {
                          required: 'ERRORS.notFilled',
                          pattern: 'ERRORS.wrongSyntax',
                          maxlength: 'ERRORS.textArea120'
                        }
                    },
                    {
                        propertyName: 'productDesignerOrganisationContactDetailPhone',
                        name: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationContactDetailPhone.name',
                        initialValue: '',
                        placeholder: 'FLD-EUD-017',
                        disabled: false,
                        jsonPath: '$.productDesignerOrganisation.productDesignerOrganisationContactDetail[*].productDesignerOrganisationContactDetailPhone',
                        updateable: true,
                        controlType: FormControlType.INPUT,
                        description: 'DEVICECONSTANTS.mdr.udi.productDesignerOrganisationContactDetailPhone.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '100%'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: 'ERRORS.textArea120'
                    },
                ]
            },
        ]
    },
    {
        propertyName: 'MDNCode',
        name: 'DEVICECONSTANTS.mdr.udi.MDNCode.name',
        description: 'DEVICECONSTANTS.mdr.udi.MDNCode.description',
        initialValue: '',
        placeholder: 'FLD-UDID-149',
        disabled: false,
        updateable: true,
        jsonPath: '$.MDNCode',
        validators: [Validators.required],
        controlType: FormControlType.MDN_SELECT,
        parsingFunction: parseSimpleField,
        group: UDIGroups.CLASSIFICATION,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'annexXVINonMedicalDeviceType',
        name: 'DEVICECONSTANTS.mdr.udi.annexXVINonMedicalDeviceType.name',
        description: 'DEVICECONSTANTS.mdr.udi.annexXVINonMedicalDeviceType.description',
        initialValue: '',
        jsonPath: '$.annexXVINonMedicalDeviceType',
        placeholder: 'FLD-UDID-147',
        stringArray: true,
        disabled: false,
        controlType: FormControlType.REPEATABLE_SELECT,
        parsingFunction: parseSimpleField,
        group: UDIGroups.CLASSIFICATION
    },
    {
        propertyName: 'medicalHumanProductSubstance',
        name: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstance.name',
        description: '',
        group: UDIGroups.DEVICE_INFORMATION,
        initialValue: '', placeholder: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstance.placeholder',
        validators: [optionalWithRequired(['medicalHumanProductSubstanceType'])],
        disabled: false,
        controlType: FormControlType.REPEATABLE,
        parsingFunction: parseArrayField,
        children: [
            {
                propertyName: 'medicalHumanProductSubstanceType',
                name: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstanceType.name',
                description: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstanceType.description',
                initialValue: '',
                placeholder: 'FLD-UDID-316',
                jsonPath: '$.medicalHumanProductSubstance[*].medicalHumanProductSubstanceType',
                disabled: false,
                controlType: FormControlType.DROPDOWN,
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'medicalHumanProductSubstanceName',
                name: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstanceName.name',
                description: '',
                group: UDIGroups.DEVICE_INFORMATION,
                initialValue: '', placeholder: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstanceName.placeholder',
                disabled: false,
                validators: [optionalWithRequired(['medicalHumanProductSubstanceNameLanguage', 'medicalHumanProductSubstanceNameText'])],
                controlType: FormControlType.REPEATABLE,
                parsingFunction: parseSimpleArray,
                children: [
                    {
                        propertyName: 'medicalHumanProductSubstanceNameLanguage',
                        name: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstanceNameLanguage.name',
                        description: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstanceNameLanguage.description',
                        initialValue: '',
                        placeholder: 'FLD-EUD-010',
                        disabled: false,
                        jsonPath: '$.medicalHumanProductSubstance[*].medicalHumanProductSubstanceName[*].medicalHumanProductSubstanceNameLanguage',
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'medicalHumanProductSubstanceNameText',
                        name: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstanceNameText.name',
                        description: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstanceNameText.description',
                        initialValue: '',
                        jsonPath: '$.medicalHumanProductSubstance[*].medicalHumanProductSubstanceName[*].medicalHumanProductSubstanceNameText',
                        updateable: true,
                        placeholder: 'FLD-UDID-317',
                        disabled: false,
                        controlType: FormControlType.INPUT,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    },
                ]
            },
            {
                propertyName: 'medicalHumanProductSubstanceINN',
                name: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstanceINN.name',
                description: 'DEVICECONSTANTS.mdr.udi.medicalHumanProductSubstanceINN.description',
                jsonPath: '$.medicalHumanProductSubstance[*].medicalHumanProductSubstanceINN',
                initialValue: '',
                placeholder: 'FLD-UDID-318',
                disabled: false,
                controlType: FormControlType.INPUT,
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: 'ERRORS.textArea2000'
            },
        ]
    }
];
