import {Filter} from '../filter.model';



export default class Filters {
    deviceTypes?: Array<string>;
    deviceTypeExcludes?: Array<string>;
    riskClasses?: Array<string>;
    riskClassExcludes?: Array<string>;
    diCodes?: Array<string>;
    diCodeExcludes?: Array<string>;
    actorCodes?: Array<string>;
    actorCodeExcludes?: Array<string>;
    models?: Array<string>;
    modelExcludes?: Array<string>;
    names?: Array<string>;
    nameExcludes?: Array<string>;
    statuses?: Array<string>;
    statusExcludes?: Array<string>;
    srn?: Array<string>;
    srnExcludes?: Array<string>;
    relatedLegacy?: Array<string>;
    relatedLegacyExcludes?: Array<string>;


    private uiMappings: {[key: string]: string} = {
        devicetype: 'deviceTypes',
        devicetype_exclude: 'deviceTypeExcludes',
        riskclass: 'riskClasses',
        riskclass_exclude: 'riskClassExcludes',
        dicode : 'diCodes',
        dicode_exclude: 'diCodeExcludes',
        actorcode: 'actorCodes',
        actorcode_exclude: 'actorCodeExcludes',
        model: 'models',
        model_exclude: 'modelExcludes',
        name: 'names',
        name_exclude: 'nameExcludes',
        status : 'statuses',
        status_exclude: 'statusExcludes',
        srn : 'srn',
        srn_exclude: 'srnExcludes',
        relatedlegacy: 'relatedLegacy',
        relatedlegacy_exclude: 'relatedLegacyExcludes',
    };

    reverseUiMappings = this.objectFlip(this.uiMappings);


    private objectFlip(obj: any): any{
        const ret: {[key: string]: any} = {};
        Object.keys(obj).forEach(key => {
            ret[obj[key]] = key;
        });
        return ret;
    }


    constructor(filters: Partial<Filters>){
        this.deviceTypes = filters.deviceTypes;
        this.deviceTypeExcludes = filters.deviceTypeExcludes ;
        this.riskClasses = filters.riskClasses;
        this.riskClassExcludes = filters.riskClassExcludes;
        this.diCodes = filters.diCodes;
        this.diCodeExcludes = filters.diCodeExcludes;
        this.actorCodes = filters.actorCodes;
        this.actorCodeExcludes = filters.actorCodeExcludes;
        this.models = filters.models;
        this.modelExcludes = filters.modelExcludes;
        this.names = filters.names;
        this.nameExcludes = filters.nameExcludes;
        this.statuses = filters.statuses;
        this.statusExcludes = filters.statusExcludes;
        this.srn = filters.srn;
        this.srnExcludes = filters.srnExcludes;
        this.relatedLegacy = filters.relatedLegacy;
        this.relatedLegacyExcludes = filters.relatedLegacyExcludes;
    }

    createDtoFromUIFilters(filters: Array<Filter>): void {
        filters.forEach((filter) => {
            const fieldAsString = filter.fieldToString();
            const valueOfFilter = filter.valueToString();

            console.log(fieldAsString + '===========');

            const currentValueOfFilter = (this as any)[this.uiMappings[fieldAsString]];

            if (currentValueOfFilter && Array.isArray(currentValueOfFilter)){
                currentValueOfFilter.push(valueOfFilter);
            }else{
                (this as any)[this.uiMappings[fieldAsString]] = [valueOfFilter];
            }
        });
    }


}
