import { Injectable } from '@angular/core';
import {EventManager, Meta} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';

export enum Platform {
    'Browser',
    'Tablet',
    'Phone'
}

@Injectable()
export class PlatformService {
    private currentPlatform: BehaviorSubject<Platform> = new BehaviorSubject<Platform>(Platform.Browser);
    public currentPlatform$ = this.currentPlatform.asObservable();
    private meta: HTMLMetaElement | null;
    private location: string;

    constructor(evManager: EventManager, private metaService: Meta) {
        this.location = window.location.href;
        this.checkCurrentPlatform(window.innerWidth);
        evManager.addGlobalEventListener('window', 'resize', () => {
            this.checkCurrentPlatform(window.innerWidth);
        });

        this.currentPlatform.subscribe((platform: Platform) => {
            if (platform === Platform.Phone) {
                this.meta = this.metaService.addTag({content: 'maximum-scale=1'});
            } else {
                if (this.meta) {
                    this.metaService.removeTagElement(this.meta);
                }
            }
        });

    }

    private checkCurrentPlatform(windowWidth: number): void {
        const locationHitlist: boolean = this.location.includes('devices') && windowWidth < 1024;
        if (windowWidth < 576) {
            if (this.currentPlatform.value !== Platform.Phone) {
                this.currentPlatform.next(Platform.Phone);
            }
        } else if (windowWidth < 992 || locationHitlist) {
            if (this.currentPlatform.value !== Platform.Tablet) {
                this.currentPlatform.next(Platform.Tablet);
            }
        } else {
            if (this.currentPlatform.value !== Platform.Browser) {
                this.currentPlatform.next(Platform.Browser);
            }
        }
    }

    public getCurrentPlatform(): Platform {
        return this.currentPlatform.getValue();
    }
}
