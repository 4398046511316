import { Injectable, Type } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class HeaderService {

    private currentComponent = new Subject<Type<any>>();
    private showHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public showHeader$: Observable<boolean> = this.showHeader.asObservable();
    public currComponent: Observable<Type<any>> = this.currentComponent.asObservable().pipe(distinctUntilChanged());

    addComponentToHeader(component: Type<any>): void{
        this.currentComponent.next(component);
    }

    getCurrentComponent(): Observable<Type<any>>{
        return this.currComponent;
    }

    clear(): void {
        this.currentComponent.next({} as Type<any>);
    }

    showHideHeader(state: boolean): void {
        this.showHeader.next(state);
    }

}
