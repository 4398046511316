import { Injectable } from "@angular/core";

@Injectable()
export class DetailsDownloadService {

    generateCSVData(device: any): any {
        const flattenedDevice: any = {};
        Object.keys(device).map((row: any) => {
            if (typeof device[row] === 'undefined' || device[row] === null){
                device[row] = '';
            }

            if (Array.isArray(device[row])){
                const arrOfObjects: any = [];
                device[row].forEach((element: any) => {
                    const mergerArr =  this.checkObjectChilds(element).join(' ');
                    arrOfObjects.push(mergerArr);
                });
                flattenedDevice[row] = arrOfObjects.join(' ');
            }else if (typeof device[row] === 'object'){
                const mergerArr =  this.checkObjectChilds(device[row]).join(' ');
                flattenedDevice[row] = mergerArr;
            }else{
                flattenedDevice[row] = device[row];
            }
        });
        return flattenedDevice;
    }

    checkObjectChilds(deviceObject: any): any{
        const arr: any = [];
        Object.keys(deviceObject).map((key: any) => {
            if (typeof deviceObject[key] === 'object'){
                this.checkObjectChilds(deviceObject[key]);
            }else if (Array.isArray(deviceObject[key])){
                const arrOfObjects: any = [];
                deviceObject[key].forEach((element: any) => {
                    const mergerArr =  this.checkObjectChilds(element).join(' ');
                    arrOfObjects.push(mergerArr);
                });
                arr.push(arrOfObjects.join(' '));
            }else{
                arr.push(key + ': ' + deviceObject[key]);
            }
        });
        return arr;
    }

    objectToCSV(flattenedDevice: any): any {
        const keys = Object.keys(flattenedDevice);
        let csvData = keys.join(',') + '\n';
        csvData += keys.map(k => flattenedDevice[k]).join(',') + '\n';
        return csvData;
    }

    downloadCSV(csvData: any, flattenedDevice: any): any {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            if (flattenedDevice.deviceType.includes('BASIC')){
                link.setAttribute('download', flattenedDevice.basicUDIIdentifierDICode + '_attribute_set');
            }else{
                link.setAttribute('download', flattenedDevice.UDIDIIdentifierDICode + '_attribute_set');
            }
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
