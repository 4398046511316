import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Filter} from '../../model/filter.model';

@Injectable()
export class FilterService {

    constructor(){}

    private readonly filters = new BehaviorSubject<Filter[]>([]);
    private readonly currentSearchFilter = new BehaviorSubject<Filter | null>(null);
    private readonly filtersOpen = new BehaviorSubject<boolean>(false);

    readonly filters$ = this.filters.asObservable();
    readonly currentSearchFilter$ = this.currentSearchFilter.asObservable();
    readonly filtersOpen$ = this.filtersOpen.asObservable();

    getFilters(): Filter[] {
        return this.filters.getValue();
    }

    addFilter(filter: Filter): void {
        const allFilters = this.filters.getValue();
        if (filter.isSearch){
            this.currentSearchFilter.next(filter);

            const currentSearchFltr: Filter | undefined = allFilters.find((curr: Filter) => {
                return curr.isSearch === true;
            });
            if (currentSearchFltr) {
                Object.assign(currentSearchFltr, filter);
                return this.filters.next(allFilters);
            } else {
                allFilters.push(filter);
                return this.filters.next(allFilters);
            }
        } else if (filter.isSort) {
          allFilters.push(filter);
          return this.filters.next(allFilters);
        }
        allFilters.push(filter);
        this.filters.next(allFilters);
    }

    removeSearchFilter(): void{
        const crntSearchFilter = this.currentSearchFilter.getValue();
        if (crntSearchFilter) {
            this.removeFilter(crntSearchFilter);
        }
    }

    removeFilter(filter: Filter): void {
        if (filter.isSearch) {
            this.currentSearchFilter.next(new Filter());
        }
        const newFilters = this.filters.getValue();
        newFilters.splice(newFilters.indexOf(filter), 1);
        this.filters.next(newFilters);
    }


    removeAllFilters(): void {
        this.filters.next([]);
    }

    removeSortFilter(): void {
      const sortFilter = this.filters.getValue().find((curr: Filter) => {
        return curr.isSort === true;
      });
      if (sortFilter) {
        this.removeFilter(sortFilter);
      }
    }

    toggleFiltering(state: boolean ): void {
        this.filtersOpen.next(state);
    }
}
