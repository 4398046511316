import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faStethoscope, faPlus, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { HeaderService, LoginService} from 'src/app/core/service';
import { EudamedCookieService } from '../../core/service/eudamed-cookie.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { EudamedCookie } from '../../core/constants/cookie.constants';
import {Platform, PlatformService} from 'src/app/core/service/platform.service';
import { environment } from '../../../environments/environment';
import {Authority} from 'src/app/core/constants/authority.constants';
import {TranslateService} from '@ngx-translate/core';
import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {BrandingService} from "../../core/service/branding-service.service";

@Component({
    selector: 'eudamed-layout',
    template: `
        <ng-container>
            <layout-browser *ngIf="currentPlatform === 0">
                <eudamed-header class="app-header"></eudamed-header>
                <ng-container content *ngTemplateOutlet="routerTempl"></ng-container>
            </layout-browser>
            <layout-mobile *ngIf="currentPlatform === 2 || currentPlatform === 1">
                <eudamed-header class="app-header"></eudamed-header>
                <ng-container content *ngTemplateOutlet="routerTempl"></ng-container>
            </layout-mobile>

            <ng-template #routerTempl>
                <router-outlet class="app-content"></router-outlet>
            </ng-template>
        </ng-container>
    `
})
export class BaseLayoutComponent implements OnInit {

    @ViewChild('sider') sider: TemplateRef<void>;
    isCollapsed = true;
    showHeader = false;
    necessaryCookieExists = false;
    isMouseInsideTheMenu = false;
    currentPlatform = Platform.Browser;
    selectedLanguage = this.eudamedCookieService.getLanguageCookie();
    faStethoscope = faStethoscope;
    faPlus = faPlus;
    applicationVersion = '';
    Authority = Authority;
    faFileDownload = faFileDownload;


    constructor(
      public router: Router,
      private headerService: HeaderService,
      protected eudamedCookieService: EudamedCookieService,
      private gtmService: GoogleTagManagerService,
      public translationService: TranslateService,
      public loginService: LoginService,
      public httpClient: HttpClient,
      public brandingService: BrandingService,
      platform: PlatformService
    ) {
        platform.currentPlatform$.subscribe((currentPlatform: Platform) => {
            this.currentPlatform = currentPlatform;
        });

    }


    ngOnInit(): void {
      this.necessaryCookieExists = this.eudamedCookieService.getCookie(EudamedCookie.NECESSARY);

      if (this.eudamedCookieService.analyticsEnabled()) {
        this.gtmService.addGtmToDom();
      }

      this.headerService.showHeader$.subscribe((headerState: boolean) => {
          this.showHeader = headerState;
      });

      this.applicationVersion = this.getApplicationVersion();
    }

    openInNewTab(url: string): void {
        const win = window.open(url, '_blank');
        win?.focus();
    }

    openFileInNewTab(fileName: any): void {
      this.httpClient
        .get(`/udimanager/v1/download/${fileName}`,
          {observe: 'response', responseType: 'blob'}
        )
        .pipe(
          catchError((err) => {
            console.log(err);
            return of(false);
          })
        )
        .subscribe((response: any) => {
          if (response !== false) {
            const blob = new Blob([response.body], {type: 'application/pdf'});
            console.log(response.body);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.href = url;
            link.click();
            link.remove();
          }
        });
    }

    goTo(route: string): void{
        this.router.navigateByUrl(route);
    }

    getApplicationVersion(): string {
      return environment.version;
    }

    getSidebarLogo(): string {
      return this.brandingService.getSidebarLogo();
    }

    getImpressum(): string {
      return '\u00A9 ' + new Date().getFullYear() + ' ' + this.brandingService.getImpressumText();
    }

    getSidebarLogoImgClass(): string {
      return this.brandingService.getSidebarLogoImgClass();
    }

}
