
export const DeviceTypeToRegisterType: {[key: string]: string} = {
    MDRUDIDEVICE: 'REGISTERMDR',
    IVDRUDIDEVICE : 'REGISTERIVDR',
    SPPUDIDEVICE: 'REGISTERSPP',
    MDEUDIUDIDEVICE: 'REGISTERMDD',
    ADEUDIUDIDEVICE: 'REGISTERAIMDD',
    IVDEUDIUDIDEVICE: 'REGISTERIVD',

    MDRBASICDEVICE: 'REGISTERBASICMDR',
    IVDRBASICDEVICE: 'REGISTERBASICIVDR',
    SPPBASICDEVICE: 'REGISTERBASICSPP',
    MDEUDIBASICDEVICE: 'REGISTERBASICMDD',
    ADEUDIBASICDEVICE: 'REGISTERBASICAIMDD',
    IVDEUDIBASICDEVICE: 'REGISTERBASICIVD'
};

