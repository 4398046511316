<ng-container [formGroup]="formGroup">
    <nz-form-item
        [style]="formBuildingInput.style? formBuildingInput.style : 'flex-basis:100%;'"
        >
        <nz-form-control [nzHasFeedback]="formBuildingInput.validators && formBuildingInput.validators.includes(Validators.required)"
        [nzErrorTip]="(formBuildingInput.errorMessage | typeof) == 'string' ? (formBuildingInput.errorMessage | translate) : errorHandlingTemplate">
            <nz-input-group>
                <eudamed-multiselect-searchable-wrapper [optionalClass]="optionalClass" [label]="(formBuildingInput.name | translate) + ((formBuildingInput.validators && formBuildingInput.validators.includes(Validators.required)) ? '*' : '')">
                    <nz-select nzMode="multiple"
                        [style]="getDropdownStyle(formBuildingInput.propertyName)"
                        nzPopoverTrigger="click"
                        [nzPopoverPlacement]="errorForm ? 'left' : 'right'"
                        nz-popover
                        [nzAllowClear]="true"
                        [nzPopoverContent]="popoverContent"
                        [nzPopoverOverlayStyle]="{maxWidth:'200px'}"
                        nzPopoverColor="white"
                        nz-input
                        [formControlName]="formBuildingInput.propertyName"
                        [nzPlaceHolder]="formBuildingInput.placeholder! | translate">
                        <nz-option *ngFor="let code of codeList" [nzLabel]="code.label | codelistTranslation" [nzValue]="code.value"></nz-option>
                        <nz-option *ngFor="let invalidCodeValue of invalidCodes" [nzLabel]="'OLD INVALID CODE [' + invalidCodeValue + ']'" [nzValue]="invalidCodeValue"></nz-option>
                    </nz-select>
                </eudamed-multiselect-searchable-wrapper>
            </nz-input-group>
        </nz-form-control>
    </nz-form-item>
</ng-container>

<ng-template #popoverContent>
    <span style="color:white;">
        {{formBuildingInput.description || '' | translate}}
    </span>
</ng-template>

<ng-template #errorHandlingTemplate let-formControl>
    <div *ngFor="let error of (formControl.errors | keyvalue)">
      <span>
        {{ error.key | handleErrorTranslationPipe : formControl }}
      </span>
    </div>
</ng-template>
