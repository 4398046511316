import { UDIGroups } from './attribute-groups.enum';
import {DeviceConstants} from '.';
import {parseSimpleField, parseSimpleArray, parseComplexField, parseArrayField} from '../../util/device-to-details.util';
import FormBuildingInput, {NumericTypes} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {FormControlType} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-control-type.enum';
import {AbstractControl, Validators} from '@angular/forms';
import {
  actorCodePattern,
  emailPattern,
  optionalWithRequired,
  optionalWithRequiredParticular,
  positiveIntegerPattern
} from './validators/complex-validators.util';
import {Injector} from '@angular/core';
import {conditionalDescriptionWithLanguage} from './services/eudamed-editor.util';
import {CriticalWarningValueCodeList, StorageHandlingConditionTypeCodeList} from '../codelists.constants';

export const IVDRUDIDEVICE: Array<DeviceConstants & FormBuildingInput> = [
    {
        propertyName: 'secondaryIdentifier',
        name: 'DEVICECONSTANTS.ivdr.udi.secondaryIdentifier.name',
        group: UDIGroups.IDENTIFICATION,
        validators: [optionalWithRequired(['secondaryIdentifierDICode', 'secondaryIdentifierIssuingEntityCode'])],
        controlType: FormControlType.NON_SHOWING,
        parsingFunction: parseComplexField,
        children: [
            {
                propertyName: 'secondaryIdentifierDICode',
                name: 'DEVICECONSTANTS.ivdr.udi.secondaryIdentifierDICode.name',
                description: 'DEVICECONSTANTS.ivdr.udi.secondaryIdentifierDICode.description',
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-136',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '47%',
                    'margin-right': '24px'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'secondaryIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.ivdr.udi.secondaryIdentifierIssuingEntityCode.name',
                controlType: FormControlType.DROPDOWN,
                placeholder: 'FLD-UDID-293',
                description: 'DEVICECONSTANTS.ivdr.udi.secondaryIdentifierIssuingEntityCode.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '47%',
                },
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'deviceStatusCode',
        name: 'DEVICECONSTANTS.ivdr.udi.deviceStatusCode.name',
        description: 'DEVICECONSTANTS.ivdr.udi.deviceStatusCode.description',
        validators: [Validators.required],
        updateable: true,
        controlType: FormControlType.DROPDOWN,
        placeholder: 'FLD-UDID-130',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'referenceNumber',
        name: 'DEVICECONSTANTS.ivdr.udi.referenceNumber.name',
        description: 'DEVICECONSTANTS.ivdr.udi.referenceNumber.description',
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-163',
        validators: [Validators.required, Validators.minLength(1), Validators.maxLength(255)],
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        style: {
            width: '100%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.tooLong255'
        }
    },
    {
        propertyName: 'directMarkingDI',
        name: 'DEVICECONSTANTS.ivdr.udi.directMarkingDI.name',
        controlType: FormControlType.NON_SHOWING,
        validators: [optionalWithRequired(['directMarkingDIDICode', 'directMarkingDIIssuingEntityCode'])],
        parsingFunction: parseComplexField,
        group: UDIGroups.IDENTIFICATION,
        children: [
            {
                propertyName: 'directMarkingDIDICode',
                name: 'DEVICECONSTANTS.ivdr.udi.directMarkingDIDICode.name',
                controlType: FormControlType.INPUT,
                updateable: true,
                placeholder: 'FLD-UDID-138',
                parsingFunction: parseSimpleField,
                description: 'DEVICECONSTANTS.ivdr.udi.directMarkingDIDICode.description',
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '47%',
                    'margin-right': '24px'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'directMarkingDIIssuingEntityCode',
                name: 'DEVICECONSTANTS.ivdr.udi.directMarkingDIIssuingEntityCode.name',
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-294',
                description: 'DEVICECONSTANTS.ivdr.udi.directMarkingDIIssuingEntityCode.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'baseQuantity',
        name: 'DEVICECONSTANTS.ivdr.udi.baseQuantity.name',
        description: 'DEVICECONSTANTS.ivdr.udi.baseQuantity.description',
        controlType: FormControlType.INPUT,
        initialValue: 1,
        validators: [Validators.required, Validators.pattern(positiveIntegerPattern)],
        numericType: NumericTypes.INTEGER,
        placeholder: 'FLD-UDID-151',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        errorMessage: {
          required: 'ERRORS.notFilled',
          pattern: 'ERRORS.numbersOnly'
        },
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'unitOfUseIdentifier',
        name: 'DEVICECONSTANTS.ivdr.udi.unitOfUseIdentifier.name',
        group: UDIGroups.IDENTIFICATION,
        validators: [optionalWithRequired(['unitOfUseIdentifierDICode', 'unitOfUseIdentifierIssuingEntityCode'])],
        controlType: FormControlType.NON_SHOWING,
        parsingFunction: parseComplexField,
        children: [
            {
                propertyName: 'unitOfUseIdentifierDICode',
                name: 'DEVICECONSTANTS.ivdr.udi.unitOfUseIdentifierDICode.name',
                description: 'DEVICECONSTANTS.ivdr.udi.unitOfUseIdentifierDICode.description',
                controlType: FormControlType.INPUT,
                updateable: true,
                placeholder: 'FLD-UDID-135',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '47%',
                    'margin-right': '24px'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'unitOfUseIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.ivdr.udi.unitOfUseIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.ivdr.udi.unitOfUseIdentifierIssuingEntityCode.description',
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-292',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'deviceType',
        name: 'DEVICECONSTANTS.ivdr.udi.deviceType.name',
        description: 'DEVICECONSTANTS.ivdr.udi.deviceType.description',
        parsingFunction: parseSimpleField,
        validators: [Validators.required],
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        placeholder: 'FLD-ATRIFY-01',
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'UDIDIIdentifierDICode',
        name: 'DEVICECONSTANTS.ivdr.udi.UDIDIIdentifierDICode.name',
        description: 'DEVICECONSTANTS.ivdr.udi.UDIDIIdentifierDICode.description',
        controlType: FormControlType.INPUT,
        validators: [Validators.required, Validators.minLength(1), Validators.maxLength(120)],
        placeholder: 'FLD-UDID-178',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        style: {
            width: '50%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea120'
        }
    },
    {
        propertyName: 'UDIDIIdentifierIssuingEntityCode',
        name: 'DEVICECONSTANTS.ivdr.udi.UDIDIIdentifierIssuingEntityCode.name',
        description: 'DEVICECONSTANTS.ivdr.udi.UDIDIIdentifierIssuingEntityCode.description',
        validators: [Validators.required],
        controlType: FormControlType.DROPDOWN,
        placeholder: 'FLD-UDID-291',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'customerMessageID',
        name: 'DEVICECONSTANTS.ivdr.basic.customerMessageID.name',
        description: 'DEVICECONSTANTS.ivdr.basic.customerMessageID.description',
        parsingFunction: parseSimpleField,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-ATRIFY-02',
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'customerTransactionID',
        name: 'DEVICECONSTANTS.ivdr.basic.customerTransactionID.name',
        description: 'DEVICECONSTANTS.ivdr.basic.customerTransactionID.description',
        parsingFunction: parseSimpleField,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-ATRIFY-03',
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'MFActorCode',
        name: 'DEVICECONSTANTS.ivdr.udi.MFActorCode.name',
        description: 'DEVICECONSTANTS.ivdr.udi.MFActorCode.description',
        parsingFunction: parseSimpleField,
        validators: [Validators.required, Validators.pattern(actorCodePattern)],
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-10',
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        style: {
            width: '50%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            pattern: 'ERRORS.actorCode'
        }
    },
    {
        propertyName: 'additionalDescription',
        name: 'DEVICECONSTANTS.ivdr.udi.additionalDescription.name',
        group: UDIGroups.DEVICE_INFORMATION,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['additionalProductDescriptionLanguage', 'additionalProductDescriptionText'])],
        parsingFunction: parseSimpleArray,
        children: [
            {
                propertyName: 'additionalProductDescriptionLanguage',
                name: 'DEVICECONSTANTS.ivdr.udi.additionalProductDescriptionLanguage.name',
                parsingFunction: parseSimpleField,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-EUD-010',
                description: 'DEVICECONSTANTS.ivdr.udi.additionalProductDescriptionLanguage.description',
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'additionalProductDescriptionText',
                name: 'DEVICECONSTANTS.ivdr.udi.additionalProductDescriptionText.name',
                parsingFunction: parseSimpleField,
                minLength: 1,
                maxLength: 2000,
                updateable: true,
                textareaRows: 6,
                controlType: FormControlType.TEXTAREA,
                placeholder: 'FLD-UDID-175',
                description: 'DEVICECONSTANTS.ivdr.udi.additionalProductDescriptionText.description',
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            }
        ]
    },
    {
        propertyName: 'website',
        name: 'DEVICECONSTANTS.ivdr.udi.website.name',
        description: 'DEVICECONSTANTS.ivdr.udi.website.description',
        controlType: FormControlType.INPUT,
        updateable: true,
        placeholder: 'FLD-UDID-174',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        style: {
            width: '100%',
            marginBottom: '48px'
        },
        validators: [Validators.minLength(1), Validators.maxLength(255)],
        errorMessage: 'ERRORS.tooLong255'
    },
    {
        propertyName: 'labeledAsSingleUse',
        name: 'DEVICECONSTANTS.ivdr.udi.labeledAsSingleUse.name',
        description: 'DEVICECONSTANTS.ivdr.udi.labeledAsSingleUse.description',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-167',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'numberOfReuses',
        name: 'DEVICECONSTANTS.ivdr.udi.numberOfReuses.name',
        placeholder: 'FLD-UDID-157',
        controlType: FormControlType.INPUT,
        validators: [Validators.pattern(positiveIntegerPattern), Validators.minLength(1), Validators.maxLength(32767)],
        numericType: NumericTypes.INTEGER,
        description: 'DEVICECONSTANTS.ivdr.udi.numberOfReuses.description',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        style: {
            marginBottom: '48px'
        },
        validationError: 'EUD-item0012',
        errorMessage: {
            maxlength: 'ERRORS.textArea32767',
            pattern: 'ERRORS.numbersOnly'
        }
    },
    {
        propertyName: 'sterile',
        name: 'DEVICECONSTANTS.ivdr.udi.sterile.name',
        parsingFunction: parseSimpleField,
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-169',
        validators: [Validators.required],
        description: 'DEVICECONSTANTS.ivdr.udi.sterile.description',
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'sterilization',
        name: 'DEVICECONSTANTS.ivdr.udi.sterilization.name',
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-170',
        parsingFunction: parseSimpleField,
        description: 'DEVICECONSTANTS.ivdr.udi.sterilization.description',
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'newDevice',
        name: 'DEVICECONSTANTS.ivdr.udi.newDevice.name',
        description: 'DEVICECONSTANTS.ivdr.udi.newDevice.description',
        initialValue: null,
        jsonPath: '$.newDevice',
        updateable: true,
        controlType: FormControlType.CHECKBOX,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-159',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'basicUDIIdentifier',
        name: 'DEVICECONSTANTS.ivdr.udi.basicUDIIdentifier.name',
        parsingFunction: parseComplexField,
        controlType: FormControlType.BASIC_ASYNC_SELECT,
        validators: [optionalWithRequired(['basicUDIIdentifierDICode', 'basicUDIIdentifierIssuingEntityCode'])],
        group: UDIGroups.IDENTIFICATION,
        style: {
            marginBottom: '48px'
        },
        children: [
            {
                propertyName: 'basicUDIIdentifierDICode',
                name: 'DEVICECONSTANTS.ivdr.udi.basicUDIIdentifierDICode.name',
                description: 'DEVICECONSTANTS.ivdr.udi.basicUDIIdentifierDICode.description',
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-145',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'basicUDIIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.ivdr.udi.basicUDIIdentifierIssuingEntityCode.name',
                parsingFunction: parseSimpleField,
                controlType: FormControlType.DROPDOWN,
                placeholder: 'FLD-UDID-01',
                description: 'DEVICECONSTANTS.ivdr.udi.basicUDIIdentifierIssuingEntityCode.description',
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'MDNCode',
        name: 'DEVICECONSTANTS.ivdr.udi.MDNCode.name',
        description: 'DEVICECONSTANTS.ivdr.udi.MDNCode.description',
        updateable: true,
        controlType: FormControlType.MDN_SELECT,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-149',
        parsingFunction: parseSimpleField,
        group: UDIGroups.CLASSIFICATION,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'productionIdentifier',
        name: 'DEVICECONSTANTS.ivdr.udi.productionIdentifier.name',
        description: 'DEVICECONSTANTS.ivdr.udi.productionIdentifier.description',
        parsingFunction: parseSimpleField,
        validators: [Validators.required],
        controlType: FormControlType.REPEATABLE_SELECT,
        updateable: true,
        placeholder: 'FLD-UDID-148',
        group: UDIGroups.IDENTIFICATION,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'tradeName',
        name: 'DEVICECONSTANTS.ivdr.udi.tradeName.name',
        group: UDIGroups.DEVICE_INFORMATION,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['tradeNameLanguage', 'tradeNameText', 'tradeNameSequenceNumber'])],
        placeholder: '',
        parsingFunction: parseSimpleArray,
        children: [
            {
                propertyName: 'tradeNameLanguage',
                name: 'DEVICECONSTANTS.ivdr.udi.tradeNameLanguage.name',
                description: 'DEVICECONSTANTS.ivdr.udi.tradeNameLanguage.description',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-EUD-010',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled',
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                    const tradeNameSequenceNumber = control.parent?.get('tradeNameSequenceNumber');

                    if (control.value) {
                        tradeNameSequenceNumber?.setValue('1');
                    }else{
                        tradeNameSequenceNumber?.setValue('');
                    }
                }
            },
            {
                propertyName: 'tradeNameText',
                name: 'DEVICECONSTANTS.ivdr.udi.tradeNameText.name',
                description: 'DEVICECONSTANTS.ivdr.udi.tradeNameText.description',
                controlType: FormControlType.TEXTAREA,
                updateable: true,
                textareaRows: 6,
                placeholder: 'FLD-UDID-176',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            },
            {
                propertyName: 'tradeNameSequenceNumber',
                name: 'DEVICECONSTANTS.ivdr.udi.tradeNameSequenceNumber.name',
                description: 'DEVICECONSTANTS.ivdr.udi.tradeNameSequenceNumber.description',
                placeholder: 'DEVICECONSTANTS.ivdr.udi.tradeNameSequenceNumber.placeholder',
                maxLength: 32767,
                controlType: FormControlType.INPUT,
                validators: [Validators.pattern(positiveIntegerPattern), Validators.minLength(1), Validators.maxLength(32767)],
                numericType: NumericTypes.INTEGER,
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                disabled: true,
                validationError: 'EUD-item0026',
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea32767',
                    pattern: 'ERRORS.numbersOnly'
                }
            }
        ]
    },
    {
        propertyName: 'storageHandlingCondition',
        name: 'DEVICECONSTANTS.ivdr.udi.storageHandlingCondition.name',
        controlType: FormControlType.REPEATABLE,
        group: UDIGroups.DEVICE_INFORMATION,
        validators: [optionalWithRequired(['storageHandlingConditionType'])],
        parsingFunction: parseArrayField,
        children: [
            {
                propertyName: 'storageHandlingConditionType',
                name: 'DEVICECONSTANTS.ivdr.udi.storageHandlingConditionType.name',
                parsingFunction: parseSimpleField,
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-211',
                description: 'DEVICECONSTANTS.ivdr.udi.storageHandlingConditionType.description',
                group: UDIGroups.DEVICE_INFORMATION,
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput, injector: Injector) => {
                    conditionalDescriptionWithLanguage(injector, control, 'storageHandlingConditionComment', 'storageHandlingConditionCommentLanguage', 'storageHandlingConditionCommentText', StorageHandlingConditionTypeCodeList);
                },
            },
            {
                propertyName: 'storageHandlingConditionComment',
                name: 'DEVICECONSTANTS.ivdr.udi.storageHandlingConditionComment.name',
                controlType: FormControlType.REPEATABLE,
                parsingFunction: parseSimpleArray,
                validators: [optionalWithRequiredParticular(['storageHandlingConditionCommentLanguage', 'storageHandlingConditionCommentText'], 'storageHandlingConditionType', 'SHC099')],
                group: UDIGroups.DEVICE_INFORMATION,
                children: [
                    {
                        propertyName: 'storageHandlingConditionCommentLanguage',
                        name: 'DEVICECONSTANTS.ivdr.udi.storageHandlingConditionCommentLanguage.name',
                        parsingFunction: parseSimpleField,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-EUD-010',
                        description: 'DEVICECONSTANTS.ivdr.udi.storageHandlingConditionCommentLanguage.description',
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'storageHandlingConditionCommentText',
                        name: 'DEVICECONSTANTS.ivdr.udi.storageHandlingConditionCommentText.name',
                        controlType: FormControlType.TEXTAREA,
                        updateable: true,
                        textareaRows: 6,
                        placeholder: 'FLD-UDID-213',
                        parsingFunction: parseSimpleField,
                        description: 'DEVICECONSTANTS.ivdr.udi.storageHandlingConditionCommentText.description',
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    }
                ]
            },
        ]
    },
    {
        propertyName: 'package',
        name: 'DEVICECONSTANTS.ivdr.udi.package.name',
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired([
            'packageIdentifierDICode',
            'packageIdentifierIssuingEntityCode',
            'packageStatusCode',
            'childIdentifierDICode',
            'childIdentifierIssuingEntityCode',
            'numberOfItems'
        ])],
        parsingFunction: parseArrayField,
        group: UDIGroups.PACKAGE_ITEM,
        children: [
            {
                propertyName: 'packageIdentifierDICode',
                name: 'DEVICECONSTANTS.ivdr.udi.packageIdentifierDICode.name',
                description: 'DEVICECONSTANTS.ivdr.udi.packageIdentifierDICode.description',
                controlType: FormControlType.INPUT,
                updateable: true,
                placeholder: 'FLD-UDID-120',
                parsingFunction: parseSimpleField,
                group: UDIGroups.PACKAGE_ITEM,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'packageIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.ivdr.udi.packageIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.ivdr.udi.packageIdentifierIssuingEntityCode.description',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-297',
                parsingFunction: parseSimpleField,
                group: UDIGroups.PACKAGE_ITEM,
                style: {
                  width: '100%'
                },
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'packageStatusCode',
                name: 'DEVICECONSTANTS.ivdr.udi.packageStatusCode.name',
                parsingFunction: parseSimpleField,
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-130',
                description: 'DEVICECONSTANTS.ivdr.udi.packageStatusCode.description',
                group: UDIGroups.PACKAGE_ITEM,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'childIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.ivdr.udi.childIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.ivdr.udi.childIdentifierIssuingEntityCode.description',
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-01',
                parsingFunction: parseSimpleField,
                group: UDIGroups.PACKAGE_ITEM,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'childIdentifierDICode',
                name: 'DEVICECONSTANTS.ivdr.udi.childIdentifierDICode.name',
                description: 'DEVICECONSTANTS.ivdr.udi.childIdentifierDICode.description',
                controlType: FormControlType.INPUT,
                updateable: true,
                placeholder: 'FLD-UDID-124',
                parsingFunction: parseSimpleField,
                group: UDIGroups.PACKAGE_ITEM,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'numberOfItems',
                name: 'DEVICECONSTANTS.ivdr.udi.numberOfItems.name',
                controlType: FormControlType.INPUT,
                updateable: true,
                validators: [Validators.pattern(positiveIntegerPattern)],
                numericType: NumericTypes.INTEGER,
                minLength: 0,
                placeholder: 'FLD-UDID-121',
                description: 'DEVICECONSTANTS.ivdr.udi.numberOfItems.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.PACKAGE_ITEM,
                errorMessage: 'ERRORS.numbersOnly'
            }
        ]
    },
    {
        propertyName: 'criticalWarning',
        name: 'DEVICECONSTANTS.ivdr.udi.criticalWarning.name',
        parsingFunction: parseArrayField,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['criticalWarningValue'])],
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'criticalWarningComment',
                name: 'DEVICECONSTANTS.mdr.udi.criticalWarningComment.name',
                description: '',
                disabled: false,
                initialValue: '',
                placeholder: 'DEVICECONSTANTS.ivdr.udi.criticalWarningComment.placeholder',
                parsingFunction: parseSimpleArray,
                validators: [optionalWithRequiredParticular(['criticalWarningCommentLanguage', 'criticalWarningCommentText'], 'criticalWarningValue', 'CW999')],
                controlType: FormControlType.REPEATABLE,
                group: UDIGroups.DEVICE_INFORMATION,
                children: [
                    {
                        propertyName: 'criticalWarningCommentLanguage',
                        name: 'DEVICECONSTANTS.ivdr.udi.criticalWarningCommentLanguage.name',
                        description: 'DEVICECONSTANTS.ivdr.udi.criticalWarningCommentLanguage.description',
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        initialValue: '',
                        disabled: false,
                        placeholder: 'FLD-EUD-010',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'criticalWarningCommentText',
                        name: 'DEVICECONSTANTS.ivdr.udi.criticalWarningCommentText.name',
                        controlType: FormControlType.TEXTAREA,
                        initialValue: '',
                        updateable: true,
                        textareaRows: 6,
                        disabled: false,
                        placeholder: 'FLD-UDID-319',
                        description: 'DEVICECONSTANTS.ivdr.udi.criticalWarningCommentText.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    }
                ]
            },
            {
                propertyName: 'criticalWarningValue',
                name: 'DEVICECONSTANTS.ivdr.udi.criticalWarningValue.name',
                parsingFunction: parseSimpleField,
                updateable: true,
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-212',
                description: 'DEVICECONSTANTS.ivdr.udi.criticalWarningValue.description',
                group: UDIGroups.DEVICE_INFORMATION,
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput, injector: Injector) => {
                    conditionalDescriptionWithLanguage(injector, control, 'criticalWarningComment', 'criticalWarningCommentLanguage', 'criticalWarningCommentText', CriticalWarningValueCodeList);
                },
            }
        ]
    },
    {
        propertyName: 'substatus',
        name: 'DEVICECONSTANTS.ivdr.udi.substatus.name',
        parsingFunction: parseArrayField,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['substatusCode', 'substatusStartDate'])],
        group: UDIGroups.IDENTIFICATION,
        children: [
            {
                propertyName: 'substatusCode',
                name: 'DEVICECONSTANTS.ivdr.udi.substatusCode.name',
                description: 'DEVICECONSTANTS.ivdr.udi.substatusCode.description',
                updateable: true,
                controlType: FormControlType.DROPDOWN,
                jsonPath: '$.substatus[*].substatusCode',
                placeholder: 'FLD-UDID-131',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'substatusStartDate',
                name: 'DEVICECONSTANTS.ivdr.udi.substatusStartDate.name',
                description: 'DEVICECONSTANTS.ivdr.udi.substatusStartDate.description',
                updateable: true,
                controlType: FormControlType.DATE,
                jsonPath: '$.substatus[*].substatusStartDate',
                placeholder: 'FLD-UDID-126',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'substatusEndDate',
                name: 'DEVICECONSTANTS.ivdr.udi.substatusEndDate.name',
                description: 'DEVICECONSTANTS.ivdr.udi.substatusEndDate.description',
                updateable: true,
                controlType: FormControlType.DATE,
                jsonPath: '$.substatus[*].substatusEndDate',
                placeholder: 'FLD-UDID-127',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION
            },
            {
                propertyName: 'substatusRecalledPrecision',
                name: '',
                controlType: FormControlType.REPEATABLE,
                validators: [optionalWithRequired(['substatusRecalledPrecisionLanguage', 'substatusRecalledPrecisionText'])],
                parsingFunction: parseSimpleArray,
                group: UDIGroups.IDENTIFICATION,
                children: [
                    {
                        propertyName: 'substatusRecalledPrecisionLanguage',
                        name: 'DEVICECONSTANTS.ivdr.udi.substatusRecalledPrecisionLanguage.name',
                        description: 'DEVICECONSTANTS.ivdr.udi.substatusRecalledPrecisionLanguage.description',
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        jsonPath: '$.substatus[*].substatusRecalledPrecision[*].substatusRecalledPrecisionLanguage',
                        placeholder: 'FLD-EUD-010',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.IDENTIFICATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'substatusRecalledPrecisionText',
                        name: 'DEVICECONSTANTS.ivdr.udi.substatusRecalledPrecisionText.name',
                        description: 'DEVICECONSTANTS.ivdr.udi.substatusRecalledPrecisionText.description',
                        textareaRows: 6,
                        controlType: FormControlType.TEXTAREA,
                        placeholder: 'FLD-UDID-122',
                        updateable: true,
                        jsonPath: '$.substatus[*].substatusRecalledPrecision[*].substatusRecalledPrecisionText',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.IDENTIFICATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    }
                ]
            },
            {
                propertyName: 'substatusRecallScope',
                name: 'DEVICECONSTANTS.ivdr.udi.substatusRecallScope.name',
                description: 'DEVICECONSTANTS.ivdr.udi.substatusRecallScope.description',
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-123',
                jsonPath: '$.substatus[*].substatusRecallScope',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION
            }
        ]
    },
    {
        propertyName: 'relatedUDILink',
        name: 'DEVICECONSTANTS.ivdr.udi.relatedUDILink.name',
        parsingFunction: parseComplexField,
        validators: [optionalWithRequired(['relatedUDILinkDICode', 'relatedUDILinkIssuingEntityCode', 'relatedUDILinkType'])],
        controlType: FormControlType.NON_SHOWING,
        hidden: true,
        group: UDIGroups.REFERENCED_DEVICE,
        children: [
            {
                propertyName: 'relatedUDILinkDICode',
                name: 'DEVICECONSTANTS.ivdr.udi.relatedUDILinkDICode.name',
                description: 'DEVICECONSTANTS.ivdr.udi.relatedUDILinkDICode.description',
                updateable: true,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-180',
                parsingFunction: parseSimpleField,
                group: UDIGroups.REFERENCED_DEVICE,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'relatedUDILinkIssuingEntityCode',
                name: 'DEVICECONSTANTS.ivdr.udi.relatedUDILinkIssuingEntityCode.name',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-341',
                description: 'DEVICECONSTANTS.ivdr.udi.relatedUDILinkIssuingEntityCode.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.REFERENCED_DEVICE,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'relatedUDILinkType',
                name: 'DEVICECONSTANTS.ivdr.udi.relatedUDILinkType.name',
                description: 'DEVICECONSTANTS.ivdr.udi.relatedUDILinkType.description',
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-181',
                parsingFunction: parseSimpleField,
                group: UDIGroups.REFERENCED_DEVICE,
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'marketInfoPlacingOnTheEU',
        name: 'DEVICECONSTANTS.ivdr.udi.marketInfoPlacingOnTheEU.name',
        controlType: FormControlType.NON_SHOWING,
        parsingFunction: parseComplexField,
        validators: [optionalWithRequired(['marketInfoPlacingOnTheEUCountry', 'marketInfoOriginalPlacedOnTheMarket'])],
        group: UDIGroups.MARKET_INFO,
        children: [
            {
                propertyName: 'marketInfoPlacingOnTheEUCountry',
                name: 'DEVICECONSTANTS.ivdr.udi.marketInfoPlacingOnTheEUCountry.name',
                description: 'DEVICECONSTANTS.ivdr.udi.marketInfoPlacingOnTheEUCountry.description',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-137',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'marketInfoPlacingOnTheEUStartDate',
                name: 'DEVICECONSTANTS.ivdr.udi.marketInfoPlacingOnTheEUStartDate.name',
                description: 'DEVICECONSTANTS.ivdr.udi.marketInfoPlacingOnTheEUStartDate.description',
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-250',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoPlacingOnTheEUEndDate',
                name: 'DEVICECONSTANTS.ivdr.udi.marketInfoPlacingOnTheEUEndDate.name',
                description: 'DEVICECONSTANTS.ivdr.udi.marketInfoPlacingOnTheEUEndDate.description',
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-251',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoOriginalPlacedOnTheMarket',
                name: 'DEVICECONSTANTS.ivdr.udi.marketInfoOriginalPlacedOnTheMarket.name',
                description: 'DEVICECONSTANTS.ivdr.udi.marketInfoOriginalPlacedOnTheMarket.description',
                controlType: FormControlType.CHECKBOX,
                placeholder: 'FLD-UDID-137',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO,
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    yesValidator: 'ERRORS.yesValidator'
                }
            },
        ]
    },
    {
        propertyName: 'marketInfoMadeAvailable',
        name: 'DEVICECONSTANTS.ivdr.udi.marketInfoMadeAvailable.name',
        group: UDIGroups.MARKET_INFO,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['marketInfoMadeAvailableCountry', 'marketInfoOriginalPlacedOnTheMarket'])],
        placeholder: '',
        parsingFunction: parseArrayField,
        children: [
            {
                propertyName: 'marketInfoMadeAvailableCountry',
                name: 'DEVICECONSTANTS.ivdr.udi.marketInfoMadeAvailableCountry.name',
                description: 'DEVICECONSTANTS.ivdr.udi.marketInfoMadeAvailableCountry.description',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-252',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'marketInfoMadeAvailableStartDate',
                name: 'DEVICECONSTANTS.ivdr.udi.marketInfoMadeAvailableStartDate.name',
                description: 'DEVICECONSTANTS.ivdr.udi.marketInfoMadeAvailableStartDate.description',
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-250',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoMadeAvailableEndDate',
                name: 'DEVICECONSTANTS.ivdr.udi.marketInfoMadeAvailableEndDate.name',
                description: 'DEVICECONSTANTS.ivdr.udi.marketInfoMadeAvailableEndDate.description',
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-251',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoOriginalPlacedOnTheMarket',
                name: 'DEVICECONSTANTS.ivdr.udi.marketInfoOriginalPlacedOnTheMarket.name',
                description: 'DEVICECONSTANTS.ivdr.udi.marketInfoOriginalPlacedOnTheMarket.description',
                controlType: FormControlType.CHECKBOX,
                placeholder: 'FLD-UDID-252',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO,
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    noValidator: 'ERRORS.noValidator'
                }
            },
        ]
    },
    {
        propertyName: 'productDesignerActorCode',
        name: 'DEVICECONSTANTS.ivdr.udi.productDesignerActorCode.name',
        description: 'DEVICECONSTANTS.ivdr.udi.productDesignerActorCode.description',
        group: UDIGroups.PRODUCT_DESIGNER,
        errorMessage: 'ERRORS.actorCode',
        validators: [Validators.pattern(actorCodePattern)],
        placeholder: 'FLD-UDID-221',
        updateable: true,
        controlType: FormControlType.INPUT,
        parsingFunction: parseComplexField,
        style: {
            width: '50%',
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'productDesignerOrganisation',
        name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisation.name',
        group: UDIGroups.PRODUCT_DESIGNER,
        controlType: FormControlType.NON_SHOWING,
        validators: [optionalWithRequired([
            'productDesignerOrganisationNameText',
            'productDesignerOrganisationNameLanguage'
        ])],
        parsingFunction: parseComplexField,
        children: [
            {
                propertyName: 'productDesignerOrganisationNameText',
                name: 'DEVICECONSTANTS.ivdr.udi.productDesingerOrganisationNameText.name',
                textareaRows: 6,
                controlType: FormControlType.TEXTAREA,
                updateable: true,
                placeholder: 'FLD-UDID-353',
                description: 'DEVICECONSTANTS.ivdr.udi.productDesingerOrganisationNameText.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.PRODUCT_DESIGNER,
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            },
            {
                propertyName: 'productDesignerOrganisationNameLanguage',
                name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationNameLanguage.name',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-EUD-010',
                description: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationNameLanguage.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.PRODUCT_DESIGNER,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'productDesignerOrganisationGeographicAddress',
                name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationGeographicAddress.name',
                controlType: FormControlType.NON_SHOWING,
                validators: [optionalWithRequired(['productDesignerOrganisationCountry', 'productDesignerOrganisationPostalCode'])],
                parsingFunction: parseComplexField,
                group: UDIGroups.PRODUCT_DESIGNER,
                children: [
                    {
                        propertyName: 'productDesignerOrganisationAddressComplement',
                        name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationAddressComplement.name',
                        description: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationAddressComplement.description',
                        controlType: FormControlType.TEXTAREA,
                        textareaRows: 4,
                        updateable: true,
                        maxLength: 500,
                        placeholder: 'FLD-EUD-040',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        errorMessage: 'ERRORS.tooLong500'
                    },
                    {
                        propertyName: 'productDesignerOrganisationCity',
                        name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationCity.name',
                        description: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationCity.description',
                        updateable: true,
                        controlType: FormControlType.TEXTAREA,
                        textareaRows: 4,
                        maxLength: 500,
                        placeholder: 'FLD-EUD-036',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        errorMessage: 'ERRORS.tooLong500'
                    },
                    {
                        propertyName: 'productDesignerOrganisationCountry',
                        name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationCountry.name',
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-EUD-042',
                        updateable: true,
                        description: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationCountry.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'productDesignerOrganisationPOBox',
                        name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationPOBox.name',
                        description: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationPOBox.description',
                        controlType: FormControlType.INPUT,
                        placeholder: 'FLD-EUD-041',
                        updateable: true,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '47%',
                            'margin-right': '24px'
                        },
                        maxLength: 120,
                        errorMessage: 'ERRORS.textArea120'
                    },
                    {
                        propertyName: 'productDesignerOrganisationPostalCode',
                        name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationPostalCode.name',
                        updateable: true,
                        controlType: FormControlType.INPUT,
                        placeholder: 'FLD-EUD-039',
                        description: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationPostalCode.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '47%'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea120'
                        }
                    },
                    {
                        propertyName: 'productDesignerOrganisationStreet',
                        name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationStreet.name',
                        updateable: true,
                        controlType: FormControlType.TEXTAREA,
                        textareaRows: 4,
                        maxLength: 500,
                        placeholder: 'FLD-EUD-034',
                        description: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationStreet.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        validators: [Validators.minLength(1), Validators.maxLength(500)],
                        errorMessage: 'ERRORS.tooLong500'
                    },
                    {
                        propertyName: 'productDesignerOrganisationStreetNum',
                        name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationStreetNum.name',
                        description: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationStreetNum.description',
                        updateable: true,
                        controlType: FormControlType.INPUT,
                        placeholder: 'FLD-EUD-035',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '100%'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: 'ERRORS.textArea120'
                    },
                ]
            },
            {
                propertyName: 'productDesignerOrganisationContactDetail',
                name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationContactDetail.name',
                parsingFunction: parseSimpleArray,
                validators: [optionalWithRequired(['productDesignerOrganisationContactDetaileMail'])],
                controlType: FormControlType.REPEATABLE,
                group: UDIGroups.PRODUCT_DESIGNER,
                children: [
                    {
                        propertyName: 'productDesignerOrganisationContactDetaileMail',
                        name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationContactDetaileMail.name',
                        description: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationContactDetaileMail.description',
                        updateable: true,
                        controlType: FormControlType.INPUT,
                        placeholder: 'FLD-EUD-018',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '100%'
                        },
                        validators: [Validators.pattern(emailPattern), Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: {
                          required: 'ERRORS.notFilled',
                          pattern: 'ERRORS.wrongSyntax',
                          maxlength: 'ERRORS.textArea120'
                        }
                    },
                    {
                        propertyName: 'productDesignerOrganisationContactDetailPhone',
                        name: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationContactDetailPhone.name',
                        updateable: true,
                        controlType: FormControlType.INPUT,
                        placeholder: 'FLD-EUD-017',
                        description: 'DEVICECONSTANTS.ivdr.udi.productDesignerOrganisationContactDetailPhone.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '100%'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: 'ERRORS.textArea120'
                    },
                ]
            },
        ]
    }
];
