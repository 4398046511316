import {AfterContentInit, Component, ContentChild, ElementRef, Input} from '@angular/core';
import {NgControl} from '@angular/forms';
import {NzInputDirective} from 'ng-zorro-antd/input';
import {NzSelectComponent} from 'ng-zorro-antd/select';

@Component({
  selector: 'eudamed-multiselect-searchable-wrapper',
  templateUrl: './eudamed-multiselect-searchable.component.html',
  styleUrls: ['./eudamed-multiselect-searchable.component.scss']
})
export class EudamedMultiselectSearchableComponent implements AfterContentInit {

  @ContentChild(NzInputDirective, {read: ElementRef}) input!: ElementRef;
  @ContentChild(NzSelectComponent) select!: NzSelectComponent;
  @ContentChild(NzSelectComponent, {read: ElementRef}) selectElement: ElementRef;

  @Input()
  label: string;

  @Input()
  optionalClass: any;

  @ContentChild(NgControl) control: NgControl;

  inputValue: any;
  currentStateClass = ['empty-input', 'label'];

  validityOfInput = true;

  ngAfterContentInit(): void {
      if (this.control.control?.value === null ||
          this.control.control?.value === '' ||
          this.control.control?.value === [] ||
              this.control.control?.value === undefined){
          this.currentStateClass = ['empty-input', 'label'];
          this.optionalClass = ['empty-input', 'label'];
      }else{
          this.currentStateClass = ['non-empty-input', 'label'];
      }

      this.control.valueChanges?.subscribe((value: any) => {
          this.inputValue = value;
          if (value === null || value === '' || value === undefined){
              this.currentStateClass = ['empty-input', 'label'];
              this.optionalClass = ['empty-input', 'label'];
          }else {
              this.currentStateClass = ['non-empty-input', 'label'];
          }
      });
      this.control.statusChanges?.subscribe((status: any) => {
          if (status === 'INVALID'){
              this.currentStateClass = ['invalid-input', 'label'];
              this.validityOfInput = false;
          }else{
            this.validityOfInput = true;
            if (this.inputValue === null ||
                this.inputValue === '' ||
                    this.inputValue === undefined){
                this.currentStateClass = ['empty-input', 'label'];
                this.optionalClass = ['empty-input', 'label'];
            }else{
                this.currentStateClass = ['non-empty-input', 'label'];
            }
          }
      });
      if (this.select) {
          const selectStylableElement: HTMLElement = this.selectElement.nativeElement.firstChild;
          selectStylableElement.style.minHeight = '52px';
          selectStylableElement.style.overflow = 'auto';
          selectStylableElement.style.display = 'flex';
          selectStylableElement.style.alignItems = 'flex-end';
          selectStylableElement.style.paddingTop = '25px';
          selectStylableElement.style.paddingLeft = '8px';
      }
  }


}
