import {Injectable} from '@angular/core';
import {BehaviorSubject, of, Subject} from 'rxjs';

@Injectable()
export class UserStoreService {

    private readonly loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly allUsers = new BehaviorSubject([]);
    private readonly searchChanged = new Subject();
    private readonly totalNumberInHeader: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private pageIndex = 1;
    private pageSize = 20;

    readonly loading$ = this.loading.asObservable();
    readonly allUsers$ = this.allUsers.asObservable();
    readonly searchChanged$ = this.searchChanged.asObservable();
    readonly total$ = this.totalNumberInHeader.asObservable();

    constructor() {}

    searchHasChanged(): void {
        this.searchChanged.next();
    }

    setLoading(value: boolean): void {
      this.loading.next(value);
    }

    setAllUsers(users: []): void {
      this.allUsers.next(users);
    }

    setTotalNumber(totalNumberInHeader: any): void {
      this.totalNumberInHeader.next(totalNumberInHeader);
    }

    setPageIndex(pageIndex: number): void {
      this.pageIndex = pageIndex;
    }

    setPageSize(pageSize: number): void {
      this.pageSize = pageSize;
    }

    getPageIndex(): number {
      return this.pageIndex;
    }

    getPageSize(): number {
      return this.pageSize;
    }
}
