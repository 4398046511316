import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationConfigurationService {

  constructor(private http: HttpClient) { }

  keycloakConfigUrl = `assets/config/keycloak.config.json`;
  configUrl = `assets/config/app.config.json`;
  websocketConfigUrl = `assets/config/websockets.config.json`;
  brandingConfigUrl = `assets/config/branding.config.json`;
  envConfigUrl = `assets/config/env.config.json`;

  private configSettings: any = null;

  get settings() {
    return this.configSettings;
  }

  public load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.configUrl).subscribe((response: any) => {
        this.configSettings = response;
        resolve(true);
      });
    });
  }

  public getKeycloakConfiguration(): Observable<any> {
    return this.http.get<any>(this.keycloakConfigUrl);
  }

  public getWebsocketConfiguration(): Observable<any> {
    return this.http.get<any>(this.websocketConfigUrl);
  }

  public getBrandingConfiguration(): Observable<any> {
    return this.http.get<any>(this.brandingConfigUrl);
  }

  public getEnvConfiguration(): Observable<any> {
    return this.http.get<any>(this.envConfigUrl);
  }
}
