<div  class="app-header" nzAlign="middle" nz-row>
    <div nz-col>
        <h1 style="margin:auto;" class="current-page-title">
            {{title}}
        </h1>
    </div>
    <div nz-col nzSpan="13">
        <ng-template midHeader></ng-template>
    </div>
    <app-user class="user-info">
    </app-user>
</div>
