<eudamed-dropdown-wrapper [label]="label | translate">
    <nz-select
        [nzShowArrow]="false"
        style="width:100%;"
        nzShowSearch
        (nzScrollToBottom)="loadMoreBasicUdis()"
        (nzFocus)="onSearch('')"
        nzServerSearch
        nzAllowClear
        [compareWith]="basicComparison"
        [nzPlaceHolder]="placeholder"
        (nzOnSearch)="onSearch($event)"
        [nzDropdownRender]="renderTemplate"
        (ngModelChange)="modelChanged($event)"
        [ngModel]="basicObject"
        >
        <nz-option *ngFor="let o of basicUdiList"  [nzValue]="o" [nzLabel]="o.basicUDIIdentifierDICode + '/' + o.basicUDIIdentifierIssuingEntityCode"></nz-option>
    </nz-select>
</eudamed-dropdown-wrapper>

<ng-template #renderTemplate>
    <nz-spin *ngIf="basicUdiListLoading"></nz-spin>
</ng-template>
