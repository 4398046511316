export class Filter {
    field: string;
    include: boolean;
    value: string;
    typeOfFilter: FilterType;
    isApplied = false;
    isSearch = false;
    isSort = false;

    constructor(){
    }


    createFromFieldAndValue(filterField: string, filterValue: string): void{
        if (filterField.endsWith('_exclude')){
            this.include = false;
            this.field = filterField.slice(0, -8);
        }else{
            this.include = true;
            this.field = filterField;
        }

        if (filterValue.startsWith('$')){
            this.typeOfFilter = FilterType.StartsWith;
            this.value = filterValue.substring(1);
        }else if (filterValue.endsWith('$')){
            this.typeOfFilter = FilterType.StartsWith;
            this.value = filterValue.substring(0, filterValue.length - 1);
        }else{
            this.typeOfFilter = FilterType.Contains;
            this.value = filterValue;
        }

        this.isApplied = true;

    }

    valueToString(): string{
        if (this.typeOfFilter === FilterType.StartsWith){
            return '$' + this.value;
        } else if (this.typeOfFilter === FilterType.EndsWith){
            return this.value + '$';
        } else {
            return this.value;
        }
    }

    fieldToString(): string {
        if (!this.include){
            return this.field + '_exclude';
        }else {
            return this.field;
        }
    }
}

export enum FilterType {
    StartsWith = 'Starts with',
    EndsWith = 'Ends with',
    Contains = 'Contains'
}
