import {Component} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseFormFieldType} from '../base-form-field-type.class';

@Component({
    selector: 'number-input-render',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss']
})
export class NumberInputRenderingComponent extends BaseFormFieldType {
    optionalClass = ['non-empty-input', 'label'];
    Validators = Validators;
    Infinity = Infinity;
}
