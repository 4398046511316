import {DeviceConstants} from '.';
import { UDIGroups } from './attribute-groups.enum';
import {parseSimpleField, parseSimpleArray, parseComplexField, parseArrayField} from '../../util/device-to-details.util';
import FormBuildingInput, {NumericTypes} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {AbstractControl, Validators} from '@angular/forms';
import {FormControlType} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-control-type.enum';
import {actorCodePattern, optionalWithRequired, optionalWithRequiredParticular, positiveIntegerPattern} from './validators/complex-validators.util';
import {Injector} from '@angular/core';
import {conditionalDescriptionWithLanguage} from './services/eudamed-editor.util';
import {CriticalWarningValueCodeList, StorageHandlingConditionTypeCodeList} from '../codelists.constants';

export const SPPUDIDEVICE: Array<DeviceConstants & FormBuildingInput> = [
    {
        propertyName: 'secondaryIdentifier',
        name: 'DEVICECONSTANTS.spp.udi.secondaryIdentifier.name',
        controlType: FormControlType.NON_SHOWING,
        placeholder: 'FLD-UDID-353',
        validators: [optionalWithRequired(['secondaryIdentifierDICode', 'secondaryIdentifierIssuingEntityCode'])],
        parsingFunction: parseComplexField,
        group: UDIGroups.IDENTIFICATION,
        children: [
            {
                propertyName: 'secondaryIdentifierDICode',
                name: 'DEVICECONSTANTS.spp.udi.secondaryIdentifierDICode.name',
                parsingFunction: parseSimpleField,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-136',
                description: 'DEVICECONSTANTS.spp.udi.secondaryIdentifierDICode.description',
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'secondaryIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.spp.udi.secondaryIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.spp.udi.secondaryIdentifierIssuingEntityCode.description',
                controlType: FormControlType.DROPDOWN,
                placeholder: 'FLD-UDID-293',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'deviceStatusCode',
        name: 'DEVICECONSTANTS.spp.udi.deviceStatusCode.name',
        controlType: FormControlType.DROPDOWN,
        validators: [Validators.required],
        updateable: true,
        placeholder: 'FLD-UDID-130',
        description: 'DEVICECONSTANTS.spp.udi.deviceStatusCode.description',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'referenceNumber',
        name: 'DEVICECONSTANTS.spp.udi.referenceNumber.name',
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-163',
        validators: [Validators.required, Validators.minLength(1), Validators.maxLength(255)],
        parsingFunction: parseSimpleField,
        description: 'DEVICECONSTANTS.spp.udi.referenceNumber.description',
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        style: {
            width: '100%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.tooLong255'
        }
    },
    {
        propertyName: 'deviceType',
        name: 'DEVICECONSTANTS.spp.udi.deviceType.name',
        parsingFunction: parseSimpleField,
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        validators: [Validators.required],
        placeholder: 'FLD-ATRIFY-01',
        description: 'DEVICECONSTANTS.spp.udi.deviceType.description',
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'UDIDIIdentifierDICode',
        name: 'DEVICECONSTANTS.spp.udi.UDIDIIdentifierDICode.name',
        description: 'DEVICECONSTANTS.spp.udi.UDIDIIdentifierDICode.description',
        controlType: FormControlType.INPUT,
        validators: [Validators.required, Validators.minLength(1), Validators.maxLength(120)],
        placeholder: 'FLD-UDID-178',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        style: {
            width: '50%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea120'
        }
    },
    {
        propertyName: 'UDIDIIdentifierIssuingEntityCode',
        name: 'DEVICECONSTANTS.spp.udi.UDIDIIdentifierIssuingEntityCode.name',
        description: 'DEVICECONSTANTS.spp.udi.UDIDIIdentifierIssuingEntityCode.description',
        controlType: FormControlType.DROPDOWN,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-291',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'customerMessageID',
        name: 'DEVICECONSTANTS.spp.basic.customerMessageID.name',
        description: 'DEVICECONSTANTS.spp.basic.customerMessageID.description',
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-ATRIFY-02',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true
    },
    {
        propertyName: 'customerTransactionID',
        name: 'DEVICECONSTANTS.spp.basic.customerTransactionID.name',
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-ATRIFY-03',
        description: 'DEVICECONSTANTS.spp.basic.customerTransactionID.description',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true
    },
    {
        propertyName: 'PRActorCode',
        name: 'DEVICECONSTANTS.spp.udi.PRActorCode.name',
        description: 'DEVICECONSTANTS.spp.udi.PRActorCode.description',
        validators: [Validators.required, Validators.pattern(actorCodePattern)],
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-44',
        jsonPath: '$.PRActorCode',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        style: {
            width: '50%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            pattern: 'ERRORS.actorCode'
        }
    },
    {
        propertyName: 'additionalDescription',
        name: 'DEVICECONSTANTS.spp.udi.additionalDescription.name',
        parsingFunction: parseSimpleArray,
        controlType: FormControlType.REPEATABLE,
        placeholder: 'FLD-UDID-353',
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'additionalProductDescriptionLanguage',
                name: 'DEVICECONSTANTS.spp.udi.additionalProductDescriptionLanguage.name',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-EUD-010',
                description: 'DEVICECONSTANTS.spp.udi.additionalProductDescriptionLanguage.description',
                validators: [Validators.required],
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'additionalProductDescriptionText',
                name: 'DEVICECONSTANTS.spp.udi.additionalProductDescriptionText.name',
                description: 'DEVICECONSTANTS.spp.udi.additionalProductDescriptionText.description',
                updateable: true,
                minLength: 1,
                maxLength: 2000,
                textareaRows: 6,
                validators: [Validators.required],
                controlType: FormControlType.TEXTAREA,
                placeholder: 'FLD-UDID-175',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            }
        ]
    },
    {
        propertyName: 'website',
        name: 'DEVICECONSTANTS.spp.udi.website.name',
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-174',
        updateable: true,
        description: 'DEVICECONSTANTS.spp.udi.website.description',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        style: {
            width: '100%',
            marginBottom: '48px'
        },
        validators: [Validators.minLength(1), Validators.maxLength(255)],
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.tooLong255'
        }
    },
    {
        propertyName: 'sterile',
        name: 'DEVICECONSTANTS.spp.udi.sterile.name',
        description: 'DEVICECONSTANTS.spp.udi.sterile.description',
        validators: [Validators.required],
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-169',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'sterilization',
        name: 'DEVICECONSTANTS.spp.udi.sterilization.name',
        description: 'DEVICECONSTANTS.spp.udi.sterilization.description',
        validators: [Validators.required],
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-170',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'basicUDIIdentifier',
        name: 'DEVICECONSTANTS.spp.udi.basicUDIIdentifier.name',
        parsingFunction: parseComplexField,
        controlType: FormControlType.BASIC_ASYNC_SELECT,
        validators: [optionalWithRequired(['basicUDIIdentifierDICode', 'basicUDIIdentifierIssuingEntityCode'])],
        placeholder: 'FLD-UDID-353',
        group: UDIGroups.IDENTIFICATION,
        style: {
            marginBottom: '48px'
        },
        children: [
            {
                propertyName: 'basicUDIIdentifierDICode',
                name: 'DEVICECONSTANTS.spp.udi.basicUDIIdentifierDICode.name',
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-145',
                description: 'DEVICECONSTANTS.spp.udi.basicUDIIdentifierDICode.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'basicUDIIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.spp.udi.basicUDIIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.spp.udi.basicUDIIdentifierIssuingEntityCode.description',
                controlType: FormControlType.DROPDOWN,
                placeholder: 'FLD-UDID-01',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'MDNCode',
        name: 'DEVICECONSTANTS.spp.udi.MDNCode.name',
        parsingFunction: parseSimpleField,
        updateable: true,
        validators: [Validators.required],
        controlType: FormControlType.MDN_SELECT,
        placeholder: 'FLD-UDID-149',
        description: 'DEVICECONSTANTS.spp.udi.MDNCode.description',
        group: UDIGroups.CLASSIFICATION,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'productionIdentifier',
        name: 'DEVICECONSTANTS.spp.udi.productionIdentifier.name',
        parsingFunction: parseSimpleField,
        validators: [Validators.required],
        controlType: FormControlType.REPEATABLE_SELECT,
        updateable: true,
        placeholder: 'FLD-UDID-148',
        description: 'DEVICECONSTANTS.spp.udi.productionIdentifier.description',
        group: UDIGroups.IDENTIFICATION,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'tradeName',
        name: 'DEVICECONSTANTS.spp.udi.tradeName.name',
        parsingFunction: parseArrayField,
        controlType: FormControlType.REPEATABLE,
        placeholder: 'FLD-UDID-353',
        validators: [optionalWithRequired(['tradeNameLanguage', 'tradeNameText', 'tradeNameSequenceNumber'])],
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'tradeNameSequenceNumber',
                name: 'DEVICECONSTANTS.spp.udi.tradeNameSequenceNumber.name',
                maxLength: 32767,
                controlType: FormControlType.INPUT,
                validators: [Validators.pattern(positiveIntegerPattern), Validators.minLength(1), Validators.maxLength(32767)],
                numericType: NumericTypes.INTEGER,
                placeholder: 'FLD-UDID-353',
                description: 'DEVICECONSTANTS.spp.udi.tradeNameSequenceNumber.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                disabled: true,
                validationError: 'EUD-item0026',
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea32767',
                    pattern: 'ERRORS.numbersOnly'
                }
            },
            {
                propertyName: 'tradeNameLanguage',
                name: 'DEVICECONSTANTS.spp.udi.tradeNameLanguage.name',
                description: 'DEVICECONSTANTS.spp.udi.tradeNameLanguage.description',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-EUD-010',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled',
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                    const tradeNameSequenceNumber = control.parent?.get('tradeNameSequenceNumber');

                    if (control.value) {
                        tradeNameSequenceNumber?.setValue('1');
                    }else{
                        tradeNameSequenceNumber?.setValue('');
                    }
                }
            },
            {
                propertyName: 'tradeNameText',
                name: 'DEVICECONSTANTS.spp.udi.tradeNameText.name',
                description: 'DEVICECONSTANTS.spp.udi.tradeNameText.description',
                controlType: FormControlType.TEXTAREA,
                textareaRows: 6,
                updateable: true,
                placeholder: 'FLD-UDID-176',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            }
        ]
    },
    {
        propertyName: 'storageHandlingCondition',
        name: 'DEVICECONSTANTS.spp.udi.storageHandlingCondition.name',
        description: 'DEVICECONSTANTS.spp.udi.storageHandlingCondition.description',
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['storageHandlingConditionType'])],
        placeholder: 'FLD-UDID-353',
        parsingFunction: parseArrayField,
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'storageHandlingConditionType',
                name: 'DEVICECONSTANTS.spp.udi.storageHandlingConditionType.name',
                parsingFunction: parseSimpleField,
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-211',
                description: 'DEVICECONSTANTS.spp.udi.storageHandlingConditionType.description',
                group: UDIGroups.DEVICE_INFORMATION,
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput, injector: Injector) => {
                    conditionalDescriptionWithLanguage(injector, control, 'storageHandlingConditionComment', 'storageHandlingConditionCommentLanguage', 'storageHandlingConditionCommentText', StorageHandlingConditionTypeCodeList);
                },
            },
            {
                propertyName: 'storageHandlingConditionComment',
                name: 'DEVICECONSTANTS.spp.udi.storageHandlingConditionComment.name',
                group: UDIGroups.DEVICE_INFORMATION,
                controlType: FormControlType.REPEATABLE,
                validators: [optionalWithRequiredParticular(['storageHandlingConditionCommentLanguage', 'storageHandlingConditionCommentText'], 'storageHandlingConditionType', 'SHC099')],
                placeholder: 'FLD-UDID-353',
                parsingFunction: parseSimpleArray,
                children: [
                    {
                        propertyName: 'storageHandlingConditionCommentLanguage',
                        name: 'DEVICECONSTANTS.spp.udi.storageHandlingConditionCommentLanguage.name',
                        parsingFunction: parseSimpleField,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-EUD-010',
                        description: 'DEVICECONSTANTS.spp.udi.storageHandlingConditionCommentLanguage.description',
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'storageHandlingConditionCommentText',
                        name: 'DEVICECONSTANTS.spp.udi.storageHandlingConditionCommentText.name',
                        controlType: FormControlType.TEXTAREA,
                        textareaRows: 6,
                        updateable: true,
                        placeholder: 'FLD-UDID-213',
                        parsingFunction: parseSimpleField,
                        description: 'DEVICECONSTANTS.spp.udi.storageHandlingConditionCommentText.description',
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            maxlength: 'ERRORS.textArea2000',
                            required: 'ERRORS.notFilled'
                        }
                    }
                ]
            },
        ]
    },
    {
        propertyName: 'package',
        name: 'DEVICECONSTANTS.spp.udi.package.name',
        controlType: FormControlType.REPEATABLE,
        placeholder: 'FLD-UDID-353',
        group: UDIGroups.PACKAGE_ITEM,
        validators: [optionalWithRequired([
            'packageIdentifierDICode',
            'packageIdentifierIssuingEntityCode',
            'packageStatusCode',
            'childIdentifierDICode',
            'childIdentifierIssuingEntityCode',
            'numberOfItems'
        ])],
        parsingFunction: parseArrayField,
        children: [
            {
                propertyName: 'packageIdentifierDICode',
                name: 'DEVICECONSTANTS.spp.udi.packageIdentifierDICode.name',
                controlType: FormControlType.INPUT,
                updateable: true,
                placeholder: 'FLD-UDID-120',
                parsingFunction: parseSimpleField,
                description: 'DEVICECONSTANTS.spp.udi.packageIdentifierDICode.description',
                group: UDIGroups.PACKAGE_ITEM,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'packageIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.spp.udi.packageIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.spp.udi.packageIdentifierIssuingEntityCode.description',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-297',
                parsingFunction: parseSimpleField,
                group: UDIGroups.PACKAGE_ITEM,
                style: {
                  width: '100%'
                },
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'packageStatusCode',
                name: 'DEVICECONSTANTS.spp.udi.packageStatusCode.name',
                description: 'DEVICECONSTANTS.spp.udi.packageStatusCode.description',
                parsingFunction: parseSimpleField,
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-130',
                group: UDIGroups.PACKAGE_ITEM,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'childIdentifierDICode',
                name: 'DEVICECONSTANTS.spp.udi.childIdentifierDICode.name',
                parsingFunction: parseSimpleField,
                controlType: FormControlType.INPUT,
                updateable: true,
                placeholder: 'FLD-UDID-124',
                description: 'DEVICECONSTANTS.spp.udi.childIdentifierDICode.description',
                group: UDIGroups.PACKAGE_ITEM,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'childIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.spp.udi.childIdentifierIssuingEntityCode.name',
                parsingFunction: parseSimpleField,
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-01',
                description: 'DEVICECONSTANTS.spp.udi.childIdentifierIssuingEntityCode.description',
                group: UDIGroups.PACKAGE_ITEM,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'numberOfItems',
                name: 'DEVICECONSTANTS.spp.udi.numberOfItems.name',
                description: 'DEVICECONSTANTS.spp.udi.numberOfItems.description',
                controlType: FormControlType.INPUT,
                updateable: true,
                validators: [Validators.pattern(positiveIntegerPattern)],
                numericType: NumericTypes.INTEGER,
                minLength: 0,
                placeholder: 'FLD-UDID-121',
                parsingFunction: parseSimpleField,
                group: UDIGroups.PACKAGE_ITEM,
                errorMessage: 'ERRORS.numbersOnly'
            }
        ]
    },
    {
        propertyName: 'criticalWarning',
        name: 'DEVICECONSTANTS.spp.udi.criticalWarning.name',
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['criticalWarningValue'])],
        placeholder: 'FLD-UDID-353',
        group: UDIGroups.DEVICE_INFORMATION,
        parsingFunction: parseArrayField,
        children: [
            {
                propertyName: 'criticalWarningValue',
                name: 'DEVICECONSTANTS.spp.udi.criticalWarningValue.name',
                parsingFunction: parseSimpleField,
                updateable: true,
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-212',
                description: 'DEVICECONSTANTS.spp.udi.criticalWarningValue.description',
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput, injector: Injector) => {
                    conditionalDescriptionWithLanguage(injector, control, 'criticalWarningComment', 'criticalWarningCommentLanguage', 'criticalWarningCommentText', CriticalWarningValueCodeList);
                },
                group: UDIGroups.DEVICE_INFORMATION
            },
            {
                propertyName: 'criticalWarningComment',
                name: 'DEVICECONSTANTS.spp.udi.criticalWarningComment.name',
                controlType: FormControlType.REPEATABLE,
                placeholder: 'FLD-UDID-353',
                validators: [optionalWithRequiredParticular(['criticalWarningCommentLanguage', 'criticalWarningCommentText'], 'criticalWarningValue', 'CW999')],
                parsingFunction: parseSimpleArray,
                group: UDIGroups.DEVICE_INFORMATION,
                children: [
                    {
                        propertyName: 'criticalWarningCommentLanguage',
                        name: 'DEVICECONSTANTS.spp.udi.criticalWarningCommentLanguage.name',
                        parsingFunction: parseSimpleField,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-EUD-010',
                        description: 'DEVICECONSTANTS.spp.udi.criticalWarningCommentLanguage.description',
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'criticalWarningCommentText',
                        name: 'DEVICECONSTANTS.spp.udi.criticalWarningCommentText.name',
                        parsingFunction: parseSimpleField,
                        controlType: FormControlType.TEXTAREA,
                        updateable: true,
                        textareaRows: 6,
                        placeholder: 'FLD-UDID-319',
                        description: 'DEVICECONSTANTS.spp.udi.storageHandlingConditionCommentText.description',
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            maxlength: 'ERRORS.textArea2000',
                            required: 'ERRORS.notFilled'
                        }
                    }
                ]
            }
        ]
    },
    {
        propertyName: 'substatus',
        name: 'DEVICECONSTANTS.spp.udi.substatus.name',
        parsingFunction: parseArrayField,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['substatusCode', 'substatusStartDate'])],
        placeholder: 'FLD-UDID-353',
        group: UDIGroups.IDENTIFICATION,
        children: [
            {
                propertyName: 'substatusCode',
                name: 'DEVICECONSTANTS.spp.udi.substatusCode.name',
                description: 'DEVICECONSTANTS.spp.udi.substatusCode.description',
                updateable: true,
                controlType: FormControlType.DROPDOWN,
                placeholder: 'FLD-UDID-131',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'substatusStartDate',
                name: 'DEVICECONSTANTS.spp.udi.substatusStartDate.name',
                description: 'DEVICECONSTANTS.spp.udi.substatusStartDate.description',
                updateable: true,
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-126',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'substatusEndDate',
                name: 'DEVICECONSTANTS.spp.udi.substatusEndDate.name',
                description: 'DEVICECONSTANTS.spp.udi.substatusEndDate.description',
                updateable: true,
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-127',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION
            },
            {
                propertyName: 'substatusRecalledPrecision',
                name: 'DEVICECONSTANTS.spp.udi.substatusRecalledPrecision.name',
                parsingFunction: parseSimpleArray,
                validators: [optionalWithRequired(['substatusRecalledPrecisionLanguage', 'substatusRecalledPrecisionText'])],
                controlType: FormControlType.REPEATABLE,
                placeholder: 'FLD-UDID-353',
                group: UDIGroups.IDENTIFICATION,
                children: [
                    {
                        propertyName: 'substatusRecalledPrecisionLanguage',
                        name: 'DEVICECONSTANTS.spp.udi.substatusRecalledPrecisionLanguage.name',
                        parsingFunction: parseSimpleField,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-EUD-010',
                        description: 'DEVICECONSTANTS.spp.udi.substatusRecalledPrecisionLanguage.description',
                        group: UDIGroups.IDENTIFICATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'substatusRecalledPrecisionText',
                        name: 'DEVICECONSTANTS.spp.udi.substatusRecalledPrecisionText.name',
                        parsingFunction: parseSimpleField,
                        textareaRows: 6,
                        controlType: FormControlType.TEXTAREA,
                        updateable: true,
                        placeholder: 'FLD-UDID-122',
                        description: 'DEVICECONSTANTS.spp.udi.substatusRecalledPrecisionText.description',
                        group: UDIGroups.IDENTIFICATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    }
                ]
            },
            {
                propertyName: 'substatusRecallScope',
                name: 'DEVICECONSTANTS.spp.udi.substatusRecallScope.name',
                parsingFunction: parseSimpleField,
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-123',
                description: 'DEVICECONSTANTS.spp.udi.substatusRecallScope.description',
                group: UDIGroups.IDENTIFICATION
            }
        ]
    }
];
