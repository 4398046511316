import { Component, Input} from '@angular/core';

@Component({
  selector: 'eudamed-number-input-wrapper',
  templateUrl: './eudamed-number-input.component.html',
  styleUrls: ['./eudamed-number-input.component.scss'],
})
export class EudamedNumberInputComponent {
    // @ContentChild(NzInputNumberComponent, {read: ElementRef}) input!: ElementRef;

    @Input()
    label: string;
}
