const STATUS_UI_MAPPINGS: {[key: string]: string} = {
    LINKED: 'NEW',
    NEW: 'NEW',
    QUEUED: 'NEW',
    SENDING: 'SENT',
    DELIVERED: 'SENT',
    RESPONDED: 'SENT',
    SUBMITTED: 'SUBMITTED',
    COMPLETED: 'SUCCESS',
    FAILED: 'FAILED',
    CONSUMED: 'SENT',
    REGISTERED: 'REGISTERED',
    DELETED: 'DELETED',
};

export default STATUS_UI_MAPPINGS;
