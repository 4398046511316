import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import FormBuildingInput from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {FormControlType} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-control-type.enum';
import * as deviceAttributeDetails from '../../constants/device-attribute-details';
import {
  AIMDDBASICDEVICE, IVDBASICDEVICE,
  IVDRBASICDEVICE,
  MDDBASICDEVICE,
  SPPBASICDEVICE
} from '../../constants/device-attribute-details';
import {
  CategoryWithState,
  sortedBudiCategories,
  sortedLegacyCategories,
  sortedUdiCategories,
  UDIGroups
} from '../../constants/device-attribute-details/attribute-groups.enum';
import EnrichingDeviceDTO from '../../model/device-enrichment/enriching-device-dto.model';
import EnrichingDeviceProcess from '../../model/device-enrichment/enriching-device-process.model';
import {ApiService} from '../api.service';
import {ValidationService} from '../validation.service';
import {CategoryStatesEnum} from './CategoryStates.enum';


export interface ProcessPair{
    basicProcess: EnrichingDeviceProcess | null;
    udiProcess: EnrichingDeviceProcess | null;
}

@Injectable()
export class DeviceEnrichmentService {

    constructor(private apiService: ApiService,
                private validationService: ValidationService){}

    private readonly enrichingDevice$ = new BehaviorSubject<EnrichingDeviceDTO | null>(null);
    private readonly enrichingDeviceProcessesPair$ = new BehaviorSubject<ProcessPair>({basicProcess: null, udiProcess: null});
    private readonly currentlyEnrichingDeviceProcess$ = new BehaviorSubject<EnrichingDeviceProcess | null>(null);

    private readonly loadingEnrichingDevice$ = new BehaviorSubject<boolean>(false);
    private readonly savingPayload = new BehaviorSubject<boolean>(false);

    private readonly categories$ = new BehaviorSubject<Array<CategoryWithState>>([]);
    private readonly currentCategory$ = new BehaviorSubject<CategoryWithState | null>(null);
    private readonly categoryIndex$ = new BehaviorSubject<number>(0);
    private readonly scrollToTop$ = new Subject();

    readonly enrichingDevice = this.enrichingDevice$.asObservable();
    readonly loadingEnrichingDevice = this.loadingEnrichingDevice$.asObservable();
    readonly categories = this.categories$.asObservable();
    readonly currentCategory = this.currentCategory$.asObservable();
    readonly enrichingDeviceProcesses = this.enrichingDeviceProcessesPair$.asObservable();
    readonly categoryIndex = this.categoryIndex$.asObservable();
    readonly currentlyEnrichingDeviceProcess = this.currentlyEnrichingDeviceProcess$.asObservable();
    readonly savingPayload$ = this.savingPayload.asObservable();
    readonly scrollToTop = this.scrollToTop$.asObservable();


    setCategoryState(category: CategoryWithState, categoryState: CategoryStatesEnum): void{
        category.state = categoryState;
    }

    getEnrichingProcesses(parentEnrichmentDevice: EnrichingDeviceDTO): ProcessPair {
        if (parentEnrichmentDevice.compound){
            const basicProcess = new EnrichingDeviceProcess(parentEnrichmentDevice, this.fullFormData(parentEnrichmentDevice.basicDeviceType), parentEnrichmentDevice.basicPayload);
            const udiProcess = new EnrichingDeviceProcess(parentEnrichmentDevice, this.fullFormData(parentEnrichmentDevice.deviceType), parentEnrichmentDevice.payload);
            return {basicProcess, udiProcess};
        } else {
            if (parentEnrichmentDevice.deviceType.toLowerCase().includes('basic')){
                return {
                    basicProcess: new EnrichingDeviceProcess(parentEnrichmentDevice, this.fullFormData(parentEnrichmentDevice.deviceType), parentEnrichmentDevice.payload),
                    udiProcess: null
                };
            }else{
                return {
                    udiProcess: new EnrichingDeviceProcess(parentEnrichmentDevice, this.fullFormData(parentEnrichmentDevice.deviceType), parentEnrichmentDevice.payload),
                    basicProcess: null
                };
            }
        }
    }

    startEnriching(device: EnrichingDeviceDTO): void {
        this.enrichingDevice$.next(device);
        this.categories$.next(this.initiateCategories(device));
        this.enrichingDeviceProcessesPair$.next(this.getEnrichingProcesses(device));
        if (device.compound){
            this.currentlyEnrichingDeviceProcess$.next(this.enrichingDeviceProcessesPair$.value.basicProcess);
        }else if (device.deviceType.toLowerCase().includes('basic')){
            this.currentlyEnrichingDeviceProcess$.next(this.enrichingDeviceProcessesPair$.value.basicProcess);
        }else{
            this.currentlyEnrichingDeviceProcess$.next(this.enrichingDeviceProcessesPair$.value.udiProcess);
        }
        this.currentCategory$.next(this.categories$.value[0]);
        this.categoryIndex$.next(0);
        this.setCategoryState(this.categories$.value[0], CategoryStatesEnum.VALID);
    }


    sendSinglePayload(): void {
        this.savingPayload.next(true);
        this.sendPayloadToBackend(this.currentlyEnrichingDeviceProcess$.value?.generateJsonPayload(), this.currentlyEnrichingDeviceProcess$.value?.parentGenericDevice)
        .subscribe(() => {
            this.savingPayload.next(false);
        });
    }

    switchTabs(tabIndex: number): void {
        this.scrollToTop$.next();
        if (tabIndex >= this.categories$.value.length){
            // Going to validation
            this.categoryIndex$.next(tabIndex);
            this.currentCategory$.next(null);

        }else if (this.currentCategory$.value === null){
            // Going FROM Validation
            this.switchCategoriesWithoutSending(tabIndex);
        }else{
            this.savingPayload.next(true);
            this.sendPayloadToBackendOnCategoryChange(this.currentlyEnrichingDeviceProcess$.value?.generateJsonPayload(), this.currentlyEnrichingDeviceProcess$.value?.parentGenericDevice)
                .subscribe(() => {
                    this.savingPayload.next(false);
                });
            this.switchCategoriesWithoutSending(tabIndex);
        }
    }


    switchCategoriesWithoutSending(tabIndex: number): void{
            this.categoryIndex$.next(tabIndex);
            const categoryGoingTo = this.categories$.value[tabIndex];
            this.currentCategory$.next(categoryGoingTo);
            if (categoryGoingTo){
                if (categoryGoingTo.state === CategoryStatesEnum.UNVISITED){
                    this.setCategoryState(categoryGoingTo, CategoryStatesEnum.VALID);
                }
            }
            if (sortedBudiCategories.includes(this.categories$.value[tabIndex])){
                this.currentlyEnrichingDeviceProcess$.next(this.enrichingDeviceProcessesPair$.value?.basicProcess);
            }else{
                this.currentlyEnrichingDeviceProcess$.next(this.enrichingDeviceProcessesPair$.value?.udiProcess);
            }

    }

    saveAndNext(tabIndex: number): void{
        this.switchTabs(tabIndex);
    }


    resetCategories(): void {
        this.categoryIndex$.next(0);
        this.currentCategory$.next(null);
    }


    finishEnrichmentAndValidate(): void{
        const budiForm = this.enrichingDeviceProcessesPair$.value?.basicProcess;
        const udiForm = this.enrichingDeviceProcessesPair$.value?.udiProcess;
        let everythingValid = true;

        if (budiForm){
            Object.values(budiForm.formGroups).every((formGroup: AbstractControl) => {
                formGroup.updateValueAndValidity();
                if (formGroup.invalid){
                    everythingValid = false;
                    return false;
                }
                return true;
            });
        }
        if (udiForm){
            Object.values(udiForm.formGroups).every((formGroup: AbstractControl) => {
                formGroup.updateValueAndValidity();
                if (formGroup.invalid){
                    everythingValid = false;
                    return false;
                }
                return true;
            });
        }

        if (everythingValid){
            const enrichedObservables: Array<Observable<any>> = [];

            Object.values(this.enrichingDeviceProcessesPair$.value).forEach((process: EnrichingDeviceProcess | null) => {
                if (process){
                    enrichedObservables.push(this.sendPayloadToBackendOnCategoryChange(process.generateJsonPayload(), process.parentGenericDevice));
                }
            });

            this.savingPayload.next(true);
            forkJoin(enrichedObservables).subscribe((populationResponses: Array<EnrichingDeviceDTO>) => {
                this.savingPayload.next(false);
                const finalEnrichingDTO = populationResponses[0];

                this.validationService.validateDevice(finalEnrichingDTO);

                this.validationService.validatePayload(finalEnrichingDTO.deviceType, finalEnrichingDTO.diCode, finalEnrichingDTO.issuingEntityCode).subscribe(() => {
                    this.nextCategoryIndex();
                });
            });
        }
    }

    nextCategoryIndex(): void {
        this.categoryIndex$.next(this.categoryIndex$.value + 1);
    }

    previousCategortyIndex(): void {
        this.categoryIndex$.next(this.categoryIndex$.value - 1);
    }

    nextCategory(): void {
        if (this.categoryIndex$.value + 1 <= this.categories$.value.length && this.categories$.value[this.categoryIndex$.value + 1].state !== CategoryStatesEnum.DISABLED){
            this.currentCategory$.next(this.categories$.value[this.categoryIndex$.value + 1]);
            this.nextCategoryIndex();
            // if (sortedUdiCategories.includes(this.categories$.value[this.categoryIndex$.value + 1])){
                // this.currentlyEnrichingDeviceProcess$.next(this.enrichingDeviceProcessesPair$.value.basicProcess);
            // }
        }
    }

    previousCategory(): void {
        if (this.categoryIndex$.value - 1 >= 0){
            this.currentCategory$.next(this.categories$.value[this.categoryIndex$.value - 1]);
            this.previousCategortyIndex();
        }
    }

    initiateCategories(device: EnrichingDeviceDTO): Array<CategoryWithState>{
        let allCategories = [];
        if (device.isLegacy === true){
            allCategories = [...sortedBudiCategories, ...sortedLegacyCategories].filter(category => category.categoryValue !== UDIGroups.REFERENCED_DEVICE);
        }else{
            allCategories = [...sortedBudiCategories, ...sortedUdiCategories].filter(category => category.categoryValue !== UDIGroups.REFERENCED_DEVICE);
        }
        // TODO make categories immutable
        allCategories.forEach((category: CategoryWithState) => {
            this.setCategoryState(category, CategoryStatesEnum.UNVISITED);
        });

        if (device.compound){
            this.categories$.next(allCategories);
            this.currentCategory$.next(this.categories$.value[0]);
            this.categoryIndex$.next(0);

            return allCategories;
        }

        if (device.deviceType.includes('BASIC')) {
            const categories = [...sortedBudiCategories];
            if (device.deviceType.includes('SPP')) {
                categories.splice(1, 3);
            }
            this.currentCategory$.next(this.categories$.value[0]);
            this.categoryIndex$.next(0);
            return categories;
        } else {
            let categories = [...sortedUdiCategories];
            if (device.isLegacy === true){
                categories = [...sortedLegacyCategories].filter(category => category.categoryValue !== UDIGroups.REFERENCED_DEVICE);
            }else{
                categories = [...sortedUdiCategories].filter(category => category.categoryValue !== UDIGroups.REFERENCED_DEVICE);
            }
            if (device.deviceType.includes('SPP')) {
                categories.splice(3, 3);
            }
            this.currentCategory$.next(this.categories$.value[0]);
            this.categoryIndex$.next(0);
            return categories;
        }
    }


    fetchDraft(deviceType: string, issuingEntity: string, diCode: string): Observable<EnrichingDeviceDTO | null>{
        this.loadingEnrichingDevice$.next(true);
        return this.apiService.get(`/udimanager/v1/editor/draft/${deviceType}/${issuingEntity}/${encodeURIComponent(diCode)}`)
        .pipe(map(resDevice => {
            this.loadingEnrichingDevice$.next(false);
            return new EnrichingDeviceDTO(resDevice);
        })).pipe(catchError(() => {
            this.loadingEnrichingDevice$.next(false);
            return of(null);
        }));
    }

    private getEnrichingEndpointOnCategoryChange(payload: any, savedDraft: EnrichingDeviceDTO): string{
        // set 'null' inside field storageHandlingConditionCommentText if it is empty or does not exist
        if (payload.storageHandlingCondition) {
            payload.storageHandlingCondition.forEach((condition: any) => {
                condition.storageHandlingConditionComment.forEach((comment: any) => {
                    if (!comment.storageHandlingConditionCommentText
                        || comment.storageHandlingConditionCommentText === null
                        || comment.storageHandlingConditionCommentText === '') {
                        comment.storageHandlingConditionCommentText = 'null';
                    }
                });
            });
        }
        if (payload.criticalWarning) {
            payload.criticalWarning.forEach((condition: any) => {
                condition.criticalWarningComment.forEach((comment: any) => {
                    if (!comment.criticalWarningCommentText
                        || comment.criticalWarningCommentText === null
                        || comment.criticalWarningCommentText === '') {
                        comment.criticalWarningCommentText = 'null';
                    }
                });
            });
        }
        if (payload.deviceType?.toLowerCase().includes('basic')
            && savedDraft.compound
            ){
                return '/udimanager/v1/editor/populate/category/basic';
            }else{
                return '/udimanager/v1/editor/populate/category';
            }
    }

    private getEnrichingEndpoint(payload: any, savedDraft: EnrichingDeviceDTO): string{
        // set 'null' inside field storageHandlingConditionCommentText if it is empty or does not exist
        if (payload.storageHandlingCondition) {
            payload.storageHandlingCondition.forEach((condition: any) => {
                condition.storageHandlingConditionComment.forEach((comment: any) => {
                    if (!comment.storageHandlingConditionCommentText
                        || comment.storageHandlingConditionCommentText === null
                        || comment.storageHandlingConditionCommentText === '') {
                        comment.storageHandlingConditionCommentText = 'null';
                    }
                });
            });
        }
        if (payload.criticalWarning) {
            payload.criticalWarning.forEach((condition: any) => {
                condition.criticalWarningComment.forEach((comment: any) => {
                    if (!comment.criticalWarningCommentText
                        || comment.criticalWarningCommentText === null
                        || comment.criticalWarningCommentText === '') {
                        comment.criticalWarningCommentText = 'null';
                    }
                });
            });
        }
        if (payload.deviceType?.toLowerCase().includes('basic')
            && savedDraft.compound
           ){
               return '/udimanager/v1/editor/populate/basic';
           }else{
               return '/udimanager/v1/editor/populate';
           }
    }

    sendPayloadToBackendOnCategoryChange(payload: any, savedDraft: any): Observable<any>{
        const headers = {'Content-Type': 'application/json'};
        const populationDto = {
            udiIdentifierDiCode: savedDraft.diCode,
            udiIdentifierIssuingEntityCode: savedDraft.issuingEntityCode,
            deviceType: savedDraft.deviceType,
            id: savedDraft.id,
            payload
        };
        return this.apiService.put(this.getEnrichingEndpointOnCategoryChange(payload, savedDraft), populationDto, headers);
    }

    sendPayloadToBackend(payload: any, savedDraft: any): Observable<any>{
        const headers = {'Content-Type': 'application/json'};
        const populationDto = {
            udiIdentifierDiCode: savedDraft.diCode,
            udiIdentifierIssuingEntityCode: savedDraft.issuingEntityCode,
            deviceType: savedDraft.deviceType,
            id: savedDraft.id,
            payload
        };
        return this.apiService.put(this.getEnrichingEndpoint(payload, savedDraft), populationDto, headers);
    }

    getFormBuildingInputsWithFieldId(fieldIds: Array<string>, fullBuildingInput: Array<FormBuildingInput>): Array<FormBuildingInput>{
        const allInputs: Array<FormBuildingInput> = [];

        if (fullBuildingInput){
            fullBuildingInput.forEach((buildingInput: FormBuildingInput) => {
                if (buildingInput.children && buildingInput.children.length > 0){
                    allInputs.push(...this.getFormBuildingInputsWithFieldId(fieldIds, buildingInput.children));
                } else {
                    if (buildingInput.placeholder && fieldIds.includes(buildingInput.placeholder)){
                        allInputs.push(buildingInput);
                    }
                }
            });
        }

        return allInputs;
    }

    fullFormData(deviceType: any): Array<FormBuildingInput>{
        switch (deviceType.includes('BASIC')){
            case true:
                if (deviceType.includes('MDR')){
                return [...deviceAttributeDetails.MDRBASICDEVICE];
            }
            else if (deviceType.includes('IVDR')){
                return [...IVDRBASICDEVICE];
            }
            else if (deviceType.includes('SPP')){
                return [...SPPBASICDEVICE];
            }
            else if (deviceType.includes('ADEUDI')){
                return [...AIMDDBASICDEVICE];
            }
            else if (deviceType.includes('MDE')){
                return [...MDDBASICDEVICE];
            }
            else if (deviceType.includes('IVDE')){
                return [...IVDBASICDEVICE];
            }
                break;
            case false:
            if (deviceType.includes('MDR')){
                return [...deviceAttributeDetails.MDRUDIDEVICE];
            }
            else if (deviceType.includes('IVDR')){
                return [...deviceAttributeDetails.IVDRUDIDEVICE];
            }
            else if (deviceType.includes('SPP')){
                return [...deviceAttributeDetails.SPPUDIDEVICE];
            }
            else if (deviceType.includes('IVDE')){
                return [...deviceAttributeDetails.IVDUDIDEVICE];
            }
            else if (deviceType.includes('MDE')){
                return [...deviceAttributeDetails.MDDUDIDEVICE];
            }
            else if (deviceType.includes('ADEUDI')){
                return [...deviceAttributeDetails.AIMDDDEVICE];
            }
            break;

        }
        return [];
    }


    getFilteredBuildingObjectsWithFieldId(fieldIds: string[], processes: ProcessPair): FormBuildingInput[]{
        const formBuildingInputs = Object.values(processes).reduce((allBuildingInputs: any[], process: EnrichingDeviceProcess) => {
            if (process){
                allBuildingInputs.push(...process.formData);
            }
            return allBuildingInputs;
        }, []);
        return this.getPropertyNames(fieldIds, formBuildingInputs);
    }

    getPropertyNames(fieldIds: string[], formBuildingInputs: FormBuildingInput[]): FormBuildingInput[]{
        return formBuildingInputs.filter((formBuilding: FormBuildingInput) => {
            return this.checkRootFormBuildingInput(formBuilding, fieldIds);
        });
    }

    private checkRootFormBuildingInput(formBuildingInput: FormBuildingInput, fieldIds: string[]): boolean{
        if (formBuildingInput.controlType === FormControlType.NON_SHOWING){
            return this.checkNonShowingFormBuildingInput(formBuildingInput, fieldIds);
        }else if (formBuildingInput.controlType === FormControlType.REPEATABLE){
            return this.checkRepeatingFormBuildingInput(formBuildingInput, fieldIds);
        }else{
            return this.checkSimpleFormBuildingInput(formBuildingInput, fieldIds);
        }
    }

    checkRepeatingFormBuildingInput(formBuildingInput: FormBuildingInput, fieldIds: string[]): boolean{
        if (formBuildingInput.children){
            return formBuildingInput.children.some((singleChild: FormBuildingInput) => {
                return this.checkRootFormBuildingInput(singleChild, fieldIds);
            });
        }
        //
        return true;
    }

    checkNonShowingFormBuildingInput(formBuildingInput: FormBuildingInput, fieldIds: string[]): boolean{
        if (formBuildingInput.children){
            return formBuildingInput.children.some((singleChild: FormBuildingInput) => {
                return this.checkRootFormBuildingInput(singleChild, fieldIds);
            });
        }
        return true;
    }

    checkSimpleFormBuildingInput(formBuildingInput: FormBuildingInput, fieldIds: string[]): boolean{
        if (formBuildingInput.placeholder){
            return fieldIds.includes(formBuildingInput.placeholder);
        }
        return false;
    }

    getFormControlsByPropertyNames(propertyNamesToShow: string[], allFormGroups: Array<FormGroup>): FormGroup {
        return Object.values(allFormGroups).reduce((formGroupToShow: FormGroup, currentFormGroup: FormGroup) => {
            propertyNamesToShow.forEach((propertyName) => {
                const abstractFound = currentFormGroup.get(propertyName);
                if (abstractFound){
                    this.patchValueOnChange(abstractFound);
                    formGroupToShow.addControl(propertyName, abstractFound);
                }
            });
            return formGroupToShow;
        }, new FormGroup({}));
    }

    private patchValueOnChange(formControlInErrorReview: AbstractControl): void {
        formControlInErrorReview.valueChanges.subscribe((change: any) => {
            formControlInErrorReview.patchValue(change, {emitEvent: false});
        });

    }

}
