import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {BaseFormFieldType} from '../base-form-field-type.class';
import {RootFormComponent} from '../root-form-component/root-form.component';

@Component({
    selector: 'non-showing-render',
    templateUrl: './non-showing-render.component.html',
    styleUrls: ['./non-showing-render.component.scss'],
    providers: [{ provide: RootFormComponent , useExisting: forwardRef(() => RootFormComponent) }],
})
export class NonShowingRenderingComponent extends BaseFormFieldType {
    @Input()
    lastVisitedPath: string;

    setClass(formGroup: any): string{
        if (formGroup.propertyName === 'productDesignerOrganisationGeographicAddress'){
            return '';
        }
        return 'groupedAttributes';
    }
}
