import {Component, forwardRef, Input} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseFormFieldType} from '../base-form-field-type.class';
import {RootFormComponent} from '../root-form-component/root-form.component';

@Component({
    selector: 'radio-render',
    templateUrl: './radio-rendering.component.html',
    styleUrls: ['./radio-rendering.component.scss'],
    providers: [{ provide: RootFormComponent , useExisting: forwardRef(() => RootFormComponent) }],
})
export class RadioRenderingComponent extends BaseFormFieldType {
    @Input()
    updating: boolean;

    Validators = Validators;

    getMarketInfoDisableValue(): any{
        return this.formGroup.controls.marketInfoOriginalPlacedOnTheMarket && this.formGroup.controls.marketInfoOriginalPlacedOnTheMarket.status !== 'DISABLED';
    }

    fetchError(): boolean{
        if (this.formGroup.controls.marketInfoPlacingOnTheEUCountry){
            return this.formGroup.controls.marketInfoOriginalPlacedOnTheMarket.status === 'INVALID';
        }
        return false;
    }

    getControlErrorMessage(formControl: any): any{
        if (formControl && ((Array.isArray(formControl.errors) && formControl.errors.includes({noValidator: true})) || (typeof formControl.errors === 'object' && formControl.errors?.noValidator === true))){
            const errorMessage = formControl.errorMessage;
            if (errorMessage && !errorMessage.hasOwnProperty('noValidator')){
                errorMessage.noValidator = 'ERRORS.noValidator';
            }else{
                formControl.errorMessage = {noValidator: 'ERRORS.noValidator'};
            }
            formControl.markAsDirty();
            formControl.updateValueAndValidity();
        }
        return formControl.errors;
    }
}
