enum DeviceColumn {
    PublicID = 'DEVICES.column.publicId',
    InternalID = 'DEVICES.column.internalId',
    Name = 'DEVICES.column.name',
    RiskClass = 'DEVICES.column.riskClass',
    LastMessaged = 'DEVICES.column.lastMessaged',
    SRN = 'DEVICES.column.srn',
    Type = 'DEVICES.column.type',
    LastMessageID = 'DEVICES.column.lastMessageId',
    Status = 'DEVICES.column.status'
}

export default DeviceColumn;

