import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'codelistTranslation',
})
export class CodelistTranslation implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(label: string): any {
        if (label.includes('CODELISTCONSTANTS')){
            return this.translateService.instant(label);
        }else{
            return label;
        }
    }
}
