import {Pipe, PipeTransform} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'handleErrorTranslationPipe'
})
export class HandleErrorTranslationPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(validationErrorKey: any, formControl: FormControl): string | void {
    if (validationErrorKey != null) {
      const errorMessages = formControl.errorMessage;
      return this.translateService.instant(errorMessages[validationErrorKey]);
    }
  }
}
