import {DeviceConstants} from '.';
import {parseSimpleField, parseComplexField, parseArrayField, parseSimpleArray} from '../../util/device-to-details.util';
import {BUDIGroups} from './attribute-groups.enum';
import FormBuildingInput from '../../../shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {FormControlType} from '../../../shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-control-type.enum';
import {FormGroup, Validators} from '@angular/forms';
import {actorCodePattern, optionalWithRequired} from './validators/complex-validators.util';

export const AIMDDBASICDEVICE: Array<DeviceConstants & FormBuildingInput> = [
    {
        propertyName: 'deviceType',
        name: 'DEVICECONSTANTS.aimdd.basic.deviceType.name',
        description: 'DEVICECONSTANTS.aimdd.basic.deviceType.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        validators: [Validators.required],
        placeholder: 'FLD-ATRIFY-01',
        hidden: true
    },
    {
        propertyName: 'basicUDIIdentifierDICode',
        name: 'DEVICECONSTANTS.aimdd.basic.basicUDIIdentifierDICode.name',
        description: 'DEVICECONSTANTS.aimdd.basic.basicUDIIdentifierDICode.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        validators: [Validators.required, Validators.minLength(1), Validators.maxLength(120)],
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-42',
        hidden: true,
        style: {
            width: '50%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea120'
        }
    },
    {
        propertyName: 'basicUDIIdentifierIssuingEntityCode',
        name: 'DEVICECONSTANTS.aimdd.basic.basicUDIIdentifierIssuingEntityCode.name',
        description: 'DEVICECONSTANTS.aimdd.basic.basicUDIIdentifierIssuingEntityCode.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        controlType: FormControlType.DROPDOWN,
        placeholder: 'FLD-UDID-295',
        hidden: true
    },
    {
        propertyName: 'customerMessageID',
        name: 'DEVICECONSTANTS.aimdd.basic.customerMessageID.name',
        description: 'DEVICECONSTANTS.aimdd.basic.customerMessageID.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-ATRIFY-02',
        hidden: true
    },
    {
        propertyName: 'customerTransactionID',
        name: 'DEVICECONSTANTS.aimdd.basic.customerTransactionID.name',
        description: 'DEVICECONSTANTS.aimdd.basic.customerTransactionID.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-ATRIFY-03',
        hidden: true
    },
    {
        propertyName: 'riskClass',
        name: 'DEVICECONSTANTS.aimdd.basic.riskClass.name',
        description: 'DEVICECONSTANTS.aimdd.basic.riskClass.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        placeholder: 'FLD-UDID-16'
    },
    {
        propertyName: 'model',
        name: 'DEVICECONSTANTS.aimdd.basic.model.name',
        description: 'DEVICECONSTANTS.aimdd.basic.model.description',
        parsingFunction: parseSimpleField,
        updateable: true,
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-20',
        hidden: (form: FormGroup) => {
            if (form.get('modelName')?.get('modelNameTerm')?.value == null || form.get('modelName')?.get('modelNameTerm')?.value === ''){
                return true;
            }else{
                return false;
            }
        },
        style: {
            width: '50%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea255'
        },
        validators: [Validators.minLength(1), Validators.maxLength(255)],
    },
    {
        propertyName: 'modelName',
        name: 'DEVICECONSTANTS.aimdd.basic.modelName.name',
        parsingFunction: parseComplexField,
        group: BUDIGroups.KEYDATA,
        description: '',
        validators: [optionalWithRequired(['modelNameTerm'])],
        controlType: FormControlType.NON_SHOWING,
        placeholder: 'DEVICECONSTANTS.aimdd.basic.modelName.placeholder',
        children: [
            {
                propertyName: 'modelNameModel',
                name: 'DEVICECONSTANTS.aimdd.basic.modelNameModel.name',
                description: 'DEVICECONSTANTS.aimdd.basic.modelNameModel.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                updateable: true,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-20',
                style: {
                    width: '47%',
                    'margin-right': '24px'
                },
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea255'
                },
                validators: [Validators.minLength(1), Validators.maxLength(255)],
            },
            {
                propertyName: 'modelNameTerm',
                name: 'DEVICECONSTANTS.aimdd.basic.modelNameTerm.name',
                description: 'DEVICECONSTANTS.aimdd.basic.modelNameTerm.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                updateable: true,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-22',
                style: {
                    width: '47%'
                },
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea255'
                },
                validators: [Validators.minLength(1), Validators.maxLength(255)],
            }
        ],
        hidden: (form: FormGroup) => {
            if (form.get('model')?.value == null || form.get('model')?.value === ''){
                return true;
            }else{
                return false;
            }
        }
    },
    {
        propertyName: 'deviceCertificateLink',
        name: 'DEVICECONSTANTS.aimdd.basic.certificateLink.name',
        group: BUDIGroups.CERTIFICATE_INFORMATION,
        validators: [optionalWithRequired(['deviceCertificateNumber', 'deviceCertificateNBActorCode', 'deviceCertificateType', 'deviceCertificateExpiryDate'])],
        parsingFunction: parseArrayField,
        description: '',
        controlType: FormControlType.REPEATABLE,
        placeholder: 'DEVICECONSTANTS.aimdd.basic.certificateLink.placeholder',
        children: [
            {
                propertyName: 'deviceCertificateNumber',
                name: 'DEVICECONSTANTS.aimdd.basic.certificateNumber.name',
                description: 'DEVICECONSTANTS.aimdd.basic.certificateNumber.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CERTIFICATE_INFORMATION,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-61',
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'deviceCertificateRevisionNumber',
                name: 'DEVICECONSTANTS.aimdd.basic.certificateRevisionNumber.name',
                description: 'DEVICECONSTANTS.aimdd.basic.certificateRevisionNumber.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CERTIFICATE_INFORMATION,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-62',
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'deviceCertificateNBActorCode',
                name: 'DEVICECONSTANTS.aimdd.basic.certificateNBActorCode.name',
                description: 'DEVICECONSTANTS.aimdd.basic.certificateNBActorCode.description',
                errorMessage: 'ERRORS.notFilled',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CERTIFICATE_INFORMATION,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-63'
            },
            {
                propertyName: 'deviceCertificateType',
                name: 'DEVICECONSTANTS.aimdd.basic.certificateType.name',
                description: 'DEVICECONSTANTS.aimdd.basic.certificateType.description',
                errorMessage: 'ERRORS.notFilled',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CERTIFICATE_INFORMATION,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-60'
            },
            {
                propertyName: 'deviceCertificateExpiryDate',
                name: 'DEVICECONSTANTS.aimdd.basic.certificateExpiryDate.name',
                description: 'DEVICECONSTANTS.aimdd.basic.certificateExpiryDate.description',
                errorMessage: 'ERRORS.notFilled',
                parsingFunction: parseSimpleField,
                updateable: true,
                group: BUDIGroups.CERTIFICATE_INFORMATION,
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-64'
            }
        ]
    },
    {
        propertyName: 'ARActorCode',
        name: 'DEVICECONSTANTS.aimdd.basic.ARActorCode.name',
        description: 'DEVICECONSTANTS.aimdd.basic.ARActorCode.description',
        parsingFunction: parseSimpleField,
        updateable: true,
        style: {
          width: '100%',
          marginBottom: '48px'
        },
        errorMessage: 'ERRORS.actorCode',
        validators: [Validators.pattern(actorCodePattern)],
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-15'
    },
    {
        propertyName: 'ARComments',
        name: 'DEVICECONSTANTS.aimdd.basic.ARComments.name',
        parsingFunction: parseArrayField,
        description: '',
        validators: [optionalWithRequired(['ARCommentOwnerActorCode', 'ARCommentLanguage', 'ARCommentText'])],
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.REPEATABLE,
        placeholder: '',
        children: [
            {
                propertyName: 'ARCommentDate',
                name: 'DEVICECONSTANTS.aimdd.basic.ARCommentDate.name',
                description: 'DEVICECONSTANTS.aimdd.basic.ARCommentDate.description',
                updateable: true,
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                controlType: FormControlType.DATETIME,
                placeholder: 'FLD-UDID-332',
            },
            {
                propertyName: 'ARCommentOwnerActorCode',
                name: 'DEVICECONSTANTS.aimdd.basic.ARCommentOwnerActorCode.name',
                description: 'DEVICECONSTANTS.aimdd.basic.ARCommentOwnerActorCode.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-43',
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'ARCommentLanguage',
                name: 'DEVICECONSTANTS.aimdd.basic.ARCommentLanguage.name',
                description: 'DEVICECONSTANTS.aimdd.basic.ARCommentLanguage.description',
                errorMessage: 'ERRORS.notFilled',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-010',
            },
            {
                propertyName: 'ARCommentText',
                name: 'DEVICECONSTANTS.aimdd.basic.ARCommentText.name',
                description: 'DEVICECONSTANTS.aimdd.basic.ARCommentText.description',
                updateable: true,
                parsingFunction: parseSimpleField,
                textareaRows: 6,
                group: BUDIGroups.KEYDATA,
                controlType: FormControlType.TEXTAREA,
                placeholder: 'FLD-UDID-41',
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            }
        ]
    },
    {
        propertyName: 'MFActorCode',
        name: 'DEVICECONSTANTS.aimdd.basic.MFActorCode.name',
        description: 'DEVICECONSTANTS.aimdd.basic.MFActorCode.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        style: {
          flex: '1',
          'padding-left': '5px',
          width: '50%'
        },
        validators: [Validators.required, Validators.pattern(actorCodePattern)],
        errorMessage: {
          required: 'ERRORS.notFilled',
          pattern: 'ERRORS.actorCode'
        },
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-10',
        hidden: true
    },
    {
        propertyName: 'applicableLegislation',
        name: 'DEVICECONSTANTS.aimdd.basic.applicableLegislation.name',
        description: 'DEVICECONSTANTS.aimdd.basic.applicableLegislation.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        controlType: FormControlType.DROPDOWN,
        placeholder: 'FLD-UDID-11',
        hidden: true
    },
    {
        propertyName: 'animalTissuesCells',
        name: 'DEVICECONSTANTS.aimdd.basic.animalTissuesCells.name',
        description: 'DEVICECONSTANTS.aimdd.basic.animalTissuesCells.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-18'
    },
    {
        propertyName: 'humanTissuesCells',
        name: 'DEVICECONSTANTS.aimdd.basic.humanTissuesCells.name',
        description: 'DEVICECONSTANTS.aimdd.basic.humanTissuesCells.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-23'
    },
    {
        propertyName: 'clinicalInvestigationLink',
        name: 'DEVICECONSTANTS.aimdd.basic.clinicalInvestigationLink.name',
        parsingFunction: parseSimpleArray,
        group: BUDIGroups.CLINICAL_INVESTIGATION,
        description: '',
        validators: [optionalWithRequired(['clinicalInvestigationReference'])],
        controlType: FormControlType.REPEATABLE,
        placeholder: '',
        children: [
            {
                propertyName: 'clinicalInvestigationReference',
                name: 'DEVICECONSTANTS.aimdd.basic.clinicalInvestigationReference.name',
                description: 'DEVICECONSTANTS.aimdd.basic.clinicalInvestigationReference.description',
                parsingFunction: parseSimpleField,
                updateable: true,
                group: BUDIGroups.CLINICAL_INVESTIGATION,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-51',
                validators: [Validators.minLength(1), Validators.maxLength(255)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.tooLong255'
                }
            },
            {
                propertyName: 'clinicalInvestigationNonEUApplicableCountries',
                name: 'DEVICECONSTANTS.aimdd.basic.clinicalInvestigationNonEUApplicableCountries.name',
                description: 'DEVICECONSTANTS.aimdd.basic.clinicalInvestigationNonEUApplicableCountries.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CLINICAL_INVESTIGATION,
                updateable: true,
                controlType: FormControlType.SEARCHABLE_MULTISELECT,
                blankSeparator: true,
                placeholder: 'FLD-UDID-300',
            }
        ]
    },
    {
        propertyName: 'humanProductCheck',
        name: 'DEVICECONSTANTS.aimdd.basic.humanProductCheck.name',
        description: 'DEVICECONSTANTS.aimdd.basic.humanProductCheck.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-155',
    },
    {
        propertyName: 'medicinalProductCheck',
        name: 'DEVICECONSTANTS.aimdd.basic.medicinalProductCheck.name',
        description: 'DEVICECONSTANTS.aimdd.basic.medicinalProductCheck.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-158',
    },
    {
        propertyName: 'specialDevice',
        name: 'DEVICECONSTANTS.aimdd.basic.specialDevice.name',
        description: 'DEVICECONSTANTS.aimdd.basic.specialDevice.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.DROPDOWN,
        updateable: true,
        placeholder: 'FLD-UDID-13',
    },
     {
        propertyName: 'multicomponentDeviceTypeCode',
        name: 'DEVICECONSTANTS.aimdd.basic.multicomponentDeviceTypeCode.name',
        description: 'DEVICECONSTANTS.aimdd.basic.multicomponentDeviceTypeCode.description',
        errorMessage: 'ERRORS.notFilled',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        validators: [Validators.required],
        controlType: FormControlType.DROPDOWN,
        placeholder: 'FLD-UDID-12',
        style: {
            marginBottom: '48px'
        }
    },
     {
        propertyName: 'active',
        name: 'DEVICECONSTANTS.aimdd.basic.active.name',
        description: 'DEVICECONSTANTS.aimdd.basic.active.description',
        errorMessage: 'ERRORS.notFilled',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-28',
    },
     {
        propertyName: 'administeringMedicine',
        name: 'DEVICECONSTANTS.aimdd.basic.administeringMedicine.name',
        description: 'DEVICECONSTANTS.aimdd.basic.administeringMedicine.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-29',
    },
     {
        propertyName: 'implantable',
        name: 'DEVICECONSTANTS.aimdd.basic.implantable.name',
        description: 'DEVICECONSTANTS.aimdd.basic.implantable.description',
        parsingFunction: parseSimpleField,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        group: BUDIGroups.MODELDATA,
        placeholder: 'FLD-UDID-30',
    },
     {
        propertyName: 'measuringFunction',
        name: 'DEVICECONSTANTS.aimdd.basic.measuringFunction.name',
        description: 'DEVICECONSTANTS.aimdd.basic.measuringFunction.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        errorMessage: 'ERRORS.notFilled',
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-31',
    },
    {
        propertyName: 'reusable',
        name: 'DEVICECONSTANTS.aimdd.basic.reusable.name',
        description: 'DEVICECONSTANTS.aimdd.basic.reusable.description',
        parsingFunction: parseSimpleField,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        group: BUDIGroups.MODELDATA,
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-32',
        style: {
            marginBottom: '48px'
        }
    }
];
