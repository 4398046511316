import { FormGroup, Validators} from '@angular/forms';
import FormBuildingInput from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {FormControlType} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-control-type.enum';
import {DeviceConstants} from '.';
import {parseArrayField, parseComplexField, parseSimpleArray, parseSimpleField} from '../../util/device-to-details.util';
import {BUDIGroups} from './attribute-groups.enum';
import {actorCodePattern, optionalWithRequired} from './validators/complex-validators.util';


export const MDRBASICDEVICE: Array<DeviceConstants & FormBuildingInput> = [
    {
        propertyName: 'deviceType',
        name: 'DEVICECONSTANTS.mdr.basic.deviceType.name',
        description: 'DEVICECONSTANTS.mdr.basic.deviceType.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        validators: [Validators.required],
        placeholder: 'FLD-ATRIFY-01',
        hidden: true,
        jsonPath: '$.deviceType'
    },
    {
        propertyName: 'basicUDIIdentifierDICode',
        name: 'DEVICECONSTANTS.mdr.basic.basicUDIIdentifierDICode.name',
        description: 'DEVICECONSTANTS.mdr.basic.basicUDIIdentifierDICode.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea120'
        },
        validators: [Validators.required, Validators.minLength(1), Validators.maxLength(120)],
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-14',
        hidden: true,
        style: {
            width: '50%'
        },
        jsonPath: '$.basicUDIIdentifierDICode'
    },
    {
        propertyName: 'basicUDIIdentifierIssuingEntityCode',
        name: 'DEVICECONSTANTS.mdr.basic.basicUDIIdentifierIssuingEntityCode.name',
        description: 'DEVICECONSTANTS.mdr.basic.basicUDIIdentifierIssuingEntityCode.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        controlType: FormControlType.DROPDOWN,
        placeholder: 'FLD-UDID-01',
        hidden: true,
        jsonPath: '$.basicUDIIdentifierIssuingEntityCode'
    },
    {
        propertyName: 'customerMessageID',
        name: 'DEVICECONSTANTS.mdr.basic.customerMessageID.name',
        description: 'DEVICECONSTANTS.mdr.basic.customerMessageID.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-ATRIFY-02',
        hidden: true
    },
    {
        propertyName: 'customerTransactionID',
        name: 'DEVICECONSTANTS.mdr.basic.customerTransactionID.name',
        description: 'DEVICECONSTANTS.mdr.basic.customerTransactionID.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-ATRIFY-03',
        hidden: true
    },
    {
        propertyName: 'riskClass',
        name: 'DEVICECONSTANTS.mdr.basic.riskClass.name',
        description: 'DEVICECONSTANTS.mdr.basic.riskClass.description',
        parsingFunction: parseSimpleField,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        placeholder: 'FLD-UDID-16',
        jsonPath: '$.riskClass'
    },
    {
        propertyName: 'model',
        name: 'DEVICECONSTANTS.mdr.basic.model.name',
        description: 'DEVICECONSTANTS.mdr.basic.model.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        jsonPath: '$.model',
        updateable: true,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-20',
        hidden: (form: FormGroup) => {
            if (form.get('modelName')?.get('modelNameTerm')?.value == null || form.get('modelName')?.get('modelNameTerm')?.value === ''){
                return true;
            }else{
                return false;
            }
        },
        style: {
            width: '50%',
            marginBottom: '48px'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea255'
        },
        validators: [Validators.minLength(1), Validators.maxLength(255)],
    },
    {
        propertyName: 'modelName',
        name: 'DEVICECONSTANTS.mdr.basic.modelName.name',
        parsingFunction: parseComplexField,
        group: BUDIGroups.KEYDATA,
        description: '',
        validators: [optionalWithRequired(['modelNameTerm'])],
        controlType: FormControlType.NON_SHOWING,
        placeholder: 'DEVICECONSTANTS.mdr.basic.modelName.placeholder',
        children: [
            {
                propertyName: 'modelNameModel',
                name: 'DEVICECONSTANTS.mdr.basic.modelNameModel.name',
                description: 'DEVICECONSTANTS.mdr.basic.modelNameModel.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                updateable: true,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-20',
                style: {
                    width: '47%',
                    'margin-right': '24px'
                },
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea255'
                },
                validators: [Validators.minLength(1), Validators.maxLength(255)],
                jsonPath: '$.modelName.modelNameModel'
            },
            {
                propertyName: 'modelNameTerm',
                name: 'DEVICECONSTANTS.mdr.basic.modelNameTerm.name',
                description: 'DEVICECONSTANTS.mdr.basic.modelNameTerm.description',
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea255'
                },
                validators: [Validators.minLength(1), Validators.maxLength(255)],
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                updateable: true,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-22',
                style: {
                    width: '47%'
                },
                jsonPath: '$.modelName.modelNameTerm'
            }
        ],
        hidden: (form: FormGroup) => {
            if (form.get('model')?.value == null || form.get('model')?.value === ''){
                return true;
            }else{
                return false;
            }
        }
    },
    {
        propertyName: 'deviceCertificateLink',
        name: 'DEVICECONSTANTS.mdr.basic.deviceCertificateLink.name',
        group: BUDIGroups.CERTIFICATE_INFORMATION,
        parsingFunction: parseArrayField,
        description: '',
        validators: [optionalWithRequired(['deviceCertificateNBActorCode', 'deviceCertificateType'])],
        controlType: FormControlType.REPEATABLE,
        maxLength: 1,
        placeholder: 'DEVICECONSTANTS.mdr.basic.deviceCertificateLink.placeholder',
        children: [
            {
                propertyName: 'deviceCertificateType',
                name: 'DEVICECONSTANTS.mdr.basic.deviceCertificateType.name',
                description: 'DEVICECONSTANTS.mdr.basic.deviceCertificateType.description',
                errorMessage: 'ERRORS.notFilled',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CERTIFICATE_INFORMATION,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-60',
                jsonPath: '$.deviceCertificateLink[*].deviceCertificateType'
            },
            {
                propertyName: 'deviceCertificateNBActorCode',
                name: 'DEVICECONSTANTS.mdr.basic.deviceCertificateNBActorCode.name',
                description: 'DEVICECONSTANTS.mdr.basic.deviceCertificateNBActorCode.description',
                errorMessage: 'ERRORS.notFilled',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CERTIFICATE_INFORMATION,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-63',
                jsonPath: '$.deviceCertificateLink[*].deviceCertificateNBActorCode'
            },
            {
                propertyName: 'deviceCertificateNumber',
                name: 'DEVICECONSTANTS.mdr.basic.deviceCertificateNumber.name',
                description: 'DEVICECONSTANTS.mdr.basic.deviceCertificateNumber.description',
                parsingFunction: parseSimpleField,
                jsonPath: '$.deviceCertificateLink[*].deviceCertificateNumber',
                group: BUDIGroups.CERTIFICATE_INFORMATION,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-61',
                style: {
                    width: '50%'
                },
                errorMessage: 'ERRORS.textArea120',
                validators: [Validators.minLength(1), Validators.maxLength(120)],
            },
            {
                propertyName: 'deviceCertificateRevisionNumber',
                name: 'DEVICECONSTANTS.mdr.basic.deviceCertificateRevisionNumber.name',
                description: 'DEVICECONSTANTS.mdr.basic.deviceCertificateRevisionNumber.description',
                parsingFunction: parseSimpleField,
                jsonPath: '$.deviceCertificateLink[*].deviceCertificateRevisionNumber',
                group: BUDIGroups.CERTIFICATE_INFORMATION,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-62',
                style: {
                    width: '50%'
                },
                errorMessage: 'ERRORS.textArea120',
                validators: [Validators.minLength(1), Validators.maxLength(120)],
            }
        ]
    },
    {
        propertyName: 'ARActorCode',
        name: 'DEVICECONSTANTS.mdr.basic.ARActorCode.name',
        description: 'DEVICECONSTANTS.mdr.basic.ARActorCode.description',
        parsingFunction: parseSimpleField,
        style: {
            width: '100%',
            marginBottom: '48px'
        },
        updateable: true,
        errorMessage: 'ERRORS.actorCode',
        group: BUDIGroups.KEYDATA,
        validators: [Validators.pattern(actorCodePattern)],
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-15',
        jsonPath: '$.ARActorCode'
    },
    {
        propertyName: 'ARComments',
        name: 'DEVICECONSTANTS.mdr.basic.ARComments.name',
        parsingFunction: parseArrayField,
        description: '',
        validators: [optionalWithRequired(['ARCommentOwnerActorCode', 'ARCommentLanguage', 'ARCommentText'])],
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.REPEATABLE,
        placeholder: '',
        children: [
            {
                propertyName: 'ARCommentDate',
                name: 'DEVICECONSTANTS.mdr.basic.ARComments.ARCommentDate.name',
                description: 'DEVICECONSTANTS.mdr.basic.ARComments.ARCommentDate.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                updateable: true,
                controlType: FormControlType.DATETIME,
                placeholder: 'FLD-UDID-332',
                jsonPath: '$.ARComments[*].ARCommentDate'
            },
            {
                propertyName: 'ARCommentOwnerActorCode',
                name: 'DEVICECONSTANTS.mdr.basic.ARComments.ARCommentOwnerActorCode.name',
                description: 'DEVICECONSTANTS.mdr.basic.ARComments.ARCommentOwnerActorCode.description',
                jsonPath: '$.ARComments[*].ARCommentOwnerActorCode',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-43',
                style: {
                    width: '50%'
                },
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
            },
            {
                propertyName: 'ARCommentLanguage',
                name: 'DEVICECONSTANTS.mdr.basic.ARComments.ARCommentLanguage.name',
                description: 'DEVICECONSTANTS.mdr.basic.ARComments.ARCommentLanguage.description',
                errorMessage: 'ERRORS.notFilled',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-010',
                jsonPath: '$.ARComments[*].ARCommentLanguage'
            },
            {
                propertyName: 'ARCommentText',
                name: 'DEVICECONSTANTS.mdr.basic.ARComments.ARCommentText.name',
                jsonPath: '$.ARComments[*].ARCommentText',
                description: 'DEVICECONSTANTS.mdr.basic.ARComments.ARCommentText.description',
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                },
                parsingFunction: parseSimpleField,
                updateable: true,
                group: BUDIGroups.KEYDATA,
                textareaRows: 6,
                controlType: FormControlType.TEXTAREA,
                placeholder: 'FLD-UDID-41',
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
            }
        ]
    },
    {
        propertyName: 'MFActorCode',
        name: 'DEVICECONSTANTS.mdr.basic.MFActorCode.name',
        description: 'DEVICECONSTANTS.mdr.basic.MFActorCode.description',
        jsonPath: '$.MFActorCode',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        style: {
            flex: '1',
            'padding-left': '5px',
            width: '50%'
        },
        validators: [Validators.required, Validators.pattern(actorCodePattern)],
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-10',
        hidden: true,
        errorMessage: {
          required: 'ERRORS.notFilled',
          pattern: 'ERRORS.actorCode'
        },
    },
    {
        propertyName: 'applicableLegislation',
        name: 'DEVICECONSTANTS.mdr.basic.applicableLegislation.name',
        description: 'DEVICECONSTANTS.mdr.basic.applicableLegislation.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        controlType: FormControlType.DROPDOWN,
        placeholder: 'FLD-UDID-11',
        hidden: true,
        jsonPath: '$.applicableLegislation'
    },
    {
        propertyName: 'active',
        name: 'DEVICECONSTANTS.mdr.basic.active.name',
        description: 'DEVICECONSTANTS.mdr.basic.active.description',
        parsingFunction: parseSimpleField,
        errorMessage: 'ERRORS.notFilled',
        group: BUDIGroups.MODELDATA,
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-28',
        jsonPath: '$.active'
    },
    {
        propertyName: 'administeringMedicine',
        name: 'DEVICECONSTANTS.mdr.basic.administeringMedicine.name',
        description: 'DEVICECONSTANTS.mdr.basic.administeringMedicine.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-29',
        jsonPath: '$.administeringMedicine'
    },
    {
        propertyName: 'implantable',
        name: 'DEVICECONSTANTS.mdr.basic.implantable.name',
        description: 'DEVICECONSTANTS.mdr.basic.implantable.description',
        parsingFunction: parseSimpleField,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        group: BUDIGroups.MODELDATA,
        placeholder: 'FLD-UDID-30',
        jsonPath: '$.implantable'
    },
    {
        propertyName: 'measuringFunction',
        name: 'DEVICECONSTANTS.mdr.basic.measuringFunction.name',
        description: 'DEVICECONSTANTS.mdr.basic.measuringFunction.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        errorMessage: 'ERRORS.notFilled',
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-31',
        jsonPath: '$.measuringFunction'
    },
    {
        propertyName: 'reusable',
        name: 'DEVICECONSTANTS.mdr.basic.reusable.name',
        description: 'DEVICECONSTANTS.mdr.basic.reusable.description',
        parsingFunction: parseSimpleField,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        group: BUDIGroups.MODELDATA,
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-32',
        jsonPath: '$.reusable'
    },
    {
        propertyName: 'IIb_implantable_exceptions',
        name: 'DEVICECONSTANTS.mdr.basic.IIb_implantable_exceptions.name',
        description: 'DEVICECONSTANTS.mdr.basic.IIb_implantable_exceptions.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-265',
        jsonPath: '$.IIb_implantable_exceptions'
    },
    {
        propertyName: 'humanTissuesCells',
        name: 'DEVICECONSTANTS.mdr.basic.humanTissuesCells.name',
        description: 'DEVICECONSTANTS.mdr.basic.humanTissuesCells.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-23',
        jsonPath: '$.humanTissuesCells'
    },
    {
        propertyName: 'animalTissuesCells',
        name: 'DEVICECONSTANTS.mdr.basic.animalTissuesCells.name',
        description: 'DEVICECONSTANTS.mdr.basic.animalTissuesCells.description',
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-18',
        jsonPath: '$.animalTissuesCells'
    },
    {
        propertyName: 'clinicalInvestigationLink',
        name: 'DEVICECONSTANTS.mdr.basic.clinicalInvestigationLink.name',
        parsingFunction: parseSimpleArray,
        description: '',
        validators: [optionalWithRequired(['clinicalInvestigationReference'])],
        group: BUDIGroups.CLINICAL_INVESTIGATION,
        controlType: FormControlType.REPEATABLE,
        placeholder: '',
        children: [
            {
                propertyName: 'clinicalInvestigationReference',
                name: 'DEVICECONSTANTS.mdr.basic.clinicalInvestigationReference.name',
                description: 'DEVICECONSTANTS.mdr.basic.clinicalInvestigationReference.description',
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.tooLong255'
                },
                parsingFunction: parseSimpleField,
                updateable: true,
                jsonPath: '$.clinicalInvestigationLink[*].clinicalInvestigationReference',
                group: BUDIGroups.CLINICAL_INVESTIGATION,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-51',
                style: {
                    width: '100%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(255)]
            },
            {
                propertyName: 'clinicalInvestigationNonEUApplicableCountries',
                name: 'DEVICECONSTANTS.mdr.basic.clinicalInvestigationNonEUApplicableCountries.name',
                description: 'DEVICECONSTANTS.mdr.basic.clinicalInvestigationNonEUApplicableCountries.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CLINICAL_INVESTIGATION,
                updateable: true,
                controlType: FormControlType.SEARCHABLE_MULTISELECT,
                blankSeparator: true,
                placeholder: 'FLD-UDID-300',
                jsonPath: '$.clinicalInvestigationLink[*].clinicalInvestigationNonEUApplicableCountries'
            }
        ]
    },
    {
        propertyName: 'medicinalProductCheck',
        name: 'DEVICECONSTANTS.mdr.basic.medicinalProductCheck.name',
        description: 'DEVICECONSTANTS.mdr.basic.medicinalProductCheck.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-158',
        jsonPath: '$.medicinalProductCheck'
    },
    {
        propertyName: 'humanProductCheck',
        name: 'DEVICECONSTANTS.mdr.basic.humanProductCheck.name',
        description: 'DEVICECONSTANTS.mdr.basic.humanProductCheck.description',
        parsingFunction: parseSimpleField,
        errorMessage: 'ERRORS.notFilled',
        jsonPath: '$.humanProductCheck',
        group: BUDIGroups.MODELDATA,
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-155',
        style: {
            marginBottom: '48px'
        }
    },
     {
        propertyName: 'specialDevice',
        name: 'DEVICECONSTANTS.mdr.basic.specialDevice.name',
        description: 'DEVICECONSTANTS.mdr.basic.specialDevice.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.DROPDOWN,
        updateable: true,
        placeholder: 'FLD-UDID-13',
        jsonPath: '$.specialDevice'
    },
     {
        propertyName: 'multicomponentDeviceTypeCode',
        name: 'DEVICECONSTANTS.mdr.basic.multicomponentDeviceTypeCode.name',
        description: 'DEVICECONSTANTS.mdr.basic.multicomponentDeviceTypeCode.description',
        parsingFunction: parseSimpleField,
        errorMessage: 'ERRORS.notFilled',
        jsonPath: '$.multicomponentDeviceTypeCode',
        validators: [Validators.required],
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.DROPDOWN,
        placeholder: 'FLD-UDID-12',
        style: {
            marginBottom: '48px'
        }
    }
];
