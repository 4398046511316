import {Component, forwardRef, Input} from '@angular/core';
import {Validators} from '@angular/forms';
import {returnCodelistStyle} from 'src/app/core/constants/codelist.style.constants';
import {BaseFormFieldType} from '../base-form-field-type.class';
import {RootFormComponent} from '../root-form-component/root-form.component';

@Component({
    selector: 'dropdown-render',
    templateUrl: './dropdown-render.component.html',
    styleUrls: ['./dropdown-render.component.scss'],
    providers: [{ provide: RootFormComponent , useExisting: forwardRef(() => RootFormComponent) }],
})
export class DropdownRenderingComponent extends BaseFormFieldType {
    optionalClass = ['non-empty-input', 'label'];
    Validators = Validators;

    @Input()
    editingObject: any;

    @Input()
    errorForm: boolean;

    getDropdownStyle(propertyName: string, style: any): any {
      if (typeof style !== 'undefined'){
        const mergedStyle = { ...style, ...returnCodelistStyle(propertyName) };
        return mergedStyle;
      }else if (typeof style === 'undefined'){
        return returnCodelistStyle(propertyName);
      }else{
        return { flexBasis: '100%' };
      }
    }

    getDropdownConstantsStyle(style: any): any {
      return style;
    }
}
