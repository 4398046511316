<div class="repeatable-code" *ngFor="let mdnCode of codesArray; let i = index">
  <nz-collapse>
    <nz-collapse-panel
      #repeatablePanel
      [nzExtra]="removeRepeatable"
      [nzHeader]="(formControlInput.name | translate) + ' ' + (i+1)"
      [nzActive]="true"
      [nzExpandedIcon]="expandedIcon"
    >
      <ng-template #removeRepeatable>
        <fa-icon
          [icon]="faTrash"
          *ngIf="codesArray.length > 1"
          (click)="removeSingleMdn(i)"
          [class.remove-repeatable-icon-active]="repeatablePanel.nzActive"
          [class.remove-repeatable-icon-inactive]="!repeatablePanel.nzActive"
          class="repeatable-headline-delete-icon"
        ></fa-icon>
      </ng-template>
      <ng-template #expandedIcon>
        <i nz-icon
           [class.expanded-repeatable-icon]="repeatablePanel.nzActive"
           [class.collapsed-repeatable-icon]="!repeatablePanel.nzActive"
           [nzType]="repeatablePanel.nzActive ? 'minus' : 'plus'"
           class="repeatable-headline-icon ant-collapse-arrow"
           [nzRotate]="repeatablePanel.nzActive ? -180 : 90"
        ></i>
      </ng-template>
      <ng-container>
        <nz-input-group>
          <eudamed-dropdown-searchable-wrapper [label]="(formControlInput.name | translate) + ((formControlInput.validators && formControlInput.validators.includes(Validators.required)) ? '*' : '')">
            <nz-select nzShowSearch
                       nzMode="default"
                       style="max-width: 440px;"
                       [style]="getDropdownStyle(formControlInput.propertyName)"
                       nzPopoverTrigger="click"
                       nzPopoverPlacement="right"
                       nz-popover
                       nzAllowClear
                       [nzPopoverContent]="popoverContent"
                       [nzPopoverOverlayStyle]="{maxWidth:'200px'}"
                       nzPopoverColor="white"
                       nz-input
                       (ngModelChange)="updateSingleMdn($event, i)"
                       [(ngModel)]="codesArray[i]"
                       [nzPlaceHolder]="formControlInput.placeholder || ''">
            <nz-option *ngFor="let code of codelists" [nzDisabled]="codesArray.includes(code.value)"
                       [nzLabel]="code.label | codelistTranslation" [nzValue]="code.value">
            </nz-option>
            </nz-select>
          </eudamed-dropdown-searchable-wrapper>
        </nz-input-group>

        <ng-template #popoverContent>
          <span style="color:white;">
              {{formControlInput.description || '' | translate}}
          </span>
        </ng-template>
      </ng-container>
    </nz-collapse-panel>
  </nz-collapse>

</div>
<button id="repeatable-button" class="add-another add-another-root"
        nz-button type="button" (click)="addNewMdnCode()"
        [disabled]="codesArray[codesArray.length-1] === null">
  <h4>
    <fa-icon [icon]="faPlus"></fa-icon>
    {{ "ENRICHMENT.body.button.addAttributeSet" | translate }}
  </h4>
</button>

