import {faAddressCard, faBoxOpen, faCertificate, faDatabase, faFingerprint, faGlobeEurope, faInfo, faKey, faLink, faMicroscope, faSitemap, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {CategoryStatesEnum} from '../../service/device-initialization/CategoryStates.enum';

export enum BUDIGroups {
    KEYDATA = 'BUDI.groups.keyData',
    MODELDATA = 'BUDI.groups.modelData',
    CLINICAL_INVESTIGATION= 'BUDI.groups.clinicalInvestigation',
    CERTIFICATE_INFORMATION= 'BUDI.groups.certificateInformation'
}

export enum UDIGroups {
    IDENTIFICATION = 'UDI.groups.identification',
    MARKET_INFO= 'UDI.groups.marketInfo',
    CLASSIFICATION= 'UDI.groups.classification',
    REFERENCED_DEVICE= 'UDI.groups.referencedDevice',
    DEVICE_INFORMATION= 'UDI.groups.deviceInformation',
    PRODUCT_DESIGNER= 'UDI.groups.productDesigner',
    PACKAGE_ITEM= 'UDI.groups.packageItem'
}



export interface CategoryWithState {
    categoryValue: UDIGroups |  BUDIGroups;
    state: CategoryStatesEnum | null;
    icon: IconDefinition;
    visited?: boolean;
    last?: boolean;
}

export const sortedLegacyCategories: Array<CategoryWithState> = [{
     categoryValue: UDIGroups.IDENTIFICATION, state: CategoryStatesEnum.UNVISITED, icon: faFingerprint
 }, {
     categoryValue: UDIGroups.CLASSIFICATION,  icon: faSitemap, state: CategoryStatesEnum.UNVISITED
 }, {
      categoryValue: UDIGroups.DEVICE_INFORMATION,  icon: faInfo, state: CategoryStatesEnum.UNVISITED
 }, {
      categoryValue: UDIGroups.MARKET_INFO,  icon: faGlobeEurope, state: CategoryStatesEnum.UNVISITED
 }, {
      categoryValue: UDIGroups.PRODUCT_DESIGNER,  icon: faAddressCard, state: CategoryStatesEnum.UNVISITED
 }, {
      categoryValue: UDIGroups.REFERENCED_DEVICE, icon: faLink, state: CategoryStatesEnum.UNVISITED
 }];

export const sortedUdiCategories: Array<CategoryWithState> = [{
    categoryValue: UDIGroups.IDENTIFICATION, state: CategoryStatesEnum.UNVISITED, icon: faFingerprint
}, {
    categoryValue: UDIGroups.CLASSIFICATION,  icon: faSitemap, state: CategoryStatesEnum.UNVISITED
},
{
     categoryValue: UDIGroups.DEVICE_INFORMATION,  icon: faInfo, state: CategoryStatesEnum.UNVISITED
}, {
     categoryValue: UDIGroups.MARKET_INFO,  icon: faGlobeEurope, state: CategoryStatesEnum.UNVISITED

}, {
     categoryValue: UDIGroups.PRODUCT_DESIGNER,  icon: faAddressCard, state: CategoryStatesEnum.UNVISITED

}, {
     categoryValue: UDIGroups.REFERENCED_DEVICE, icon: faLink, state: CategoryStatesEnum.UNVISITED

}, {
     categoryValue: UDIGroups.PACKAGE_ITEM,  icon: faBoxOpen, state: CategoryStatesEnum.UNVISITED, last: true
}];

export const sortedBudiCategories: CategoryWithState[] = [{
     categoryValue: BUDIGroups.KEYDATA,  icon: faKey, state: CategoryStatesEnum.UNVISITED
}, {
    categoryValue: BUDIGroups.MODELDATA, icon: faDatabase, state: CategoryStatesEnum.UNVISITED
}, {
     categoryValue: BUDIGroups.CERTIFICATE_INFORMATION, icon: faCertificate, state: CategoryStatesEnum.UNVISITED
}, {
     categoryValue: BUDIGroups.CLINICAL_INVESTIGATION, icon: faMicroscope,  state: CategoryStatesEnum.UNVISITED, last: true
}];
