import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DeviceService} from './device.store.service';
import Device from '../model/device.model';

export enum SelectionType {
  ALL,
  CURRENT_PAGE,
  NONE,
  FREE_SELECT
}

@Injectable()
export class SelectionService {
  private currentSelectionType: BehaviorSubject<SelectionType> = new BehaviorSubject<SelectionType>(SelectionType.NONE);
  private selectedDevices: BehaviorSubject<Array<Device>> = new BehaviorSubject<Array<Device>>([]);

  public currentSelectionType$ = this.currentSelectionType.asObservable();
  public selectedDevices$ = this.selectedDevices.asObservable();

  constructor(private deviceService: DeviceService) {
  }

  get getCurrentSelectionType(): SelectionType {
    return this.currentSelectionType.getValue();
  }

  get getCurrentlySelectedDevices(): Array<Device> {
    return this.selectedDevices.getValue();
  }

  addSelectedRow(device: any): void {
    this.currentSelectionType.next(SelectionType.FREE_SELECT);
    const newSelectedDevices = [...this.selectedDevices.value, device];
    this.selectedDevices.next(newSelectedDevices);
  }

  removeRowFromSelection(device: any): void {
    this.currentSelectionType.next(SelectionType.FREE_SELECT);
    const newSelectedDevices = this.selectedDevices.getValue().filter((element) => !element.equals(device));
    this.selectedDevices.next(newSelectedDevices);
  }

  resetCurrentlySelectedDevices(): void {
    this.currentSelectionType.next(SelectionType.NONE);
    this.selectedDevices.next([]);
  }

  selectionTypeMenu(selectionCriteria: SelectionType): void {
    this.currentSelectionType.next(selectionCriteria);

    if (selectionCriteria === SelectionType.CURRENT_PAGE) {
      this.selectedDevices.next(this.deviceService.getCurrentDevicesPage());
    } else if (selectionCriteria === SelectionType.ALL) {
      this.selectedDevices.next(this.deviceService.getCurrentDevicesPage());
    } else if (selectionCriteria === SelectionType.NONE) {
      this.selectedDevices.next([]);
    }
  }
}
