import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  AIMDDDEVICE,
  IVDRUDIDEVICE,
  IVDUDIDEVICE,
  MDDUDIDEVICE,
  MDRUDIDEVICE,
  SPPUDIDEVICE
} from '../constants/device-attribute-details';
import ValidationErrors, { ValidationMessage } from '../model/device-enrichment/validation-errors.model';
import { ApiService } from './api.service';
import { NotificationService } from './notifications/notification.service';

export interface DeviceValidationErrors{
    type: string;
    errors: string[];
}

@Injectable()
export class ValidationService {
    constructor(private api: ApiService, private notificationService: NotificationService, private translateService: TranslateService){}

    private readonly rawValidationErrors = new BehaviorSubject<ValidationErrors | null>(null);
    private readonly currentlyReviewing = new BehaviorSubject<ValidationMessage | null>(null);
    private readonly validationsLoading = new BehaviorSubject<boolean>(false);
    private readonly validatedDevice = new BehaviorSubject<any>(null);

    private readonly totalNumberOfMandatoryFields = new BehaviorSubject<number>(0);
    private readonly validMandatoryFields = new BehaviorSubject<Array<AbstractControl>>([]);

    readonly rawValidationErrors$ = this.rawValidationErrors.asObservable();
    readonly currentlyReviewing$ = this.currentlyReviewing.asObservable();
    readonly validationsLoading$ = this.validationsLoading.asObservable();
    readonly validatedDevice$ = this.validatedDevice.asObservable();

    readonly totalNumberOfMandatoryFields$ = this.totalNumberOfMandatoryFields.asObservable();
    readonly validMandatoryFields$ = this.validMandatoryFields.asObservable();

    resetMandatoryCounter(): void {
        this.totalNumberOfMandatoryFields.next(0);
        this.validMandatoryFields.next([]);
    }

    setTotalNumberOfMandatoryFields(mandatoryFields: number): void{
        this.totalNumberOfMandatoryFields.next(mandatoryFields);
    }

    addOneToTotalNumberOfMandatories(): void {
        this.totalNumberOfMandatoryFields.next(this.totalNumberOfMandatoryFields.getValue() + 1);
    }


    addOneValidMandatoryField(validMandatoryControl: AbstractControl): void {
        const allValidMandatories = this.validMandatoryFields.getValue();
        if (!allValidMandatories.includes(validMandatoryControl)){
            allValidMandatories.push(validMandatoryControl);
            this.validMandatoryFields.next(allValidMandatories);
        }
    }

    removeMandatoryFieldAsValid(invalidMandatoryField: AbstractControl): void {
        const allValidMandatories = this.validMandatoryFields.getValue();
        const indexOfInvalid = allValidMandatories.indexOf(invalidMandatoryField);
        if (indexOfInvalid !== -1){
            // tslint:disable-next-line: variable-name
            const newValidFields = allValidMandatories.filter((_validControls, index: number) => {
              return index !== indexOfInvalid;
            });
            this.validMandatoryFields.next(newValidFields);
        }
    }

    validateDevice(device: any): void {
        this.validatedDevice.next(device);
    }

    reviewValidationError(error: ValidationMessage | null): void{
        this.currentlyReviewing.next(error);
    }

    clearValidationErrorsAndReview(): void {
        this.currentlyReviewing.next(null);
        this.rawValidationErrors.next(null);
        this.validationsLoading.next(false);
    }

    validatePayload(deviceType: string, diCode: string, issuingEntityCode: string): Observable<any>  {
        const headers = {'Content-Type': 'application/json'};
        this.validationsLoading.next(true);
        let deviceVersion = 0;
        this.api.get(`/udimanager/v1/editor/draft/${deviceType}/${issuingEntityCode}/${diCode}`).subscribe((res: any) => {
            deviceVersion = res.version;
        });
        const validationErrors = this.fetchAllValidationErrorsForNonUpdatableFields(deviceType);
        const filteredByTypeValidationErrors = validationErrors.length > 0 ? validationErrors.find((v: DeviceValidationErrors) => v.type === deviceType) : [];
        return this.api.get(`/udimanager/v1/editor/validate/${deviceType}/${encodeURIComponent(diCode)}/${issuingEntityCode}`, headers ).pipe(map((res: any) => {
            const validationResponse = new ValidationErrors(res.validationResponse);
            // ---
            // TODO in future
            // temporarely solution for handling errors for nonupdateable fields
            if (validationResponse.messages.length > 0){
                for (let i = validationResponse.messages.length - 1; i >= 0; i--){
                    const err: any = res.validationResponse[0].messages[i];
                    if (filteredByTypeValidationErrors?.errors?.length > 0 && filteredByTypeValidationErrors?.errors?.filter((f: string) => f === err.code).length > 0 && deviceVersion > 0){
                        this.notificationService.showWarningNotification(`${err.severity} ${err.code}`, err.description, {});
                        validationResponse?.messages.splice(i, 1);
                    }
                }
            }
            // ---
            this.rawValidationErrors.next(validationResponse);
            this.validationsLoading.next(false);
        })).pipe(catchError(() => {
            this.notificationService.showErrorNotification(this.translateService.instant('NOTIFICATION.validation.error'), this.translateService.instant('NOTIFICATION.validation.errorMessage'), {});
            this.validationsLoading.next(false);
            return of(null);
        }));
    }

    fetchAllValidationErrorsForNonUpdatableFields(deviceType: string): any{
        const arr: DeviceValidationErrors[] = [
            {type: 'MDRUDIDEVICE', errors: []},
            {type: 'SPPUDIDEVICE', errors: []},
            {type: 'MDDUDIDEVICE', errors: []},
            {type: 'IVDRUDIDEVICE', errors: []},
            {type: 'IVDUDIDEVICE', errors: []},
            {type: 'AIMDDDEVICE', errors: []}
        ];
        switch (deviceType) {
            case 'MDRUDIDEVICE':
                MDRUDIDEVICE.find(o => {
                    if (o.children){
                        o.children.find((oc: any) => {
                            if (oc.validationError){
                                arr.find(a => {
                                    if (a.type === 'MDRUDIDEVICE'){
                                        a.errors.push(oc.validationError);
                                    }
                                });
                            }
                        });
                    }else{
                        if (o.validationError){
                            arr.find(a => {
                                if (a.type === 'MDRUDIDEVICE'){
                                    a.errors.push(o.validationError);
                                }
                            });
                        }
                    }
                });
                break;
            case 'SPPUDIDEVICE':
                SPPUDIDEVICE.find(o => {
                    if (o.children){
                        o.children.find((oc: any) => {
                            if (oc.validationError){
                                arr.find(a => {
                                    if (a.type === 'SPPUDIDEVICE'){
                                        a.errors.push(oc.validationError);
                                    }
                                });
                            }
                        });
                    }else{
                        if (o.validationError){
                            arr.find(a => {
                                if (a.type === 'SPPUDIDEVICE'){
                                    a.errors.push(o.validationError);
                                }
                            });
                        }
                    }
                });
                break;
            case 'MDDUDIDEVICE':
                MDDUDIDEVICE.find(o => {
                    if (o.children){
                        o.children.find((oc: any) => {
                            if (oc.validationError){
                                arr.find(a => {
                                    if (a.type === 'MDDUDIDEVICE'){
                                        a.errors.push(oc.validationError);
                                    }
                                });
                            }
                        });
                    }else{
                        if (o.validationError){
                            arr.find(a => {
                                if (a.type === 'MDDUDIDEVICE'){
                                    a.errors.push(o.validationError);
                                }
                            });
                        }
                    }
                });
                break;
            case 'IVDRUDIDEVICE':
                IVDRUDIDEVICE.find(o => {
                    if (o.children){
                        o.children.find((oc: any) => {
                            if (oc.validationError){
                                arr.find(a => {
                                    if (a.type === 'IVDRUDIDEVICE'){
                                        a.errors.push(oc.validationError);
                                    }
                                });
                            }
                        });
                    }else{
                        if (o.validationError){
                            arr.find(a => {
                                if (a.type === 'IVDRUDIDEVICE'){
                                    a.errors.push(o.validationError);
                                }
                            });
                        }
                    }
                });
                break;
            case 'IVDUDIDEVICE':
                IVDUDIDEVICE.find(o => {
                    if (o.children){
                        o.children.find((oc: any) => {
                            if (oc.validationError){
                                arr.find(a => {
                                    if (a.type === 'IVDUDIDEVICE'){
                                        a.errors.push(oc.validationError);
                                    }
                                });
                            }
                        });
                    }else{
                        if (o.validationError){
                            arr.find(a => {
                                if (a.type === 'IVDUDIDEVICE'){
                                    a.errors.push(o.validationError);
                                }
                            });
                        }
                    }
                });
                break;
            case 'AIMDDDEVICE':
                AIMDDDEVICE.find(o => {
                    if (o.children){
                        o.children.find((oc: any) => {
                            if (oc.validationError){
                                arr.find(a => {
                                    if (a.type === 'AIMDDDEVICE'){
                                        a.errors.push(oc.validationError);
                                    }
                                });
                            }
                        });
                    }else{
                        if (o.validationError){
                            arr.find(a => {
                                if (a.type === 'AIMDDDEVICE'){
                                    a.errors.push(o.validationError);
                                }
                            });
                        }
                    }
                });
                break;
        }
        return arr;
    }
}
