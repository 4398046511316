import {FormGroup} from '@angular/forms';
import FormBuildingInput
  from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {
  CategoryWithState,
  sortedBudiCategories,
  sortedLegacyCategories,
  sortedUdiCategories,
  UDIGroups
} from '../../constants/device-attribute-details/attribute-groups.enum';
import {CategoryStatesEnum} from '../../service/device-initialization/CategoryStates.enum';
import {mapFromFormGroupToObject} from '../../service/device-initialization/map-form-to-model.util';
import EnrichingDeviceDTO from './enriching-device-dto.model';

export default class EnrichingDeviceProcess {
    parentGenericDevice: EnrichingDeviceDTO;
    formData: Array<FormBuildingInput>;
    filteredFormData: {[key: string]: Array<FormBuildingInput>};
    formGroups: {[key: string]: FormGroup} = {};
    categories: Array<CategoryWithState>;
    initialPayload: any;
    isBasic: boolean;
    compound: boolean;

    constructor(parentGenericDevice: EnrichingDeviceDTO,
                formData: Array<FormBuildingInput>,
                initialPayload: any,
               ){
        this.parentGenericDevice = parentGenericDevice;
        this.formData = formData;
        this.initialPayload = initialPayload;

        this.compound = parentGenericDevice.compound || false;
        this.categories = this.initializeCategories(initialPayload);
        this.filteredFormData = this.filterFormData(this.formData);
    }


    private initializeCategories(initialPayload: any): Array<CategoryWithState>{
        if (initialPayload.deviceType.includes('BASIC')) {
            const categories = [...sortedBudiCategories];
            if (initialPayload.deviceType.includes('SPP')) {
                categories.splice(1, 3);
            }
            return categories;
        } else {
            let categories = [];
            if (initialPayload.deviceType.includes('MDE') || initialPayload.deviceType.includes('IVDE') || initialPayload.deviceType.includes('ADE')) {
                categories = [...sortedLegacyCategories].filter(category => category.categoryValue !== UDIGroups.REFERENCED_DEVICE);
            }else{
                categories = [...sortedUdiCategories].filter(category => category.categoryValue !== UDIGroups.REFERENCED_DEVICE);
            }
            if (initialPayload.deviceType.includes('SPP')) {
                categories.splice(3, 3);
            }
            return categories;
        }

    }

    private filterFormData(formData: Array<FormBuildingInput>): {[key: string]: Array<FormBuildingInput>}{
        return formData.reduce((previousFiltered: {[key: string]: Array<FormBuildingInput>}, singleFormData: FormBuildingInput) => {
            if (!Array.isArray(previousFiltered[singleFormData.group])){
                previousFiltered[singleFormData.group] = [];
            }
            previousFiltered[singleFormData.group].push(singleFormData);
            return previousFiltered;
        }, {});
    }

    generateJsonPayload(): any{
        const payloadAsSingleFormGroup = new FormGroup({});

        Object.keys(this.formGroups).forEach((formsPerCategory: string) => {
            const formGroupOfOneCategory = this.formGroups[formsPerCategory];
            Object.keys(formGroupOfOneCategory.controls).forEach((cntrl: string) => {
                const control = formGroupOfOneCategory.get(cntrl);
                if (control) {
                    payloadAsSingleFormGroup.addControl(cntrl, control);
                }
            });
        });

        const devicePayload: {[key: string]: any} = {};

        this.formData.forEach((formInput: FormBuildingInput) => {
            const abstractControl = payloadAsSingleFormGroup.get(formInput.propertyName);
            if (abstractControl){
                const mappedField = mapFromFormGroupToObject(abstractControl, formInput);
                if (mappedField !== null && mappedField !== undefined){
                    devicePayload[formInput.propertyName] = mappedField;
                }
            }
        });
        return devicePayload;
    }

    setFormGroupPerCategory(category: CategoryWithState, formGroup: FormGroup): void{
        this.formGroups[category.categoryValue] = formGroup;
        formGroup.statusChanges.subscribe((status) => {
            if (status === 'INVALID'){
                category.state = CategoryStatesEnum.ISSUES;
            }
            if (status === 'VALID'){
                if (category.state !== CategoryStatesEnum.ERROR){
                    category.state = CategoryStatesEnum.VALID;
                }
            }
        });
    }

}
