<ng-container [formGroup]="formGroup">
    <nz-form-item
        [class]="fetchError() ? 'form-item-error' : ''"
        [style]="formBuildingInput.style? formBuildingInput.style : 'flex-basis:100%;'"
        >
        <nz-form-control #error [nzHasFeedback]="formBuildingInput.validators && formBuildingInput.validators.includes(Validators.required)" 
        [nzErrorTip]="(formBuildingInput.errorMessage | typeof) == 'string' ? (formBuildingInput.errorMessage | translate) : errorHandlingTemplate">
            <div style="width:100%;">
                <div style="display: block;">
                    <span class="radioButtonPlaceholder">{{formBuildingInput.placeholder}}</span>
                    <span [style]="error.status === 'error' ? 'color: var(--error); font-weight: 600;' : ''">
                        {{(formBuildingInput.name | translate) + ((formBuildingInput.validators && formBuildingInput.validators.includes(Validators.required)) ? '*' : '')}}
                    </span>
                </div>
            </div>
            <div style="width:100%; display: block; margin-top: 8px;">
                <nz-radio-group [formControlName]="formBuildingInput.propertyName">
                    <label nz-radio [nzValue]="true">{{'ENRICHMENT.body.text.yes' | translate}}</label>
                    <label nz-radio [nzValue]="false">{{'ENRICHMENT.body.text.no' | translate}}</label>
                </nz-radio-group>
                <a *ngIf="getMarketInfoDisableValue() && !(updating && !formBuildingInput.updateable) && getByName(formGroup, formBuildingInput.propertyName)?.value !== null && (!formBuildingInput.validators || (formBuildingInput.validators && !formBuildingInput.validators.includes(Validators.required)))"
                    style="float: right; text-decoration: underline; width: fit-content; height: fit-content;"
                    (click)="getByName(formGroup, formBuildingInput.propertyName)?.setValue(null)">
                    {{'ENRICHMENT.body.button.clearSection' | translate}}
                </a>
            </div>
        </nz-form-control>
    </nz-form-item>
</ng-container>

<ng-template #errorHandlingTemplate let-formControl>
    <div *ngFor="let error of (getControlErrorMessage(formControl) | keyvalue)">
      <span>
        {{ error.key | handleErrorTranslationPipe : formControl }}
      </span>
    </div>
</ng-template>
