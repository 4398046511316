import {Pipe, PipeTransform} from '@angular/core';
import {CodelistValue, EudamedComponentCodelists} from 'src/app/core/constants/codelists.constants';

@Pipe({
  name: 'codelist'
})
export class CodelistValuesPipe implements PipeTransform {
    codelists = EudamedComponentCodelists;

    transform(propertyName: string, editingObject: any): CodelistValue[]{
        const formCodeList = this.codelists.filter(cl => cl.formElement === propertyName)[0].codeList;
        if (editingObject && propertyName === 'riskClass'){
            if (editingObject.deviceType === 'MDRBASICDEVICE' || editingObject.deviceType === 'MDEUDIBASICDEVICE' || editingObject.deviceType.includes('SPP') ||
                editingObject.deviceType === 'MDR' || editingObject.deviceType === 'MDD'){
                return formCodeList?.perTypeFilter?.MDR || formCodeList?.values || [];
            }else if (editingObject.deviceType === 'ADEUDIBASICDEVICE' || editingObject.deviceType === 'AIMDD'){
                return formCodeList?.perTypeFilter?.AIMDD || formCodeList?.values || [];
            }else if (editingObject.deviceType === 'IVDRBASICDEVICE' || editingObject.deviceType === 'IVDR'){
                return formCodeList?.perTypeFilter?.IVDR || formCodeList?.values || [];
            }else if (editingObject.deviceType === 'IVDEUDIBASICDEVICE' || editingObject.deviceType === 'IVD'){
                return formCodeList?.perTypeFilter?.IVDD || formCodeList?.values || [];
            }else{
                return formCodeList?.values || [];
            }
        }else if (editingObject && propertyName === 'specialDevice'){
            if (editingObject.deviceType === 'MDRBASICDEVICE' || editingObject.deviceType === 'MDR'){
                return formCodeList?.perTypeFilter?.MDR || formCodeList?.values || [];
            } else if (editingObject.deviceType === 'MDEUDIBASICDEVICE' || editingObject.deviceType === 'MDD') {
              return formCodeList?.perTypeFilter?.MDD || formCodeList?.values || [];
            } else if (editingObject.deviceType === 'ADEUDIBASICDEVICE' || editingObject.deviceType === 'AIMDD') {
              return formCodeList?.perTypeFilter?.AIMDD || formCodeList?.values || [];
            }
            else if (editingObject.deviceType === 'IVDRBASICDEVICE' || editingObject.deviceType === 'IVDR'){
                return formCodeList?.perTypeFilter?.IVDR || formCodeList?.values || [];
            } else if (editingObject.deviceType === 'IVDEUDIBASICDEVICE' || editingObject.deviceType === 'IVD') {
              return formCodeList?.perTypeFilter?.IVD || formCodeList?.values || [];
            }
            else{
                return formCodeList?.values || [];
            }
        }else if (editingObject && propertyName === 'deviceCertificateType'){
            if (editingObject.deviceType === 'MDRBASICDEVICE' || editingObject.deviceType === 'MDR'){
                return formCodeList?.perTypeFilter?.MDR || formCodeList?.values || [];
            }else if (editingObject.deviceType === 'MDEUDIBASICDEVICE' || editingObject.deviceType === 'MDD'){
                return formCodeList?.perTypeFilter?.MDD || formCodeList?.values || [];
            }else if (editingObject.deviceType === 'ADEUDIBASICDEVICE' || editingObject.deviceType === 'AIMDD'){
                return formCodeList?.perTypeFilter?.AIMDD || formCodeList?.values || [];
            }else if (editingObject.deviceType === 'IVDRBASICDEVICE' || editingObject.deviceType === 'IVDR'){
                return formCodeList?.perTypeFilter?.IVDR || formCodeList?.values || [];
            }else if (editingObject.deviceType === 'IVDEUDIBASICDEVICE' || editingObject.deviceType === 'IVD'){
                return formCodeList?.perTypeFilter?.IVDD || formCodeList?.values || [];
            }else{
                return formCodeList?.values || [];
            }
        }else{
            return formCodeList?.values || [];
        }
    }
}
