import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'codelistMDNTranslation',
})
export class CodelistMDNTranslation implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(label: string): any {
        if (label.split(' ')[1].includes('CODELISTCONSTANTS')){
            return label.split(' ')[0] + ' ' + this.translateService.instant(label.split(' ')[1]);
        }else{
            return label;
        }
    }
}
