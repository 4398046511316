import {DeviceConstants} from 'src/app/core/constants/device-attribute-details';
import {TreeNode} from 'src/app/pages/devices/device-details/base-device-details.component';

export function parseSimpleField(currentFieldConstants: DeviceConstants, objectToParse: any): TreeNode[] {
    if (objectToParse == null) {
        return [{
            title: currentFieldConstants.name,
            value: '',
            description: currentFieldConstants.description || '',
            group: currentFieldConstants.group
        }];
    } else {
        if (!Array.isArray(objectToParse)){
            return [{
                title: currentFieldConstants.name,
                value: objectToParse,
                description: currentFieldConstants.description || '',
                group: currentFieldConstants.group
            }];
        } else {
            const allSimpleArrayFields = [];
            for (const value of objectToParse) {
                allSimpleArrayFields.push({
                    title: currentFieldConstants.name,
                    value,
                    description: currentFieldConstants.description || '',
                    group: currentFieldConstants.group
                });
            }
            return allSimpleArrayFields;
        }
    }
}

export function parseArrayField(currentFieldConstants: DeviceConstants, objectToParse: any): TreeNode[] {
    const nodesToReturn: TreeNode[] = [];


    // This field in the object is empty, we make a an empty node with one repeating node and no values inside it
    if (objectToParse == null) {
        const rootNodeForChildren: TreeNode = {
            title: currentFieldConstants.name,
            value: '',
            group: currentFieldConstants.group,
            description: '',
            children: []
        };

        if (currentFieldConstants.children){
            const oneEmptyChild: TreeNode = {
                title: '',
                value: '',
                description: '',
                group: currentFieldConstants.group,
                children: []
            };
            for (const child of currentFieldConstants.children) {
                oneEmptyChild.children?.push({
                    title: child.name,
                    value: '',
                    description: child.description || '',
                    group: child.group
                });
            }

            rootNodeForChildren.children?.push(oneEmptyChild);
            nodesToReturn.push(rootNodeForChildren);
            return nodesToReturn;
        }
    } else {
        const rootNodeForChildren: TreeNode = {
            title: currentFieldConstants.name,
            value: '',
            group: currentFieldConstants.group,
            description: '',
            children: []
        };
        for (const arrayItemInObject of objectToParse) {
            const oneItem: TreeNode  = {
                title: '',
                value: '',
                description: '',
                group: currentFieldConstants.group,
                children: []
            };

            if (currentFieldConstants.children) {
                for (const constantInArray of currentFieldConstants.children) {
                    if (constantInArray.parsingFunction){
                        oneItem.children?.push(...constantInArray.parsingFunction(constantInArray, arrayItemInObject[constantInArray.propertyName]));
                    }
                }
            }
            rootNodeForChildren.children?.push(oneItem);
        }
        nodesToReturn.push(rootNodeForChildren);
        return nodesToReturn;
    }


    return nodesToReturn;
}


export function parseComplexField(currentFieldConstants: DeviceConstants, objectToParse: any): TreeNode[] {
    const nodesToReturn = [];
    if (objectToParse == null){
        nodesToReturn.push({
            title: currentFieldConstants.name,
            value: '',
            description: currentFieldConstants.description || '',
            group: currentFieldConstants.group
        });
        return nodesToReturn;
    } else {
        if (currentFieldConstants.children){
            for (const complexObjectItem of currentFieldConstants.children) {
                if (complexObjectItem.parsingFunction) {
                    nodesToReturn.push(...complexObjectItem.parsingFunction(complexObjectItem, objectToParse[complexObjectItem.propertyName]));
                }
            }
        }
        return nodesToReturn;
    }
}

export function parseSimpleArray(currentFieldConstants: DeviceConstants, objectToParse: any): TreeNode[] {
    const nodesToReturn = [];
    if (objectToParse){
            for (const it of objectToParse) {
                if (currentFieldConstants.children) {
                    nodesToReturn.push({
                        title: `${currentFieldConstants.children[1].name}`,
                        value: `${it[currentFieldConstants.children[1].propertyName]} [${it[currentFieldConstants.children[0].propertyName]}]`,
                        group: currentFieldConstants.group,
                        description: currentFieldConstants.children[0].description || '',
                    });
                }
            }
        } else {
            if (currentFieldConstants.children){
                nodesToReturn.push({
                    title: currentFieldConstants.children[1].name,
                    value: '',
                    group: currentFieldConstants.group,
                    description: currentFieldConstants.children[0].description || ''
                });
            }
        }
    return nodesToReturn;
}
