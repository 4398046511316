import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import FormBuildingInput from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {AIMDDBASICDEVICE, AIMDDDEVICE, DeviceConstants, IVDBASICDEVICE, IVDRBASICDEVICE, IVDRUDIDEVICE, IVDUDIDEVICE, MDDBASICDEVICE, MDDUDIDEVICE, MDRBASICDEVICE, MDRUDIDEVICE, SPPBASICDEVICE, SPPUDIDEVICE} from '../../constants/device-attribute-details';
import {Filter} from '../../model/filter.model';
import ColumnJsonPath from '../../model/reports/column-json-path.interface';
import Filters from '../../model/reports/filters.model';
import ReportField from '../../model/reports/report-field.model';
import Report from '../../model/reports/report.interface';
import {ApiService} from '../api.service';
import {FilterService} from '../filters/filter.service';

@Injectable()
export default class ReportsService {
    private reports: BehaviorSubject<Array<Report>> = new BehaviorSubject([] as Array<Report>);
    private reportsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    reportsNumber$ = this.reports.pipe(map((reports: Array<Report>) => {
        return reports.length || 0;
    }));

    public reports$ = this.reports.asObservable();

    public reportsLoading$ = this.reportsLoading.asObservable();

    constructor(private apiService: ApiService,
                private translationService: TranslateService,
                private filterService: FilterService){
    }

    fetchReports(): void{
        this.reportsLoading.next(true);
        this.apiService.get('/reporting/v1/reports/')
        .pipe(catchError(() => {
            this.reportsLoading.next(false);
            return of(null);
        }))
        .subscribe((reports: any) => {
            if (reports){
                this.reports.next(reports);
            }
            this.reportsLoading.next(false);
        });
    }

    getOneReport(reportId: string): Observable<Report | null> {
        return this.apiService.get(`/reporting/v1/reports/${reportId}`)
        .pipe(catchError(() => {
            return of(null);
        }));
    }

    sendReport(report: Report): Observable<Report>{
        return this.apiService.post('/reporting/v1/reports/', report, { 'Content-Type': 'application/json' });
    }

    deleteReport(reportId: string): Observable<any> {
      return this.apiService.delete(`/reporting/v1/reports/${reportId}`)
      .pipe(catchError(() => {
        return of(false);
      }));
    }

    mapFiltersToFilterDto(): Filters {
        const filtersDto = new Filters({});
        const allFilters = this.filterService.getFilters();
        filtersDto.createDtoFromUIFilters(allFilters);
        return filtersDto;
    }

    initializeFiltersFromReport(report: Report): void{
        const filtersDto = report.filtersDto;

        Object.keys(filtersDto).forEach((filterField) => {
            const uiVersionOfFilterField = filtersDto.reverseUiMappings[filterField];
            const filterFromFilterDto = (report.filtersDto as any)[filterField];

            if ( filterFromFilterDto && Array.isArray(filterFromFilterDto)){
                filterFromFilterDto.forEach((singleFilterValue) => {
                    const newFilter = new Filter();
                    newFilter.createFromFieldAndValue(uiVersionOfFilterField, singleFilterValue);
                    this.filterService.addFilter(newFilter);
                });
            }
        });
    }

    updateReportFieldPositions(reportFields: Array<ReportField>): void{
        reportFields.forEach((reportField, index) => {
            reportField.positionInReport = index;
        });
    }

    getAllJsonPaths(): Array<ColumnJsonPath>{
        const mappedJsonPaths: Array<ColumnJsonPath> = [];
        const flattenedConstants: Array<DeviceConstants & FormBuildingInput> = [];

        [
         ...MDRBASICDEVICE,
         ...MDRUDIDEVICE,
         ...IVDRBASICDEVICE,
         ...IVDRUDIDEVICE,
         ...SPPBASICDEVICE,
         ...SPPUDIDEVICE,
         ...IVDBASICDEVICE,
         ...IVDUDIDEVICE,
         ...MDDBASICDEVICE,
         ...MDDUDIDEVICE,
         ...AIMDDBASICDEVICE,
         ...AIMDDDEVICE
        ]
         .forEach((deviceConstant) => {
            flattenedConstants.push(...this.flattenSingleField(deviceConstant));
        });

        flattenedConstants.forEach((singleConstant: DeviceConstants & FormBuildingInput) => {
          if (singleConstant.jsonPath && singleConstant.jsonPath !== ''){
            const columnJsonPath: ColumnJsonPath = {
              jsonPath: singleConstant.jsonPath || '',
              labelWithFieldId: singleConstant.placeholder + ' : ' + this.translationService.instant(singleConstant.name),
            };
            mappedJsonPaths.push(columnJsonPath);
          }
        });

        const withUniqueJsonPaths = [...new Map(mappedJsonPaths.map(item =>
                                                       [item.jsonPath, item])).values()];

        console.log(withUniqueJsonPaths);
        return withUniqueJsonPaths;
    }

    updateReportFieldNames(reportFields: Array<ReportField>, allJsonPaths: Array<ColumnJsonPath>): void{
      reportFields.forEach((reportField) => {
        const pathWithName = allJsonPaths.find((jsonPathWithName) => {
          return jsonPathWithName.jsonPath === reportField.jsonPath;
        });

        if (pathWithName){
          reportField.fieldName = pathWithName.labelWithFieldId;
        }
      });
    }


    flattenSingleField(deviceConstant: DeviceConstants & FormBuildingInput): Array<DeviceConstants & FormBuildingInput>{
        const flattenedConstants: Array<DeviceConstants & FormBuildingInput> = [];
        if (deviceConstant.children){
            deviceConstant.children.forEach((childDeviceConstant: any) => {
                flattenedConstants.push(...this.flattenSingleField(childDeviceConstant));
            });
        }else{
            flattenedConstants.push(deviceConstant);
        }
        return flattenedConstants;
    }

}
