import {Component, Input, OnInit} from '@angular/core';
import { faCookieBite } from '@fortawesome/free-solid-svg-icons';
import {EudamedCookieService} from '../../core/service/eudamed-cookie.service';
import {EudamedCookie} from '../../core/constants/cookie.constants';
import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

  @Input() modalOpen: boolean;
  icon = faCookieBite;
  necessaryCookie = true;
  analyticsCookie = false;

  showOptions = false;

  constructor(private eudamedCookieService: EudamedCookieService,
              private httpClient: HttpClient) { }

  ngOnInit(): void {}

  handleOk(): void {
    setTimeout(() => {
      this.eudamedCookieService.createCookie(EudamedCookie.NECESSARY, this.necessaryCookie);
      if (this.analyticsCookie) {
        this.eudamedCookieService.createCookie(
          EudamedCookie.ANALYTICS,
          this.analyticsCookie
        );
      }
      if (!this.analyticsCookie) {
        this.eudamedCookieService.deleteCookie(EudamedCookie.ANALYTICS);
      }
      this.modalOpen = false;
      window.location.reload();
    }, 1000);
  }

  openInNewTab(url: string): void {
    const win = window.open(url, '_blank');
    win?.focus();
  }

  openFileInNewTab(fileName: any): void {
    this.httpClient
      .get(`/udimanager/v1/download/${fileName}`,
        {observe: 'response', responseType: 'blob'}
      )
      .pipe(
        catchError((err) => {
          console.log(err);
          return of(false);
        })
      )
      .subscribe((response: any) => {
        if (response !== false) {
          const blob = new Blob([response.body], {type: 'application/pdf'});
          console.log(response.body);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.href = url;
          link.click();
          link.remove();
        }
      });
  }

  allowAllCookies(): void {
    this.analyticsCookie = true;
    this.eudamedCookieService.createCookie(EudamedCookie.NECESSARY, this.necessaryCookie);
    this.eudamedCookieService.createCookie(EudamedCookie.ANALYTICS, this.analyticsCookie);
    this.modalOpen = false;
    window.location.reload();
  }
}
