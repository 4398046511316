import { Injectable } from '@angular/core';
import {ApiService} from '../../../core/service/api.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import TokenModel, {ActorCodeType} from './token.model';
import EudamedTokenInterface from '../../../core/service/eudamed-token/eudamed-token.model';
import {catchError, map} from 'rxjs/operators';
import {HttpParams, HttpResponse} from '@angular/common/http';
import ActorCode from '../../../core/model/actor-code.model';
import {Filter} from '../../../core/model/filter.model';
import {FilterService} from '../../../core/service/filters/filter.service';
import {DeviceService} from '../../../core/service';

@Injectable({
  providedIn: 'root'
})
export class TokenManagementService {

  private actorCodesWithoutToken: BehaviorSubject<ActorCode[]> = new BehaviorSubject<ActorCode[]>([]);
  public actorCodesWithoutToken$: Observable<ActorCode[]> = this.actorCodesWithoutToken.asObservable();

  constructor(private apiService: ApiService,
              private filterService: FilterService,
              private deviceService: DeviceService) { }

  setActorCodesWithoutToken(actorCodes: ActorCode[]): void {
    this.actorCodesWithoutToken.next(actorCodes);
  }

  getTokens(): Observable<any> {
    return this.apiService.get(`/udimanager/v1/editor/eudamed-token`);
  }

  postTokens(tokenData: Array<TokenModel>): Observable<any> {
    const tokenDataFilter = tokenData.filter((tokenModel) => tokenModel.eudamedToken !== null);
    return this.apiService.post(`/udimanager/v1/editor/eudamed-token`, tokenDataFilter, {'Content-Type': 'application/json'});
  }

  getTokenByActorCode(actorCodeType: ActorCodeType, actorCode: string): Observable<EudamedTokenInterface | null> {
    return this.apiService.get(`/udimanager/v1/editor/eudamed-token/${actorCodeType}/${actorCode}`, {observe: 'response' as 'body'}).pipe(map((response: HttpResponse<any>) => {
      if (response.status === 200 && response.body.eudamedToken !== '') {
        return { actorCode, token: response.body.eudamedToken };
      } else {
        return null;
      }
    })).pipe(catchError(() => {
      return of(null);
    }));
  }

  getAllActorCodesWithoutTokens(tokenData: Array<TokenModel>): Observable<any> {
    return this.apiService.post('/udimanager/v1/editor/eudamed-token/from-actorcodes', tokenData, {'Content-Type': 'application/json'});
  }

  getAllActorCodesWithoutTokensForAllDevices(): Observable<any> {
    let params: HttpParams = new HttpParams();

    this.filterService.getFilters().forEach((filter: Filter) => {
      filter.isApplied = true;
      if (filter.isSort) {
        params = params.set('direction', filter.valueToString());
        params = params.set('column', filter.fieldToString());
      } else {
        params = params.set(filter.fieldToString(), filter.valueToString());
      }
    });

    const legislation = this.deviceService.getCurrentLegislation();
    const numberOfDevices = this.deviceService.getNumberOfAllDevices();
    return this.apiService.get(`/udimanager/v1/editor/eudamed-token/all/${legislation}/${numberOfDevices}`, { headers: { 'Content-Type': 'application/json' }, params });
  }
}
