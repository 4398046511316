import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormControlType} from '../../form-interfaces/form-control-type.enum';
import {BaseFormFieldType} from '../base-form-field-type.class';

@Component({
    selector: 'form-root-render',
    templateUrl: './root-form.component.html',
    styleUrls: ['./root-form.component.scss']
})
export class RootFormComponent extends BaseFormFieldType {
    @Input()
    lastVisitedPath: any;

    @Input()
    rootNested: boolean;

    @Input()
    localFormGroup: FormGroup;

    @Input()
    index: any;

    @Input()
    updating: boolean;

    @Input()
    editingObject: any;

    @Input()
    errorForm: boolean;

    FormControlType = FormControlType;

}
