<div style="overflow:auto;border:1px solid var(--gray3); border-radius:6px; position: absolute; right:0px; width:80%; height:100%;background-color:white;">
    <div style="margin: 32px;" nz-row nzJustify="space-between">
        <h2 style="font-weight:600;">{{ 'DEVICES.filters.filter' | translate}}</h2>
        <i
            (click)="hideFilters()"
            nz-icon
            style="margin-top:6px;">
          <fa-icon [icon]="faTimes" style="margin-bottom: -4px; font-size: 1.125rem;"></fa-icon>
        </i>
    </div>
    <div style="margin:32px;" class="filters-body">
        <button nz-button
                class="filters"
                *ngFor="let filter of filters | filter: filterOnlyApplied"
                [style]="filter.isApplied ? 'overflow:auto; background-color:#DBDBDB; width:100%; height:40px; margin-bottom: 8px;': 'overflow:auto; background-color:white; width:100%; height:40px; margin-bottom: 8px;'"
                >
                    <ng-container style="width:80%;" *ngIf="!filter.isSearch; else searchBubble">
                        <b>{{columnFilterOptions[filter.field].name | translate}}</b> : {{filter.typeOfFilter}} {{filter.value}}
                    </ng-container>
                    <ng-template #searchBubble>
                        <i>{{ 'DEVICES.search' | translate }}</i> : {{filter.value}}
                    </ng-template>
                    <i
                        nz-icon
                        nzTooltipTitle="{{ 'DEVICES.filters.remove' | translate }}"
                        nz-tooltip
                        style="width:20%; margin-top:4px;"
                        (click)="removeFilter(filter)"
                        nzType="close">
                    </i>
        </button>
        <nz-divider></nz-divider>
        <div *ngFor="let filter of filters; let i = index" nz-row>
            <div style="width:100%;" *ngIf="filters.length > 1 && i != 0">
                <nz-divider nzText="{{ 'DEVICES.filters.and' | translate }}"></nz-divider>
            </div>
            <ng-container *ngIf="!filter.isSearch">
                <div style="width:100%">
                    <nz-radio-group (ngModelChange)="filter.isApplied = false;" nz-row nzJustify="space-between" style="margin-bottom:16px;" ngSize="large" [(ngModel)]="filter.include">
                        <label nz-radio [nzValue]="true">{{ 'DEVICES.filters.include' | translate }}</label>
                        <label nz-radio [nzValue]="false">{{ 'DEVICES.filters.exclude' | translate }}</label>
                    </nz-radio-group>
                    <fa-icon
                        [styles]="{ cursor: 'pointer', 'font-size': '1rem' }"
                        nz-icon
                        [icon]="faTrash"
                        (click)="removeFilter(filter)"
                        style="float: right;"
                    ></fa-icon>
                    <div style="clear: both;"></div>
                </div>
                <div nz-row style="width:100%; margin-bottom: 16px;">
                    <nz-select (ngModelChange)="fieldChanged($event, filter)" [(ngModel)]="filter.field" style="width:100%">
                        <nz-option *ngFor="let deviceField of columnFilterOptions | keyvalue" [nzValue]="deviceField.key" [nzLabel]="deviceField.value.name | translate"></nz-option>
                    </nz-select>
                </div>
                <div nz-row style="width:100%; margin-bottom:16px;">
                    <nz-select (ngModelChange)="filter.isApplied = false;" *ngIf="columnFilterOptions[filter.field].type === FilterInputType.Input; else selectFilter" [(ngModel)]="filter.typeOfFilter" style="width:100%;">
                        <nz-option [nzValue]="FilterType.Contains" nzLabel="{{ 'DEVICES.filters.contains' | translate }}"></nz-option>
                        <nz-option [nzValue]="FilterType.StartsWith" nzLabel="{{ 'DEVICES.filters.starts' | translate }}"></nz-option>
                        <nz-option [nzValue]="FilterType.EndsWith" nzLabel="{{ 'DEVICES.filters.ends' | translate }}"></nz-option>
                    </nz-select>
                    <ng-template #selectFilter>
                        <nz-select style="width:100%" [(ngModel)]="filter.typeOfFilter" [nzDisabled]="true">
                            <nz-option [nzValue]="FilterType.Contains" nzLabel="{{ 'DEVICES.filters.contains' | translate }}"></nz-option>
                        </nz-select>
                    </ng-template>
                </div>
                <input (ngModelChange)="filter.isApplied = false;" *ngIf="columnFilterOptions[filter.field].type === FilterInputType.Input" nz-input style="margin-bottom:16px;" [placeholder]="'DEVICES.filters.valuePlaceholder' | translate" [(ngModel)]="filter.value" />
                <nz-select (ngModelChange)="filter.isApplied = false;" nzPlaceHolder="{{ 'DEVICES.filters.selectPlaceholder' }}" *ngIf="columnFilterOptions[filter.field].type === FilterInputType.Select"  [(ngModel)]="filter.value" style="margin-bottom:16px; width:100%">
                    <nz-option *ngFor="let option of columnFilterOptions[filter.field].options" [nzValue]="option.value" [nzLabel]="filter.field === 'status' ? ('STATUS.'+option.label | translate) : option.label"></nz-option>
                </nz-select>
            </ng-container>
        </div>
        <div style="width: 100%;">
            <button style="width: 100%; margin-bottom: 16px; margin-top: 20px; overflow-wrap: break-word; height: fit-content;" (click)="addFilter()" nzType="default" nz-button>
                <fa-icon [ngStyle]="{'font-size': '1rem'}"
                         nz-icon
                         [icon]="faPlus">
                </fa-icon>
                <span style="font-weight: 600;">
                    {{ 'DEVICES.filters.add' | translate }}
                </span>
            </button>
        </div>
        <div nz-row nzJustify="space-between">
          <a class="cancel-button" (click)="triggerExitAnimation()">{{ 'DEVICES.filters.cancel' | translate }}</a>
          <button style="width: 50%; overflow-wrap: break-word; height: fit-content; font-weight: 600;" (click)="applyFilters()" nzType="primary" nz-button>{{ 'DEVICES.filters.apply' | translate }}</button>
        </div>
    </div>
</div>
<div (click)="hideFilters()" style=" background-color:rgba(255, 255, 255, 0.5); height:100%; width:100%;">
</div>
