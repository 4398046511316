<ng-container [formGroup]="formGroup">
    <nz-form-item
        [style]="formBuildingInput.style? formBuildingInput.style : 'flex-basis:100%;'"
        >
        <nz-form-control [nzHasFeedback]="formBuildingInput.validators && formBuildingInput.validators.includes(Validators.required)" [nzErrorTip]="formBuildingInput.errorMessage | translate">
            <nz-input-group>
                <eudamed-date-picker-wrapper [optionalClass]="optionalClass" [label]="(formBuildingInput.name | translate) + ((formBuildingInput.validators && formBuildingInput.validators.includes(Validators.required)) ? '*' : '')">
                    <nz-date-picker
                        [nzPlaceHolder]="formBuildingInput.placeholder || '' | translate"
                        [nzShowTime]="{ nzFormat: 'HH:mm:ss' }"
                        nzFormat="yyyy-MM-dd HH:mm:ss"
                        nzSize="small"
                        style="height:52px; width:50%; display:flex; align-items:flex-end;"
                        [formControlName]="formBuildingInput.propertyName"
                        ></nz-date-picker>
                </eudamed-date-picker-wrapper>
            </nz-input-group>
        </nz-form-control>
    </nz-form-item>
</ng-container>
