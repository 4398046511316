  export const determineUploadToEudamedStorageUrl = (deviceType: string, isCollection: boolean) => {
  let url = '/eudamed/v1/devices';

  switch (deviceType) {
    case 'MDRBASICDEVICE':
      url = url.concat('/mdr/basic-udi-dis');
      break;
    case 'MDRUDIDEVICE':
      url = url.concat('/mdr/udi-dis');
      break;
    case 'SPPBASICDEVICE':
      url = url.concat('/spp/basic-udi-dis');
      break;
    case 'SPPUDIDEVICE':
      url = url.concat('/spp/udi-dis');
      break;
    case 'IVDRBASICDEVICE':
      url = url.concat('/ivdr/basic-udi-dis');
      break;
    case 'IVDRUDIDEVICE':
      url = url.concat('/ivdr/udi-dis');
      break;
    case 'LINKMDR':
      url = url.concat('/mdr/udi-dis/link');
      break;
    case 'LINKIVDR':
      url = url.concat('/ivdr/udi-dis/link');
      break;
    case 'LINKSPP':
      url = url.concat('/spp/udi-dis/link');
      break;
    case 'REGISTERMDR':
      url = url.concat('/mdr/udi-dis/registration');
      break;
    case 'REGISTERIVDR':
      url = url.concat('/ivdr/udi-dis/registration');
      break;
    case 'REGISTERSPP':
      url = url.concat('/spp/udi-dis/registration');
      break;
    case 'MDEUDIBASICDEVICE':
      url = url.concat('/mdd/basic-udi-dis');
      break;
    case 'MDEUDIUDIDEVICE':
      url = url.concat('/mdd/udi-dis');
      break;
    case 'ADEUDIBASICDEVICE':
      url = url.concat('/aimdd/basic-udi-dis');
      break;
    case 'ADEUDIUDIDEVICE':
      url = url.concat('/aimdd/udi-dis');
      break;
    case 'IVDEUDIBASICDEVICE':
      url = url.concat('/ivd/basic-udi-dis');
      break;
    case 'IVDEUDIUDIDEVICE':
      url = url.concat('/ivd/udi-dis');
      break;
    case 'LINKMDD':
      url = url.concat('/mdd/udi-dis/link');
      break;
    case 'LINKAIMDD':
      url = url.concat('/aimdd/udi-dis/link');
      break;
    case 'LINKIVD':
      url = url.concat('/ivd/udi-dis/link');
      break;
    case 'REGISTERMDD':
      url = url.concat('/mdd/udi-dis/registration');
      break;
    case 'REGISTERAIMDD':
      url = url.concat('/aimdd/udi-dis/registration');
      break;
    case 'REGISTERIVD':
      url = url.concat('/ivd/udi-dis/registration');
      break;
  }

  if (isCollection) {
    url = url.concat('/collection');
  }

  return url;
};

  export const determineUploadUrl = (deviceType: string, fileUid: string) => {
    let url = '/udimanager/v1/excel/devices';

    switch (deviceType) {
      case 'MDRBASICDEVICE':
        url = url.concat('/mdr/basic-udi-dis');
        break;
      case 'MDRUDIDEVICE':
        url = url.concat('/mdr/udi-dis');
        break;
      case 'SPPBASICDEVICE':
        url = url.concat('/spp/basic-udi-dis');
        break;
      case 'SPPUDIDEVICE':
        url = url.concat('/spp/udi-dis');
        break;
      case 'IVDRBASICDEVICE':
        url = url.concat('/ivdr/basic-udi-dis');
        break;
      case 'IVDRUDIDEVICE':
        url = url.concat('/ivdr/udi-dis');
        break;
      case 'MDEUDIBASICDEVICE':
        url = url.concat('/mdd/basic-udi-dis');
        break;
      case 'MDEUDIUDIDEVICE':
        url = url.concat('/mdd/udi-dis');
        break;
      case 'ADEUDIBASICDEVICE':
        url = url.concat('/aimdd/basic-udi-dis');
        break;
      case 'ADEUDIUDIDEVICE':
        url = url.concat('/aimdd/udi-dis');
        break;
      case 'IVDEUDIBASICDEVICE':
        url = url.concat('/ivd/basic-udi-dis');
        break;
      case 'IVDEUDIUDIDEVICE':
        url = url.concat('/ivd/udi-dis');
        break;
      case 'PACKLINKMDR':
        url = url.concat('/mdr/package-udi-dis');
        break;
      case 'PACKLINKSPP':
        url = url.concat('/spp/package-udi-dis');
        break;
      case 'PACKLINKIVDR':
        url = url.concat('/ivdr/package-udi-dis');
        break;
      case 'LINKMDR':
        url = url.concat('/link/mdr');
        break;
      case 'LINKIVDR':
        url = url.concat('/link/ivdr');
        break;
      case 'LINKSPP':
        url = url.concat('/link/spp');
        break;
      case 'LINKMDD':
        url = url.concat('/link/mdd');
        break;
      case 'LINKAIMDD':
        url = url.concat('/link/aimdd');
        break;
      case 'LINKIVD':
        url = url.concat('/link/ivd');
        break;
      case 'REGISTERMDR':
        url = url.concat('/registration/mdr');
        break;
      case 'REGISTERIVDR':
        url = url.concat('/registration/ivdr');
        break;
      case 'REGISTERSPP':
        url = url.concat('/registration/spp');
        break;
      case 'REGISTERMDD':
        url = url.concat('/registration/mdd');
        break;
      case 'REGISTERAIMDD':
        url = url.concat('/registration/aimdd');
        break;
      case 'REGISTERIVD':
        url = url.concat('/registration/ivd');
        break;
      case 'REGISTERBASICMDR':
        url = url.concat('/registration/mdr/basic-udi');
        break;
      case 'REGISTERBASICSPP':
        url = url.concat('/registration/spp/basic-udi');
        break;
      case 'REGISTERBASICIVDR':
        url = url.concat('/registration/ivdr/basic-udi');
        break;
      case 'REGISTERBASICMDD':
        url = url.concat('/registration/mdd/basic-udi');
        break;
      case 'REGISTERBASICAIMDD':
        url = url.concat('/registration/aimdd/basic-udi');
        break;
      case 'REGISTERBASICIVD':
        url = url.concat('/registration/ivd/basic-udi');
        break;
    }

    return url.concat('/' + fileUid);
  };

