import DeviceFieldToStringUtil from '../util/device-field-to-string.util';
import DeviceColumn from './device-column.enum';

export default class Device {
    fullPayload: any;
    [DeviceColumn.SRN]: string;
    [DeviceColumn.Name]: string;
    [DeviceColumn.Type]: string;
    [DeviceColumn.Status]: string;
    [DeviceColumn.RiskClass]: string;
    [DeviceColumn.PublicID]: string;
    [DeviceColumn.InternalID]: string;
    [DeviceColumn.LastMessaged]: string;
    [DeviceColumn.LastMessageID]: string;

    constructor(fullDevice: Partial<Device>) {
        this.fullPayload = fullDevice;
        this[DeviceColumn.SRN] = DeviceFieldToStringUtil[DeviceColumn.SRN](fullDevice);
        this[DeviceColumn.Name] = DeviceFieldToStringUtil[DeviceColumn.Name](fullDevice);
        this[DeviceColumn.Type] = DeviceFieldToStringUtil[DeviceColumn.Type](fullDevice);
        this[DeviceColumn.Status] = DeviceFieldToStringUtil[DeviceColumn.Status](fullDevice);
        this[DeviceColumn.RiskClass] = DeviceFieldToStringUtil[DeviceColumn.RiskClass](fullDevice);
        this[DeviceColumn.PublicID] = DeviceFieldToStringUtil[DeviceColumn.PublicID](fullDevice);
        this[DeviceColumn.InternalID] = DeviceFieldToStringUtil[DeviceColumn.InternalID](fullDevice);
        this[DeviceColumn.LastMessageID] = DeviceFieldToStringUtil[DeviceColumn.LastMessageID](fullDevice);
        this[DeviceColumn.LastMessaged] = DeviceFieldToStringUtil[DeviceColumn.LastMessaged](fullDevice);
    }

    getKeyAsString(): string {
      return this[DeviceColumn.PublicID] + this[DeviceColumn.Type] + this.getIssuingEntity();
    }

    getIssuingEntity(): string{
        const dataDeviceType = this[DeviceColumn.Type].toUpperCase().includes('BASIC') ? 'BASIC' : 'UDI';
        if (dataDeviceType === 'BASIC') {
            return this.fullPayload.basicUDIIdentifierIssuingEntityCode;
        } else {
            return this.fullPayload.UDIDIIdentifierIssuingEntityCode;
        }
    }

    equals(object: Device): boolean {
      return this[DeviceColumn.PublicID] === object[DeviceColumn.PublicID] &&
             this[DeviceColumn.Type] === object[DeviceColumn.Type] &&
             this.getIssuingEntity() === object.getIssuingEntity();
    }
}

