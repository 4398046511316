import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {KeycloakService} from 'keycloak-angular';

@Directive({ selector: '[eudRole]' })
export class EudRoleDirective{

    private hasView = false;

    constructor(private template: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private keycloak: KeycloakService
               ) {}


    @Input()
    set eudRole(roles: string[]) {
        const isInAllRoles = roles.every((role) => {
            return this.keycloak.isUserInRole(role);
        });

        if (isInAllRoles && !this.hasView) {
            setTimeout(() => {
                this.viewContainer.createEmbeddedView(this.template);
                this.hasView = true;
            });
        } else if (!isInAllRoles && this.hasView) {
            setTimeout(() => {
                this.viewContainer.clear();
                this.hasView = false;
            });
        }
    }

}
