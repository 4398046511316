import {Component} from '@angular/core';
import {faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import {Filter} from 'src/app/core/model/filter.model';
import {BaseDeviceFilterComponent} from '../device-filter.component';
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";

@Component({
    selector: 'device-filter-mobile',
    templateUrl: './device-filter-mobile.component.html',
    styleUrls: ['./device-filter-mobile.component.scss']
})
export class DeviceFilterMobileComponent extends BaseDeviceFilterComponent {
    faTimes = faTimes;
    faTrash = faTrashAlt;
    faPlus = faPlus;

    hideFilters(): void {
        this.filters.forEach((filter) => {
            if (!filter.isApplied) {
                this.filterService.removeFilter(filter);
            }
        });
        this.filterService.toggleFiltering(false);
    }

    filterOnlyApplied(item: Filter): boolean{
        return item.isApplied;
    }
}
