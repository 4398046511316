import {Component} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseFormFieldType} from '../base-form-field-type.class';

@Component({
    selector: 'mdn-render',
    templateUrl: './mdn-render.component.html',
    styleUrls: ['./mdn-render.component.scss']
})
export class MdnRenderingComponent extends BaseFormFieldType {
    optionalClass = ['non-empty-input', 'label'];
    Validators = Validators;
    Infinity = Infinity;
}
