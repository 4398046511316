import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import FormBuildingInput, {NumericTypes} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {FormControlType} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-control-type.enum';

export function mapFromFormGroupToObject(formData: AbstractControl, formBuildingInput: FormBuildingInput ): any {

    if (formData instanceof FormControl){
        let objectOfCurrentField: any = '';
        if (formData.value === null || formData.value === '' || (Array.isArray(formData.value) && formData.value.length === 0)) {
            return;
        }

        if (formBuildingInput.controlType === FormControlType.BASIC_ASYNC_SELECT){
            return formData.value;
        }else if (formBuildingInput.controlType === FormControlType.DATETIME){
            var date = new Date(formData.value);
            // const date: Date = formData.value;
            objectOfCurrentField = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}T${(date.getHours()).toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}Z`;
        } else if (formData.value instanceof Date) {
            var date = new Date(formData.value);
            // const date = formData.value;
            objectOfCurrentField = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}`;
        } else if (formBuildingInput.blankSeparator) {
            let blankList = '';
            // @ts-ignore
            formData.value.forEach(vl => {
                // @ts-ignore
                blankList += vl + ' ';
            });
            // @ts-ignore
            objectOfCurrentField = blankList.substring(0, blankList.length - 1);
        } else if (formBuildingInput.controlType === FormControlType.INPUT) {
            if (formBuildingInput.numericType !== undefined){
                if (formBuildingInput.numericType === NumericTypes.INTEGER){
                    objectOfCurrentField = parseInt(formData.value, 10);
                }else if (formBuildingInput.numericType === NumericTypes.DECIMAL){
                    objectOfCurrentField = parseFloat(formData.value);
                } else {
                    objectOfCurrentField = formData.value;
                }
            }else{
                objectOfCurrentField = formData.value;
            }
            // objectOfCurrentField = formData.value;
        }else{
            objectOfCurrentField = formData.value;
        }

        return objectOfCurrentField;
    } else if (formData instanceof FormArray) {

        // if (!formData.touched){
            // return;
        // }
        if (formData.controls){
            const objectOfCurrentField: any = [];
            formData.controls.forEach((childInArrayObject: AbstractControl) => {
                if (formBuildingInput.children) {
                    const repeatableObject: {[key: string]: any} = {};
                    formBuildingInput.children.forEach((formChild: FormBuildingInput) => {
                        const formFieldOfChild = childInArrayObject.get(formChild.propertyName);
                        if (formFieldOfChild){
                            const mappedField = mapFromFormGroupToObject(formFieldOfChild, formChild);
                            if (mappedField !== '' && mappedField !== null && mappedField !== undefined){
                               repeatableObject[formChild.propertyName] = mappedField;
                            }
                        }
                    });
                    if (Object.keys(repeatableObject).length !== 0){
                        objectOfCurrentField.push(repeatableObject);
                    }
                }
            });
            return objectOfCurrentField.length === 0 ? null : objectOfCurrentField;
        }
    } else if (formData instanceof FormGroup){
        const objectOfCurrentField: {[key: string]: any} = {};
        if (formBuildingInput.children){
            formBuildingInput.children.forEach((formChild: FormBuildingInput) => {
                const childFormData = formData.get(formChild.propertyName);
                if (childFormData){
                    const mappedField = mapFromFormGroupToObject(childFormData , formChild);
                    if (mappedField !== '' && mappedField !== null && mappedField !== undefined){
                        objectOfCurrentField[formChild.propertyName] = mappedField;
                    }
                }
            });
            if (Object.keys(objectOfCurrentField).length === 0){
                return;
            }
            return objectOfCurrentField;
        }
    }
}
