import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(array: any[], filterFunction: (item: any) => boolean): any[]{
        if (array && Array.isArray(array)) {
            return array.filter(filterFunction);
        }
        return [];
    }
}
