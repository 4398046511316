import {DeviceConstants} from '.';
import { BUDIGroups } from './attribute-groups.enum';
import {parseSimpleField, parseComplexField, parseArrayField, parseSimpleArray} from '../../util/device-to-details.util';
import FormBuildingInput from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {FormGroup, Validators} from '@angular/forms';
import {FormControlType} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-control-type.enum';
import {actorCodePattern, optionalWithRequired} from './validators/complex-validators.util';


export const IVDBASICDEVICE: Array<DeviceConstants & FormBuildingInput > = [
    {
        propertyName: 'deviceType',
        name: 'DEVICECONSTANTS.ivd.basic.deviceType.name',
        description: 'DEVICECONSTANTS.ivd.basic.deviceType.description',
        parsingFunction: parseSimpleField,
        validators: [Validators.required],
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        placeholder: 'FLD-ATRIFY-01',
        group: BUDIGroups.KEYDATA,
        hidden: true
    },
    {
        propertyName: 'basicUDIIdentifierDICode',
        name: 'DEVICECONSTANTS.ivd.basic.basicUDIIdentifierDICode.name',
        description: 'DEVICECONSTANTS.ivd.basic.basicUDIIdentifierDICode.description',
        controlType: FormControlType.INPUT,
        validators: [Validators.required, Validators.minLength(1), Validators.maxLength(120)],
        placeholder: 'FLD-UDID-14',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        hidden: true,
        style: {
            marginBottom: '48px'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea120'
        }
    },
    {
        propertyName: 'basicUDIIdentifierIssuingEntityCode',
        name: 'DEVICECONSTANTS.ivd.basic.basicUDIIdentifierIssuingEntityCode.name',
        description: 'DEVICECONSTANTS.ivd.basic.basicUDIIdentifierIssuingEntityCode.description',
        parsingFunction: parseSimpleField,
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.DROPDOWN,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-01',
        group: BUDIGroups.KEYDATA,
        hidden: true
    },
    {
        propertyName: 'riskClass',
        name: 'DEVICECONSTANTS.ivd.basic.riskClass.name',
        description: 'DEVICECONSTANTS.ivd.basic.riskClass.description',
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-16',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA
    },
    {
        propertyName: 'model',
        name: 'DEVICECONSTANTS.ivd.basic.model.name',
        description: 'DEVICECONSTANTS.ivd.basic.model.description',
        updateable: true,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-20',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        hidden: (form: FormGroup) => {
            if (form.get('modelName')?.get('modelNameTerm')?.value == null || form.get('modelName')?.get('modelNameTerm')?.value === ''){
                return true;
            }else{
                return false;
            }
        },
        style: {
            width: '50%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea255'
        },
        validators: [Validators.minLength(1), Validators.maxLength(255)],
    },
    {
        propertyName: 'modelName',
        name: 'DEVICECONSTANTS.ivd.basic.modelName.name',
        parsingFunction: parseComplexField,
        controlType: FormControlType.NON_SHOWING,
        validators: [optionalWithRequired(['modelNameTerm'])],
        placeholder: 'FLD-UDID-01',
        group: BUDIGroups.KEYDATA,
        children: [
            {
                propertyName: 'modelNameModel',
                name: 'DEVICECONSTANTS.ivd.basic.modelNameModel.name',
                description: 'DEVICECONSTANTS.ivd.basic.modelNameModel.description',
                parsingFunction: parseSimpleField,
                updateable: true,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-20',
                group: BUDIGroups.KEYDATA,
                style: {
                    width: '47%',
                    'margin-right': '24px'
                },
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea255'
                },
                validators: [Validators.minLength(1), Validators.maxLength(255)],
            },
            {
                propertyName: 'modelNameTerm',
                name: 'DEVICECONSTANTS.ivd.basic.modelNameTerm.name',
                description: 'DEVICECONSTANTS.ivd.basic.modelNameTerm.description',
                updateable: true,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-22',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                style: {
                    width: '47%'
                },
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea255'
                },
                validators: [Validators.minLength(1), Validators.maxLength(255)],
            }
        ],
        hidden: (form: FormGroup) => {
            if (form.get('model')?.value == null || form.get('model')?.value === ''){
                return true;
            }else{
                return false;
            }
        }
    },
    {
        propertyName: 'deviceCertificateLink',
        name: 'DEVICECONSTANTS.ivd.basic.certificateLink.name',
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['deviceCertificateNumber', 'deviceCertificateNBActorCode', 'deviceCertificateType', 'deviceCertificateExpiryDate'])],
        placeholder: 'FLD-UDID-01',
        group: BUDIGroups.CERTIFICATE_INFORMATION,
        parsingFunction: parseArrayField,
        children: [
            {
                propertyName: 'deviceCertificateNumber',
                name: 'DEVICECONSTANTS.ivd.basic.certificateNumber.name',
                description: 'DEVICECONSTANTS.ivd.basic.certificateNumber.description',
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-61',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CERTIFICATE_INFORMATION,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'deviceCertificateRevisionNumber',
                name: 'DEVICECONSTANTS.ivd.basic.certificateRevisionNumber.name',
                description: 'DEVICECONSTANTS.ivd.basic.certificateRevisionNumber.description',
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-62',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CERTIFICATE_INFORMATION,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'deviceCertificateNBActorCode',
                name: 'DEVICECONSTANTS.ivd.basic.certificateNBActorCode.name',
                description: 'DEVICECONSTANTS.ivd.basic.certificateNBActorCode.decription',
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-63',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CERTIFICATE_INFORMATION
            },
            {
                propertyName: 'deviceCertificateType',
                name: 'DEVICECONSTANTS.ivd.basic.certificateType.name',
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-60',
                description: 'DEVICECONSTANTS.ivd.basic.certificateType.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CERTIFICATE_INFORMATION
            },
            {
                propertyName: 'deviceCertificateExpiryDate',
                name: 'DEVICECONSTANTS.ivd.basic.certificateExpiryDate.name',
                parsingFunction: parseSimpleField,
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.DATE,
                updateable: true,
                placeholder: 'FLD-UDID-64',
                description: 'DEVICECONSTANTS.ivd.basic.certificateExpiryDate.description',
                group: BUDIGroups.CERTIFICATE_INFORMATION
            }
        ]
    },
    {
        propertyName: 'ARActorCode',
        name: 'DEVICECONSTANTS.ivd.basic.ARActorCode.name',
        parsingFunction: parseSimpleField,
        controlType: FormControlType.INPUT,
        updateable: true,
        placeholder: 'FLD-UDID-15',
        validators: [Validators.pattern(actorCodePattern)],
        errorMessage: 'ERRORS.actorCode',
        description: 'DEVICECONSTANTS.ivd.basic.ARActorCode.description',
        group: BUDIGroups.KEYDATA,
        style: {
            width: '100%',
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'ARComments',
        name: 'DEVICECONSTANTS.ivd.basic.ARComments.name',
        parsingFunction: parseArrayField,
        description: '',
        validators: [optionalWithRequired(['ARCommentOwnerActorCode', 'ARCommentLanguage', 'ARCommentText'])],
        group: BUDIGroups.KEYDATA,
        controlType: FormControlType.REPEATABLE,
        placeholder: '',
        children: [
            {
                propertyName: 'ARCommentDate',
                name: 'DEVICECONSTANTS.ivd.basic.ARCommentDate.name',
                updateable: true,
                description: 'DEVICECONSTANTS.ivd.basic.ARCommentDate.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                controlType: FormControlType.DATETIME,
                placeholder: 'FLD-UDID-332',
            },
            {
                propertyName: 'ARCommentOwnerActorCode',
                name: 'DEVICECONSTANTS.ivd.basic.ARCommentOwnerActorCode.name',
                description: 'DEVICECONSTANTS.ivd.basic.ARCommentOwnerActorCode.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-43',
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'ARCommentLanguage',
                name: 'DEVICECONSTANTS.ivd.basic.ARCommentLanguage.name',
                description: 'DEVICECONSTANTS.ivd.basic.ARCommentLanguage.description',
                errorMessage: 'ERRORS.notFilled',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.KEYDATA,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-010',
            },
            {
                propertyName: 'ARCommentText',
                name: 'DEVICECONSTANTS.ivd.basic.ARCommentText.name',
                description: 'DEVICECONSTANTS.ivd.basic.ARCommentText.description',
                parsingFunction: parseSimpleField,
                updateable: true,
                group: BUDIGroups.KEYDATA,
                textareaRows: 6,
                controlType: FormControlType.TEXTAREA,
                placeholder: 'FLD-UDID-41',
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            }
        ]
    },
    {
        propertyName: 'MFActorCode',
        name: 'DEVICECONSTANTS.ivd.basic.MFActorCode.name',
        description: 'DEVICECONSTANTS.ivd.basic.MFActorCode.description',
        errorMessage: {
            required: 'ERRORS.notFilled',
            pattern: 'ERRORS.actorCode'
        },
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-10',
        validators: [Validators.required, Validators.pattern(actorCodePattern)],
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        hidden: true,
        style: {
            width: '50%'
        }
    },
    {
        propertyName: 'applicableLegislation',
        name: 'DEVICECONSTANTS.ivd.basic.applicableLegislation.name',
        description: 'DEVICECONSTANTS.ivd.basic.applicableLegislation.description',
        errorMessage: 'ERRORS.notFilled',
        validators: [Validators.required],
        controlType: FormControlType.DROPDOWN,
        placeholder: 'FLD-UDID-11',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA,
        hidden: true
    },
    {
        propertyName: 'animalTissuesCells',
        name: 'DEVICECONSTANTS.ivd.basic.animalTissuesCells.name',
        description: 'DEVICECONSTANTS.ivd.basic.animalTissuesCells.description',
        parsingFunction: parseSimpleField,
        validators: [Validators.required],
        errorMessage: 'ERRORS.notFilled',
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-18',
        group: BUDIGroups.MODELDATA
    },
    {
        propertyName: 'humanTissuesCells',
        name: 'DEVICECONSTANTS.ivd.basic.humanTissuesCells.name',
        description: 'DEVICECONSTANTS.ivd.basic.humanTissuesCells.description',
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.CHECKBOX,
        initialValue: null,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-23',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA
    },
    {
        propertyName: 'clinicalInvestigationLink',
        name: 'DEVICECONSTANTS.ivd.basic.clinicalInvestigationLink.name',
        parsingFunction: parseSimpleArray,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['clinicalInvestigationReference'])],
        group: BUDIGroups.CLINICAL_INVESTIGATION,
        children: [
            {
                propertyName: 'clinicalInvestigationReference',
                name: 'DEVICECONSTANTS.ivd.basic.clinicalInvestigationReference.name',
                description: 'DEVICECONSTANTS.ivd.basic.clinicalInvestigationReference.description',
                updateable: true,
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-51',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CLINICAL_INVESTIGATION,
                style: {
                    width: '100%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(255)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.tooLong255'
                }
            },
            {
                propertyName: 'clinicalInvestigationNonEUApplicableCountries',
                name: 'DEVICECONSTANTS.ivd.basic.clinicalInvestigationNonEUApplicableCountries.name',
                controlType: FormControlType.SEARCHABLE_MULTISELECT,
                blankSeparator: true,
                placeholder: 'FLD-UDID-300',
                updateable: true,
                description: 'DEVICECONSTANTS.ivd.basic.clinicalInvestigationNonEUApplicableCountries.description',
                parsingFunction: parseSimpleField,
                group: BUDIGroups.CLINICAL_INVESTIGATION
            }
        ]
    },
    {
        propertyName: 'specialDevice',
        name: 'DEVICECONSTANTS.ivd.basic.specialDevice.name',
        description: 'DEVICECONSTANTS.ivd.basic.specialDevice.description',
        controlType: FormControlType.DROPDOWN,
        updateable: true,
        placeholder: 'FLD-UDID-13',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.KEYDATA
    },
    {
        propertyName: 'companionDiagnostics',
        name: 'DEVICECONSTANTS.ivd.basic.companionDiagnostics.name',
        description: 'DEVICECONSTANTS.ivd.basic.companionDiagnostics.description',
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.CHECKBOX,
        initialValue: null,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-33',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA
    },
    {
        propertyName: 'instrument',
        name: 'DEVICECONSTANTS.ivd.basic.instrument.name',
        description: 'DEVICECONSTANTS.ivd.basic.instrument.description',
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.CHECKBOX,
        initialValue: null,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-264',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA
    },
    {
        propertyName: 'kit',
        name: 'DEVICECONSTANTS.ivd.basic.kit.name',
        description: 'DEVICECONSTANTS.ivd.basic.kit.description',
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.CHECKBOX,
        initialValue: null,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-356',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA
    },
    {
        propertyName: 'microbialSubstances',
        name: 'DEVICECONSTANTS.ivd.basic.microbialSubstances.name',
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.CHECKBOX,
        initialValue: null,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-34',
        description: 'DEVICECONSTANTS.ivd.basic.microbialSubstances.description',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA
    },
    {
        propertyName: 'nearPatientTesting',
        name: 'DEVICECONSTANTS.ivd.basic.nearPatientTesting.name',
        description: 'DEVICECONSTANTS.ivd.basic.nearPatientTesting.description',
        errorMessage: 'ERRORS.notFilled',
        initialValue: null,
        validators: [Validators.required],
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-35',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA
    },
    {
        propertyName: 'professionalTesting',
        name: 'DEVICECONSTANTS.ivd.basic.professionalTesting.name',
        description: 'DEVICECONSTANTS.ivd.basic.professionalTesting.description',
        parsingFunction: parseSimpleField,
        initialValue: null,
        validators: [Validators.required],
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-263',
        group: BUDIGroups.MODELDATA
    },
    {
        propertyName: 'reagent',
        name: 'DEVICECONSTANTS.ivd.basic.reagent.name',
        description: 'DEVICECONSTANTS.ivd.basic.reagent.description',
        parsingFunction: parseSimpleField,
        initialValue: null,
        validators: [Validators.required],
        errorMessage: 'ERRORS.notFilled',
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-262',
        group: BUDIGroups.MODELDATA
    },
    {
        propertyName: 'selfTesting',
        name: 'DEVICECONSTANTS.ivd.basic.selfTesting.name',
        description: 'DEVICECONSTANTS.ivd.basic.selfTesting.description',
        errorMessage: 'ERRORS.notFilled',
        initialValue: null,
        validators: [Validators.required],
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-36',
        parsingFunction: parseSimpleField,
        group: BUDIGroups.MODELDATA,
        style: {
            marginBottom: '48px'
        }
    },
];
