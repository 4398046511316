import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function optionalWithRequired(requiredFields: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control instanceof FormGroup){
            const isAnythingEntered = Object.values(control.controls).some((controlInOptional: AbstractControl) => {
                if (controlInOptional){
                    return checkIfAnythingEnteredAbstract(controlInOptional);
                }
                return false;
            });

            if (isAnythingEntered){
                requiredFields.forEach((required: string) => {
                    const requiredControl = control.get(required);
                    if (requiredControl){
                        invalidateAbstractControl(requiredControl);
                    }
                });
            } else {
                requiredFields.forEach((required: string) => {
                    const requiredControl = control.get(required);
                    if (requiredControl){
                        clearErrorsAbstract(requiredControl);
                    }
                });
            }
        }
        return null;
    };
}

function checkIfAnythingEnteredAbstract(abstractControl: AbstractControl): boolean {
    if (abstractControl instanceof FormControl){
        return checkIfAnythingEnteredControl(abstractControl);
    } else if (abstractControl instanceof FormGroup){
        return checkIfAnythingEnteredGroup(abstractControl);
    } else if (abstractControl instanceof FormArray) {
        return checkIfAnythingEnteredArray(abstractControl);
    } else {
        return false;
    }
}

function checkIfAnythingEnteredControl(formControl: FormControl): boolean {
    if (formControl.value === undefined || formControl.value === null || formControl.value === ''){
        return false;
    }
    return true;
}

function checkIfAnythingEnteredArray(formArray: FormArray): boolean {
    return formArray.controls.some((singleAbstract: AbstractControl) => {
        return checkIfAnythingEnteredAbstract(singleAbstract);
    });
}

function checkIfAnythingEnteredGroup(formGroup: FormGroup): boolean {
    return Object.values(formGroup.controls).some((singleAbstract: AbstractControl) => {
        return checkIfAnythingEnteredAbstract(singleAbstract);
    });
}

function invalidateAbstractControl(abstractControl: AbstractControl): void {
    if (abstractControl instanceof FormControl){
        return invalidateFormControl(abstractControl);
    } else if (abstractControl instanceof FormGroup){
        return invalidateFormGroup(abstractControl);
    } else if (abstractControl instanceof FormArray) {
        return invalidateFormArray(abstractControl);
    } else {
        return;
    }
}

function invalidateFormControl(formControl: FormControl): void{
    if (formControl?.value === undefined || formControl?.value === null || formControl?.value === '' ){
        formControl?.setErrors({required: 'This field is required'});
    }
}

function invalidateFormGroup(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach((singleAbstract: AbstractControl) => {
        return invalidateAbstractControl(singleAbstract);
    });
}

function invalidateFormArray(formArray: FormArray): void {
    formArray.controls.forEach((singleAbstract: AbstractControl) => {
        return invalidateAbstractControl(singleAbstract);
    });
}



function clearErrorsAbstract(abstractControl: AbstractControl): void{
    if (abstractControl instanceof FormControl){
        return clearErrorsControl(abstractControl);
    } else if (abstractControl instanceof FormGroup){
        return clearErrorsGroup(abstractControl);
    } else if (abstractControl instanceof FormArray) {
        return clearErrorsArray(abstractControl);
    } else {
        return;
    }

}


function clearErrorsControl(formControl: FormControl): void{
    if (!formControl?.value){
        formControl?.setErrors(null);
    }
}

function clearErrorsGroup(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach((singleAbstract: AbstractControl) => {
        return clearErrorsAbstract(singleAbstract);
    });

}

function clearErrorsArray(formArray: FormArray): void {
    formArray.controls.forEach((singleAbstract: AbstractControl) => {
        return clearErrorsAbstract(singleAbstract);
    });
}

export const positiveIntegerPattern = /^[0-9]*[1-9][0-9]*$/;
export const integerPattern = /^([+-]?[1-9]\d*|0)$/;
export const decimalPattern = /^\d*\.?\d*$/;
export const actorCodePattern = '((AF|AX|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AT|AZ|BH|BS|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BQ|BA|BW|BV|BR|IO|BN|BG|BF|BI|KH|CM|CA|CV|KY|CF|TD|CL|CN|CX|CC|CO|KM|CG|CD|CK|CR|CI|HR|CU|CW|CY|CZ|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MA|MZ|MM|NA|NR|NP|NL|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|RE|RO|RU|RW|BL|SH|KN|LC|MF|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SX|SK|SI|SB|SO|ZA|GS|SS|ES|LK|SD|SR|SJ|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|UM|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW|XI)-(AR|MF|SP|IM|PR)-[0-9]{9})|NA';
export const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const cascodePattern = /^\d{1,7}-\d{2}-\d$/;
export const eccodePattern = /^\d{3}-\d{3}-\d$/;

export function optionalWithRequiredParticular(requiredFields: string[], field: any, value: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control instanceof FormGroup){
            const type = control.parent?.parent?.get(field)?.value;
            if (type === value){
                requiredFields.forEach((required: string) => {
                    const requiredControl = control.get(required);
                    if (requiredControl){
                        invalidateAbstractControl(requiredControl);
                    }
                });
            }else{
                requiredFields.forEach((required: string) => {
                    const requiredControl = control.get(required);
                    if (requiredControl){
                        clearErrorsAbstract(requiredControl);
                    }
                });

                const isAnythingEntered = Object.values(control.controls).some((controlInOptional: AbstractControl) => {
                    if (controlInOptional){
                        return checkIfAnythingEnteredAbstract(controlInOptional);
                    }
                    return false;
                });

                if (isAnythingEntered){
                    requiredFields.forEach((required: string) => {
                        const requiredControl = control.get(required);
                        // remove required error from field storageHandlingConditionCommentText if there is any kind of asterisk in storage type field
                        if (requiredControl){
                            if (requiredControl.asterisk){
                                invalidateAbstractControl(requiredControl);
                            }else{
                                clearErrorsAbstract(requiredControl);
                            }
                        }
                    });
                } else {
                    requiredFields.forEach((required: string) => {
                        const requiredControl = control.get(required);
                        if (requiredControl){
                            clearErrorsAbstract(requiredControl);
                        }
                    });
                }
            }
        }
        return null;
    };
}
