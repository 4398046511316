import { AfterViewInit, Component } from '@angular/core';
import {BaseLayoutComponent} from '../base-layout.component';

@Component({
    selector: 'layout-browser',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends BaseLayoutComponent implements AfterViewInit{

    ngAfterViewInit(): void {
        if (this.sider){
            this.sider.elementRef.nativeElement.addEventListener('mouseenter', () => {
                this.isMouseInsideTheMenu = true;
                setTimeout(() => {
                    if (this.isMouseInsideTheMenu){
                        this.isCollapsed = false;
                    }
                }, 400);
            });

            this.sider.elementRef.nativeElement.addEventListener('mouseleave', () => {
                this.isMouseInsideTheMenu = false;
                this.isCollapsed = true;
            });
        }
    }
}

