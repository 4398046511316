<ng-container [ngSwitch]="formBuildingInput.controlType" *ngIf="(getByName(formGroup, formBuildingInput.propertyName).hidden | typeof) === 'function' ? getByName(formGroup, formBuildingInput.propertyName).hidden(localFormGroup) : !getByName(formGroup, formBuildingInput.propertyName).hidden">
    <div style="width:100%;">
        <ng-container *ngSwitchCase="FormControlType.INPUT">
            <form-input-render
                [formBuildingInput]="formBuildingInput" [formGroup]="formGroup"></form-input-render>
        </ng-container>
        <ng-container *ngSwitchCase="FormControlType.NUMBER_INPUT">
            <number-input-render [formBuildingInput]="formBuildingInput" [formGroup]="formGroup"></number-input-render>
        </ng-container>
        <ng-container *ngSwitchCase="FormControlType.TEXTAREA">
            <textarea-render
                [formBuildingInput]="formBuildingInput" [formGroup]="formGroup"></textarea-render>
        </ng-container>
        <ng-container *ngSwitchCase="FormControlType.REPEATABLE">
            <repeatable-render
                [formBuildingInput]="formBuildingInput" 
                [formGroup]="formGroup" 
                [rootNested]="rootNested" 
                [editingObject]="editingObject"
                [lastVisitedPath]="(lastVisitedPath === '') ? formBuildingInput.propertyName : lastVisitedPath+'.'+formBuildingInput.propertyName" >
            </repeatable-render>
        </ng-container>
        <ng-container *ngSwitchCase="FormControlType.NON_SHOWING">
            <non-showing-render
                [formBuildingInput]="formBuildingInput"
                [formGroup]="formGroup"
                [lastVisitedPath]="(lastVisitedPath === '') ? formBuildingInput.propertyName : lastVisitedPath+'.'+formBuildingInput.propertyName"
                ></non-showing-render>
        </ng-container>
        <ng-container *ngSwitchCase="FormControlType.CHECKBOX">
            <radio-render
                [formBuildingInput]="formBuildingInput"
                [formGroup]="formGroup"
                [updating]="updating"
                >
            </radio-render>
        </ng-container>
        <ng-container *ngSwitchCase="FormControlType.DATE">
            <date-render
                [formBuildingInput]="formBuildingInput"
                [formGroup]="formGroup"
                >
            </date-render>
        </ng-container>
        <ng-container *ngSwitchCase="FormControlType.DATETIME">
            <datetime-render
                [formBuildingInput]="formBuildingInput"
                [formGroup]="formGroup"
                >
            </datetime-render>
        </ng-container>
        <ng-container *ngSwitchCase="FormControlType.DROPDOWN">
            <dropdown-render
                [formBuildingInput]="formBuildingInput"
                [formGroup]="formGroup"
                [editingObject]="editingObject"
                [errorForm]="errorForm"
                >
            </dropdown-render>
        </ng-container>
        <ng-container *ngSwitchCase="FormControlType.SEARCHABLE_DROPDOWN">
            <searchable-dropdown-render
                [formBuildingInput]="formBuildingInput"
                [formGroup]="formGroup"
                [editingObject]="editingObject"
                [errorForm]="errorForm"
                >
            </searchable-dropdown-render>
        </ng-container>
        <ng-container *ngSwitchCase="FormControlType.SEARCHABLE_MULTISELECT">
            <searchable-multiselect-render
                [formBuildingInput]="formBuildingInput"
                [formGroup]="formGroup"
                [editingObject]="editingObject"
                [errorForm]="errorForm"
                >
            </searchable-multiselect-render>
        </ng-container>
        <ng-container *ngSwitchCase="FormControlType.MDN_SELECT">
            <mdn-render
                [formBuildingInput]="formBuildingInput"
                [formGroup]="formGroup"
                >
            </mdn-render>
        </ng-container>

        <ng-container *ngSwitchCase="FormControlType.REPEATABLE_SELECT">
            <repeatable-select-render
                [formBuildingInput]="formBuildingInput"
                [formGroup]="formGroup"
                [editingObject]="editingObject"
                >
            </repeatable-select-render>
        </ng-container>

        <ng-container *ngSwitchCase="FormControlType.BASIC_ASYNC_SELECT">
            <basic-async-render
                [formBuildingInput]="formBuildingInput"
                [formGroup]="formGroup"
                [editingObject]="editingObject"
                >
            </basic-async-render>
        </ng-container>
    </div>
</ng-container>
