import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'typeof'
})
export class TypeofPipe implements PipeTransform {
  transform(obj: any): string {
    return typeof obj;
  }
}
