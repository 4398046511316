<nz-layout
  [class.sider-open]="!isCollapsed"
  style="height: 100%"
  class="app-layout"
>
  <div
    class="sider"
    [@openCloseSider]="!isCollapsed ? 'open' : 'closed'"
    (@openCloseSider.start)="$event.element.style.display = 'block'"
    (@openCloseSider.done)="
      $event.element.style.display =
        $event.toState === 'open' ? 'block' : 'none'
    "
  >
    <div style="height: 100%" nz-row>
      <div nzSpan="24" nz-col>
        <div style="height: 30%">
          <div nz-row style="margin-left: 20px; color: white">
            <fa-icon
              (click)="isCollapsed = true"
              style="font-size: 30px"
              [icon]="faTimes"
            >
            </fa-icon>
          </div>
          <div>
            <ul nz-menu nzMode="inline" class="list-of-links" nzType="dark">
              <!-- Menu links -->
              <li
                id="devicesMenuButton"
                (click)="isCollapsed = true"
                nz-menu-item
                [routerLink]="['/devices']"
                [nzMatchRouter]="true"
                class="menu-item"
              >
                <fa-icon
                  class="icon"
                  nz-icon
                  [icon]="faStethoscope"
                  [styles]="{ color: '#f2f2f2', width: '18px', height: '18px' }"
                ></fa-icon>
                <span>{{ "LAYOUT.title" | translate }}</span>
              </li>
              <li
                *ngIf="currentPlatform !== Platform.Phone"
                id="importDevicesMenuButton"
                (click)="isCollapsed = true"
                nz-menu-item
                [routerLink]="['/add-devices/excel']"
                nz-menu-item
                [nzMatchRouter]="true"
                class="menu-item"
              >
                <fa-icon
                  class="icon"
                  nz-icon
                  [icon]="faPlus"
                  [styles]="{
                    color: '#f2f2f2',
                    width: '18px',
                    height: '18px',
                    'margin-left': '0px'
                  }"
                ></fa-icon>
                <span>{{ "LAYOUT.import" | translate }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div
          style="
            height: 70%;
            color: white;
            display: flex;
            flex-direction: column-reverse;
          "
          *ngIf="!isCollapsed"
        >
          <div style="border-top: 1px solid #4b6b87">
            <div style="font-weight: 300; margin: 15px">
              <ng-template #self [ngTemplateOutlet]="self"
                           let-privacy="privacyLink" let-disclaimer="disclaimerLink"
                           [ngTemplateOutletContext]="{ privacyLink: ('LAYOUT.privacyLink' | translate), disclaimerLink : ('LAYOUT.disclaimerLink' | translate) }"
              >
                <span (click)="openInNewTab(disclaimer)" style="cursor:pointer;text-decoration: underline;">
                  {{ 'LAYOUT.disclaimer' | translate }}
                </span> |
                <span (click)="openFileInNewTab(privacy)" style="cursor:pointer;text-decoration: underline;">
                  {{ 'LAYOUT.privacy' | translate }}
                </span>
              </ng-template>
              <span style="display: block">{{getImpressum()}} </span>
            </div>
          </div>
          <ul nz-menu nzMode="inline" class="list-of-links" nzTheme="dark">
            <li
              nz-submenu
              style="border-top: 1px solid #4b6b87; color: white"
              [nzTitle]="firstAndLastName"
              class="menu-item"
            >
              <ul>
                <li
                  (click)="isCollapsed = true"
                  [nzMatchRouter]="true"
                  [routerLink]="['/account']"
                  class="menu-item"
                  nz-menu-item
                >
                  {{ "USER.account" | translate }}
                </li>
                <li
                  (click)="isCollapsed = true"
                  [nzMatchRouter]="true"
                  [routerLink]="['/settings']"
                  class="menu-item"
                  nz-menu-item
                >
                  {{ "USER.settings" | translate }}
                </li>
                <li
                  (click)="isCollapsed = true"
                  *eudRole="[Authority.ADMIN]"
                  [nzMatchRouter]="true"
                  [routerLink]="['/admin/users']"
                  class="menu-item"
                  nz-menu-item
                >
                  {{ "USER.admin" | translate }}
                </li>
                <li (click)="logout()" class="menu-item" nz-menu-item>
                  {{ "USER.logout" | translate }}
                </li>
              </ul>
            </li>
            <li
              nz-submenu
              style="border-top: 1px solid #4b6b87; color: white"
              nzTitle="{{
                'USER.language'
                  | translate
                    : {
                        currentLang:
                          translationService.currentLang.toUpperCase()
                      }
              }}"
              class="menu-item"
            >
              <ul>
                <li
                  [class]="
                    selectedLanguage == lang
                      ? 'ant-menu-item-selected menu-item'
                      : 'menu-item'
                  "
                  *ngFor="let lang of translationService.getLangs()"
                  nz-menu-item
                  (click)="changeLanguage(lang)"
                >
                  {{ lang | uppercase }}
                </li>
              </ul>
            </li>
            <li
              style="border-top: 1px solid #4b6b87"
              nz-menu-item
              (click)="
                openInNewTab('https://www.atrify.com/en/kundenbereich/#support')
              "
              class="menu-item"
            >
              <span>{{ "USER.help" | translate }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div (click)="isCollapsed = true" *ngIf="!isCollapsed" id="overlay"></div>
  <nz-layout>
    <nz-header
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--background2);
        height: 50px;
      "
      *ngIf="showHeader"
    >
      <fa-icon
        (click)="isCollapsed = !isCollapsed"
        nz-icon
        class="trigger"
        [icon]="faBars"
      >
      </fa-icon>
      <img
        (click)="goTo('/')"
        style="width: 200px; height: 30px"
        src="{{getSidebarLogo()}}"
      />
    </nz-header>
    <nz-content class="inner-content">
      <ng-content select="[content]"></ng-content>
      <app-cookie-banner
        *ngIf="!necessaryCookieExists"
        [modalOpen]="!necessaryCookieExists"
      ></app-cookie-banner>
    </nz-content>
  </nz-layout>
</nz-layout>
