export const returnCodelistStyle = (propertyName: string) => {
  switch (propertyName)   {
    case 'MDNCode':
      return { width: '100%' };
    case 'basicUDIIdentifierIssuingEntityCode':
      return { width: '50%' };
    case 'UDIDIIdentifierIssuingEntityCode':
      return { width: '50%' };
    case 'relatedUDILinkIssuingEntityCode':
      return { width: '100%', 'max-width': '448px' };
    case 'secondaryIdentifierIssuingEntityCode':
      return { width: '100%', height: '52px' };
    case 'childIdentifierIssuingEntityCode':
      return { width: '100%' };
    case 'directMarkingDIIssuingEntityCode':
      return { width: '100%', height: '52px' };
    case 'unitOfUseIdentifierIssuingEntityCode':
      return { width: '100%', height: '52px' };
    case 'packageIdentifierIssuingEntityCode':
      return { width: '100%' };
    case 'applicableLegislation':
      return { width: '100%' };
    case 'deviceStatusCode':
      return { width: '100%' };
    case 'typeOfSystemOrProcedurePack':
      return { width: '100%' };
    case 'substatusCode':
      return { width: '100%' };
    case 'specialDevice':
      return { width: '100%', marginBottom: '48px' };
    case 'productionIdentifier':
      return { width: '100%' };
    case 'substatusRecallScope':
      return { width: '100%' };
    case 'multicomponentDeviceTypeCode':
      return { width: '100%' };
    case 'packageStatusCode':
      return { width: '100%' };
    case 'relatedUDILinkType':
      return { width: '100%' };
    case 'medicalHumanProductSubstanceType':
      return { width: '100%' };
    case 'clinicalSizePrecision':
      return { width: '100%' };
    case 'CMRSubstanceCategory':
      return { width: '100%' };
    case 'marketInfoPlacingOnTheEUCountry':
      return { width: '100%', 'max-width': '448px' };
    case 'marketInfoMadeAvailableCountry':
      return { width: '100%' };
    case 'clinicalInvestigationNonEUApplicableCountries':
      return { width: '100%' };
    case 'deviceType':
      return { width: '100%' };
    case 'additionalProductDescriptionLanguage':
      return { width: '100%' };
    case 'riskClass':
      return { width: '50%', marginBottom: '18px' };
    case 'ARCommentLanguage':
      return { width: '50%' };
    case 'tradeNameLanguage':
      return { width: '50%' };
    case 'medicinalPurposeLanguage':
      return { width: '50%' };
    case 'deviceCertificateType':
      return { width: '100%' };
    case 'storageHandlingConditionCommentLanguage':
      return { width: '100%' };
    case 'storageHandlingConditionType':
      return { width: '100%' };
    case 'criticalWarningCommentLanguage':
      return { width: '100%' };
    case 'criticalWarningValue':
      return { width: '100%' };
    case 'substatusRecalledPrecisionLanguage':
      return { width: '100%' };
    case 'annexXVINonMedicalDeviceType':
      return { width: '100%' };
    case 'productDesignerOrganisationCountry':
      return { width: '100%', 'max-width': '448px' };
    case 'productDesignerOrganisationNameLanguage':
      return { width: '100%', 'max-width': '448px' };
    case 'medicalHumanProductSubstanceNameLanguage':
      return { width: '100%' };
    case 'clinicalSizeType':
      return { width: '100%' };
    case 'clinicalSizeValueUnitOfMeasure':
      return { width: '100%' };
    case 'clinicalSizeRangeValueUnitOfMeasure':
      return { width: '100%' };
    case 'clinicalSizeTypeDescriptionLanguage':
      return { width: '100%' };
    case 'clinicalSizeValueUnitOfMeasureDescriptionLanguage':
      return { width: '100%' };
    case 'clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage':
      return { width: '100%' };
    case 'CMRSubstanceNameLanguage':
      return { width: '100%' };
    case 'endocrineSubstanceNameLanguage':
      return { width: '100%' };
  }
  return {};
};
