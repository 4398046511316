<ng-container [formGroup]="formGroup">
    <nz-form-item [style]="formBuildingInput.style? formBuildingInput.style : 'flex-basis:100%;'">
        <nz-form-control [nzHasFeedback]="formBuildingInput.validators && formBuildingInput.validators.includes(Validators.required)"
            [nzErrorTip]="(formBuildingInput.errorMessage | typeof) == 'string' ? (formBuildingInput.errorMessage | translate) : errorHandlingTemplate">
            <nz-input-group>
                <eudamed-input-wrapper [optionalClass]="optionalClass" [label]="(formBuildingInput.name | translate) + ((formBuildingInput.validators && formBuildingInput.validators.includes(Validators.required)) ? '*' : '')">
                    <input
                    nzPopoverTrigger="focus"
                    nzPopoverPlacement="right"
                    nz-popover
                    [nzPopoverContent]="popoverContent"
                    [nzPopoverOverlayStyle]="{maxWidth:'200px'}"
                    nzPopoverColor="white"
                    nz-input
                    [formControlName]="formBuildingInput.propertyName"
                    [placeholder]="formBuildingInput.placeholder || ''  | translate"/>
                </eudamed-input-wrapper>
            </nz-input-group>
        </nz-form-control>
    </nz-form-item>
</ng-container>

<ng-template #popoverContent>
    <div style="display:block;">
        <span style="color:white;">
            {{formBuildingInput.description || '' | translate}}
        </span>
    </div>
</ng-template>

<ng-template #errorHandlingTemplate let-formControl>
  <div *ngFor="let error of (formControl.errors | keyvalue)">
    <span>
      {{ error.key | handleErrorTranslationPipe : formControl }}
    </span>
  </div>
</ng-template>
