import {HttpParams, HttpResponse} from '@angular/common/http';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {of, Subject} from 'rxjs';
import {catchError, debounceTime, map, switchMap} from 'rxjs/operators';
import {ApiService} from 'src/app/core/service/api.service';
import {DeviceTypeToLegislation} from 'src/app/core/util/device-type-to-legislation.util';
import {SearchDescription} from 'src/app/pages/add-devices/manual-add-devices/manual-device.component';


interface BasicOption{
    basicUDIIdentifierDICode: string;
    basicUDIIdentifierIssuingEntityCode: string;
}

@Component({
    selector: 'app-eudamed-basic-select',
    templateUrl: './eudamed-basic-select.component.html',
    styleUrls: ['./eudamed-basic-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EudamedBasicSelectComponent),
                multi: true
        }
    ]
})
export class EudamedBasicSelectComponent implements OnInit, ControlValueAccessor {
    @Input()
    editingDevice: any;
    basicObject: {basicUDIIdentifierDICode: string, basicUDIIdentifierIssuingEntityCode: string};

    maxPagesOfBudis: number;
    currentBasicUdiScrollPage = 0;

    @Input()
    label: string;

    @Input()
    placeholder: string;

    currentSearching = '';
    basicUdiListLoading = false;
    basicUdiList: any[] = [];
    searchNotifier = new Subject<SearchDescription>();

    constructor(private apiService: ApiService){}

    onChangeCallback = (value: any) => {};

    ngOnInit(): void {
        const basicUdiList$ = this.searchNotifier
            .asObservable()
            .pipe(debounceTime(500))
            .pipe(switchMap((searchDescription: SearchDescription) => {
                if (searchDescription.loadingMore === false) {
                    this.currentBasicUdiScrollPage = 0;
                }
                return this.searchOnServer(searchDescription);
            }));

        basicUdiList$.subscribe((response: any) => {
            const listOfOptions: Array<BasicOption> = response.responseData.map((singleBudi: any) => {
                return {
                    basicUDIIdentifierDICode: singleBudi.basicUDIIdentifierDICode,
                    basicUDIIdentifierIssuingEntityCode: singleBudi.basicUDIIdentifierIssuingEntityCode
                };
            });

            // console.log('---------------');

            if (response.loadingMore) {
                this.basicUdiList.push(...listOfOptions);
            } else {
                this.basicUdiList = listOfOptions;
            }
            this.basicUdiListLoading = false;
            });

    }

    basicComparison(firstBasic: BasicOption, secondBasic: BasicOption): boolean {
        if (firstBasic?.basicUDIIdentifierDICode === secondBasic?.basicUDIIdentifierDICode){
            if (firstBasic.basicUDIIdentifierIssuingEntityCode === secondBasic?.basicUDIIdentifierIssuingEntityCode){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }


    modelChanged(basicSelected: {basicUDIIdentifierDICode: string, basicUDIIdentifierIssuingEntityCode: string}): void{
        this.value = basicSelected;
    }

    searchOnServer(searchDescription: SearchDescription): any{
        this.currentSearching = searchDescription.searchValue;
        let params: HttpParams = new HttpParams()
                        .set('page', (this.currentBasicUdiScrollPage).toString())
                        .set('perPage', '20')
                        .set('devicetype', 'BASIC')
                        .set('actorcode', this.editingDevice.MFActorCode || this.editingDevice.PRActorCode);

        if (searchDescription.searchValue){
            params = params.set('dicode', searchDescription.searchValue );
        }

        const legislationToQuery = DeviceTypeToLegislation[this.editingDevice.deviceType].toLowerCase();

        return this.apiService.get(`/eudamed/v1/devices/${legislationToQuery}/search`, {params, observe: 'response' as 'body'}).pipe(map((response: HttpResponse<any>) => {
            const totalNumberInHeader = response.headers?.get('x-total-count');

            if (totalNumberInHeader){
                this.maxPagesOfBudis = Math.ceil(parseInt(totalNumberInHeader, 10) / 20);
            }

            if (response.body) {
                return {responseData: response.body, loadingMore: searchDescription.loadingMore};
            } else {
                return {responseData: response, loadingMore: searchDescription.loadingMore};
            }
        })).pipe(catchError(() => {
            this.basicUdiListLoading = false;
            return of({ responseData: [], loadingMore: searchDescription.loadingMore });
        }));
    }

    onSearch(searchTerm: string): void{
        this.basicUdiListLoading = true;
        this.searchNotifier.next({searchValue: searchTerm, loadingMore: false});
    }

    loadMoreBasicUdis(): void {
        if (this.maxPagesOfBudis <= this.currentBasicUdiScrollPage + 1){
            return;
        } else {
            this.currentBasicUdiScrollPage = this.currentBasicUdiScrollPage + 1;
            this.searchNotifier.next({searchValue: this.currentSearching, loadingMore: true});
        }
    }


    set value(value: any) {
        this.onChangeCallback(value);
        this.basicObject = value;
    }


    writeValue(obj: any): void {
        if (obj) {
            this.basicObject = obj;
            // console.log(obj);
            this.onSearch(obj?.basicUDIIdentifierDICode || '');
        }
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
    }
}
