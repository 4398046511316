import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {NotificationService} from './notification.service';

@Component({
    selector: 'notification-template',
    styles: ['::ng-deep .ant-notification-notice-close { display: none; }'],
    template: `
    <ng-template let-data="data">
        <div nz-row nzAlign="middle" [style]="data.style">
            <div nz-col nzSpan="24">
                <div nz-row>
                    <div nz-col >
                        <i
                            *ngIf="data.content.titleIcon"
                            nz-icon
                            [style]="data.content.iconStyle"
                            [nzType]="data.content.titleIcon"
                            [nzTheme]="'fill'">
                        </i>
                        <span [style]="data.content.titleStyle">{{data.content.title}}</span>
                    </div>
                </div>
            </div>
            <div nz-col nzSpan="24">
                <div nz-row>
                    <div nz-col [style]="data.content.contentStyle">
                        <span [style]="data.content.contentTextStyle">{{data.content.text}}</span>
                            <br/>
                            <div [style]="{ textAlign: 'right' }">
                                <button nz-button [nzSize]="button.size" (click)="button.onClick($event)" *ngFor="let button of data.buttons" [nzType]="button.type" [style]="button.style">{{button.text}}</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>`
})
export class NotificationTemplateComponent implements AfterViewInit {

    @ViewChild(TemplateRef, { static: false }) template: TemplateRef<{}>;

    constructor(private notificationService: NotificationService){}


    ngAfterViewInit(): void {
        this.notificationService.notificationTemplate = this.template;
    }

}
