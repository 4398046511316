import {ChangeDetectorRef, Component, forwardRef, Input, OnInit} from '@angular/core';
import {CodelistValue} from '../../../../core/constants/codelists.constants';
import {returnCodelistStyle} from '../../../../core/constants/codelist.style.constants';
import FormBuildingInput from '../eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {DeviceConstants} from '../../../../core/constants/device-attribute-details';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-eudamed-repeatable',
    templateUrl: './eudamed-repeatable.component.html',
    styleUrls: ['./eudamed-repeatable.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EudamedRepeatableSelectComponent),
                multi: true
        }
    ]
})
export class EudamedRepeatableSelectComponent implements OnInit, ControlValueAccessor {

    constructor() {}
    set value(value: any) {
        if (this.formControlInput.stringArray){
            this.onChangeCallback(value.split(' '));
        }else{
            this.onChangeCallback(value);
        }
        this.codesSelected = value;
    }

    @Input()
    formControlInput!: FormBuildingInput;

    @Input()
    codelists: CodelistValue[];

    Validators = Validators;

    codesSelected = '';
    codesArray: any[] = [''];

    faTrash = faTrashAlt;
    faPlus = faPlus;

    ngOnInit(): void {
    }

    onChangeCallback = (value: any) => {};

    getDropdownStyle(propertyName: string): any {
        return returnCodelistStyle(propertyName);
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: any): void {
        if (obj) {
            if (this.formControlInput.stringArray){
                this.codesSelected = obj.join(' ');
                this.codesArray = obj;
            }else{
                this.codesSelected = obj;
                this.codesArray = obj.split(' ');
            }
        }
    }

    addNewMdnCode(): void{
        this.codesArray.push(null);
    }

    updateSingleMdn($event: any, i: number): void{
        if (!this.codesArray.includes($event)) {
            this.codesArray[i] = $event;
            this.value = this.codesArray.join(' ');
        } else {
            this.codesArray[i] = null;
            this.value = this.codesArray.join(' ');
        }
    }

    removeSingleMdn(i: number): void{
        this.codesArray.splice(i, 1);
        this.value = this.codesArray.join(' ');
    }
}
