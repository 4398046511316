export default class TokenModel {
  initialValue?: string | null;
  actorCode: string;
  actorCodeType: ActorCodeType;
  eudamedToken: string | null;
  initialNodeIDValue?: string | null;
  nodeID: string | null;

  constructor(actorCode: string, actorCodeType: ActorCodeType, eudamedToken: string, nodeID: string) {
    this.actorCode = actorCode;
    this.actorCodeType = actorCodeType;
    this.eudamedToken = eudamedToken;
    this.nodeID = nodeID;
  }
}

export enum ActorCodeType {
  PR_ACTOR_CODE = "PR",
  MF_ACTOR_CODE = "MF"
}
