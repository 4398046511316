import {DeviceConstants} from '.';
import {UDIGroups} from './attribute-groups.enum';
import {parseSimpleField, parseSimpleArray, parseComplexField, parseArrayField} from '../../util/device-to-details.util';
import FormBuildingInput, {NumericTypes} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-building-input.interface';
import {FormControlType} from 'src/app/shared/common/components/eudamed-forms/eudamed-form/form-interfaces/form-control-type.enum';
import {AbstractControl, FormArray, Validators} from '@angular/forms';
import {
  actorCodePattern,
  decimalPattern,
  emailPattern,
  optionalWithRequired,
  optionalWithRequiredParticular,
  positiveIntegerPattern
} from './validators/complex-validators.util';
import {Injector} from '@angular/core';
import {conditionalDescriptionWithLanguage} from './services/eudamed-editor.util';
import {CriticalWarningValueCodeList, StorageHandlingConditionTypeCodeList} from '../codelists.constants';

export const AIMDDDEVICE: Array<DeviceConstants & FormBuildingInput> = [
    {
        propertyName: 'basicUDIIdentifier',
        name: 'DEVICECONSTANTS.aimdd.udi.basicUDIIdentifier.name',
        group: UDIGroups.IDENTIFICATION,
        controlType: FormControlType.BASIC_ASYNC_SELECT,
        validators: [optionalWithRequired(['basicUDIIdentifierDICode', 'basicUDIIdentifierIssuingEntityCode'])],
        placeholder: 'FLD-UDID-01',
        parsingFunction: parseComplexField,
        style: {
            marginBottom: '48px'
        },
        children: [
            {
                propertyName: 'basicUDIIdentifierDICode',
                name: 'DEVICECONSTANTS.aimdd.udi.basicUDIIdentifierDICode.name',
                controlType: FormControlType.INPUT,
                placeholder: 'FLD-UDID-145',
                description: 'DEVICECONSTANTS.aimdd.udi.basicUDIIdentifierDICode.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                style: {
                    width: '50%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'basicUDIIdentifierIssuingEntityCode',
                name: 'DEVICECONSTANTS.aimdd.udi.basicUDIIdentifierIssuingEntityCode.name',
                description: 'DEVICECONSTANTS.aimdd.udi.basicUDIIdentifierIssuingEntityCode.description',
                controlType: FormControlType.DROPDOWN,
                placeholder: 'FLD-UDID-295',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'deviceStatusCode',
        name: 'DEVICECONSTANTS.aimdd.udi.deviceStatusCode.name',
        description: 'DEVICECONSTANTS.aimdd.udi.deviceStatusCode.description',
        validators: [Validators.required],
        updateable: true,
        controlType: FormControlType.DROPDOWN,
        placeholder: 'FLD-UDID-130',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'substatus',
        controlType: FormControlType.REPEATABLE,
        placeholder: 'FLD-UDID-01',
        name: 'DEVICECONSTANTS.aimdd.udi.substatus.name',
        validators: [optionalWithRequired(['substatusCode', 'substatusStartDate'])],
        parsingFunction: parseArrayField,
        group: UDIGroups.IDENTIFICATION,
        children: [
            {
                propertyName: 'substatusCode',
                name: 'DEVICECONSTANTS.aimdd.udi.substatusCode.name',
                description: 'DEVICECONSTANTS.aimdd.udi.substatusCode.description',
                updateable: true,
                controlType: FormControlType.DROPDOWN,
                placeholder: 'FLD-UDID-131',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'substatusStartDate',
                name: 'DEVICECONSTANTS.aimdd.udi.substatusStartDate.name',
                description: 'DEVICECONSTANTS.aimdd.udi.substatusStartDate.description',
                updateable: true,
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-126',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'substatusEndDate',
                name: 'DEVICECONSTANTS.aimdd.udi.substatusEndDate.name',
                controlType: FormControlType.DATE,
                updateable: true,
                placeholder: 'FLD-UDID-127',
                description: 'DEVICECONSTANTS.aimdd.udi.substatusEndDate.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION
            },
            {
                propertyName: 'substatusRecalledPrecision',
                name: 'DEVICECONSTANTS.aimdd.udi.substatusRecalledPrecision.name',
                validators: [optionalWithRequired(['substatusRecalledPrecisionLanguage', 'substatusRecalledPrecisionText'])],
                controlType: FormControlType.REPEATABLE,
                placeholder: 'FLD-UDID-',
                parsingFunction: parseSimpleArray,
                group: UDIGroups.IDENTIFICATION,
                children: [
                    {
                        propertyName: 'substatusRecalledPrecisionLanguage',
                        name: 'DEVICECONSTANTS.aimdd.udi.substatusRecalledPrecisionLanguage.name',
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-EUD-010',
                        description: 'DEVICECONSTANTS.aimdd.udi.substatusRecalledPrecisionLanguage.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.IDENTIFICATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'substatusRecalledPrecisionText',
                        name: 'DEVICECONSTANTS.aimdd.udi.substatusRecalledPrecisionText.name',
                        textareaRows: 6,
                        controlType: FormControlType.TEXTAREA,
                        updateable: true,
                        placeholder: 'FLD-UDID-122',
                        description: 'DEVICECONSTANTS.aimdd.udi.substatusRecalledPrecisionText.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.IDENTIFICATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    }
                ]
            },
            {
                propertyName: 'substatusRecallScope',
                name: 'DEVICECONSTANTS.aimdd.udi.substatusRecallScope.name',
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-123',
                description: 'DEVICECONSTANTS.aimdd.udi.substatusRecallScope.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.IDENTIFICATION
            }
        ]
    },
    {
        propertyName: 'deviceType',
        name: 'DEVICECONSTANTS.aimdd.udi.deviceType.name',
        description: 'DEVICECONSTANTS.aimdd.udi.deviceType.description',
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        placeholder: 'FLD-ATRIFY-01',
        validators: [Validators.required],
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'UDIDIIdentifierDICode',
        name: 'DEVICECONSTANTS.aimdd.udi.UDIDIIdentifierDICode.name',
        description: 'DEVICECONSTANTS.aimdd.udi.UDIDIIdentifierDICode.description',
        controlType: FormControlType.INPUT,
        validators: [Validators.required, Validators.minLength(1), Validators.maxLength(120)],
        placeholder: 'FLD-UDID-342',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea120'
        }
    },
    {
        propertyName: 'UDIDIIdentifierIssuingEntityCode',
        name: 'DEVICECONSTANTS.aimdd.udi.UDIDIIdentifierIssuingEntityCode.name',
        description: 'DEVICECONSTANTS.aimdd.udi.UDIDIIdentifierIssuingEntityCode.description',
        validators: [Validators.required],
        controlType: FormControlType.SEARCHABLE_DROPDOWN,
        placeholder: 'FLD-UDID-341',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'customerMessageID',
        name: 'DEVICECONSTANTS.aimdd.udi.customerMessageID.name',
        description: 'DEVICECONSTANTS.aimdd.udi.customerMessageID.description',
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-ATRIFY-02',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true
    },
    {
        propertyName: 'customerTransactionID',
        name: 'DEVICECONSTANTS.aimdd.udi.customerTransactionID.name',
        description: 'DEVICECONSTANTS.aimdd.udi.customerTransactionID.description',
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-ATRIFY-03',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true
    },
    {
        propertyName: 'MFActorCode',
        name: 'DEVICECONSTANTS.aimdd.udi.MFActorCode.name',
        description: 'DEVICECONSTANTS.aimdd.udi.MFActorCode.description',
        controlType: FormControlType.INPUT,
        validators: [Validators.required, Validators.pattern(actorCodePattern)],
        placeholder: 'FLD-UDID-10',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        style: {
            width: '50%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            pattern: 'ERRORS.actorCode'
        },
    },
    {
        propertyName: 'additionalDescription',
        name: 'DEVICECONSTANTS.aimdd.udi.additionalDescription.name',
        parsingFunction: parseSimpleArray,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['additionalProductDescriptionLanguage', 'additionalProductDescriptionText'])],
        placeholder: 'FLD-UDID-01',
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'additionalProductDescriptionLanguage',
                name: 'DEVICECONSTANTS.aimdd.udi.additionalProductDescriptionLanguage.name',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-EUD-010',
                description: 'DEVICECONSTANTS.aimdd.udi.additionalProductDescriptionLanguage.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'additionalProductDescriptionText',
                name: 'DEVICECONSTANTS.aimdd.udi.additionalProductlDescriptionText.name',
                minLength: 1,
                maxLength: 2000,
                textareaRows: 6,
                updateable: true,
                controlType: FormControlType.TEXTAREA,
                placeholder: 'FLD-UDID-175',
                description: 'DEVICECONSTANTS.aimdd.udi.additionalProductlDescriptionText.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            }
        ]
    },
    {
        propertyName: 'website',
        name: 'DEVICECONSTANTS.aimdd.udi.website.name',
        description: 'DEVICECONSTANTS.aimdd.udi.website.description',
        updateable: true,
        controlType: FormControlType.INPUT,
        placeholder: 'FLD-UDID-174',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        style: {
            width: '100%',
            marginBottom: '48px'
        },
        validators: [Validators.minLength(1), Validators.maxLength(255)],
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.tooLong255'
        }
    },
    {
        propertyName: 'labeledAsSingleUse',
        name: 'DEVICECONSTANTS.aimdd.udi.labeledAsSingleUse.name',
        controlType: FormControlType.CHECKBOX,
        initialValue: null,
        placeholder: 'FLD-UDID-167',
        validators: [Validators.required],
        description: 'DEVICECONSTANTS.aimdd.udi.labeledAsSingleUse.description',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'numberOfReuses',
        name: 'DEVICECONSTANTS.aimdd.udi.numberOfReuses.name',
        controlType: FormControlType.INPUT,
        validators: [Validators.pattern(positiveIntegerPattern), Validators.minLength(1), Validators.maxLength(32767)],
        numericType: NumericTypes.INTEGER,
        placeholder: 'FLD-UDID-157',
        description: 'DEVICECONSTANTS.aimdd.udi.numberOfReuses.description',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        style: {
            marginBottom: '48px'
        },
        validationError: 'EUD-item0012',
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.textArea32767',
            pattern: 'ERRORS.numbersOnly'
        }
    },
    {
        propertyName: 'reprocessed',
        name: 'DEVICECONSTANTS.aimdd.udi.reprocessed.name',
        controlType: FormControlType.CHECKBOX,
        initialValue: null,
        validators: [Validators.required],
        placeholder: 'FLD-UDID-164',
        description: 'DEVICECONSTANTS.aimdd.udi.reprocessed.description',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'sterile',
        name: 'DEVICECONSTANTS.aimdd.udi.sterile.name',
        description: 'DEVICECONSTANTS.aimdd.udi.sterile.description',
        validators: [Validators.required],
        initialValue: null,
        controlType: FormControlType.CHECKBOX,
        placeholder: 'FLD-UDID-169',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'sterilization',
        name: 'DEVICECONSTANTS.aimdd.udi.sterilization.name',
        description: 'DEVICECONSTANTS.aimdd.udi.sterilization.description',
        validators: [Validators.required],
        controlType: FormControlType.CHECKBOX,
        initialValue: null,
        placeholder: 'FLD-UDID-170',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'latex',
        name: 'DEVICECONSTANTS.aimdd.udi.latex.name',
        controlType: FormControlType.CHECKBOX,
        initialValue: null,
        placeholder: 'FLD-UDID-156',
        validators: [Validators.required],
        description: 'DEVICECONSTANTS.aimdd.udi.latex.description',
        parsingFunction: parseSimpleField,
        group: UDIGroups.DEVICE_INFORMATION,
        errorMessage: 'ERRORS.notFilled',
        style: {
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'clinicalSize',
        name: 'DEVICECONSTANTS.aimdd.udi.clinicalSize.name',
        parsingFunction: parseArrayField,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['clinicalSizeType', 'clinicalSizePrecision'])],
        placeholder: 'FLD-UDID-353',
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'clinicalSizeType',
                name: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeType.name',
                parsingFunction: parseSimpleField,
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-190',
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                  const clinicalSizeTypeDescriptionControl = control.parent?.get('clinicalSizeTypeDescription');
                  const conditionType = control.value;

                  if (clinicalSizeTypeDescriptionControl && clinicalSizeTypeDescriptionControl instanceof FormArray) {
                    if (conditionType && conditionType === 'CST999') {
                      clinicalSizeTypeDescriptionControl.hidden = false;
                    } else {
                      clinicalSizeTypeDescriptionControl.hidden = true;
                      clinicalSizeTypeDescriptionControl.controls.forEach(x => {
                        x.get('clinicalSizeTypeDescriptionLanguage')?.setValue('');
                        x.get('clinicalSizeTypeDescriptionText')?.setValue('');
                      });
                    }
                  }
                },
                description: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeType.description',
                group: UDIGroups.DEVICE_INFORMATION
            },
            {
                propertyName: 'clinicalSizeTypeDescription',
                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypDescription.name',
                description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypDescription.description',
                initialValue: '',
                placeholder: 'FLD-UDID-195',
                disabled: false,
                validators: [optionalWithRequired(['clinicalSizeTypeDescriptionLanguage', 'clinicalSizeTypeDescriptionText'])],
                controlType: FormControlType.REPEATABLE,
                parsingFunction: parseSimpleArray,
                group: UDIGroups.DEVICE_INFORMATION,
                children: [
                    {
                        propertyName: 'clinicalSizeTypeDescriptionLanguage',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypeDescriptionLanguage.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypeDescriptionLanguage.description',
                        initialValue: '',
                        placeholder: 'FLD-EUD-010',
                        disabled: false,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'clinicalSizeTypeDescriptionText',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypeDescriptionText.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeTypeDescriptionText.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-358',
                        disabled: false,
                        minLength: 1,
                        maxLength: 2000,
                        textareaRows: 6,
                        controlType: FormControlType.TEXTAREA,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    },
                ]
            },
            {
                propertyName: 'clinicalSizePrecision',
                name: 'DEVICECONSTANTS.aimdd.udi.clinicalSizePrecision.name',
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.DROPDOWN,
                placeholder: 'FLD-UDID-191',
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                  const clinicalSizeValueControl = control.parent?.get('clinicalSizeValue');
                  const clinicalSizeTextControl = control.parent?.get('clinicalSizeText');
                  const clinicalSizeRangeControl = control.parent?.get('clinicalSizeRange');
                  const conditionType = control.value;

                  if (conditionType) {
                    if (conditionType === 'RANGE') {
                      clinicalSizeTextControl?.get('clinicalSizeTextValue')?.setValue('');
                      clinicalSizeValueControl?.get('clinicalSizeValueData')?.setValue('');
                      clinicalSizeValueControl?.get('clinicalSizeValueUnitOfMeasure')?.setValue('');

                      if (clinicalSizeValueControl && clinicalSizeTextControl && clinicalSizeRangeControl){
                        clinicalSizeValueControl.hidden = true;
                        clinicalSizeTextControl.hidden = true;
                        clinicalSizeRangeControl.hidden = false;
                      }
                    } else if (conditionType === 'TEXT') {
                      clinicalSizeRangeControl?.get('clinicalSizeMinimumValue')?.setValue('');
                      clinicalSizeRangeControl?.get('clinicalSizeMaximumValue')?.setValue('');
                      clinicalSizeRangeControl?.get('clinicalSizeRangeValueUnitOfMeasure')?.setValue('');
                      clinicalSizeValueControl?.get('clinicalSizeValueData')?.setValue('');
                      clinicalSizeValueControl?.get('clinicalSizeValueUnitOfMeasure')?.setValue('');

                      if (clinicalSizeValueControl && clinicalSizeTextControl && clinicalSizeRangeControl){
                        clinicalSizeValueControl.hidden = true;
                        clinicalSizeTextControl.hidden = false;
                        clinicalSizeRangeControl.hidden = true;
                      }
                    } else if (conditionType === 'VALUE') {
                      clinicalSizeRangeControl?.get('clinicalSizeMinimumValue')?.setValue('');
                      clinicalSizeRangeControl?.get('clinicalSizeMaximumValue')?.setValue('');
                      clinicalSizeRangeControl?.get('clinicalSizeRangeValueUnitOfMeasure')?.setValue('');
                      clinicalSizeTextControl?.get('clinicalSizeTextValue')?.setValue('');

                      if (clinicalSizeValueControl && clinicalSizeTextControl && clinicalSizeRangeControl){
                        clinicalSizeValueControl.hidden = false;
                        clinicalSizeTextControl.hidden = true;
                        clinicalSizeRangeControl.hidden = true;
                      }
                    }
                  }else {
                    if (clinicalSizeValueControl && clinicalSizeTextControl && clinicalSizeRangeControl){
                      clinicalSizeValueControl.hidden = true;
                      clinicalSizeTextControl.hidden = true;
                      clinicalSizeRangeControl.hidden = true;
                    }
                  }
                },
                description: 'DEVICECONSTANTS.aimdd.udi.clinicalSizePrecision.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION
            },
            {
                propertyName: 'clinicalSizeValue',
                name: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeValueMinimum.name',
                hidden: true,
                controlType: FormControlType.NON_SHOWING,
                placeholder: 'FLD-UDID-01',
                validators: [optionalWithRequired(['clinicalSizeValueData', 'clinicalSizeValueUnitOfMeasure'])],
                parsingFunction: parseComplexField,
                group: UDIGroups.DEVICE_INFORMATION,
                children: [
                    {
                        propertyName: 'clinicalSizeValueData',
                        name: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeValueData.name',
                        controlType: FormControlType.INPUT,
                        placeholder: 'FLD-UDID-196',
                        description: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeValueData.description',
                        validators: [Validators.pattern(decimalPattern)],
                        numericType: NumericTypes.DECIMAL,
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.decimalNumbersOnly',
                        jsonPath: '$.clinicalSize[*].clinicalSizeValue.clinicalSizeValueData'
                    },
                    {
                        propertyName: 'clinicalSizeValueUnitOfMeasure',
                        name: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeValueUnitOfMeasure.name',
                        errorMessage: 'ERRORS.notFilled',
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-UDID-195-VAL',
                        onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                          const clinicalSizeTypeDescriptionControl = control.parent?.get('clinicalSizeValueUnitOfMeasureDescription');
                          const conditionType = control.value;

                          if (clinicalSizeTypeDescriptionControl && clinicalSizeTypeDescriptionControl instanceof FormArray) {
                            if (conditionType && conditionType === 'MU999') {
                              clinicalSizeTypeDescriptionControl.hidden = false;
                            } else {
                              clinicalSizeTypeDescriptionControl.hidden = true;
                              clinicalSizeTypeDescriptionControl.controls.forEach(x => {
                                x.get('clinicalSizeValueUnitOfMeasureDescriptionLanguage')?.setValue('');
                                x.get('clinicalSizeValueUnitOfMeasureDescriptionText')?.setValue('');
                              });
                            }
                          }
                        },
                        description: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeValueUnitOfMeasure.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        jsonPath: '$.clinicalSize[*].clinicalSizeValue.clinicalSizeValueUnitOfMeasure'
                    },
                    {
                        propertyName: 'clinicalSizeValueUnitOfMeasureDescription',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescription.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescription.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-195',
                        disabled: false,
                        hidden: true,
                        controlType: FormControlType.REPEATABLE,
                        parsingFunction: parseSimpleArray,
                        validators: [optionalWithRequired(['clinicalSizeValueUnitOfMeasureDescriptionLanguage',
                                                          'clinicalSizeValueUnitOfMeasureDescriptionText'])],
                        group: UDIGroups.DEVICE_INFORMATION,
                        children: [
                            {
                                propertyName: 'clinicalSizeValueUnitOfMeasureDescriptionLanguage',
                                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescriptionLanguage.name',
                                description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescriptionLanguage.description',
                                initialValue: '',
                                placeholder: 'FLD-EUD-010',
                                disabled: false,
                                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                                parsingFunction: parseSimpleField,
                                group: UDIGroups.DEVICE_INFORMATION
                            },
                            {
                                propertyName: 'clinicalSizeValueUnitOfMeasureDescriptionText',
                                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescriptionText.name',
                                description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeValueUnitOfMeasureDescriptionText.description',
                                initialValue: '',
                                placeholder: 'FLD-UDID-359',
                                disabled: false,
                                minLength: 1,
                                maxLength: 2000,
                                textareaRows: 6,
                                controlType: FormControlType.TEXTAREA,
                                parsingFunction: parseSimpleField,
                                group: UDIGroups.DEVICE_INFORMATION,
                                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                                errorMessage: {
                                    required: 'ERRORS.notFilled',
                                    maxlength: 'ERRORS.textArea2000'
                                }
                            },

                        ]
                    },
                ]
            },
            {
                propertyName: 'clinicalSizeRange',
                name: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeRange.name',
                controlType: FormControlType.NON_SHOWING,
                placeholder: 'FLD-UDID-01',
                hidden: true,
                parsingFunction: parseComplexField,
                validators: [optionalWithRequired(['clinicalSizeMinimumValue', 'clinicalSizeMaximumValue', 'clinicalSizeRangeValueUnitOfMeasure'])],
                group: UDIGroups.DEVICE_INFORMATION,
                children: [
                    {
                        propertyName: 'clinicalSizeMinimumValue',
                        name: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeMinimumValue.name',
                        controlType: FormControlType.INPUT,
                        validators: [Validators.pattern(decimalPattern)],
                        numericType: NumericTypes.DECIMAL,
                        decimal: true,
                        placeholder: 'FLD-UDID-193',
                        description: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeMinimumValue.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.decimalNumbersOnly'
                    },
                    {
                        propertyName: 'clinicalSizeMaximumValue',
                        controlType: FormControlType.INPUT,
                        validators: [Validators.pattern(decimalPattern)],
                        numericType: NumericTypes.DECIMAL,
                        decimal: true,
                        placeholder: 'FLD-UDID-192',
                        name: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeMaximumValue.name',
                        description: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeMaximumValue.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.decimalNumbersOnly'
                    },
                    {
                        propertyName: 'clinicalSizeRangeValueUnitOfMeasure',
                        errorMessage: 'ERRORS.notFilled',
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-UDID-195',
                        onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                          const clinicalSizeTypeDescriptionControl = control.parent?.get('clinicalSizeRangeValueUnitOfMeasureDescription');
                          const conditionType = control.value;

                          if (clinicalSizeTypeDescriptionControl && clinicalSizeTypeDescriptionControl instanceof FormArray) {
                            if (conditionType && conditionType === 'MU999') {
                              clinicalSizeTypeDescriptionControl.hidden = false;
                            } else {
                              clinicalSizeTypeDescriptionControl.hidden = true;
                              clinicalSizeTypeDescriptionControl.controls.forEach(x => {
                                x.get('clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage')?.setValue('');
                                x.get('clinicalSizeRangeValueUnitOfMeasureDescriptionText')?.setValue('');
                              });
                            }
                          }
                        },
                        name: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeRangeValueUnitOfMeasure.name',
                        description: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeRangeValueUnitOfMeasure.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION
                    },
                    {
                        propertyName: 'clinicalSizeRangeValueUnitOfMeasureDescription',
                        name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescription.name',
                        description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescription.description',
                        initialValue: '',
                        placeholder: 'FLD-UDID-195',
                        validators: [optionalWithRequired(['clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage',
                                                          'clinicalSizeRangeValueUnitOfMeasureDescriptionText'])],
                        disabled: false,
                        hidden: true,
                        controlType: FormControlType.REPEATABLE,
                        parsingFunction: parseSimpleArray,
                        group: UDIGroups.DEVICE_INFORMATION,
                        children: [
                            {
                                propertyName: 'clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage',
                                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage.name',
                                description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage.description',
                                initialValue: '',
                                placeholder: 'FLD-EUD-010',
                                disabled: false,
                                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                                parsingFunction: parseSimpleField,
                                group: UDIGroups.DEVICE_INFORMATION,
                                errorMessage: 'ERRORS.notFilled'
                            },
                            {
                                propertyName: 'clinicalSizeRangeValueUnitOfMeasureDescriptionText',
                                name: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescriptionText.name',
                                description: 'DEVICECONSTANTS.mdr.udi.clinicalSizeRangeValueUnitOfMeasureDescriptionText.description',
                                initialValue: '',
                                placeholder: 'FLD-UDID-359',
                                disabled: false,
                                minLength: 1,
                                maxLength: 2000,
                                textareaRows: 6,
                                controlType: FormControlType.TEXTAREA,
                                parsingFunction: parseSimpleField,
                                group: UDIGroups.DEVICE_INFORMATION,
                                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                                errorMessage: {
                                    required: 'ERRORS.notFilled',
                                    maxlength: 'ERRORS.textArea2000'
                                }
                            },

                        ]
                    },
                ]
            },
            {
                propertyName: 'clinicalSizeText',
                name: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeText.name',
                controlType: FormControlType.NON_SHOWING,
                placeholder: '',
                hidden: true,
                validators: [optionalWithRequired(['clinicalSizeTextValue'])],
                description: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeText.description',
                parsingFunction: parseComplexField,
                group: UDIGroups.DEVICE_INFORMATION,
                children: [
                    {
                        propertyName: 'clinicalSizeTextValue',
                        controlType: FormControlType.INPUT,
                        placeholder: 'FLD-UDID-194',
                        name: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeTextValue.name',
                        description: 'DEVICECONSTANTS.aimdd.udi.clinicalSizeTextValue.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        style: {
                            width: '100%'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(255)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.tooLong255'
                        }
                    }
                ]
            }
        ]
    },
    {
        propertyName: 'storageHandlingCondition',
        name: 'DEVICECONSTANTS.aimdd.udi.storageHandlingCondition.name',
        controlType: FormControlType.REPEATABLE,
        placeholder: 'FLD-UDID-01',
        validators: [optionalWithRequired(['storageHandlingConditionType'])],
        parsingFunction: parseArrayField,
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'storageHandlingConditionType',
                name: 'DEVICECONSTANTS.aimdd.udi.storageHandlingConditionType.name',
                description: 'DEVICECONSTANTS.aimdd.udi.storageHandlingConditionType.description',
                updateable: true,
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-211',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput, injector: Injector) => {
                    conditionalDescriptionWithLanguage(injector, control, 'storageHandlingConditionComment', 'storageHandlingConditionCommentLanguage', 'storageHandlingConditionCommentText', StorageHandlingConditionTypeCodeList);
                },
            },
            {
                propertyName: 'storageHandlingConditionComment',
                name: 'DEVICECONSTANTS.aimdd.udi.storageHandlingConditionComment.name',
                description: '',
                controlType: FormControlType.REPEATABLE,
                placeholder: 'FLD-UDID-01',
                parsingFunction: parseSimpleArray,
                validators: [optionalWithRequiredParticular(['storageHandlingConditionCommentLanguage', 'storageHandlingConditionCommentText'], 'storageHandlingConditionType', 'SHC099')],
                group: UDIGroups.DEVICE_INFORMATION,
                children: [
                    {
                        propertyName: 'storageHandlingConditionCommentLanguage',
                        name: 'DEVICECONSTANTS.aimdd.udi.storageHandlingConditionCommentLanguage.name',
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-EUD-010',
                        description: 'DEVICECONSTANTS.aimdd.udi.storageHandlingConditionCommentLanguage.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'storageHandlingConditionCommentText',
                        name: 'DEVICECONSTANTS.aimdd.udi.storageHandlingConditionCommentText.name',
                        controlType: FormControlType.TEXTAREA,
                        updateable: true,
                        textareaRows: 6,
                        placeholder: 'FLD-UDID-213',
                        description: 'DEVICECONSTANTS.aimdd.udi.storageHandlingConditionCommentText.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            maxlength: 'ERRORS.textArea2000',
                            required: 'ERRORS.notFilled'
                        }
                    }
                ]
            },
        ]
    },
    {
        propertyName: 'criticalWarning',
        name: 'DEVICECONSTANTS.aimdd.udi.criticalWarning.name',
        validators: [optionalWithRequired(['criticalWarningValue'])],
        controlType: FormControlType.REPEATABLE,
        placeholder: 'FLD-UDID-01',
        parsingFunction: parseArrayField,
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'criticalWarningValue',
                name: 'DEVICECONSTANTS.aimdd.udi.criticalWarningValue.name',
                errorMessage: 'ERRORS.notFilled',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-212',
                description: 'DEVICECONSTANTS.aimdd.udi.criticalWarningValue.description',
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput, injector: Injector) => {
                    conditionalDescriptionWithLanguage(injector, control, 'criticalWarningComment', 'criticalWarningCommentLanguage', 'criticalWarningCommentText', CriticalWarningValueCodeList);
                },
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
            },
            {
                propertyName: 'criticalWarningComment',
                name: 'DEVICECONSTANTS.aimdd.udi.criticalWarningComment.name',
                controlType: FormControlType.REPEATABLE,
                validators: [optionalWithRequired(['criticalWarningCommentLanguage', 'criticalWarningCommentText'])],
                placeholder: 'FLD-UDID-01',
                group: UDIGroups.DEVICE_INFORMATION,
                parsingFunction: parseSimpleArray,
                children: [
                    {
                        propertyName: 'criticalWarningCommentLanguage',
                        name: 'DEVICECONSTANTS.aimdd.udi.criticalWarningCommentLanguage.name',
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-UDID-010',
                        description: 'DEVICECONSTANTS.aimdd.udi.criticalWarningCommentLanguage.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'criticalWarningCommentText',
                        name: 'DEVICECONSTANTS.aimdd.udi.criticalWarningCommentText.name',
                        updateable: true,
                        controlType: FormControlType.TEXTAREA,
                        textareaRows: 6,
                        placeholder: 'FLD-UDID-319',
                        description: 'DEVICECONSTANTS.aimdd.udi.criticalWarningCommentText.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            maxlength: 'ERRORS.textArea2000',
                            required: 'ERRORS.notFilled'
                        }
                    }
                ]
            }
        ]
    },
    {
        propertyName: 'medicalHumanProductSubstance',
        name: 'DEVICECONSTANTS.aimdd.udi.medicalHumanProductSubstance.name',
        parsingFunction: parseArrayField,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['medicalHumanProductSubstanceType'])],
        placeholder: 'FLD-UDID-01',
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'medicalHumanProductSubstanceType',
                name: 'DEVICECONSTANTS.aimdd.udi.medicalHumanProductSubstanceType.name',
                description: 'DEVICECONSTANTS.aimdd.udi.medicalHumanProductSubstanceType.description',
                updateable: true,
                controlType: FormControlType.DROPDOWN,
                placeholder: 'FLD-UDID-316',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'medicalHumanProductSubstanceName',
                name: 'DEVICECONSTANTS.aimdd.udi.medicalHumanProductSubstanceName.name',
                controlType: FormControlType.REPEATABLE,
                validators: [optionalWithRequired(['medicalHumanProductSubstanceNameLanguage', 'medicalHumanProductSubstanceNameText'])],
                placeholder: 'FLD-UDID-01',
                group: UDIGroups.DEVICE_INFORMATION,
                parsingFunction: parseSimpleArray,
                children: [
                    {
                        propertyName: 'medicalHumanProductSubstanceNameLanguage',
                        name: 'DEVICECONSTANTS.aimdd.udi.medicalHumanProductSubstanceNameLanguage.name',
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-EUD-010',
                        description: 'DEVICECONSTANTS.aimdd.udi.medicalHumanProductSubstanceNameLanguage.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'medicalHumanProductSubstanceNameText',
                        name: 'DEVICECONSTANTS.aimdd.udi.medicalHumanProductSubstanceNameText.name',
                        textareaRows: 6,
                        updateable: true,
                        controlType: FormControlType.TEXTAREA,
                        placeholder: 'FLD-UDID-317',
                        description: 'DEVICECONSTANTS.aimdd.udi.medicalHumanProductSubstanceNameText.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.DEVICE_INFORMATION,
                        validators: [Validators.minLength(1), Validators.maxLength(2000)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea2000'
                        }
                    },
                ]
            },
            {
                propertyName: 'medicalHumanProductSubstanceINN',
                name: 'DEVICECONSTANTS.aimdd.udi.medicalHumanProductSubstanceINN.name',
                controlType: FormControlType.INPUT,
                updateable: true,
                placeholder: 'FLD-UDID-318',
                description: 'DEVICECONSTANTS.aimdd.udi.medicalHumanProductSubstanceINN.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: {
                    maxlength: 'ERRORS.textArea2000'
                }
            },
        ]
    },
    {
        propertyName: 'MDNCode',
        name: 'DEVICECONSTANTS.aimdd.udi.MDNCode.name',
        controlType: FormControlType.MDN_SELECT,
        placeholder: 'FLD-UDID-149',
        updateable: true,
        validators: [Validators.required],
        description: 'DEVICECONSTANTS.aimdd.udi.MDNCode.description',
        parsingFunction: parseSimpleField,
        group: UDIGroups.CLASSIFICATION,
        errorMessage: 'ERRORS.notFilled'
    },
    {
        propertyName: 'referenceNumber',
        name: 'DEVICECONSTANTS.aimdd.udi.referenceNumber.name',
        controlType: FormControlType.INPUT,
        validators: [Validators.required, Validators.minLength(1), Validators.maxLength(255)],
        placeholder: 'FLD-UDID-163',
        description: 'DEVICECONSTANTS.aimdd.udi.referenceNumber.description',
        parsingFunction: parseSimpleField,
        group: UDIGroups.IDENTIFICATION,
        hidden: true,
        style: {
            width: '100%'
        },
        errorMessage: {
            required: 'ERRORS.notFilled',
            maxlength: 'ERRORS.tooLong255'
        }
    },
    {
        propertyName: 'tradeName',
        name: 'DEVICECONSTANTS.aimdd.udi.tradeName.name',
        parsingFunction: parseSimpleArray,
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['tradeNameLanguage', 'tradeNameText', 'tradeNameSequenceNumber'])],
        placeholder: 'FLD-UDID-01',
        group: UDIGroups.DEVICE_INFORMATION,
        children: [
            {
                propertyName: 'tradeNameLanguage',
                name: 'DEVICECONSTANTS.aimdd.udi.tradeNameLanguage.name',
                description: 'DEVICECONSTANTS.aimdd.udi.tradeNameLanguage.description',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-EUD-010',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                errorMessage: 'ERRORS.notFilled',
                onChange: (control: AbstractControl, formBuildingInput: FormBuildingInput) => {
                    const tradeNameSequenceNumber = control.parent?.get('tradeNameSequenceNumber');

                    if (control.value) {
                        tradeNameSequenceNumber?.setValue('1');
                    }else{
                        tradeNameSequenceNumber?.setValue('');
                    }
                }
            },
            {
                propertyName: 'tradeNameText',
                name: 'DEVICECONSTANTS.aimdd.udi.tradeNameText.name',
                controlType: FormControlType.TEXTAREA,
                textareaRows: 6,
                updateable: true,
                placeholder: 'FLD-UDID-176',
                description: 'DEVICECONSTANTS.aimdd.udi.tradeNameText.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            },
            {
                propertyName: 'tradeNameSequenceNumber',
                name: 'DEVICECONSTANTS.aimdd.udi.tradeNameSequenceNumber.name',
                maxLength: 32767,
                controlType: FormControlType.INPUT,
                validators: [Validators.pattern(positiveIntegerPattern), Validators.minLength(1), Validators.maxLength(32767)],
                numericType: NumericTypes.INTEGER,
                placeholder: '',
                description: 'DEVICECONSTANTS.aimdd.udi.tradeNameSequenceNumber.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.DEVICE_INFORMATION,
                disabled: true,
                validationError: 'EUD-item0026',
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea32767',
                    pattern: 'ERRORS.numbersOnly'
                }
            },
        ]
    },
    {
        propertyName: 'relatedUDILink',
        name: 'DEVICECONSTANTS.aimdd.udi.relatedUDILink.name',
        controlType: FormControlType.NON_SHOWING,
        placeholder: '',
        validators: [optionalWithRequired(['relatedUDILinkDICode', 'relatedUDILinkIssuingEntityCode', 'relatedUDILinkType'])],
        parsingFunction: parseComplexField,
        hidden: true,
        group: UDIGroups.REFERENCED_DEVICE,
        children: [
            {
                propertyName: 'relatedUDILinkDICode',
                name: 'DEVICECONSTANTS.aimdd.udi.relatedUDILinkDICode.name',
                controlType: FormControlType.INPUT,
                updateable: true,
                placeholder: 'FLD-UDID-331',
                description: 'DEVICECONSTANTS.aimdd.udi.relatedUDILinkDICode.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.REFERENCED_DEVICE,
                style: {
                    width: '100%'
                },
                validators: [Validators.minLength(1), Validators.maxLength(120)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea120'
                }
            },
            {
                propertyName: 'relatedUDILinkIssuingEntityCode',
                name: 'DEVICECONSTANTS.aimdd.udi.relatedUDILinkIssuingEntityCode.name',
                controlType: FormControlType.DROPDOWN,
                placeholder: 'FLD-UDID-291',
                description: 'DEVICECONSTANTS.aimdd.udi.relatedUDILinkIssuingEntityCode.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.REFERENCED_DEVICE,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'relatedUDILinkType',
                name: 'DEVICECONSTANTS.aimdd.udi.relatedUDILinkType.name',
                description: 'DEVICECONSTANTS.aimdd.udi.relatedUDILinkType.description',
                controlType: FormControlType.DROPDOWN,
                updateable: true,
                placeholder: 'FLD-UDID-181',
                parsingFunction: parseSimpleField,
                group: UDIGroups.REFERENCED_DEVICE,
                errorMessage: 'ERRORS.notFilled'
            }
        ]
    },
    {
        propertyName: 'marketInfoPlacingOnTheEU',
        name: 'DEVICECONSTANTS.aimdd.udi.marketInfoPlacingOnTheEU.name',
        parsingFunction: parseComplexField,
        controlType: FormControlType.NON_SHOWING,
        validators: [optionalWithRequired(['marketInfoPlacingOnTheEUCountry', 'marketInfoOriginalPlacedOnTheMarket'])],
        placeholder: 'FLD-UDID-01',
        group: UDIGroups.MARKET_INFO,
        children: [
            {
                propertyName: 'marketInfoPlacingOnTheEUCountry',
                name: 'DEVICECONSTANTS.aimdd.udi.marketInfoPlacingOnTheEUCountry.name',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-137',
                description: 'DEVICECONSTANTS.aimdd.udi.marketInfoPlacingOnTheEUCountry.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'marketInfoPlacingOnTheEUStartDate',
                name: 'DEVICECONSTANTS.aimdd.udi.marketInfoPlacingOnTheEUStartDate.name',
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-250',
                description: 'DEVICECONSTANTS.aimdd.udi.marketInfoPlacingOnTheEUStartDate.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoPlacingOnTheEUEndDate',
                name: 'DEVICECONSTANTS.aimdd.udi.marketInfoPlacingOnTheEUEndDate.name',
                errorMessage: 'DEVICECONSTANTS.aimdd.udi.marketInfoPlacingOnTheEUEndDate.errorMessage',
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-251',
                description: 'DEVICECONSTANTS.aimdd.udi.marketInfoPlacingOnTheEUEndDate.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoOriginalPlacedOnTheMarket',
                name: 'DEVICECONSTANTS.aimdd.udi.marketInfoOriginalPlacedOnTheMarket.name',
                description: 'DEVICECONSTANTS.aimdd.udi.marketInfoOriginalPlacedOnTheMarket.description',
                controlType: FormControlType.CHECKBOX,
                placeholder: 'FLD-UDID-137',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO,
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    yesValidator: 'ERRORS.yesValidator'
                }
            },
        ]
    },
    {
        propertyName: 'marketInfoMadeAvailable',
        name: 'DEVICECONSTANTS.aimdd.udi.marketInfoMadeAvailable.name',
        controlType: FormControlType.REPEATABLE,
        validators: [optionalWithRequired(['marketInfoMadeAvailableCountry', 'marketInfoOriginalPlacedOnTheMarket'])],
        placeholder: 'FLD-UDID-01',
        parsingFunction: parseArrayField,
        group: UDIGroups.MARKET_INFO,
        children: [
            {
                propertyName: 'marketInfoMadeAvailableCountry',
                name: 'DEVICECONSTANTS.aimdd.udi.marketInfoMadeAvailableCountry.name',
                description: 'DEVICECONSTANTS.aimdd.udi.marketInfoMadeAvailableCountry.description',
                parsingFunction: parseSimpleField,
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-UDID-252',
                group: UDIGroups.MARKET_INFO,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'marketInfoMadeAvailableStartDate',
                name: 'DEVICECONSTANTS.aimdd.udi.marketInfoMadeAvailableStartDate.name',
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-250',
                description: 'DEVICECONSTANTS.aimdd.udi.marketInfoMadeAvailableStartDate.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoMadeAvailableEndDate',
                name: 'DEVICECONSTANTS.aimdd.udi.marketInfoMadeAvailableEndDate.name',
                controlType: FormControlType.DATE,
                placeholder: 'FLD-UDID-251',
                description: 'DEVICECONSTANTS.aimdd.udi.marketInfoMadeAvailableEndDate.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO
            },
            {
                propertyName: 'marketInfoOriginalPlacedOnTheMarket',
                name: 'DEVICECONSTANTS.aimdd.udi.marketInfoOriginalPlacedOnTheMarket.name',
                controlType: FormControlType.CHECKBOX,
                placeholder: 'FLD-UDID-252',
                description: 'DEVICECONSTANTS.aimdd.udi.marketInfoOriginalPlacedOnTheMarket.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.MARKET_INFO,
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    noValidator: 'ERRORS.noValidator'
                }
            },
        ]
    },
    {
        propertyName: 'productDesignerActorCode',
        name: 'DEVICECONSTANTS.aimdd.udi.productDesignerActorCode.name',
        description: 'DEVICECONSTANTS.aimdd.udi.productDesignerActorCode.description',
        controlType: FormControlType.INPUT,
        errorMessage: 'ERRORS.actorCode',
        validators: [Validators.pattern(actorCodePattern)],
        updateable: true,
        placeholder: 'FLD-UDID-221',
        parsingFunction: parseSimpleField,
        group: UDIGroups.PRODUCT_DESIGNER,
        style: {
            width: '50%',
            marginBottom: '48px'
        }
    },
    {
        propertyName: 'productDesignerOrganisation',
        name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisation.name',
        controlType: FormControlType.NON_SHOWING,
        validators: [optionalWithRequired([
            'productDesignerOrganisationNameLanguage',
            'productDesignerOrganisationNameText'
        ])],
        placeholder: 'FLD-UDID-01',
        parsingFunction: parseComplexField,
        group: UDIGroups.PRODUCT_DESIGNER,
        children: [
            {
                propertyName: 'productDesignerOrganisationGeographicAddress',
                name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationGeographicAddress.name',
                controlType: FormControlType.NON_SHOWING,
                validators: [optionalWithRequired(['productDesignerOrganisationCountry', 'productDesignerOrganisationPostalCode'])],
                placeholder: 'FLD-UDID-01',
                parsingFunction: parseComplexField,
                group: UDIGroups.PRODUCT_DESIGNER,
                children: [
                    {
                        propertyName: 'productDesignerOrganisationAddressComplement',
                        name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationAddressComplement.name',
                        updateable: true,
                        controlType: FormControlType.TEXTAREA,
                        textareaRows: 4,
                        maxLength: 500,
                        placeholder: 'FLD-EUD-040',
                        description: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationAddressComplement.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        errorMessage: 'ERRORS.tooLong500'
                    },
                    {
                        propertyName: 'productDesignerOrganisationCity',
                        name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationCity.name',
                        description: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationCity.description',
                        updateable: true,
                        controlType: FormControlType.TEXTAREA,
                        textareaRows: 4,
                        maxLength: 500,
                        placeholder: 'FLD-EUD-036',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        errorMessage: 'ERRORS.tooLong500'
                    },
                    {
                        propertyName: 'productDesignerOrganisationCountry',
                        name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationCountry.name',
                        description: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationCountry.description',
                        updateable: true,
                        controlType: FormControlType.SEARCHABLE_DROPDOWN,
                        placeholder: 'FLD-EUD-042',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        errorMessage: 'ERRORS.notFilled'
                    },
                    {
                        propertyName: 'productDesignerOrganisationPOBox',
                        name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationPOBox.name',
                        controlType: FormControlType.INPUT,
                        updateable: true,
                        placeholder: 'FLD-EUD-041',
                        description: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationPOBox.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '47%',
                            'margin-right': '24px'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: {
                            maxlength: 'ERRORS.textArea120'
                        }
                    },
                    {
                        propertyName: 'productDesignerOrganisationPostalCode',
                        name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationPostalCode.name',
                        updateable: true,
                        controlType: FormControlType.INPUT,
                        placeholder: 'FLD-EUD-039',
                        description: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationPostalCode.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '47%'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: {
                            required: 'ERRORS.notFilled',
                            maxlength: 'ERRORS.textArea120'
                        }
                    },
                    {
                        propertyName: 'productDesignerOrganisationStreet',
                        name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationStreet.name',
                        updateable: true,
                        controlType: FormControlType.TEXTAREA,
                        textareaRows: 4,
                        maxLength: 500,
                        placeholder: 'FLD-EUD-034',
                        description: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationStreet.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        errorMessage: 'ERRORS.tooLong500'
                    },
                    {
                        propertyName: 'productDesignerOrganisationStreetNum',
                        name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationStreetNum.name',
                        controlType: FormControlType.INPUT,
                        updateable: true,
                        placeholder: 'FLD-EUD-035',
                        description: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationStreetNum.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '100%'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: {
                            maxlength: 'ERRORS.textArea120'
                        }
                    },
                ]
            },
            {
                propertyName: 'productDesignerOrganisationContactDetail',
                name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationContactDetail.name',
                controlType: FormControlType.REPEATABLE,
                placeholder: 'FLD-UDID-01',
                validators: [optionalWithRequired(['productDesignerOrganisationContactDetaileMail'])],
                parsingFunction: parseSimpleArray,
                group: UDIGroups.PRODUCT_DESIGNER,
                children: [
                    {
                        propertyName: 'productDesignerOrganisationContactDetaileMail',
                        name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationContactDetaileMail.name',
                        controlType: FormControlType.INPUT,
                        updateable: true,
                        placeholder: 'FLD-EUD-018',
                        description: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationContactDetaileMail.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '100%'
                        },
                        validators: [Validators.pattern(emailPattern), Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: {
                          required: 'ERRORS.notFilled',
                          pattern: 'ERRORS.wrongSyntax',
                          maxlength: 'ERRORS.textArea120'
                        }
                    },
                    {
                        propertyName: 'productDesignerOrganisationContactDetailPhone',
                        name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationContactDetailPhone.name',
                        controlType: FormControlType.INPUT,
                        updateable: true,
                        placeholder: 'FLD-EUD-017',
                        description: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationContactDetailPhone.description',
                        parsingFunction: parseSimpleField,
                        group: UDIGroups.PRODUCT_DESIGNER,
                        style: {
                            width: '100%'
                        },
                        validators: [Validators.minLength(1), Validators.maxLength(120)],
                        errorMessage: {
                            maxlength: 'ERRORS.textArea120'
                        }
                    },
                ]
            },
            {
                propertyName: 'productDesignerOrganisationNameLanguage',
                name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationNameLanguage.name',
                description: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationNameLanguage.description',
                controlType: FormControlType.SEARCHABLE_DROPDOWN,
                placeholder: 'FLD-EUD-010',
                parsingFunction: parseSimpleField,
                group: UDIGroups.PRODUCT_DESIGNER,
                errorMessage: 'ERRORS.notFilled'
            },
            {
                propertyName: 'productDesignerOrganisationNameText',
                name: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationNameText.name',
                textareaRows: 6,
                controlType: FormControlType.TEXTAREA,
                updateable: true,
                placeholder: 'FLD-UDID-353',
                description: 'DEVICECONSTANTS.aimdd.udi.productDesignerOrganisationNameText.description',
                parsingFunction: parseSimpleField,
                group: UDIGroups.PRODUCT_DESIGNER,
                validators: [Validators.minLength(1), Validators.maxLength(2000)],
                errorMessage: {
                    required: 'ERRORS.notFilled',
                    maxlength: 'ERRORS.textArea2000'
                }
            },

        ]
    },
];
