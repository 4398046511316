export enum FormControlType {
    INPUT,
    NUMBER_INPUT,
    SELECT,
    REPEATABLE,
    NON_SHOWING,
    DATE,
    CHECKBOX,
    RADIO,
    DROPDOWN,
    SEARCHABLE_DROPDOWN,
    TEXTAREA,
    MULTISELECT,
    SEARCHABLE_MULTISELECT,
    MDN_SELECT,
    REPEATABLE_SELECT,
    BASIC_ASYNC_SELECT,
    DATETIME
}
