import { Injectable } from '@angular/core';

export interface Brand {
  brand: string;
  color: string;
  sidebarLogo: string;
  sidebarLogoImgClass: string;
  logo: string;
  logoClass: string;
  logoClassTablet: string;
  impressum: string;
}

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  logoPath: string;
  logoClass: string;
  logoClassTablet: string;
  sidebarLogoPath: string;
  sidebarLogoImgClass: string;
  impressumText: string;

  defaultLogoPath = 'assets/branding/_default/manager-logo-group.svg';
  defaultLogoClass: 'atrify-logo-image-wrapper';
  defaultLogoClassTablet: 'atrify-logo-image-wrapper';
  defaultSidebarLogoPath = 'assets/branding/_default/manager-logo-dark-no.svg';
  defaultSidebarLogoImgClass = 'sidebar-expanded-logo-default';
  defaultImpressumText = 'atrify Gmbh';

  constructor() { }

  getSidebarLogo(): string {
    return this.sidebarLogoPath;
  }

  getLogo(): string {
    return this.logoPath;
  }

  getLogoClass(): string {
    return this.logoClass;
  }

  getLogoClassTablet(): string {
    return this.logoClassTablet;
  }

  getImpressumText(): string {
    return this.impressumText;
  }

  getSidebarLogoImgClass(): string {
    return this.sidebarLogoImgClass;
  }

  getLogoPath(brandingConfigurations: Brand[], url: string): string {
    const branding = brandingConfigurations.find(bc => bc.brand === url);
    if (branding) {
      return branding.logo;
    }

    return this.defaultLogoPath;
  }

  getLogoCssClass(brandingConfigurations: Brand[], url: string): string {
    const branding = brandingConfigurations.find(bc => bc.brand === url);
    if (branding) {
      return branding.logoClass;
    }

    return this.defaultLogoClass;
  }

  getLogoTabletCssClass(brandingConfigurations: Brand[], url: string): string {
    const branding = brandingConfigurations.find(bc => bc.brand === url);
    if (branding) {
      return branding.logoClassTablet;
    }

    return this.defaultLogoClassTablet;
  }

  getSidebarLogoPath(brandingConfigurations: Brand[], url: string): string {
    const branding = brandingConfigurations.find(bc => bc.brand === url);
    if (branding) {
      return branding.sidebarLogo;
    }

    return this.defaultSidebarLogoPath;
  }

  getSidebarLogoCssClass(brandingConfigurations: Brand[], url: string): string {
    const branding = brandingConfigurations.find(bc => bc.brand === url);
    if (branding) {
      return branding.sidebarLogoImgClass;
    }

    return this.defaultSidebarLogoImgClass;
  }

  getImpressumTextForBrand(brandingConfigurations: Brand[], url: string): string {
    const branding = brandingConfigurations.find(bc => bc.brand === url);
    if (branding) {
      return branding.impressum;
    }

    return this.defaultImpressumText;
  }
}
