export default class ValidationErrors {
    messages: Array<ValidationMessage> = [];
    // result: string[] = [];
    // scope: string;
    // timestamp: string;

    constructor(validationErrors: Array<Partial<ValidationErrors>>){
        validationErrors.forEach((validationError: Partial<ValidationErrors>) => {
            const allMessages = validationError.messages?.map((message) => {
                return new ValidationMessage(message);
            });
            if (allMessages){
                this.messages.push(...allMessages);
            }
        });
        // this.messages = validationErrors.messages?.map((message) => {
            // return new ValidationMessage(message);
        // }) || [];
        // this.result = validationErrors.result || '';
        // this.scope = validationErrors.scope || '';
        // this.timestamp = validationErrors.timestamp || '';
    }
}

export class ValidationMessage {
    code: string;
    description: string;
    severity: any;

    constructor(message: Partial<ValidationMessage>) {
        this.code = message.code || '';
        this.description = message.description || '';
        this.severity = message.severity || '';
    }

    getAllFieldIds(): Array<string> | null{
        // const fieldIdsRegex = /(?<=\[).+?(?=\])/;
        const regEx = /(?<=\[).+?(?=\])/g;

        return this.description.match(regEx);
    }
}
