import { Injectable } from '@angular/core';

export interface Env {
  environment: string;
}

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  environment: string;

  defaultEnvironment = 'LOCALHOST';

  constructor() {}

  getEnv(): string {
    return this.environment;
  }

  getEnvironmentForEnv(envConfigurations: Env): string {
    const env = envConfigurations.environment;
    if (env) {
      return env;
    }

    return this.defaultEnvironment;
  }
}
