import { AfterContentInit, Component, ContentChild, Input } from '@angular/core';
import {NgControl} from '@angular/forms';

@Component({
    selector: 'eudamed-date-picker-wrapper',
    templateUrl: './eudamed-date-picker.component.html',
    styleUrls: ['./eudamed-date-picker.component.scss']
})
export class EudamedDatePickerComponent implements AfterContentInit {

    @Input()
    label: string;

    @Input()
    property: string;

    validityOfInput = true;


    @ContentChild(NgControl) control: NgControl;

    inputValue: any;
    currentStateClass = ['empty-input', 'label'];

    @Input()
    optionalClass: any;

    ngAfterContentInit(): void {
        if (this.control.control?.value === null ||
            this.control.control?.value === '' ||
            this.control.control?.value === undefined){
            this.optionalClass = ['empty-input', 'label'];
            this.currentStateClass = ['empty-input', 'label'];
        }else{
            this.currentStateClass = ['non-empty-input', 'label'];
        }

        this.control.valueChanges?.subscribe((value: any) => {
            this.inputValue = value;
            if (value === null || value === '' || value === undefined){
                this.optionalClass = ['empty-input', 'label'];
                this.currentStateClass = ['empty-input', 'label'];
            }else {
                this.currentStateClass = ['non-empty-input', 'label'];
            }
        });
        this.control.statusChanges?.subscribe((status: any) => {
            if (status === 'INVALID'){
                this.currentStateClass = ['invalid-input', 'label'];
                this.validityOfInput = false;
            }else{
                this.validityOfInput = true;
                if (this.inputValue === null ||
                    this.inputValue === '' ||
                    this.inputValue === undefined){
                    this.optionalClass = ['empty-input', 'label'];
                    this.currentStateClass = ['empty-input', 'label'];
                }else{
                    this.currentStateClass = ['non-empty-input', 'label'];
                }
            }
        });
    }

}
