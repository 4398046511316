import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Authority } from 'src/app/core/constants/authority.constants';
import { EudamedCookieService } from 'src/app/core/service/eudamed-cookie.service';
import { LoginService } from '../../core/service';
import { catchError, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/core/service/env.service';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public userData: any = {};
  Authority = Authority;
  selectedLanguage = this.eudamedCookieService.getLanguageCookie();
  userMenuOpen = false;
  currentFiles: any = [];

  faInfoCircle = faInfoCircle;

  constructor(
      private loginService: LoginService,
      private router: Router,
      public translationService: TranslateService,
      private eudamedCookieService: EudamedCookieService,
      private httpClient: HttpClient,
      private envService: EnvService
  ) {}



  ngOnInit(): void {
      this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
          this.selectedLanguage = this.eudamedCookieService.getLanguageCookie();
      });
      this.loginService.getUserData().then(result => {
          const fn = result.firstName ? result.firstName : '';
          const ln = result.lastName ? result.lastName : '';
          const un = result.username ? result.username : '';
          if (fn.replace(/\s/g, '').length === 0 && ln.replace(/\s/g, '').length === 0){
              this.userData.name = un;
          }else{
              this.userData.name = fn + ' ' + ln;
          }
          this.userData.initials = fn.charAt(0) + ln.charAt(0);
      });
  }

  openSettings(): void {
      this.router.navigateByUrl('/settings');
  }

  openAccount(): void {
    this.router.navigateByUrl('/account');
  }

  routeTo(route: string): void{
      this.router.navigateByUrl(route);
  }

  changeLanguage(lang: string): void {
      this.selectedLanguage = lang;
      this.translationService.use(this.selectedLanguage);
      this.eudamedCookieService.changeLanguageCookie(this.selectedLanguage);
  }

  logout(): void {
    this.loginService.logout();
  }

  openInNewTab(url: string): void {
      const win = window.open(url, '_blank');
      win?.focus();
  }

  openInSameTab(url: string): void {
      window.open(url, '_self');
  }

  openFileInNewTab(fileName: any): void {
      this.httpClient
          .get(`/udimanager/v1/download/${fileName}`,
              {observe: 'response', responseType: 'blob'}
          )
          .pipe(
              catchError((err) => {
                  console.log(err);
                  return of(false);
              })
          )
          .subscribe((response: any) => {
              if (response !== false) {
                  const blob = new Blob([response.body], {type: 'application/pdf'});
                  console.log(response.body);
                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.setAttribute('target', '_blank');
                  link.href = url;
                  link.click();
                  link.remove();
              }
          });
  }

  getCurrentEnv(): string{
      return this.envService.getEnv();
  }
}
