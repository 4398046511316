import { AfterContentInit, Component, ContentChild, ElementRef, Input } from '@angular/core';
import {NgControl} from '@angular/forms';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { NzSelectComponent } from 'ng-zorro-antd/select';
@Component({
  selector: 'eudamed-input-wrapper',
  templateUrl: './eudamed-input.component.html',
  styleUrls: ['./eudamed-input.component.scss'],
})
export class EudamedInputComponent implements AfterContentInit{
    @ContentChild(NzInputDirective, {read: ElementRef}) input!: ElementRef;
    @ContentChild(NzSelectComponent) select!: NzSelectComponent;
    @ContentChild(NzSelectComponent, {read: ElementRef}) selectElement: ElementRef;

    @ContentChild(NgControl) control: NgControl;

    isEmpty = true;
    isFocused: boolean;
    isError: boolean;
    inputValue: any;

    validityOfInput = true;

    @Input()
    label: string;

    @Input()
    optionalClass: any;

    currentStateClass = ['empty-input', 'label'];

    ngAfterContentInit(): void {
        if (this.control?.control?.value === undefined ||
            this.control?.control?.value === '' ||
            this.control?.control?.value === null){
            this.optionalClass = ['empty-input', 'label'];
            this.currentStateClass = ['empty-input', 'label'];
        }else{
            this.currentStateClass = ['non-empty-input', 'label'];
        }

        this.control?.valueChanges?.subscribe((value: any) => {
            this.inputValue = value;
            if (value === null || value === '' || value === undefined){
                this.optionalClass = ['empty-input', 'label'];
                this.currentStateClass = ['empty-input', 'label'];
            }else {
                this.currentStateClass = ['non-empty-input', 'label'];
            }
        });
        this.control?.statusChanges?.subscribe((status: any) => {
            if (status === 'INVALID'){
                this.currentStateClass = ['invalid-input', 'label'];
                this.validityOfInput = false;
            }else{
                this.validityOfInput = true;
                if (this.inputValue === null ||
                    this.inputValue === '' ||
                    this.inputValue === undefined){
                    this.optionalClass = ['empty-input', 'label'];
                    this.currentStateClass = ['empty-input', 'label'];
                }else{
                    this.currentStateClass = ['non-empty-input', 'label'];
                }
            }
        });

        if (this.select){
             const selectStylableElement: HTMLElement = this.selectElement.nativeElement.firstChild;
             selectStylableElement.style.height = '52px';
             selectStylableElement.style.display = 'flex';
             selectStylableElement.style.alignItems = 'flex-end';
             selectStylableElement.style.paddingLeft = '8px';
         }
    }
}
