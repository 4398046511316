import {Injectable} from '@angular/core';
import {SelectionService, SelectionType} from './selection.service';
import TokenModel, {ActorCodeType} from '../../pages/account/token-management/token.model';
import {ActorCodesService} from './actor-codes.service';
import {TokenManagementService} from '../../pages/account/token-management/token-management.service';
import DeviceColumn from '../model/device-column.enum';
import {Observable, zip} from 'rxjs';
import SimplifiedDevice from '../model/simplified-device.model';
import {ApiService} from './api.service';
import {DeviceService} from './device.store.service';
import {HttpParams} from '@angular/common/http';
import {Filter} from '../model/filter.model';
import {FilterService} from './filters/filter.service';
import {ActionType} from "../constants/action-type.constants";
import Device from "../model/device.model";

@Injectable()
export class EudamedActionService {

  private tokenData: Array<TokenModel>;

  constructor(private selectionService: SelectionService,
              private actorCodesService: ActorCodesService,
              private tokenManagementService: TokenManagementService,
              private apiService: ApiService,
              private deviceService: DeviceService,
              private filterService: FilterService) {
    this.tokenManagementService.getTokens().subscribe((eudamedToken: Array<TokenModel>) => {
      this.actorCodesService.retrieveAllActorCodes().subscribe(() => {
        this.tokenData = [...this.actorCodesService.MFActorCodes]
          .map(actorCode => {
            const savedToken = eudamedToken.find((tokenModel) => tokenModel.actorCode === actorCode && tokenModel.actorCodeType === ActorCodeType.MF_ACTOR_CODE);
            return {actorCode, actorCodeType: ActorCodeType.MF_ACTOR_CODE, eudamedToken: savedToken?.eudamedToken || '', initialValue: savedToken?.eudamedToken || '', initialNodeIDValue: savedToken?.nodeID || '', nodeID: savedToken?.nodeID || ''};
          });
        this.tokenData.push(...[...this.actorCodesService.PRActorCodes]
          .map(actorCode => {
            const savedToken = eudamedToken.find((tokenModel) => tokenModel.actorCode === actorCode && tokenModel.actorCodeType === ActorCodeType.PR_ACTOR_CODE);
            return {actorCode, actorCodeType: ActorCodeType.PR_ACTOR_CODE, eudamedToken: savedToken?.eudamedToken || '', initialValue: savedToken?.eudamedToken || '', initialNodeIDValue: savedToken?.nodeID || '', nodeID: savedToken?.nodeID || ''};
          }));
      });
    });
  }

  checkTokensForBulkRegister(): Observable<any> | null {
    if (this.selectionService.getCurrentSelectionType === SelectionType.FREE_SELECT || this.selectionService.getCurrentSelectionType === SelectionType.CURRENT_PAGE) {
      const currentDevicesActorCodes = this.selectionService.getCurrentlySelectedDevices.map((device) => {
        return new TokenModel(device[DeviceColumn.SRN], device.fullPayload.MFActorCode ? ActorCodeType.MF_ACTOR_CODE : ActorCodeType.PR_ACTOR_CODE, '', '');
        // return { actorCode: device[DeviceColumn.SRN], actorCodeType: device.fullPayload.MFActorCode ? ActorCodeType.MF_ACTOR_CODE : ActorCodeType.PR_ACTOR_CODE };
      }).reduce<Array<TokenModel>>((uniqueActorCodes, currentActorCode) => {
        if (uniqueActorCodes.some((actorCodeToCheck) => actorCodeToCheck.actorCode === currentActorCode.actorCode
          && actorCodeToCheck.actorCodeType === currentActorCode.actorCodeType)) {
          return uniqueActorCodes;
        } else {
          uniqueActorCodes.push(currentActorCode);
          return uniqueActorCodes;
        }
      }, []);

      // console.log(currentDevicesActorCodes);
      // console.log(this.tokenData);

      return this.tokenManagementService.getAllActorCodesWithoutTokens(currentDevicesActorCodes);
    } else {
      return this.tokenManagementService.getAllActorCodesWithoutTokensForAllDevices();
    }
  }

  sendCurrentlySelectedDevices(): Observable<any> {
    if (this.selectionService.getCurrentSelectionType === SelectionType.FREE_SELECT || this.selectionService.getCurrentSelectionType === SelectionType.CURRENT_PAGE) {
      return this.executeActionForSelectedDevices(ActionType.SEND);
    } else {
      return this.executeActionForAllDevices(ActionType.SEND);
    }
  }

  deleteProvidedDevices(actionType: ActionType, providedDevices: Device[]): Observable<any> {
    const basicDeviceToBeDeleted = providedDevices.find(device => device.fullPayload.deviceType.includes('BASIC'));
    const udidiDevicesToBeDeleted = providedDevices.filter(device => !device.fullPayload.deviceType.includes('BASIC'))
      .map(device => device.fullPayload);
    if (basicDeviceToBeDeleted) {
      if (udidiDevicesToBeDeleted.length === 0) {
        return this.apiService.delete(`/eudamed/v1/devices/${this.deviceService.getCurrentLegislation()}/basic-udi-dis/${basicDeviceToBeDeleted.fullPayload.basicUDIIdentifierIssuingEntityCode}/${encodeURIComponent(basicDeviceToBeDeleted.fullPayload.basicUDIIdentifierDICode)}/${actionType === ActionType.DELETE_WITH_LINKED}`);
      } else {
        const basicObservable = this.apiService.delete(`/eudamed/v1/devices/${this.deviceService.getCurrentLegislation()}/basic-udi-dis/${basicDeviceToBeDeleted.fullPayload.basicUDIIdentifierIssuingEntityCode}/${encodeURIComponent(basicDeviceToBeDeleted.fullPayload.basicUDIIdentifierDICode)}/${actionType === ActionType.DELETE_WITH_LINKED}`);
        const udidiObservable = this.apiService.delete(`/eudamed/v1/devices/${this.deviceService.getCurrentLegislation()}/udi-dis/collection`,
          udidiDevicesToBeDeleted, {'Content-Type': 'application/json'});
        return zip(basicObservable, udidiObservable);
      }
    } else {
      udidiDevicesToBeDeleted.forEach(udidevice => {
        if (udidevice.basicUDIIdentifier?.basicUDIIdentifierDICode === '' && udidevice.basicUDIIdentifier?.basicUDIIdentifierIssuingEntityCode === '') {
          udidevice.basicUDIIdentifier.basicUDIIdentifierDICode = 'null';
          udidevice.basicUDIIdentifier.basicUDIIdentifierIssuingEntityCode = 'null';
        }
      });
      return this.apiService.delete(`/eudamed/v1/devices/${this.deviceService.getCurrentLegislation()}/udi-dis/collection`,
        udidiDevicesToBeDeleted, {'Content-Type': 'application/json'});
    }
  }

  executeActionForSingleDevice(actionType: ActionType, deviceToBeDeleted: Device): Observable<any> {
    // if (actionType === ActionType.DELETE || actionType === ActionType.DELETE_WITH_LINKED) {
    return this.deleteProvidedDevices(actionType, [deviceToBeDeleted]);
    // }
  }

  executeActionForSelectedDevices(actionType: ActionType): Observable<any> {
    if (actionType === ActionType.DELETE || actionType === ActionType.DELETE_WITH_LINKED) {
      return this.deleteProvidedDevices(actionType, this.selectionService.getCurrentlySelectedDevices);
    } else {
      const currentSimplifiedDevices = this.selectionService.getCurrentlySelectedDevices.map((device) => {
        return new SimplifiedDevice(device[DeviceColumn.PublicID], device.getIssuingEntity(), device.fullPayload.deviceType, device[DeviceColumn.SRN], device.fullPayload.MFActorCode ? ActorCodeType.MF_ACTOR_CODE : ActorCodeType.PR_ACTOR_CODE);
      });
      const actionName = (actionType === ActionType.SEND) ? 'register' : 'update';
      return this.apiService.post(`/udimanager/v1/editor/bulk-actions/bulk-${actionName}/collection`, currentSimplifiedDevices, {'Content-Type': 'application/json'});
    }
  }

  executeActionForAllDevices(actionType: ActionType): Observable<any> {
    let params: HttpParams = new HttpParams();

    this.filterService.getFilters().forEach((filter: Filter) => {
      filter.isApplied = true;
      if (filter.isSort) {
        params = params.set('direction', filter.valueToString());
        params = params.set('column', filter.fieldToString());
      } else {
        params = params.set(filter.fieldToString(), filter.valueToString());
      }
    });

    const legislation = this.deviceService.getCurrentLegislation();
    const numberOfDevices = this.deviceService.getNumberOfAllDevices();
    const actionName = (actionType === ActionType.SEND) ? 'register' : 'update';
    return this.apiService.get(`/udimanager/v1/editor/bulk-actions/bulk-${actionName}/collection/${legislation}/${numberOfDevices}`, { headers: { 'Content-Type': 'application/json' }, params });
  }

  updateCurrentlySelectedDevices(): Observable<any> {
    if (this.selectionService.getCurrentSelectionType === SelectionType.FREE_SELECT || this.selectionService.getCurrentSelectionType === SelectionType.CURRENT_PAGE) {
      return this.executeActionForSelectedDevices(ActionType.UPDATE);
    } else {
      return this.executeActionForAllDevices(ActionType.UPDATE);
    }
  }
}
