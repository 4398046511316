import {MdnEditorCodelist} from './codelistsmdn.constants';

export interface CodelistValue {
  value: string;
  label: string;
  description: string;
  disabled?: boolean;
  level?: number;
  bottomLevel?: boolean;
}

export interface Codelist {
  name: string;
  values: CodelistValue[];
  perTypeFilter?: {[key: string]: CodelistValue[]};
}

export interface CodelistRiskClass {
  name: string;
  type: [
    {
      typeName: string,
      values: CodelistValue[],
    },
    {
      typeName: string,
      values: CodelistValue[],
    },
    {
      typeName: string,
      values: CodelistValue[],
    },
    {
      typeName: string,
      values: CodelistValue[],
    },
    {
      typeName: string,
      values: CodelistValue[],
    },
  ];
}

export interface CodelistSpecial {
  name: string;
  valuesMDR: CodelistValue[];
  valuesIVDR: CodelistValue[];
}

export interface ComponentCodeList {
  formElement: string;
  codeList?: Codelist;
}

export const IssuingEntityCodeList: Codelist = {
  name: 'IssuingEntity Code List',
  values: [
    { value: 'GS1', label: 'GS1', description: '' },
    { value: 'HIBCC', label: 'HIBCC', description: '' },
    { value: 'ICCBBA', label: 'ICCBBA', description: '' },
    { value: 'IFA', label: 'IFA', description: '' },
    { value: 'EUDAMED', label: 'EUDAMED', description: '' }
  ]
};

export const ApplicableLegislationTypeCodeList: Codelist = {
  name: 'ApplicableLegislationType Code List',
  values: [
    { value: 'MDD', label: 'MDD', description: ''},
    { value: 'AIMDD', label: 'AIMDD', description: ''},
    { value: 'IVDD', label: 'IVDD', description: ''},
    { value: 'MDR', label: 'MDR', description: ''},
    { value: 'IVDR', label: 'IVDR', description: ''}
  ]
};

export const MulticomponentDeviceTypeCodeList: Codelist = {
  name: 'MulticomponentDeviceType Code List',
  values: [
    { value: 'DEVICE', label: 'CODELISTCONSTANTS.multicomponentDeviceTypeCode.DEVICE', description: '' },
    { value: 'PROCEDURE_PACK', label: 'CODELISTCONSTANTS.multicomponentDeviceTypeCode.PROCEDURE_PACK', description: '' },
    { value: 'SYSTEM', label: 'CODELISTCONSTANTS.multicomponentDeviceTypeCode.SYSTEM', description: '' }
  ]
};

export const SpecialDeviceTypeCodeList: Codelist = {
  name: 'SpecialDeviceType Code List',
  values: [
    { value: 'MDR_RIGID_GAS_PERMEABLE', label: 'CODELISTCONSTANTS.specialDevice.MDR_RIGID_GAS_PERMEABLE', description: '' },
    { value: 'MDR_SOFTWARE', label: 'CODELISTCONSTANTS.specialDevice.MDR_SOFTWARE', description: '' },
    { value: 'MDR_STANDARD_SOFT_CONTACT_LENSES', label: 'CODELISTCONSTANTS.specialDevice.MDR_STANDARD_SOFT_CONTACT_LENSES', description: '' },
    { value: 'IVDR_SOFTWARE', label: 'CODELISTCONSTANTS.specialDevice.IVDR_SOFTWARE', description: '' }
  ],
  perTypeFilter: {
      MDR: [
        { value: 'MDR_RIGID_GAS_PERMEABLE', label: 'CODELISTCONSTANTS.specialDevice.MDR_RIGID_GAS_PERMEABLE', description: 'Rigid Gas Permeable (RGP) - Made-to-Order Soft Contact Lenses.' },
        { value: 'MDR_SOFTWARE', label: 'CODELISTCONSTANTS.specialDevice.MDR_SOFTWARE', description: 'Software' },
        { value: 'MDR_STANDARD_SOFT_CONTACT_LENSES', label: 'CODELISTCONSTANTS.specialDevice.MDR_STANDARD_SOFT_CONTACT_LENSES', description: 'Standard Soft Contact Lenses.' },
        { value: 'MDR_MADE_TO_ORDER', label: 'CODELISTCONSTANTS.specialDevice.MDR_MADE_TO_ORDER', description: 'Made to order soft contact lenses.' },
        { value: 'MDR_SPECTACLES_FRAMES', label: 'CODELISTCONSTANTS.specialDevice.MDR_SPECTACLES_FRAMES', description: 'Spectacle frames.' },
        { value: 'MDR_SPECTACLES_LENSES', label: 'CODELISTCONSTANTS.specialDevice.MDR_SPECTACLES_LENSES', description: 'Spectacle lenses.' },
        { value: 'MDR_READY_MADE_SPECTACLES', label: 'CODELISTCONSTANTS.specialDevice.MDR_READY_MADE_SPECTACLES', description: 'Ready-made reading spectacles.' }
      ],
      IVDR: [
        { value: 'IVDR_SOFTWARE', label: 'CODELISTCONSTANTS.specialDevice.IVDR_SOFTWARE', description: '' }
      ],
      MDD: [
        { value: 'MDD_MADE_TO_ORDER', label: 'CODELISTCONSTANTS.specialDevice.MDD_MADE_TO_ORDER', description: 'Made to order soft contact lenses.' },
        { value: 'MDD_READY_MADE_SPECTACLES', label: 'CODELISTCONSTANTS.specialDevice.MDD_READY_MADE_SPECTACLES', description: 'Ready-made reading spectacles.' },
        { value: 'MDD_RIGID_GAS_PERMEABLE', label: 'CODELISTCONSTANTS.specialDevice.MDD_RIGID_GAS_PERMEABLE', description: 'Rigid Gas Permeable (RGP) Contact Lenses.' },
        { value: 'MDD_SOFTWARE', label: 'CODELISTCONSTANTS.specialDevice.MDD_SOFTWARE', description: 'Software' },
        { value: 'MDD_SPECTACLES_FRAMES', label: 'CODELISTCONSTANTS.specialDevice.MDD_SPECTACLES_FRAMES', description: 'Spectacle frames.' },
        { value: 'MDD_SPECTACLES_LENSES', label: 'CODELISTCONSTANTS.specialDevice.MDD_SPECTACLES_LENSES', description: 'Spectacle lenses.' },
        { value: 'MDD_STANDARD_SOFT_CONTACT_LENSES', label: 'CODELISTCONSTANTS.specialDevice.MDD_STANDARD_SOFT_CONTACT_LENSES', description: 'Standard soft contact lenses.' }
      ],
      AIMDD: [
        { value: 'AIMDD_SOFTWARE', label: 'CODELISTCONSTANTS.specialDevice.AIMDD_SOFTWARE', description: 'Software'}
      ],
      IVD: [
        { value: 'IVDD_SOFTWARE', label: 'CODELISTCONSTANTS.specialDevice.IVDD_SOFTWARE', description: 'Software'}
      ]
  }
};

export const RiskClassTypeCodeList: Codelist = {
  name: 'RiskClassType Code List',
  values: [
        { value: 'CLASS_I', label: 'CLASS_I', description: '' },
        { value: 'CLASS_IIA', label: 'CLASS_IIA', description: '' },
        { value: 'CLASS_IIB', label: 'CLASS_IIB', description: '' },
        { value: 'CLASS_III', label: 'CLASS_III', description: '' },
        { value: 'AIMDD', label: 'AIMDD', description: '' },
        { value: 'CLASS_A', label: 'CLASS_A', description: '' },
        { value: 'CLASS_B', label: 'CLASS_B', description: '' },
        { value: 'CLASS_C', label: 'CLASS_C', description: '' },
        { value: 'CLASS_D', label: 'CLASS_D', description: '' },
        { value: 'IVD_ANNEX_II_LIST_A', label: 'IVD_ANNEX_II_LIST_A', description: '' },
        { value: 'IVD_ANNEX_II_LIST_B', label: 'IVD_ANNEX_II_LIST_B', description: '' },
        { value: 'IVD_DEVICES_SELF_TESTING', label: 'IVD_DEVICES_SELF_TESTING', description: '' },
        { value: 'IVD_GENERAL', label: 'IVD_GENERAL', description: '' }
  ],
  perTypeFilter: {
      MDR: [
        { value: 'CLASS_I', label: 'CLASS_I', description: '' },
        { value: 'CLASS_IIA', label: 'CLASS_IIA', description: '' },
        { value: 'CLASS_IIB', label: 'CLASS_IIB', description: '' },
        { value: 'CLASS_III', label: 'CLASS_III', description: '' },
      ],
      MDD: [
        { value: 'CLASS_I', label: 'CLASS_I', description: '' },
        { value: 'CLASS_IIA', label: 'CLASS_IIA', description: '' },
        { value: 'CLASS_IIB', label: 'CLASS_IIB', description: '' },
        { value: 'CLASS_III', label: 'CLASS_III', description: '' },
      ],
      AIMDD: [
        { value: 'AIMDD', label: 'AIMDD', description: '' },
      ],
      IVDR: [
        { value: 'CLASS_A', label: 'CLASS_A', description: '' },
        { value: 'CLASS_B', label: 'CLASS_B', description: '' },
        { value: 'CLASS_C', label: 'CLASS_C', description: '' },
        { value: 'CLASS_D', label: 'CLASS_D', description: '' },
      ],
      IVDD: [
        { value: 'IVD_ANNEX_II_LIST_A', label: 'IVD_ANNEX_II_LIST_A', description: '' },
        { value: 'IVD_ANNEX_II_LIST_B', label: 'IVD_ANNEX_II_LIST_B', description: '' },
        { value: 'IVD_DEVICES_SELF_TESTING', label: 'IVD_DEVICES_SELF_TESTING', description: '' },
        { value: 'IVD_GENERAL', label: 'IVD_GENERAL', description: '' }
      ]
  }
};

export const DeviceCertificateTypeCodeList: Codelist = {
  name: 'DeviceCertificateType Code List',
  values: [
    { value: 'AIMDD_II_EX_4', label: 'AIMDD_II_EX_4', description: ''},
    { value: 'AIMDD_II_4', label: 'AIMDD_II_4', description: ''},
    { value: 'AIMDD_III', label: 'AIMDD_III', description: ''},
    { value: 'AIMDD_IV', label: 'AIMDD_IV', description: ''},
    { value: 'AIMDD_V', label: 'AIMDD_V', description: ''},
    { value: 'IVDD_III_6', label: 'IVDD_III_6', description: ''},
    { value: 'IVDD_IV_EX_4_6', label: 'IVDD_IV_EX_4_6', description: ''},
    { value: 'IVDD_IV_4', label: 'IVDD_IV_4', description: ''},
    { value: 'IVDD_IV_6', label: 'IVDD_IV_6', description: ''},
    { value: 'IVDD_V', label: 'IVDD_V', description: ''},
    { value: 'IVDD_VI', label: 'IVDD_VI', description: ''},
    { value: 'IVDD_VII_EX_5', label: 'IVDD_VII_EX_5', description: ''},
    { value: 'IVDD_VII_5', label: 'IVDD_VII_5', description: ''},
    { value: 'IVDR_PRODUCTION_QUALITY_ASSURANCE', label: 'IVDR_PRODUCTION_QUALITY_ASSURANCE', description: ''},
    { value: 'IVDR_QUALITY_MANAGEMENT_SYSTEM', label: 'IVDR_QUALITY_MANAGEMENT_SYSTEM', description: ''},
    { value: 'IVDR_TECHNICAL_DOCUMENTATION', label: 'IVDR_TECHNICAL_DOCUMENTATION', description: ''},
    { value: 'IVDR_TYPE_EXAMINATION', label: 'IVDR_TYPE_EXAMINATION', description: ''},
    { value: 'MDD_II_EX_4', label: 'MDD_II_EX_4', description: ''},
    { value: 'MDD_II_4', label: 'MDD_II_4', description: ''},
    { value: 'MDD_III', label: 'MDD_III', description: ''},
    { value: 'MDD_IV', label: 'MDD_IV', description: ''},
    { value: 'MDD_V', label: 'MDD_V', description: ''},
    { value: 'MDD_VI', label: 'MDD_VI', description: ''},
    { value: 'MDR_PRODUCT_VERIFICATION', label: 'MDR_PRODUCT_VERIFICATION', description: ''},
    { value: 'MDR_QUALITY_ASSURANCE', label: 'MDR_QUALITY_ASSURANCE', description: ''},
    { value: 'MDR_QUALITY_MANAGEMENT_SYSTEM', label: 'MDR_QUALITY_MANAGEMENT_SYSTEM', description: ''},
    { value: 'MDR_TECHNICAL_DOCUMENTATION', label: 'MDR_TECHNICAL_DOCUMENTATION', description: ''},
    { value: 'MDR_TYPE_EXAMINATION', label: 'MDR_TYPE_EXAMINATION', description: ''}
  ],
  perTypeFilter: {
    MDR: [
      { value: 'MDR_PRODUCT_VERIFICATION', label: 'MDR_PRODUCT_VERIFICATION', description: ''},
      { value: 'MDR_QUALITY_ASSURANCE', label: 'MDR_QUALITY_ASSURANCE', description: ''},
      { value: 'MDR_QUALITY_MANAGEMENT_SYSTEM', label: 'MDR_QUALITY_MANAGEMENT_SYSTEM', description: ''},
      { value: 'MDR_TECHNICAL_DOCUMENTATION', label: 'MDR_TECHNICAL_DOCUMENTATION', description: ''},
      { value: 'MDR_TYPE_EXAMINATION', label: 'MDR_TYPE_EXAMINATION', description: ''},
    ],
    MDD: [
      { value: 'MDD_II_EX_4', label: 'MDD_II_EX_4', description: ''},
      { value: 'MDD_II_4', label: 'MDD_II_4', description: ''},
      { value: 'MDD_III', label: 'MDD_III', description: ''},
      { value: 'MDD_IV', label: 'MDD_IV', description: ''},
      { value: 'MDD_V', label: 'MDD_V', description: ''},
      { value: 'MDD_VI', label: 'MDD_VI', description: ''},
    ],
    AIMDD: [
      { value: 'AIMDD_II_EX_4', label: 'AIMDD_II_EX_4', description: ''},
      { value: 'AIMDD_II_4', label: 'AIMDD_II_4', description: ''},
      { value: 'AIMDD_III', label: 'AIMDD_III', description: ''},
      { value: 'AIMDD_IV', label: 'AIMDD_IV', description: ''},
      { value: 'AIMDD_V', label: 'AIMDD_V', description: ''},
    ],
    IVDR: [
      { value: 'IVDR_PRODUCTION_QUALITY_ASSURANCE', label: 'IVDR_PRODUCTION_QUALITY_ASSURANCE', description: ''},
      { value: 'IVDR_QUALITY_MANAGEMENT_SYSTEM', label: 'IVDR_QUALITY_MANAGEMENT_SYSTEM', description: ''},
      { value: 'IVDR_TECHNICAL_DOCUMENTATION', label: 'IVDR_TECHNICAL_DOCUMENTATION', description: ''},
      { value: 'IVDR_TYPE_EXAMINATION', label: 'IVDR_TYPE_EXAMINATION', description: ''},
    ],
    IVDD: [
      { value: 'IVDD_III_6', label: 'IVDD_III_6', description: ''},
      { value: 'IVDD_IV_EX_4_6', label: 'IVDD_IV_EX_4_6', description: ''},
      { value: 'IVDD_IV_4', label: 'IVDD_IV_4', description: ''},
      { value: 'IVDD_IV_6', label: 'IVDD_IV_6', description: ''},
      { value: 'IVDD_V', label: 'IVDD_V', description: ''},
      { value: 'IVDD_VI', label: 'IVDD_VI', description: ''},
      { value: 'IVDD_VII_EX_5', label: 'IVDD_VII_EX_5', description: ''},
      { value: 'IVDD_VII_5', label: 'IVDD_VII_5', description: ''},
    ]
  }
};

export const SubstatusRecallScopeCodeList: Codelist = {
  name: 'SubstatusRecallScope Code List',
  values: [
    { value: 'LOT_NUMBER', label: 'LOT_NUMBER', description: '' },
    { value: 'SERIAL_NUMBER', label: 'SERIAL_NUMBER', description: '' },
    { value: 'SOFTWARE_VERSION', label: 'SOFTWARE_VERSION', description: '' },
    { value: 'UDI-DI', label: 'UDI-DI', description: '' }
  ]
};

export const DeviceStatusCodeCodeList: Codelist = {
  name: 'DeviceStatusCode Code List',
  values: [
    { value: 'ON_THE_MARKET', label: 'CODELISTCONSTANTS.deviceStatusCode.ON_THE_MARKET', description: '' },
    { value: 'NO_LONGER_PLACED_ON_THE_MARKET', label: 'CODELISTCONSTANTS.deviceStatusCode.NO_LONGER_PLACED_ON_THE_MARKET', description: '' },
    { value: 'NOT_INTENDED_FOR_EU_MARKET', label: 'CODELISTCONSTANTS.deviceStatusCode.NOT_INTENDED_FOR_EU_MARKET', description: '' }
  ]
};

export const DeviceSubStatusCodeCodeList: Codelist = {
  name: 'DeviceSubStatusCode Code List',
  values: [
    { value: 'RECALLED', label: 'CODELISTCONSTANTS.substatusCode.RECALLED', description: '' },
    { value: 'FIELD_SAFETY_CORRECTIVE_ACTION_INITIATED', label: 'CODELISTCONSTANTS.substatusCode.FIELD_SAFETY_CORRECTIVE_ACTION_INITIATED', description: '' }
  ]
};

export const EUCountryTypeCodeList: Codelist = {
  name: 'EUCountryType Code List',
  values: [
    { value: 'AT', label: 'Austria [AT]', description: '' },
    { value: 'BE', label: 'Belgium [BE]', description: '' },
    { value: 'BG', label: 'Bulgaria [BG]', description: '' },
    { value: 'HR', label: 'Croatia [HR]', description: '' },
    { value: 'CY', label: 'Cyprus [CY]', description: '' },
    { value: 'CZ', label: 'Czech Republic [CZ]', description: '' },
    { value: 'DK', label: 'Denmark [DK]', description: '' },
    { value: 'EE', label: 'Estonia [EE]', description: '' },
    { value: 'FI', label: 'Finland [FI]', description: '' },
    { value: 'FR', label: 'France [FR]', description: '' },
    { value: 'DE', label: 'Germany [DE]', description: '' },
    { value: 'EL', label: 'Greece [EL]', description: '' },
    { value: 'HU', label: 'Hungary [HU]', description: '' },
    { value: 'IE', label: 'Ireland [IE]', description: '' },
    { value: 'IS', label: 'Iceland [IS]', description: '' },
    { value: 'IT', label: 'Italy [IT]', description: '' },
    { value: 'LV', label: 'Latvia [LV]', description: '' },
    { value: 'LI', label: 'Liechtenstein [LI]', description: '' },
    { value: 'LT', label: 'Lithuania [LT]', description: '' },
    { value: 'LU', label: 'Luxembourg [LU]', description: '' },
    { value: 'MT', label: 'Malta [MT]', description: '' },
    { value: 'NL', label: 'Netherlands [NL]', description: '' },
    { value: 'NO', label: 'Norway [NO]', description: '' },
    { value: 'PL', label: 'Poland [PL]', description: '' },
    { value: 'PT', label: 'Portugal [PT]', description: '' },
    { value: 'RO', label: 'Romania [RO]', description: '' },
    { value: 'SK', label: 'Slovakia [SK]', description: '' },
    { value: 'SI', label: 'Slovenia [SI]', description: '' },
    { value: 'ES', label: 'Spain [ES]', description: '' },
    { value: 'SE', label: 'Sweden [SE]', description: '' },
    { value: 'TR', label: 'Turkey [TR]', description: '' },
    { value: 'XI', label: 'United Kingdom (Northern Ireland only) [XI]', description: '' }
  ]
};

export const NonMedicalDeviceTypeCodeList: Codelist = {
  name: 'NonMedicalDeviceType Code List',
  values: [
    { value: 'BRAIN_ELECTROSTIMULATION', label: 'CODELISTCONSTANTS.annexXVINonMedicalDeviceType.BRAIN_ELECTROSTIMULATION', description: '' },
    { value: 'CONTACT_LENSES', label: 'CODELISTCONSTANTS.annexXVINonMedicalDeviceType.CONTACT_LENSES', description: '' },
    { value: 'EMR', label: 'CODELISTCONSTANTS.annexXVINonMedicalDeviceType.EMR', description: '' },
    { value: 'EQUIPMENT_FOR_ADIPOSE_TISSUE', label: 'CODELISTCONSTANTS.annexXVINonMedicalDeviceType.EQUIPMENT_FOR_ADIPOSE_TISSUE', description: '' },
    { value: 'FILLING_BY_INJECTION', label: 'CODELISTCONSTANTS.annexXVINonMedicalDeviceType.FILLING_BY_INJECTION', description: '' },
    { value: 'PRODUCT_IN_BODY', label: 'CODELISTCONSTANTS.annexXVINonMedicalDeviceType.PRODUCT_IN_BODY', description: '' }
  ]
};

export const ProductionIdentifierElementTypeCodeList: Codelist = {
  name: 'PIElementType Code List',
  values: [
    { value: 'BATCH_NUMBER', label: 'CODELISTCONSTANTS.productionIdentifier.BATCH_NUMBER', description: '' },
    { value: 'EXPIRATION_DATE', label: 'CODELISTCONSTANTS.productionIdentifier.EXPIRATION_DATE', description: '' },
    { value: 'MANUFACTURING_DATE', label: 'CODELISTCONSTANTS.productionIdentifier.MANUFACTURING_DATE', description: '' },
    { value: 'SERIALISATION_NUMBER', label: 'CODELISTCONSTANTS.productionIdentifier.SERIALISATION_NUMBER', description: '' },
    { value: 'SOFTWARE_IDENTIFICATION', label: 'CODELISTCONSTANTS.productionIdentifier.SOFTWARE_IDENTIFICATION', description: '' }
  ]
};

export const RelatedUDILinkTypeCodeList: Codelist = {
  name: 'RelatedUDILinkType Code List',
  values: [
    { value: 'LEGACY_REGULATION_DEVICE_LINK', label: 'LEGACY_REGULATION_DEVICE_LINK', description: '' }
  ]
};

export const ClinicalSizeTypeCodeList: Codelist = {
  name: 'ClinicalSizeType Code List',
  values: [
    { value: 'CST1', label: 'CODELISTCONSTANTS.clinicalSizeType.CST1', description: '' },
    { value: 'CST2', label: 'CODELISTCONSTANTS.clinicalSizeType.CST2', description: '' },
    { value: 'CST3', label: 'CODELISTCONSTANTS.clinicalSizeType.CST3', description: '' },
    { value: 'CST4', label: 'CODELISTCONSTANTS.clinicalSizeType.CST4', description: '' },
    { value: 'CST5', label: 'CODELISTCONSTANTS.clinicalSizeType.CST5', description: '' },
    { value: 'CST6', label: 'CODELISTCONSTANTS.clinicalSizeType.CST6', description: '' },
    { value: 'CST7', label: 'CODELISTCONSTANTS.clinicalSizeType.CST7', description: '' },
    { value: 'CST8', label: 'CODELISTCONSTANTS.clinicalSizeType.CST8', description: '' },
    { value: 'CST9', label: 'CODELISTCONSTANTS.clinicalSizeType.CST9', description: '' },
    { value: 'CST10', label: 'CODELISTCONSTANTS.clinicalSizeType.CST10', description: '' },
    { value: 'CST11', label: 'CODELISTCONSTANTS.clinicalSizeType.CST11', description: '' },
    { value: 'CST12', label: 'CODELISTCONSTANTS.clinicalSizeType.CST12', description: '' },
    { value: 'CST13', label: 'CODELISTCONSTANTS.clinicalSizeType.CST13', description: '' },
    { value: 'CST14', label: 'CODELISTCONSTANTS.clinicalSizeType.CST14', description: '' },
    { value: 'CST15', label: 'CODELISTCONSTANTS.clinicalSizeType.CST15', description: '' },
    { value: 'CST16', label: 'CODELISTCONSTANTS.clinicalSizeType.CST16', description: '' },
    { value: 'CST17', label: 'CODELISTCONSTANTS.clinicalSizeType.CST17', description: '' },
    { value: 'CST18', label: 'CODELISTCONSTANTS.clinicalSizeType.CST18', description: '' },
    { value: 'CST19', label: 'CODELISTCONSTANTS.clinicalSizeType.CST19', description: '' },
    { value: 'CST20', label: 'CODELISTCONSTANTS.clinicalSizeType.CST20', description: '' },
    { value: 'CST21', label: 'CODELISTCONSTANTS.clinicalSizeType.CST21', description: '' },
    { value: 'CST22', label: 'CODELISTCONSTANTS.clinicalSizeType.CST22', description: '' },
    { value: 'CST23', label: 'CODELISTCONSTANTS.clinicalSizeType.CST23', description: '' },
    { value: 'CST24', label: 'CODELISTCONSTANTS.clinicalSizeType.CST24', description: '' },
    { value: 'CST25', label: 'CODELISTCONSTANTS.clinicalSizeType.CST25', description: '' },
    { value: 'CST26', label: 'CODELISTCONSTANTS.clinicalSizeType.CST26', description: '' },
    { value: 'CST27', label: 'CODELISTCONSTANTS.clinicalSizeType.CST27', description: '' },
    { value: 'CST28', label: 'CODELISTCONSTANTS.clinicalSizeType.CST28', description: '' },
    { value: 'CST29', label: 'CODELISTCONSTANTS.clinicalSizeType.CST29', description: '' },
    { value: 'CST30', label: 'CODELISTCONSTANTS.clinicalSizeType.CST30', description: '' },
    { value: 'CST31', label: 'CODELISTCONSTANTS.clinicalSizeType.CST31', description: '' },
    { value: 'CST32', label: 'CODELISTCONSTANTS.clinicalSizeType.CST32', description: '' },
    { value: 'CST33', label: 'CODELISTCONSTANTS.clinicalSizeType.CST33', description: '' },
    { value: 'CST34', label: 'CODELISTCONSTANTS.clinicalSizeType.CST34', description: '' },
    { value: 'CST35', label: 'CODELISTCONSTANTS.clinicalSizeType.CST35', description: '' },
    { value: 'CST36', label: 'CODELISTCONSTANTS.clinicalSizeType.CST36', description: '' },
    { value: 'CST37', label: 'CODELISTCONSTANTS.clinicalSizeType.CST37', description: '' },
    { value: 'CST38', label: 'CODELISTCONSTANTS.clinicalSizeType.CST38', description: '' },
    { value: 'CST39', label: 'CODELISTCONSTANTS.clinicalSizeType.CST39', description: '' },
    { value: 'CST40', label: 'CODELISTCONSTANTS.clinicalSizeType.CST40', description: '' },
    { value: 'CST41', label: 'CODELISTCONSTANTS.clinicalSizeType.CST41', description: '' },
    { value: 'CST42', label: 'CODELISTCONSTANTS.clinicalSizeType.CST42', description: '' },
    { value: 'CST43', label: 'CODELISTCONSTANTS.clinicalSizeType.CST43', description: '' },
    { value: 'CST44', label: 'CODELISTCONSTANTS.clinicalSizeType.CST44', description: '' },
    { value: 'CST45', label: 'CODELISTCONSTANTS.clinicalSizeType.CST45', description: '' },
    { value: 'CST46', label: 'CODELISTCONSTANTS.clinicalSizeType.CST46', description: '' },
    { value: 'CST47', label: 'CODELISTCONSTANTS.clinicalSizeType.CST47', description: '' },
    { value: 'CST48', label: 'CODELISTCONSTANTS.clinicalSizeType.CST48', description: '' },
    { value: 'CST49', label: 'CODELISTCONSTANTS.clinicalSizeType.CST49', description: '' },
    { value: 'CST50', label: 'CODELISTCONSTANTS.clinicalSizeType.CST50', description: '' },
    { value: 'CST51', label: 'CODELISTCONSTANTS.clinicalSizeType.CST51', description: '' },
    { value: 'CST52', label: 'CODELISTCONSTANTS.clinicalSizeType.CST52', description: '' },
    { value: 'CST53', label: 'CODELISTCONSTANTS.clinicalSizeType.CST53', description: '' },
    { value: 'CST54', label: 'CODELISTCONSTANTS.clinicalSizeType.CST54', description: '' },
    { value: 'CST55', label: 'CODELISTCONSTANTS.clinicalSizeType.CST55', description: '' },
    { value: 'CST56', label: 'CODELISTCONSTANTS.clinicalSizeType.CST56', description: '' },
    { value: 'CST57', label: 'CODELISTCONSTANTS.clinicalSizeType.CST57', description: '' },
    { value: 'CST58', label: 'CODELISTCONSTANTS.clinicalSizeType.CST58', description: '' },
    { value: 'CST59', label: 'CODELISTCONSTANTS.clinicalSizeType.CST59', description: '' },
    { value: 'CST60', label: 'CODELISTCONSTANTS.clinicalSizeType.CST60', description: '' },
    { value: 'CST61', label: 'CODELISTCONSTANTS.clinicalSizeType.CST61', description: '' },
    { value: 'CST62', label: 'CODELISTCONSTANTS.clinicalSizeType.CST62', description: '' },
    { value: 'CST63', label: 'CODELISTCONSTANTS.clinicalSizeType.CST63', description: '' },
    { value: 'CST65', label: 'CODELISTCONSTANTS.clinicalSizeType.CST65', description: '' },
    { value: 'CST66', label: 'CODELISTCONSTANTS.clinicalSizeType.CST66', description: '' },
    { value: 'CST67', label: 'CODELISTCONSTANTS.clinicalSizeType.CST67', description: '' },
    { value: 'CST999', label: 'CODELISTCONSTANTS.clinicalSizeType.CST999', description: '' }
  ]
};

export const ClinicalSizePrecisionCodeList: Codelist = {
  name: 'ClinicalSizePrecision Code List',
  values: [
    { value: 'RANGE', label: 'CODELISTCONSTANTS.clinicalSizePrecision.RANGE', description: '' },
    { value: 'TEXT', label: 'CODELISTCONSTANTS.clinicalSizePrecision.TEXT', description: '' },
    { value: 'VALUE', label: 'CODELISTCONSTANTS.clinicalSizePrecision.VALUE', description: '' }
  ]
};

export const ClinicalSizeUnitOfMeasureTypeCodeList: Codelist = {
  name: 'ClinicalSizeUnitOfMeasureType Code List',
  values: [
    { value: 'MU01', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU01', description: '' },
    { value: 'MU02', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU02', description: '' },
    { value: 'MU03', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU03', description: '' },
    { value: 'MU04', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU04', description: '' },
    { value: 'MU05', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU05', description: '' },
    { value: 'MU06', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU06', description: '' },
    { value: 'MU07', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU07', description: '' },
    { value: 'MU08', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU08', description: '' },
    { value: 'MU09', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU09', description: '' },
    { value: 'MU10', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU10', description: '' },
    { value: 'MU11', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU11', description: '' },
    { value: 'MU12', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU12', description: '' },
    { value: 'MU13', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU13', description: '' },
    { value: 'MU14', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU14', description: '' },
    { value: 'MU15', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU15', description: '' },
    { value: 'MU16', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU16', description: '' },
    { value: 'MU17', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU17', description: '' },
    { value: 'MU18', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU18', description: '' },
    { value: 'MU19', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU19', description: '' },
    { value: 'MU20', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU20', description: '' },
    { value: 'MU21', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU21', description: '' },
    { value: 'MU22', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU22', description: '' },
    { value: 'MU23', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU23', description: '' },
    { value: 'MU24', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU24', description: '' },
    { value: 'MU25', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU25', description: '' },
    { value: 'MU26', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU26', description: '' },
    { value: 'MU27', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU27', description: '' },
    { value: 'MU28', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU28', description: '' },
    { value: 'MU29', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU29', description: '' },
    { value: 'MU30', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU30', description: '' },
    { value: 'MU31', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU31', description: '' },
    { value: 'MU32', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU32', description: '' },
    { value: 'MU33', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU33', description: '' },
    { value: 'MU34', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU34', description: '' },
    { value: 'MU35', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU35', description: '' },
    { value: 'MU36', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU36', description: '' },
    { value: 'MU37', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU37', description: '' },
    { value: 'MU38', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU38', description: '' },
    { value: 'MU39', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU39', description: '' },
    { value: 'MU40', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU40', description: '' },
    { value: 'MU41', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU41', description: '' },
    { value: 'MU42', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU42', description: '' },
    { value: 'MU43', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU43', description: '' },
    { value: 'MU44', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU44', description: '' },
    { value: 'MU45', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU45', description: '' },
    { value: 'MU46', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU46', description: '' },
    { value: 'MU47', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU47', description: '' },
    { value: 'MU48', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU48', description: '' },
    { value: 'MU49', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU49', description: '' },
    { value: 'MU50', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU50', description: '' },
    { value: 'MU51', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU51', description: '' },
    { value: 'MU52', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU52', description: '' },
    { value: 'MU53', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU53', description: '' },
    { value: 'MU54', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU54', description: '' },
    { value: 'MU55', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU55', description: '' },
    { value: 'MU56', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU56', description: '' },
    { value: 'MU57', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU57', description: '' },
    { value: 'MU58', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU58', description: '' },
    { value: 'MU59', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU59', description: '' },
    { value: 'MU60', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU60', description: '' },
    { value: 'MU61', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU61', description: '' },
    { value: 'MU62', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU62', description: '' },
    { value: 'MU63', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU63', description: '' },
    { value: 'MU64', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU64', description: '' },
    { value: 'MU65', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU65', description: '' },
    { value: 'MU66', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU66', description: '' },
    { value: 'MU67', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU67', description: '' },
    { value: 'MU68', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU68', description: '' },
    { value: 'MU69', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU69', description: '' },
    { value: 'MU70', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU70', description: '' },
    { value: 'MU71', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU71', description: '' },
    { value: 'MU72', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU72', description: '' },
    { value: 'MU73', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU73', description: '' },
    { value: 'MU74', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU74', description: '' },
    { value: 'MU75', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU75', description: '' },
    { value: 'MU76', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU76', description: '' },
    { value: 'MU77', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU77', description: '' },
    { value: 'MU78', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU78', description: '' },
    { value: 'MU79', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU79', description: '' },
    { value: 'MU80', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU80', description: '' },
    { value: 'MU81', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU81', description: '' },
    { value: 'MU82', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU82', description: '' },
    { value: 'MU83', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU83', description: '' },
    { value: 'MU84', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU84', description: '' },
    { value: 'MU85', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU85', description: '' },
    { value: 'MU86', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU86', description: '' },
    { value: 'MU87', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU87', description: '' },
    { value: 'MU88', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU88', description: '' },
    { value: 'MU89', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU89', description: '' },
    { value: 'MU90', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU90', description: '' },
    { value: 'MU91', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU91', description: '' },
    { value: 'MU92', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU92', description: '' },
    { value: 'MU93', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU93', description: '' },
    { value: 'MU94', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU94', description: '' },
    { value: 'MU95', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU95', description: '' },
    { value: 'MU96', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU96', description: '' },
    { value: 'MU97', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU97', description: '' },
    { value: 'MU98', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU98', description: '' },
    { value: 'MU99', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU99', description: '' },
    { value: 'MU100', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU100', description: '' },
    { value: 'MU101', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU101', description: '' },
    { value: 'MU102', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU102', description: '' },
    { value: 'MU103', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU103', description: '' },
    { value: 'MU104', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU104', description: '' },
    { value: 'MU105', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU105', description: '' },
    { value: 'MU106', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU106', description: '' },
    { value: 'MU107', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU107', description: '' },
    { value: 'MU108', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU108', description: '' },
    { value: 'MU109', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU109', description: '' },
    { value: 'MU110', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU110', description: '' },
    { value: 'MU111', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU111', description: '' },
    { value: 'MU112', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU112', description: '' },
    { value: 'MU113', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU113', description: '' },
    { value: 'MU114', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU114', description: '' },
    { value: 'MU115', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU115', description: '' },
    { value: 'MU116', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU116', description: '' },
    { value: 'MU117', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU117', description: '' },
    { value: 'MU118', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU118', description: '' },
    { value: 'MU119', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU119', description: '' },
    { value: 'MU120', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU120', description: '' },
    { value: 'MU121', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU121', description: '' },
    { value: 'MU122', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU122', description: '' },
    { value: 'MU123', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU123', description: '' },
    { value: 'MU124', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU124', description: '' },
    { value: 'MU125', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU125', description: '' },
    { value: 'MU126', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU126', description: '' },
    { value: 'MU127', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU127', description: '' },
    { value: 'MU128', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU128', description: '' },
    { value: 'MU129', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU129', description: '' },
    { value: 'MU130', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU130', description: '' },
    { value: 'MU131', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU131', description: '' },
    { value: 'MU132', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU132', description: '' },
    { value: 'MU133', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU133', description: '' },
    { value: 'MU134', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU134', description: '' },
    { value: 'MU135', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU135', description: '' },
    { value: 'MU136', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU136', description: '' },
    { value: 'MU137', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU137', description: '' },
    { value: 'MU138', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU138', description: '' },
    { value: 'MU139', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU139', description: '' },
    { value: 'MU140', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU140', description: '' },
    { value: 'MU141', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU141', description: '' },
    { value: 'MU142', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU142', description: '' },
    { value: 'MU143', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU143', description: '' },
    { value: 'MU144', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU144', description: '' },
    { value: 'MU145', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU145', description: '' },
    { value: 'MU146', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU146', description: '' },
    { value: 'MU147', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU147', description: '' },
    { value: 'MU148', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU148', description: '' },
    { value: 'MU149', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU149', description: '' },
    { value: 'MU150', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU150', description: '' },
    { value: 'MU151', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU151', description: '' },
    { value: 'MU152', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU152', description: '' },
    { value: 'MU153', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU153', description: '' },
    { value: 'MU154', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU154', description: '' },
    { value: 'MU156', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU156', description: '' },
    { value: 'MU157', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU157', description: '' },
    { value: 'MU158', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU158', description: '' },
    { value: 'MU159', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU159', description: '' },
    { value: 'MU160', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU160', description: '' },
    { value: 'MU161', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU161', description: '' },
    { value: 'MU162', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU162', description: '' },
    { value: 'MU163', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU163', description: '' },
    { value: 'MU164', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU164', description: '' },
    { value: 'MU165', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU165', description: '' },
    { value: 'MU166', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU166', description: '' },
    { value: 'MU167', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU167', description: '' },
    { value: 'MU168', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU168', description: '' },
    { value: 'MU169', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU169', description: '' },
    { value: 'MU170', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU170', description: '' },
    { value: 'MU172', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU172', description: '' },
    { value: 'MU173', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU173', description: '' },
    { value: 'MU175', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU175', description: '' },
    { value: 'MU176', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU176', description: '' },
    { value: 'MU999', label: 'CODELISTCONSTANTS.clinicalSizeValueUnitOfMeasure.MU999', description: '' }
  ]
};

export const MedicalHumanProductSubstanceTypeCodeList: Codelist = {
  name: 'MedicalHumanProductSubstanceType Code List',
  values: [
    { value: 'MEDICINAL_PRODUCT_SUBSTANCE', label: 'CODELISTCONSTANTS.medicalHumanProductSubstanceType.MEDICINAL_PRODUCT_SUBSTANCE', description: '' },
    { value: 'HUMAN_PRODUCT_SUBSTANCE', label: 'CODELISTCONSTANTS.medicalHumanProductSubstanceType.HUMAN_PRODUCT_SUBSTANCE', description: '' }
  ]
};

export const CMRSubstanceCategoryCodeList: Codelist = {
  name: 'CMRSubstanceCategory Code List',
  values: [
    { value: 'CMR_1A', label: 'CMR_1A', description: '' },
    { value: 'CMR_1B', label: 'CMR_1B', description: '' }
  ]
};

export const StorageHandlingConditionTypeCodeList: Codelist = {
  name: 'StorageHandlingConditionType Code List',
  values: [
    { value: 'SHC099', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC099', description: '' },
    { value: 'SHC001', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC001', description: '' },
    { value: 'SHC002', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC002', description: '' },
    { value: 'SHC003', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC003', description: '' },
    { value: 'SHC004', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC004', description: '' },
    { value: 'SHC005', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC005', description: '' },
    { value: 'SHC006', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC006', description: '' },
    { value: 'SHC007', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC007', description: '' },
    { value: 'SHC008', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC008', description: '' },
    { value: 'SHC009', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC009', description: '' },
    { value: 'SHC010', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC010', description: '' },
    { value: 'SHC012', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC012', description: '' },
    { value: 'SHC013', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC013', description: '' },
    { value: 'SHC014', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC014', description: '' },
    { value: 'SHC015', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC015', description: '' },
    { value: 'SHC016', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC016', description: '' },
    { value: 'SHC017', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC017', description: '' },
    { value: 'SHC018', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC018', description: '' },
    { value: 'SHC019', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC019', description: '' },
    { value: 'SHC021', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC021', description: '' },
    { value: 'SHC022', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC022', description: '' },
    { value: 'SHC023', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC023', description: '' },
    { value: 'SHC024', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC024', description: '' },
    { value: 'SHC025', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC025', description: '' },
    { value: 'SHC033', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC033', description: '' },
    { value: 'SHC035', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC035', description: '' },
    { value: 'SHC036', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC036', description: '' },
    { value: 'SHC037', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC037', description: '' },
    { value: 'SHC038', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC038', description: '' },
    { value: 'SHC042', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC042', description: '' },
    { value: 'SHC044', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC044', description: '' },
    { value: 'SHC045', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC045', description: '' },
    { value: 'SHC046', label: 'CODELISTCONSTANTS.storageHandlingConditionType.SHC046', description: '' }
  ]
};

export const CriticalWarningValueCodeList: Codelist = {
  name: 'CriticalWarningValue Code List',
  values: [
    { value: 'CW999', label: 'CODELISTCONSTANTS.criticalWarningValue.CW999', description: '' },
    { value: 'CW001', label: 'CODELISTCONSTANTS.criticalWarningValue.CW001', description: '' },
    { value: 'CW006', label: 'CODELISTCONSTANTS.criticalWarningValue.CW006', description: '' },
    { value: 'CW007', label: 'CODELISTCONSTANTS.criticalWarningValue.CW007', description: '' },
    { value: 'CW008', label: 'CODELISTCONSTANTS.criticalWarningValue.CW008', description: '' },
    { value: 'CW009', label: 'CODELISTCONSTANTS.criticalWarningValue.CW009', description: '' },
    { value: 'CW010', label: 'CODELISTCONSTANTS.criticalWarningValue.CW010', description: '' },
    { value: 'CW011', label: 'CODELISTCONSTANTS.criticalWarningValue.CW011', description: '' },
    { value: 'CW012', label: 'CODELISTCONSTANTS.criticalWarningValue.CW012', description: '' },
    { value: 'CW013', label: 'CODELISTCONSTANTS.criticalWarningValue.CW013', description: '' },
    { value: 'CW014', label: 'CODELISTCONSTANTS.criticalWarningValue.CW014', description: '' },
    { value: 'CW015', label: 'CODELISTCONSTANTS.criticalWarningValue.CW015', description: '' },
    { value: 'CW016', label: 'CODELISTCONSTANTS.criticalWarningValue.CW016', description: '' },
    { value: 'CW017', label: 'CODELISTCONSTANTS.criticalWarningValue.CW017', description: '' },
    { value: 'CW018', label: 'CODELISTCONSTANTS.criticalWarningValue.CW018', description: '' },
    { value: 'CW020', label: 'CODELISTCONSTANTS.criticalWarningValue.CW020', description: '' },
    { value: 'CW022', label: 'CODELISTCONSTANTS.criticalWarningValue.CW022', description: '' },
    { value: 'CW023', label: 'CODELISTCONSTANTS.criticalWarningValue.CW023', description: '' },
    { value: 'CW024', label: 'CODELISTCONSTANTS.criticalWarningValue.CW024', description: '' },
    { value: 'CW025', label: 'CODELISTCONSTANTS.criticalWarningValue.CW025', description: '' },
    { value: 'CW026', label: 'CODELISTCONSTANTS.criticalWarningValue.CW026', description: '' },
    { value: 'CW027', label: 'CODELISTCONSTANTS.criticalWarningValue.CW027', description: '' },
    { value: 'CW028', label: 'CODELISTCONSTANTS.criticalWarningValue.CW028', description: '' },
    { value: 'CW029', label: 'CODELISTCONSTANTS.criticalWarningValue.CW029', description: '' },
    { value: 'CW030', label: 'CODELISTCONSTANTS.criticalWarningValue.CW030', description: '' },
    { value: 'CW031', label: 'CODELISTCONSTANTS.criticalWarningValue.CW031', description: '' },
    { value: 'CW032', label: 'CODELISTCONSTANTS.criticalWarningValue.CW032', description: '' },
    { value: 'CW035', label: 'CODELISTCONSTANTS.criticalWarningValue.CW035', description: '' },
    { value: 'CW036', label: 'CODELISTCONSTANTS.criticalWarningValue.CW036', description: '' },
    { value: 'CW037', label: 'CODELISTCONSTANTS.criticalWarningValue.CW037', description: '' },
    { value: 'CW038', label: 'CODELISTCONSTANTS.criticalWarningValue.CW038', description: '' },
    { value: 'CW039', label: 'CODELISTCONSTANTS.criticalWarningValue.CW039', description: '' },
    { value: 'CW040', label: 'CODELISTCONSTANTS.criticalWarningValue.CW040', description: '' },
    { value: 'CW041', label: 'CODELISTCONSTANTS.criticalWarningValue.CW041', description: '' },
    { value: 'CW042', label: 'CODELISTCONSTANTS.criticalWarningValue.CW042', description: '' },
    { value: 'CW043', label: 'CODELISTCONSTANTS.criticalWarningValue.CW043', description: '' },
    { value: 'CW044', label: 'CODELISTCONSTANTS.criticalWarningValue.CW044', description: '' },
    { value: 'CW045', label: 'CODELISTCONSTANTS.criticalWarningValue.CW045', description: '' },
    { value: 'CW046', label: 'CODELISTCONSTANTS.criticalWarningValue.CW046', description: '' },
    { value: 'CW047', label: 'CODELISTCONSTANTS.criticalWarningValue.CW047', description: '' },
    { value: 'CW048', label: 'CODELISTCONSTANTS.criticalWarningValue.CW048', description: '' },
    { value: 'CW049', label: 'CODELISTCONSTANTS.criticalWarningValue.CW049', description: '' },
    { value: 'CW050', label: 'CODELISTCONSTANTS.criticalWarningValue.CW050', description: '' },
    { value: 'CW051', label: 'CODELISTCONSTANTS.criticalWarningValue.CW051', description: '' },
    { value: 'CW052', label: 'CODELISTCONSTANTS.criticalWarningValue.CW052', description: '' },
    { value: 'CW053', label: 'CODELISTCONSTANTS.criticalWarningValue.CW053', description: '' },
    { value: 'CW054', label: 'CODELISTCONSTANTS.criticalWarningValue.CW054', description: '' },
    { value: 'CW055', label: 'CODELISTCONSTANTS.criticalWarningValue.CW055', description: '' },
    { value: 'CW056', label: 'CODELISTCONSTANTS.criticalWarningValue.CW056', description: '' },
    { value: 'CW057', label: 'CODELISTCONSTANTS.criticalWarningValue.CW057', description: '' },
    { value: 'CW058', label: 'CODELISTCONSTANTS.criticalWarningValue.CW058', description: '' },
    { value: 'CW059', label: 'CODELISTCONSTANTS.criticalWarningValue.CW059', description: '' },
    { value: 'CW060', label: 'CODELISTCONSTANTS.criticalWarningValue.CW060', description: '' },
    { value: 'CW061', label: 'CODELISTCONSTANTS.criticalWarningValue.CW061', description: '' },
    { value: 'CW062', label: 'CODELISTCONSTANTS.criticalWarningValue.CW062', description: '' },
    { value: 'CW063', label: 'CODELISTCONSTANTS.criticalWarningValue.CW063', description: '' },
    { value: 'CW064', label: 'CODELISTCONSTANTS.criticalWarningValue.CW064', description: '' },
    { value: 'CW065', label: 'CODELISTCONSTANTS.criticalWarningValue.CW065', description: '' },
    { value: 'CW066', label: 'CODELISTCONSTANTS.criticalWarningValue.CW066', description: '' },
    { value: 'CW067', label: 'CODELISTCONSTANTS.criticalWarningValue.CW067', description: '' },
    { value: 'CW068', label: 'CODELISTCONSTANTS.criticalWarningValue.CW068', description: '' },
    { value: 'CW069', label: 'CODELISTCONSTANTS.criticalWarningValue.CW069', description: '' },
    { value: 'CW070', label: 'CODELISTCONSTANTS.criticalWarningValue.CW070', description: '' },
    { value: 'CW071', label: 'CODELISTCONSTANTS.criticalWarningValue.CW071', description: '' },
    { value: 'CW072', label: 'CODELISTCONSTANTS.criticalWarningValue.CW072', description: '' },
    { value: 'CW073', label: 'CODELISTCONSTANTS.criticalWarningValue.CW073', description: '' },
    { value: 'CW074', label: 'CODELISTCONSTANTS.criticalWarningValue.CW074', description: '' },
    { value: 'CW075', label: 'CODELISTCONSTANTS.criticalWarningValue.CW075', description: '' },
    { value: 'CW076', label: 'CODELISTCONSTANTS.criticalWarningValue.CW076', description: '' },
    { value: 'CW077', label: 'CODELISTCONSTANTS.criticalWarningValue.CW077', description: '' },
    { value: 'CW078', label: 'CODELISTCONSTANTS.criticalWarningValue.CW078', description: '' },
    { value: 'CW079', label: 'CODELISTCONSTANTS.criticalWarningValue.CW079', description: '' },
    { value: 'CW080', label: 'CODELISTCONSTANTS.criticalWarningValue.CW080', description: '' },
    { value: 'CW081', label: 'CODELISTCONSTANTS.criticalWarningValue.CW081', description: '' },
    { value: 'CW082', label: 'CODELISTCONSTANTS.criticalWarningValue.CW082', description: '' },
    { value: 'CW083', label: 'CODELISTCONSTANTS.criticalWarningValue.CW083', description: '' },
    { value: 'CW084', label: 'CODELISTCONSTANTS.criticalWarningValue.CW084', description: '' },
    { value: 'CW085', label: 'CODELISTCONSTANTS.criticalWarningValue.CW085', description: '' },
    { value: 'CW086', label: 'CODELISTCONSTANTS.criticalWarningValue.CW086', description: '' },
    { value: 'CW087', label: 'CODELISTCONSTANTS.criticalWarningValue.CW087', description: '' },
    { value: 'CW088', label: 'CODELISTCONSTANTS.criticalWarningValue.CW088', description: '' },
    { value: 'CW089', label: 'CODELISTCONSTANTS.criticalWarningValue.CW089', description: '' },
    { value: 'CW090', label: 'CODELISTCONSTANTS.criticalWarningValue.CW090', description: '' },
    { value: 'CW091', label: 'CODELISTCONSTANTS.criticalWarningValue.CW091', description: '' },
    { value: 'CW092', label: 'CODELISTCONSTANTS.criticalWarningValue.CW092', description: '' },
    { value: 'CW093', label: 'CODELISTCONSTANTS.criticalWarningValue.CW093', description: '' },
    { value: 'CW094', label: 'CODELISTCONSTANTS.criticalWarningValue.CW094', description: '' },
    { value: 'CW095', label: 'CODELISTCONSTANTS.criticalWarningValue.CW095', description: '' },
    { value: 'CW096', label: 'CODELISTCONSTANTS.criticalWarningValue.CW096', description: '' },
    { value: 'CW097', label: 'CODELISTCONSTANTS.criticalWarningValue.CW097', description: '' },
    { value: 'CW098', label: 'CODELISTCONSTANTS.criticalWarningValue.CW098', description: '' },
    { value: 'CW099', label: 'CODELISTCONSTANTS.criticalWarningValue.CW099', description: '' },
    { value: 'CW100', label: 'CODELISTCONSTANTS.criticalWarningValue.CW100', description: '' },
    { value: 'CW101', label: 'CODELISTCONSTANTS.criticalWarningValue.CW101', description: '' },
    { value: 'CW102', label: 'CODELISTCONSTANTS.criticalWarningValue.CW102', description: '' },
    { value: 'CW103', label: 'CODELISTCONSTANTS.criticalWarningValue.CW103', description: '' },
    { value: 'CW104', label: 'CODELISTCONSTANTS.criticalWarningValue.CW104', description: '' },
    { value: 'CW105', label: 'CODELISTCONSTANTS.criticalWarningValue.CW105', description: '' },
    { value: 'CW106', label: 'CODELISTCONSTANTS.criticalWarningValue.CW106', description: '' },
    { value: 'CW107', label: 'CODELISTCONSTANTS.criticalWarningValue.CW107', description: '' },
    { value: 'CW108', label: 'CODELISTCONSTANTS.criticalWarningValue.CW108', description: '' },
    { value: 'CW109', label: 'CODELISTCONSTANTS.criticalWarningValue.CW109', description: '' },
    { value: 'CW110', label: 'CODELISTCONSTANTS.criticalWarningValue.CW110', description: '' },
    { value: 'CW111', label: 'CODELISTCONSTANTS.criticalWarningValue.CW111', description: '' },
    { value: 'CW112', label: 'CODELISTCONSTANTS.criticalWarningValue.CW112', description: '' },
    { value: 'CW113', label: 'CODELISTCONSTANTS.criticalWarningValue.CW113', description: '' },
    { value: 'CW114', label: 'CODELISTCONSTANTS.criticalWarningValue.CW114', description: '' },
    { value: 'CW115', label: 'CODELISTCONSTANTS.criticalWarningValue.CW115', description: '' },
    { value: 'CW116', label: 'CODELISTCONSTANTS.criticalWarningValue.CW116', description: '' },
    { value: 'CW117', label: 'CODELISTCONSTANTS.criticalWarningValue.CW117', description: '' },
    { value: 'CW118', label: 'CODELISTCONSTANTS.criticalWarningValue.CW118', description: '' },
    { value: 'CW119', label: 'CODELISTCONSTANTS.criticalWarningValue.CW119', description: '' },
    { value: 'CW120', label: 'CODELISTCONSTANTS.criticalWarningValue.CW120', description: '' },
    { value: 'CW121', label: 'CODELISTCONSTANTS.criticalWarningValue.CW121', description: '' },
    { value: 'CW122', label: 'CODELISTCONSTANTS.criticalWarningValue.CW122', description: '' },
    { value: 'CW123', label: 'CODELISTCONSTANTS.criticalWarningValue.CW123', description: '' },
    { value: 'CW124', label: 'CODELISTCONSTANTS.criticalWarningValue.CW124', description: '' },
    { value: 'CW125', label: 'CODELISTCONSTANTS.criticalWarningValue.CW125', description: '' },
    { value: 'CW126', label: 'CODELISTCONSTANTS.criticalWarningValue.CW126', description: '' },
    { value: 'CW127', label: 'CODELISTCONSTANTS.criticalWarningValue.CW127', description: '' },
    { value: 'CW128', label: 'CODELISTCONSTANTS.criticalWarningValue.CW128', description: '' },
    { value: 'CW129', label: 'CODELISTCONSTANTS.criticalWarningValue.CW129', description: '' },
    { value: 'CW130', label: 'CODELISTCONSTANTS.criticalWarningValue.CW130', description: '' },
    { value: 'CW131', label: 'CODELISTCONSTANTS.criticalWarningValue.CW131', description: '' },
    { value: 'CW132', label: 'CODELISTCONSTANTS.criticalWarningValue.CW132', description: '' },
    { value: 'CW133', label: 'CODELISTCONSTANTS.criticalWarningValue.CW133', description: '' },
    { value: 'CW134', label: 'CODELISTCONSTANTS.criticalWarningValue.CW134', description: '' },
    { value: 'CW135', label: 'CODELISTCONSTANTS.criticalWarningValue.CW135', description: '' },
    { value: 'CW136', label: 'CODELISTCONSTANTS.criticalWarningValue.CW136', description: '' },
    { value: 'CW137', label: 'CODELISTCONSTANTS.criticalWarningValue.CW137', description: '' },
    { value: 'CW138', label: 'CODELISTCONSTANTS.criticalWarningValue.CW138', description: '' },
    { value: 'CW139', label: 'CODELISTCONSTANTS.criticalWarningValue.CW139', description: '' },
    { value: 'CW140', label: 'CODELISTCONSTANTS.criticalWarningValue.CW140', description: '' },
    { value: 'CW141', label: 'CODELISTCONSTANTS.criticalWarningValue.CW141', description: '' },
    { value: 'CW142', label: 'CODELISTCONSTANTS.criticalWarningValue.CW142', description: '' },
    { value: 'CW143', label: 'CODELISTCONSTANTS.criticalWarningValue.CW143', description: '' },
    { value: 'CW144', label: 'CODELISTCONSTANTS.criticalWarningValue.CW144', description: '' },
    { value: 'CW146', label: 'CODELISTCONSTANTS.criticalWarningValue.CW146', description: '' },
    { value: 'CW147', label: 'CODELISTCONSTANTS.criticalWarningValue.CW147', description: '' },
    { value: 'CW148', label: 'CODELISTCONSTANTS.criticalWarningValue.CW148', description: '' },
    { value: 'CW149', label: 'CODELISTCONSTANTS.criticalWarningValue.CW149', description: '' },
    { value: 'CW150', label: 'CODELISTCONSTANTS.criticalWarningValue.CW150', description: '' },
    { value: 'CW151', label: 'CODELISTCONSTANTS.criticalWarningValue.CW151', description: '' },
    { value: 'CW152', label: 'CODELISTCONSTANTS.criticalWarningValue.CW152', description: '' },
    { value: 'CW153', label: 'CODELISTCONSTANTS.criticalWarningValue.CW153', description: '' },
    { value: 'CW154', label: 'CODELISTCONSTANTS.criticalWarningValue.CW154', description: '' },
    { value: 'CW155', label: 'CODELISTCONSTANTS.criticalWarningValue.CW155', description: '' },
    { value: 'CW156', label: 'CODELISTCONSTANTS.criticalWarningValue.CW156', description: '' },
    { value: 'CW157', label: 'CODELISTCONSTANTS.criticalWarningValue.CW157', description: '' },
    { value: 'CW158', label: 'CODELISTCONSTANTS.criticalWarningValue.CW158', description: '' },
    { value: 'CW159', label: 'CODELISTCONSTANTS.criticalWarningValue.CW159', description: '' },
    { value: 'CW160', label: 'CODELISTCONSTANTS.criticalWarningValue.CW160', description: '' },
    { value: 'CW161', label: 'CODELISTCONSTANTS.criticalWarningValue.CW161', description: '' },
    { value: 'CW162', label: 'CODELISTCONSTANTS.criticalWarningValue.CW162', description: '' },
    { value: 'CW163', label: 'CODELISTCONSTANTS.criticalWarningValue.CW163', description: '' },
    { value: 'CW164', label: 'CODELISTCONSTANTS.criticalWarningValue.CW164', description: '' },
    { value: 'CW165', label: 'CODELISTCONSTANTS.criticalWarningValue.CW165', description: '' },
    { value: 'CW166', label: 'CODELISTCONSTANTS.criticalWarningValue.CW166', description: '' },
    { value: 'CW167', label: 'CODELISTCONSTANTS.criticalWarningValue.CW167', description: '' },
    { value: 'CW168', label: 'CODELISTCONSTANTS.criticalWarningValue.CW168', description: '' },
    { value: 'CW169', label: 'CODELISTCONSTANTS.criticalWarningValue.CW169', description: '' },
    { value: 'CW170', label: 'CODELISTCONSTANTS.criticalWarningValue.CW170', description: '' },
    { value: 'CW171', label: 'CODELISTCONSTANTS.criticalWarningValue.CW171', description: '' },
    { value: 'CW172', label: 'CODELISTCONSTANTS.criticalWarningValue.CW172', description: '' },
    { value: 'CW173', label: 'CODELISTCONSTANTS.criticalWarningValue.CW173', description: '' },
    { value: 'CW175', label: 'CODELISTCONSTANTS.criticalWarningValue.CW175', description: '' },
    { value: 'CW176', label: 'CODELISTCONSTANTS.criticalWarningValue.CW176', description: '' },
    { value: 'CW177', label: 'CODELISTCONSTANTS.criticalWarningValue.CW177', description: '' },
    { value: 'CW178', label: 'CODELISTCONSTANTS.criticalWarningValue.CW178', description: '' },
    { value: 'CW179', label: 'CODELISTCONSTANTS.criticalWarningValue.CW179', description: '' },
    { value: 'CW180', label: 'CODELISTCONSTANTS.criticalWarningValue.CW180', description: '' },
    { value: 'CW181', label: 'CODELISTCONSTANTS.criticalWarningValue.CW181', description: '' },
    { value: 'CW182', label: 'CODELISTCONSTANTS.criticalWarningValue.CW182', description: '' },
    { value: 'CW183', label: 'CODELISTCONSTANTS.criticalWarningValue.CW183', description: '' },
    { value: 'CW184', label: 'CODELISTCONSTANTS.criticalWarningValue.CW184', description: '' },
    { value: 'CW185', label: 'CODELISTCONSTANTS.criticalWarningValue.CW185', description: '' },
    { value: 'CW186', label: 'CODELISTCONSTANTS.criticalWarningValue.CW186', description: '' },
    { value: 'CW187', label: 'CODELISTCONSTANTS.criticalWarningValue.CW187', description: '' },
    { value: 'CW188', label: 'CODELISTCONSTANTS.criticalWarningValue.CW188', description: '' },
    { value: 'CW189', label: 'CODELISTCONSTANTS.criticalWarningValue.CW189', description: '' },
    { value: 'CW190', label: 'CODELISTCONSTANTS.criticalWarningValue.CW190', description: '' },
    { value: 'CW191', label: 'CODELISTCONSTANTS.criticalWarningValue.CW191', description: '' },
    { value: 'CW192', label: 'CODELISTCONSTANTS.criticalWarningValue.CW192', description: '' },
    { value: 'CW193', label: 'CODELISTCONSTANTS.criticalWarningValue.CW193', description: '' },
    { value: 'CS194', label: 'CODELISTCONSTANTS.criticalWarningValue.CS194', description: '' },
    { value: 'CW195', label: 'CODELISTCONSTANTS.criticalWarningValue.CW195', description: '' },
    { value: 'CW196', label: 'CODELISTCONSTANTS.criticalWarningValue.CW196', description: '' },
    { value: 'CW197', label: 'CODELISTCONSTANTS.criticalWarningValue.CW197', description: '' },
    { value: 'CW198', label: 'CODELISTCONSTANTS.criticalWarningValue.CW198', description: '' },
    { value: 'CW199', label: 'CODELISTCONSTANTS.criticalWarningValue.CW199', description: '' },
    { value: 'CW201', label: 'CODELISTCONSTANTS.criticalWarningValue.CW201', description: '' },
    { value: 'CW202', label: 'CODELISTCONSTANTS.criticalWarningValue.CW202', description: '' },
    { value: 'CW203', label: 'CODELISTCONSTANTS.criticalWarningValue.CW203', description: '' },
    { value: 'CW206', label: 'CODELISTCONSTANTS.criticalWarningValue.CW206', description: '' },
    { value: 'CW207', label: 'CODELISTCONSTANTS.criticalWarningValue.CW207', description: '' },
    { value: 'CW208', label: 'CODELISTCONSTANTS.criticalWarningValue.CW208', description: '' },
    { value: 'CW209', label: 'CODELISTCONSTANTS.criticalWarningValue.CW209', description: '' },
    { value: 'CW210', label: 'CODELISTCONSTANTS.criticalWarningValue.CW210', description: '' },
    { value: 'CW211', label: 'CODELISTCONSTANTS.criticalWarningValue.CW211', description: '' },
    { value: 'CW212', label: 'CODELISTCONSTANTS.criticalWarningValue.CW212', description: '' },
    { value: 'CW213', label: 'CODELISTCONSTANTS.criticalWarningValue.CW213', description: '' },
    { value: 'CW216', label: 'CODELISTCONSTANTS.criticalWarningValue.CW216', description: '' },
    { value: 'CW217', label: 'CODELISTCONSTANTS.criticalWarningValue.CW217', description: '' },
    { value: 'CW218', label: 'CODELISTCONSTANTS.criticalWarningValue.CW218', description: '' },
    { value: 'CW219', label: 'CODELISTCONSTANTS.criticalWarningValue.CW219', description: '' },
    { value: 'CW220', label: 'CODELISTCONSTANTS.criticalWarningValue.CW220', description: '' },
    { value: 'CW221', label: 'CODELISTCONSTANTS.criticalWarningValue.CW221', description: '' },
    { value: 'CW223', label: 'CODELISTCONSTANTS.criticalWarningValue.CW223', description: '' },
    { value: 'CW224', label: 'CODELISTCONSTANTS.criticalWarningValue.CW224', description: '' },
    { value: 'CW225', label: 'CODELISTCONSTANTS.criticalWarningValue.CW225', description: '' },
    { value: 'CW226', label: 'CODELISTCONSTANTS.criticalWarningValue.CW226', description: '' },
    { value: 'CW254', label: 'CODELISTCONSTANTS.criticalWarningValue.CW254', description: '' },
    { value: 'CW255', label: 'CODELISTCONSTANTS.criticalWarningValue.CW255', description: '' },
    { value: 'CW257', label: 'CODELISTCONSTANTS.criticalWarningValue.CW257', description: '' },
    { value: 'CW258', label: 'CODELISTCONSTANTS.criticalWarningValue.CW258', description: '' },
    { value: 'CW259', label: 'CODELISTCONSTANTS.criticalWarningValue.CW259', description: '' },
    { value: 'CW260', label: 'CODELISTCONSTANTS.criticalWarningValue.CW260', description: '' },
    { value: 'CW261', label: 'CODELISTCONSTANTS.criticalWarningValue.CW261', description: '' },
    { value: 'CW262', label: 'CODELISTCONSTANTS.criticalWarningValue.CW262', description: '' },
    { value: 'CW263', label: 'CODELISTCONSTANTS.criticalWarningValue.CW263', description: '' },
    { value: 'CW264', label: 'CODELISTCONSTANTS.criticalWarningValue.CW264', description: '' },
    { value: 'CW265', label: 'CODELISTCONSTANTS.criticalWarningValue.CW265', description: '' },
    { value: 'CW266', label: 'CODELISTCONSTANTS.criticalWarningValue.CW266', description: '' },
    { value: 'CW267', label: 'CODELISTCONSTANTS.criticalWarningValue.CW267', description: '' },
    { value: 'CW268', label: 'CODELISTCONSTANTS.criticalWarningValue.CW268', description: '' },
    { value: 'CW269', label: 'CODELISTCONSTANTS.criticalWarningValue.CW269', description: '' },
    { value: 'CW270', label: 'CODELISTCONSTANTS.criticalWarningValue.CW270', description: '' },
    { value: 'CW271', label: 'CODELISTCONSTANTS.criticalWarningValue.CW271', description: '' },
    { value: 'CW272', label: 'CODELISTCONSTANTS.criticalWarningValue.CW272', description: '' },
    { value: 'CW273', label: 'CODELISTCONSTANTS.criticalWarningValue.CW273', description: '' },
    { value: 'CW274', label: 'CODELISTCONSTANTS.criticalWarningValue.CW274', description: '' },
    { value: 'CW275', label: 'CODELISTCONSTANTS.criticalWarningValue.CW275', description: '' },
    { value: 'CW276', label: 'CODELISTCONSTANTS.criticalWarningValue.CW276', description: '' },
    { value: 'CW277', label: 'CODELISTCONSTANTS.criticalWarningValue.CW277', description: '' },
    { value: 'CW278', label: 'CODELISTCONSTANTS.criticalWarningValue.CW278', description: '' },
    { value: 'CW279', label: 'CODELISTCONSTANTS.criticalWarningValue.CW279', description: '' },
    { value: 'CW280', label: 'CODELISTCONSTANTS.criticalWarningValue.CW280', description: '' },
    { value: 'CW281', label: 'CODELISTCONSTANTS.criticalWarningValue.CW281', description: '' },
    { value: 'CW282', label: 'CODELISTCONSTANTS.criticalWarningValue.CW282', description: '' },
    { value: 'CW283', label: 'CODELISTCONSTANTS.criticalWarningValue.CW283', description: '' },
    { value: 'CW284', label: 'CODELISTCONSTANTS.criticalWarningValue.CW284', description: '' },
    { value: 'CW285', label: 'CODELISTCONSTANTS.criticalWarningValue.CW285', description: '' },
    { value: 'CW286', label: 'CODELISTCONSTANTS.criticalWarningValue.CW286', description: '' },
    { value: 'CW287', label: 'CODELISTCONSTANTS.criticalWarningValue.CW287', description: '' },
    { value: 'CW288', label: 'CODELISTCONSTANTS.criticalWarningValue.CW288', description: '' },
    { value: 'CW289', label: 'CODELISTCONSTANTS.criticalWarningValue.CW289', description: '' },
    { value: 'CW290', label: 'CODELISTCONSTANTS.criticalWarningValue.CW290', description: '' },
    { value: 'CW291', label: 'CODELISTCONSTANTS.criticalWarningValue.CW291', description: '' },
    { value: 'CW292', label: 'CODELISTCONSTANTS.criticalWarningValue.CW292', description: '' },
    { value: 'CW293', label: 'CODELISTCONSTANTS.criticalWarningValue.CW293', description: '' },
    { value: 'CW294', label: 'CODELISTCONSTANTS.criticalWarningValue.CW294', description: '' },
    { value: 'CW295', label: 'CODELISTCONSTANTS.criticalWarningValue.CW295', description: '' },
    { value: 'CW296', label: 'CODELISTCONSTANTS.criticalWarningValue.CW296', description: '' },
    { value: 'CW297', label: 'CODELISTCONSTANTS.criticalWarningValue.CW297', description: '' },
    { value: 'CW298', label: 'CODELISTCONSTANTS.criticalWarningValue.CW298', description: '' },
    { value: 'CW299', label: 'CODELISTCONSTANTS.criticalWarningValue.CW299', description: '' },
    { value: 'CW300', label: 'CODELISTCONSTANTS.criticalWarningValue.CW300', description: '' },
    { value: 'CW301', label: 'CODELISTCONSTANTS.criticalWarningValue.CW301', description: '' },
    { value: 'CW302', label: 'CODELISTCONSTANTS.criticalWarningValue.CW302', description: '' },
    { value: 'CW303', label: 'CODELISTCONSTANTS.criticalWarningValue.CW303', description: '' },
    { value: 'CW304', label: 'CODELISTCONSTANTS.criticalWarningValue.CW304', description: '' },
    { value: 'CW305', label: 'CODELISTCONSTANTS.criticalWarningValue.CW305', description: '' },
    { value: 'CW306', label: 'CODELISTCONSTANTS.criticalWarningValue.CW306', description: '' },
    { value: 'CW307', label: 'CODELISTCONSTANTS.criticalWarningValue.CW307', description: '' },
    { value: 'CW308', label: 'CODELISTCONSTANTS.criticalWarningValue.CW308', description: '' },
    { value: 'CW309', label: 'CODELISTCONSTANTS.criticalWarningValue.CW309', description: '' },
    { value: 'CW310', label: 'CODELISTCONSTANTS.criticalWarningValue.CW310', description: '' },
    { value: 'CW311', label: 'CODELISTCONSTANTS.criticalWarningValue.CW311', description: '' },
    { value: 'CW312', label: 'CODELISTCONSTANTS.criticalWarningValue.CW312', description: '' },
    { value: 'CW313', label: 'CODELISTCONSTANTS.criticalWarningValue.CW313', description: '' },
    { value: 'CW314', label: 'CODELISTCONSTANTS.criticalWarningValue.CW314', description: '' },
    { value: 'CW315', label: 'CODELISTCONSTANTS.criticalWarningValue.CW315', description: '' },
    { value: 'CW316', label: 'CODELISTCONSTANTS.criticalWarningValue.CW316', description: '' },
    { value: 'CW317', label: 'CODELISTCONSTANTS.criticalWarningValue.CW317', description: '' },
    { value: 'CW318', label: 'CODELISTCONSTANTS.criticalWarningValue.CW318', description: '' },
    { value: 'CW319', label: 'CODELISTCONSTANTS.criticalWarningValue.CW319', description: '' },
    { value: 'CW320', label: 'CODELISTCONSTANTS.criticalWarningValue.CW320', description: '' },
    { value: 'CW321', label: 'CODELISTCONSTANTS.criticalWarningValue.CW321', description: '' },
    { value: 'CW322', label: 'CODELISTCONSTANTS.criticalWarningValue.CW322', description: '' },
    { value: 'CW323', label: 'CODELISTCONSTANTS.criticalWarningValue.CW323', description: '' },
    { value: 'CW324', label: 'CODELISTCONSTANTS.criticalWarningValue.CW324', description: '' },
    { value: 'CW325', label: 'CODELISTCONSTANTS.criticalWarningValue.CW325', description: '' },
    { value: 'CW326', label: 'CODELISTCONSTANTS.criticalWarningValue.CW326', description: '' },
    { value: 'CW327', label: 'CODELISTCONSTANTS.criticalWarningValue.CW327', description: '' },
    { value: 'CW328', label: 'CODELISTCONSTANTS.criticalWarningValue.CW328', description: '' },
    { value: 'CW329', label: 'CODELISTCONSTANTS.criticalWarningValue.CW329', description: '' },
    { value: 'CW330', label: 'CODELISTCONSTANTS.criticalWarningValue.CW330', description: '' },
    { value: 'CW331', label: 'CODELISTCONSTANTS.criticalWarningValue.CW331', description: '' },
    { value: 'CW332', label: 'CODELISTCONSTANTS.criticalWarningValue.CW332', description: '' },
    { value: 'CW333', label: 'CODELISTCONSTANTS.criticalWarningValue.CW333', description: '' },
    { value: 'CW334', label: 'CODELISTCONSTANTS.criticalWarningValue.CW334', description: '' },
    { value: 'CW335', label: 'CODELISTCONSTANTS.criticalWarningValue.CW335', description: '' },
    { value: 'CW336', label: 'CODELISTCONSTANTS.criticalWarningValue.CW336', description: '' },
    { value: 'CW337', label: 'CODELISTCONSTANTS.criticalWarningValue.CW337', description: '' },
    { value: 'CW338', label: 'CODELISTCONSTANTS.criticalWarningValue.CW338', description: '' },
    { value: 'CW339', label: 'CODELISTCONSTANTS.criticalWarningValue.CW339', description: '' },
    { value: 'CW340', label: 'CODELISTCONSTANTS.criticalWarningValue.CW340', description: '' },
    { value: 'CW341', label: 'CODELISTCONSTANTS.criticalWarningValue.CW341', description: '' },
    { value: 'CW342', label: 'CODELISTCONSTANTS.criticalWarningValue.CW342', description: '' },
    { value: 'CW343', label: 'CODELISTCONSTANTS.criticalWarningValue.CW343', description: '' },
    { value: 'CW344', label: 'CODELISTCONSTANTS.criticalWarningValue.CW344', description: '' },
    { value: 'CW345', label: 'CODELISTCONSTANTS.criticalWarningValue.CW345', description: '' },
    { value: 'CW346', label: 'CODELISTCONSTANTS.criticalWarningValue.CW3466', description: '' },
    { value: 'CW347', label: 'CODELISTCONSTANTS.criticalWarningValue.CW3477', description: '' },
    { value: 'CW348', label: 'CODELISTCONSTANTS.criticalWarningValue.CW3488', description: '' },
    { value: 'CW349', label: 'CODELISTCONSTANTS.criticalWarningValue.CW349', description: '' },
    { value: 'CW350', label: 'CODELISTCONSTANTS.criticalWarningValue.CW350', description: '' },
    { value: 'CW351', label: 'CODELISTCONSTANTS.criticalWarningValue.CW351', description: '' },
    { value: 'CW352', label: 'CODELISTCONSTANTS.criticalWarningValue.CW352', description: '' },
    { value: 'CW353', label: 'CODELISTCONSTANTS.criticalWarningValue.CW353', description: '' },
    { value: 'CW354', label: 'CODELISTCONSTANTS.criticalWarningValue.CW354', description: '' },
    { value: 'CW355', label: 'CODELISTCONSTANTS.criticalWarningValue.CW355', description: '' },
    { value: 'CW356', label: 'CODELISTCONSTANTS.criticalWarningValue.CW356', description: '' },
    { value: 'CW357', label: 'CODELISTCONSTANTS.criticalWarningValue.CW357', description: '' },
    { value: 'CW358', label: 'CODELISTCONSTANTS.criticalWarningValue.CW358', description: '' },
    { value: 'CW359', label: 'CODELISTCONSTANTS.criticalWarningValue.CW359', description: '' },
    { value: 'CW360', label: 'CODELISTCONSTANTS.criticalWarningValue.CW360', description: '' },
    { value: 'CW361', label: 'CODELISTCONSTANTS.criticalWarningValue.CW361', description: '' },
    { value: 'CW362', label: 'CODELISTCONSTANTS.criticalWarningValue.CW362', description: '' },
    { value: 'CW363', label: 'CODELISTCONSTANTS.criticalWarningValue.CW363', description: '' },
    { value: 'CW364', label: 'CODELISTCONSTANTS.criticalWarningValue.CW364', description: '' },
    { value: 'CW365', label: 'CODELISTCONSTANTS.criticalWarningValue.CW365', description: '' },
    { value: 'CW366', label: 'CODELISTCONSTANTS.criticalWarningValue.CW366', description: '' },
    { value: 'CW367', label: 'CODELISTCONSTANTS.criticalWarningValue.CW367', description: '' },
    { value: 'CW368', label: 'CODELISTCONSTANTS.criticalWarningValue.CW368', description: '' },
    { value: 'CW369', label: 'CODELISTCONSTANTS.criticalWarningValue.CW369', description: '' },
    { value: 'CW370', label: 'CODELISTCONSTANTS.criticalWarningValue.CW370', description: '' },
    { value: 'CW371', label: 'CODELISTCONSTANTS.criticalWarningValue.CW371', description: '' },
    { value: 'CW372', label: 'CODELISTCONSTANTS.criticalWarningValue.CW372', description: '' },
    { value: 'CW373', label: 'CODELISTCONSTANTS.criticalWarningValue.CW373', description: '' },
    { value: 'CW374', label: 'CODELISTCONSTANTS.criticalWarningValue.CW374', description: '' },
    { value: 'CW375', label: 'CODELISTCONSTANTS.criticalWarningValue.CW375', description: '' },
    { value: 'CW376', label: 'CODELISTCONSTANTS.criticalWarningValue.CW376', description: '' },
    { value: 'CW377', label: 'CODELISTCONSTANTS.criticalWarningValue.CW377', description: '' },
    { value: 'CW378', label: 'CODELISTCONSTANTS.criticalWarningValue.CW378', description: '' },
    { value: 'CW379', label: 'CODELISTCONSTANTS.criticalWarningValue.CW379', description: '' },
    { value: 'CW380', label: 'CODELISTCONSTANTS.criticalWarningValue.CW380', description: '' },
    { value: 'CW381', label: 'CODELISTCONSTANTS.criticalWarningValue.CW381', description: '' },
    { value: 'CW382', label: 'CODELISTCONSTANTS.criticalWarningValue.CW382', description: '' },
    { value: 'CW383', label: 'CODELISTCONSTANTS.criticalWarningValue.CW383', description: '' },
    { value: 'CW384', label: 'CODELISTCONSTANTS.criticalWarningValue.CW384', description: '' },
    { value: 'CW385', label: 'CODELISTCONSTANTS.criticalWarningValue.CW385', description: '' },
    { value: 'CW386', label: 'CODELISTCONSTANTS.criticalWarningValue.CW386', description: '' },
    { value: 'CW387', label: 'CODELISTCONSTANTS.criticalWarningValue.CW387', description: '' },
    { value: 'CW388', label: 'CODELISTCONSTANTS.criticalWarningValue.CW388', description: '' },
    { value: 'CW389', label: 'CODELISTCONSTANTS.criticalWarningValue.CW389', description: '' },
    { value: 'CW390', label: 'CODELISTCONSTANTS.criticalWarningValue.CW390', description: '' },
    { value: 'CW391', label: 'CODELISTCONSTANTS.criticalWarningValue.CW391', description: '' },
    { value: 'CW392', label: 'CODELISTCONSTANTS.criticalWarningValue.CW392', description: '' },
    { value: 'CW393', label: 'CODELISTCONSTANTS.criticalWarningValue.CW393', description: '' },
    { value: 'CW394', label: 'CODELISTCONSTANTS.criticalWarningValue.CW394', description: '' },
    { value: 'CW395', label: 'CODELISTCONSTANTS.criticalWarningValue.CW395', description: '' },
    { value: 'CW396', label: 'CODELISTCONSTANTS.criticalWarningValue.CW396', description: '' },
    { value: 'CW397', label: 'CODELISTCONSTANTS.criticalWarningValue.CW397', description: '' },
    { value: 'CW398', label: 'CODELISTCONSTANTS.criticalWarningValue.CW398', description: '' },
    { value: 'CW399', label: 'CODELISTCONSTANTS.criticalWarningValue.CW399', description: '' },
    { value: 'CW400', label: 'CODELISTCONSTANTS.criticalWarningValue.CW400', description: '' },
    { value: 'CW401', label: 'CODELISTCONSTANTS.criticalWarningValue.CW401', description: '' },
    { value: 'CW402', label: 'CODELISTCONSTANTS.criticalWarningValue.CW402', description: '' },
    { value: 'CW403', label: 'CODELISTCONSTANTS.criticalWarningValue.CW403', description: '' },
    { value: 'CW404', label: 'CODELISTCONSTANTS.criticalWarningValue.CW404', description: '' },
    { value: 'CW405', label: 'CODELISTCONSTANTS.criticalWarningValue.CW405', description: '' },
    { value: 'CW406', label: 'CODELISTCONSTANTS.criticalWarningValue.CW406', description: '' },
    { value: 'CW407', label: 'CODELISTCONSTANTS.criticalWarningValue.CW407', description: '' },
    { value: 'CW408', label: 'CODELISTCONSTANTS.criticalWarningValue.CW408', description: '' },
    { value: 'CW409', label: 'CODELISTCONSTANTS.criticalWarningValue.CW409', description: '' },
    { value: 'CW410', label: 'CODELISTCONSTANTS.criticalWarningValue.CW410', description: '' },
    { value: 'CW411', label: 'CODELISTCONSTANTS.criticalWarningValue.CW411', description: '' },
    { value: 'CW412', label: 'CODELISTCONSTANTS.criticalWarningValue.CW412', description: '' },
    { value: 'CW413', label: 'CODELISTCONSTANTS.criticalWarningValue.CW413', description: '' },
    { value: 'CW414', label: 'CODELISTCONSTANTS.criticalWarningValue.CW414', description: '' },
    { value: 'CW415', label: 'CODELISTCONSTANTS.criticalWarningValue.CW415', description: '' },
    { value: 'CW416', label: 'CODELISTCONSTANTS.criticalWarningValue.CW416', description: '' },
    { value: 'CW417', label: 'CODELISTCONSTANTS.criticalWarningValue.CW417', description: '' },
    { value: 'CW418', label: 'CODELISTCONSTANTS.criticalWarningValue.CW418', description: '' },
    { value: 'CW419', label: 'CODELISTCONSTANTS.criticalWarningValue.CW419', description: '' },
    { value: 'CW420', label: 'CODELISTCONSTANTS.criticalWarningValue.CW420', description: '' },
    { value: 'CW421', label: 'CODELISTCONSTANTS.criticalWarningValue.CW421', description: '' },
    { value: 'CW422', label: 'CODELISTCONSTANTS.criticalWarningValue.CW422', description: '' },
    { value: 'CW423', label: 'CODELISTCONSTANTS.criticalWarningValue.CW423', description: '' },
    { value: 'CW424', label: 'CODELISTCONSTANTS.criticalWarningValue.CW424', description: '' },
    { value: 'CW425', label: 'CODELISTCONSTANTS.criticalWarningValue.CW425', description: '' },
    { value: 'CW426', label: 'CODELISTCONSTANTS.criticalWarningValue.CW426', description: '' },
    { value: 'CW427', label: 'CODELISTCONSTANTS.criticalWarningValue.CW427', description: '' },
    { value: 'CW428', label: 'CODELISTCONSTANTS.criticalWarningValue.CW428', description: '' },
    { value: 'CW429', label: 'CODELISTCONSTANTS.criticalWarningValue.CW429', description: '' },
    { value: 'CW430', label: 'CODELISTCONSTANTS.criticalWarningValue.CW430', description: '' },
    { value: 'CW431', label: 'CODELISTCONSTANTS.criticalWarningValue.CW431', description: '' },
    { value: 'CW432', label: 'CODELISTCONSTANTS.criticalWarningValue.CW432', description: '' },
    { value: 'CW433', label: 'CODELISTCONSTANTS.criticalWarningValue.CW433', description: '' },
    { value: 'CW434', label: 'CODELISTCONSTANTS.criticalWarningValue.CW434', description: '' },
    { value: 'CW435', label: 'CODELISTCONSTANTS.criticalWarningValue.CW435', description: '' },
    { value: 'CW346', label: 'CODELISTCONSTANTS.criticalWarningValue.CW346', description: '' },
    { value: 'CW347', label: 'CODELISTCONSTANTS.criticalWarningValue.CW347', description: '' },
    { value: 'CW348', label: 'CODELISTCONSTANTS.criticalWarningValue.CW348', description: '' },
    { value: 'CW439', label: 'CODELISTCONSTANTS.criticalWarningValue.CW439', description: '' },
    { value: 'CW440', label: 'CODELISTCONSTANTS.criticalWarningValue.CW440', description: '' },
    { value: 'CW441', label: 'CODELISTCONSTANTS.criticalWarningValue.CW441', description: '' },
    { value: 'CW442', label: 'CODELISTCONSTANTS.criticalWarningValue.CW442', description: '' },
    { value: 'CW443', label: 'CODELISTCONSTANTS.criticalWarningValue.CW443', description: '' },
    { value: 'CW444', label: 'CODELISTCONSTANTS.criticalWarningValue.CW444', description: '' },
    { value: 'CW445', label: 'CODELISTCONSTANTS.criticalWarningValue.CW445', description: '' },
    { value: 'CW446', label: 'CODELISTCONSTANTS.criticalWarningValue.CW446', description: '' },
    { value: 'CW447', label: 'CODELISTCONSTANTS.criticalWarningValue.CW447', description: '' },
    { value: 'CW448', label: 'CODELISTCONSTANTS.criticalWarningValue.CW448', description: '' },
    { value: 'CW449', label: 'CODELISTCONSTANTS.criticalWarningValue.CW449', description: '' },
    { value: 'CW450', label: 'CODELISTCONSTANTS.criticalWarningValue.CW450', description: '' },
    { value: 'CW451', label: 'CODELISTCONSTANTS.criticalWarningValue.CW451', description: '' },
    { value: 'CW452', label: 'CODELISTCONSTANTS.criticalWarningValue.CW452', description: '' },
    { value: 'CW453', label: 'CODELISTCONSTANTS.criticalWarningValue.CW453', description: '' },
    { value: 'CW454', label: 'CODELISTCONSTANTS.criticalWarningValue.CW454', description: '' },
    { value: 'CW455', label: 'CODELISTCONSTANTS.criticalWarningValue.CW455', description: '' },
    { value: 'CW456', label: 'CODELISTCONSTANTS.criticalWarningValue.CW456', description: '' },
    { value: 'CW457', label: 'CODELISTCONSTANTS.criticalWarningValue.CW457', description: '' },
    { value: 'CW458', label: 'CODELISTCONSTANTS.criticalWarningValue.CW458', description: '' },
    { value: 'CW459', label: 'CODELISTCONSTANTS.criticalWarningValue.CW459', description: '' },
    { value: 'CW460', label: 'CODELISTCONSTANTS.criticalWarningValue.CW460', description: '' },
    { value: 'CW461', label: 'CODELISTCONSTANTS.criticalWarningValue.CW461', description: '' },
    { value: 'CW462', label: 'CODELISTCONSTANTS.criticalWarningValue.CW462', description: '' },
    { value: 'CW463', label: 'CODELISTCONSTANTS.criticalWarningValue.CW463', description: '' },
    { value: 'CW464', label: 'CODELISTCONSTANTS.criticalWarningValue.CW464', description: '' },
    { value: 'CW465', label: 'CODELISTCONSTANTS.criticalWarningValue.CW465', description: '' },
    { value: 'CW466', label: 'CODELISTCONSTANTS.criticalWarningValue.CW466', description: '' }
  ]
};

export const CountryISOTypeCodeList: Codelist = {
  name: 'CountryISOType Code List',
  values: [
    { value: 'AF', label: 'AFGHANISTAN [AF]', description: '' },
    { value: 'AX', label: 'ALAND ISLANDS [AX]', description: '' },
    { value: 'AL', label: 'ALBANIA [AL]', description: '' },
    { value: 'DZ', label: 'ALGERIA [DZ]', description: '' },
    { value: 'AS', label: 'AMERICAN SAMOA [AS]', description: '' },
    { value: 'AD', label: 'ANDORRA [AD]', description: '' },
    { value: 'AO', label: 'ANGOLA [AO]', description: '' },
    { value: 'AI', label: 'ANGUILLA [AI]', description: '' },
    { value: 'AQ', label: 'ANTARCTICA [AQ]', description: '' },
    { value: 'AG', label: 'ANTIGUA AND BARBUDA [AG]', description: '' },
    { value: 'AR', label: 'ARGENTINA [AR]', description: '' },
    { value: 'AM', label: 'ARMENIA [AM]', description: '' },
    { value: 'AW', label: 'ARUBA [AW]', description: '' },
    { value: 'AU', label: 'AUSTRALIA [AU]', description: '' },
    { value: 'AT', label: 'AUSTRIA [AT]', description: '' },
    { value: 'AZ', label: 'AZERBAIJAN [AZ]', description: '' },
    { value: 'BS', label: 'BAHAMAS [BS]', description: '' },
    { value: 'BH', label: 'BAHRAIN [BH]', description: '' },
    { value: 'BD', label: 'BANGLADESH [BD]', description: '' },
    { value: 'BB', label: 'BARBADOS [BB]', description: '' },
    { value: 'BY', label: 'BELARUS [BY]', description: '' },
    { value: 'BE', label: 'BELGIUM [BE]', description: '' },
    { value: 'BZ', label: 'BELIZE [BZ]', description: '' },
    { value: 'BJ', label: 'BENIN [BJ]', description: '' },
    { value: 'BM', label: 'BERMUDA [BM]', description: '' },
    { value: 'BT', label: 'BHUTAN [BT]', description: '' },
    { value: 'BO', label: 'BOLIVIA [BO]', description: '' },
    { value: 'BQ', label: 'BONAIRE, SAINT EUSTATIUS AND SABA [BQ]', description: '' },
    { value: 'BA', label: 'BOSNIA AND HERZEGOWINA [BA]', description: '' },
    { value: 'BW', label: 'BOTSWANA [BW]', description: '' },
    { value: 'BV', label: 'BOUVET ISLAND [BV]', description: '' },
    { value: 'BR', label: 'BRAZIL [BR]', description: '' },
    { value: 'IO', label: 'BRITISH INDIAN OCEAN TERRITORY [IO]', description: '' },
    { value: 'BN', label: 'BRUNEI DARUSSALAM [BN]', description: '' },
    { value: 'BG', label: 'BULGARIA [BG]', description: '' },
    { value: 'BF', label: 'BURKINA FASO [BF]', description: '' },
    { value: 'BI', label: 'BURUNDI [BI]', description: '' },
    { value: 'KH', label: 'CAMBODIA [KH]', description: '' },
    { value: 'CM', label: 'CAMEROON [CM]', description: '' },
    { value: 'CA', label: 'CANADA [CA]', description: '' },
    { value: 'CV', label: 'CAPE VERDE [CV]', description: '' },
    { value: 'KY', label: 'CAYMAN ISLANDS [KY]', description: '' },
    { value: 'CF', label: 'CENTRAL AFRICAN REPUBLIC [CF]', description: '' },
    { value: 'TD', label: 'CHAD [TD]', description: '' },
    { value: 'CL', label: 'CHILE [CL]', description: '' },
    { value: 'CN', label: 'CHINA [CN]', description: '' },
    { value: 'CX', label: 'CHRISTMAS ISLAND [CX]', description: '' },
    { value: 'CC', label: 'COCOS (KEELING) ISLANDS [CC]', description: '' },
    { value: 'CO', label: 'COLOMBIA [CO]', description: '' },
    { value: 'KM', label: 'COMOROS [KM]', description: '' },
    { value: 'CG', label: 'CONGO [CG]', description: '' },
    { value: 'CK', label: 'COOK ISLANDS [CK]', description: '' },
    { value: 'CR', label: 'COSTA RICA [CR]', description: '' },
    { value: 'CI', label: 'COTE D\'IVOIRE [CI]', description: '' },
    { value: 'HR', label: 'CROATIA [HR]', description: '' },
    { value: 'CU', label: 'CUBA [CU]', description: '' },
    { value: 'CW', label: 'CURACAO [CW]', description: '' },
    { value: 'CY', label: 'CYPRUS [CY]', description: '' },
    { value: 'CZ', label: 'CZECH REPUBLIC [CZ]', description: '' },
    { value: 'DK', label: 'DENMARK [DK]', description: '' },
    { value: 'DJ', label: 'DJIBOUTI [DJ]', description: '' },
    { value: 'DM', label: 'DOMINICA [DM]', description: '' },
    { value: 'DO', label: 'DOMINICAN REPUBLIC [DO]', description: '' },
    { value: 'EC', label: 'ECUADOR [EC]', description: '' },
    { value: 'EG', label: 'EGYPT [EG]', description: '' },
    { value: 'SV', label: 'EL SALVADOR [SV]', description: '' },
    { value: 'GQ', label: 'EQUATORIAL GUINEA [GQ]', description: '' },
    { value: 'ER', label: 'ERITREA [ER]', description: '' },
    { value: 'EE', label: 'ESTONIA [EE]', description: '' },
    { value: 'SZ', label: 'ESWATINI [SZ]', description: '' },
    { value: 'ET', label: 'ETHIOPIA [ET]', description: '' },
    { value: 'FK', label: 'FALKLAND ISLANDS (MALVINAS) [FK]', description: '' },
    { value: 'FO', label: 'FAROE ISLANDS [FO]', description: '' },
    { value: 'FJ', label: 'FIJI [FJ]', description: '' },
    { value: 'FI', label: 'FINLAND [FI]', description: '' },
    { value: 'FR', label: 'FRANCE [FR]', description: '' },
    { value: 'GF', label: 'FRENCH GUIANA [GF]', description: '' },
    { value: 'PF', label: 'FRENCH POLYNESIA [PF]', description: '' },
    { value: 'TF', label: 'FRENCH SOUTHERN TERRITORIES [TF]', description: '' },
    { value: 'GA', label: 'GABON [GA]', description: '' },
    { value: 'GM', label: 'GAMBIA [GM]', description: '' },
    { value: 'GE', label: 'GEORGIA [GE]', description: '' },
    { value: 'DE', label: 'GERMANY [DE]', description: '' },
    { value: 'GH', label: 'GHANA [GH]', description: '' },
    { value: 'GI', label: 'GIBRALTAR [GI]', description: '' },
    { value: 'EL', label: 'GREECE [EL]', description: '' },
    { value: 'GL', label: 'GREENLAND [GL]', description: '' },
    { value: 'GD', label: 'GRENADA [GD]', description: '' },
    { value: 'GP', label: 'GUADELOUPE [GP]', description: '' },
    { value: 'GU', label: 'GUAM [GU]', description: '' },
    { value: 'GT', label: 'GUATEMALA [GT]', description: '' },
    { value: 'GG', label: 'GUERNSEY [GG]', description: '' },
    { value: 'GN', label: 'GUINEA [GN]', description: '' },
    { value: 'GW', label: 'GUINEA-BISSAU [GW]', description: '' },
    { value: 'GY', label: 'GUYANA [GY]', description: '' },
    { value: 'HT', label: 'HAITI [HT]', description: '' },
    { value: 'HM', label: 'HEARD AND MC DONALD ISLANDS [HM]', description: '' },
    { value: 'VA', label: 'HOLY SEE [VA]', description: '' },
    { value: 'HN', label: 'HONDURAS [HN]', description: '' },
    { value: 'HK', label: 'HONG KONG [HK]', description: '' },
    { value: 'HU', label: 'HUNGARY [HU]', description: '' },
    { value: 'IS', label: 'ICELAND [IS]', description: '' },
    { value: 'IN', label: 'INDIA [IN]', description: '' },
    { value: 'ID', label: 'INDONESIA [ID]', description: '' },
    { value: 'IR', label: 'IRAN (ISLAMIC REPUBLIC OF) [IR]', description: '' },
    { value: 'IQ', label: 'IRAQ [IQ]', description: '' },
    { value: 'IE', label: 'IRELAND [IE]', description: '' },
    { value: 'IM', label: 'ISLE OF MAN [IM]', description: '' },
    { value: 'IL', label: 'ISRAEL [IL]', description: '' },
    { value: 'IT', label: 'ITALY [IT]', description: '' },
    { value: 'JM', label: 'JAMAICA [JM]', description: '' },
    { value: 'JP', label: 'JAPAN [JP]', description: '' },
    { value: 'JE', label: 'JERSEY [JE]', description: '' },
    { value: 'JO', label: 'JORDAN [JO]', description: '' },
    { value: 'KZ', label: 'KAZAKHSTAN [KZ]', description: '' },
    { value: 'KE', label: 'KENYA [KE]', description: '' },
    { value: 'KI', label: 'KIRIBATI [KI]', description: '' },
    { value: 'KR', label: 'KOREA (REPUBLIC OF [SOUTH] KOREA) [KR]', description: '' },
    { value: 'KP', label: 'KOREA DEMOCRATIC PEOPLES REPUBLIC OF [NORTH] KOREA) [KP]', description: '' },
    { value: 'KW', label: 'KUWAIT [KW]', description: '' },
    { value: 'KG', label: 'KYRGYZSTAN [KG]', description: '' },
    { value: 'LA', label: 'LAO PEOPLE\'S DEMOCRATIC REPUBLIC [LA]', description: '' },
    { value: 'LV', label: 'LATVIA [LV]', description: '' },
    { value: 'LB', label: 'LEBANON [LB]', description: '' },
    { value: 'LS', label: 'LESOTHO [LS]', description: '' },
    { value: 'LR', label: 'LIBERIA [LR]', description: '' },
    { value: 'LY', label: 'LIBYAN ARAB JAMAHIRIYA [LY]', description: '' },
    { value: 'LI', label: 'LIECHTENSTEIN [LI]', description: '' },
    { value: 'LT', label: 'LITHUANIA [LT]', description: '' },
    { value: 'LU', label: 'LUXEMBOURG [LU]', description: '' },
    { value: 'MO', label: 'MACAO [MO]', description: '' },
    { value: 'MK', label: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF [MK]', description: '' },
    { value: 'MG', label: 'MADAGASCAR [MG]', description: '' },
    { value: 'MW', label: 'MALAWI [MW]', description: '' },
    { value: 'MY', label: 'MALAYSIA [MY]', description: '' },
    { value: 'MV', label: 'MALDIVES [MV]', description: '' },
    { value: 'ML', label: 'MALI [ML]', description: '' },
    { value: 'MT', label: 'MALTA [MT]', description: '' },
    { value: 'MH', label: 'MARSHALL ISLANDS [MH]', description: '' },
    { value: 'MQ', label: 'MARTINIQUE [MQ]', description: '' },
    { value: 'MR', label: 'MAURITANIA [MR]', description: '' },
    { value: 'MU', label: 'MAURITIUS [MU]', description: '' },
    { value: 'YT', label: 'MAYOTTE [YT]', description: '' },
    { value: 'MX', label: 'MEXICO [MX]', description: '' },
    { value: 'FM', label: 'MICRONESIA (FEDERATED STATES OF) [FM]', description: '' },
    { value: 'MD', label: 'MOLDOVA, REPUBLIC OF [MD]', description: '' },
    { value: 'MC', label: 'MONACO [MC]', description: '' },
    { value: 'MN', label: 'MONGOLIA [MN]', description: '' },
    { value: 'ME', label: 'MONTENEGRO [ME]', description: '' },
    { value: 'MS', label: 'MONTSERRAT [MS]', description: '' },
    { value: 'MA', label: 'MOROCCO [MA]', description: '' },
    { value: 'MZ', label: 'MOZAMBIQUE [MZ]', description: '' },
    { value: 'MM', label: 'MYANMAR [MM]', description: '' },
    { value: 'NA', label: 'NAMIBIA [NA]', description: '' },
    { value: 'NR', label: 'NAURU [NR]', description: '' },
    { value: 'NP', label: 'NEPAL [NP]', description: '' },
    { value: 'NL', label: 'NETHERLANDS [NL]', description: '' },
    { value: 'NC', label: 'NEW CALEDONIA [NC]', description: '' },
    { value: 'NZ', label: 'NEW ZEALAND [NZ]', description: '' },
    { value: 'NI', label: 'NICARAGUA [NI]', description: '' },
    { value: 'NE', label: 'NIGER [NE]', description: '' },
    { value: 'NG', label: 'NIGERIA [NG]', description: '' },
    { value: 'NU', label: 'NIUE [NU]', description: '' },
    { value: 'NF', label: 'NORFOLK ISLAND [NF]', description: '' },
    { value: 'MP', label: 'NORTHERN MARIANA ISLANDS [MP]', description: '' },
    { value: 'NO', label: 'NORWAY [NO]', description: '' },
    { value: 'OM', label: 'OMAN [OM]', description: '' },
    { value: 'PK', label: 'PAKISTAN [PK]', description: '' },
    { value: 'PW', label: 'PALAU [PW]', description: '' },
    { value: 'PS', label: 'PALESTINIAN TERRITORY, OCCUPIED [PS]', description: '' },
    { value: 'PA', label: 'PANAMA [PA]', description: '' },
    { value: 'PG', label: 'PAPUA NEW GUINEA [PG]', description: '' },
    { value: 'PY', label: 'PARAGUAY [PY]', description: '' },
    { value: 'PE', label: 'PERU [PE]', description: '' },
    { value: 'PH', label: 'PHILIPPINES [PH]', description: '' },
    { value: 'PN', label: 'PITCAIRN [PN]', description: '' },
    { value: 'PL', label: 'POLAND [PL]', description: '' },
    { value: 'PT', label: 'PORTUGAL [PT]', description: '' },
    { value: 'PR', label: 'PUERTO RICO [PR]', description: '' },
    { value: 'QA', label: 'QATAR [QA]', description: '' },
    { value: 'RE', label: 'REUNION [RE]', description: '' },
    { value: 'RO', label: 'ROMANIA [RO]', description: '' },
    { value: 'RU', label: 'RUSSIAN FEDERATION [RU]', description: '' },
    { value: 'RW', label: 'RWANDA [RW]', description: '' },
    { value: 'KN', label: 'SAINT KITTS AND NEVIS [KN]', description: '' },
    { value: 'LC', label: 'SAINT LUCIA [LC]', description: '' },
    { value: 'VC', label: 'SAINT VINCENT AND THE GRENADINES [VC]', description: '' },
    { value: 'BL', label: 'SAINT-BARTHELEMY [BL]', description: '' },
    { value: 'SX', label: 'SAINT-MARTIN (DUTCH PART) [SX]', description: '' },
    { value: 'MF', label: 'SAINT-MARTIN (FRENCH PART) [MF]', description: '' },
    { value: 'WS', label: 'SAMOA [WS]', description: '' },
    { value: 'SM', label: 'SAN MARINO [SM]', description: '' },
    { value: 'ST', label: 'SAO TOME AND PRINCIPE [ST]', description: '' },
    { value: 'SA', label: 'SAUDI ARABIA [SA]', description: '' },
    { value: 'SN', label: 'SENEGAL [SN]', description: '' },
    { value: 'RS', label: 'SERBIA [RS]', description: '' },
    { value: 'SC', label: 'SEYCHELLES [SC]', description: '' },
    { value: 'SL', label: 'SIERRA LEONE [SL]', description: '' },
    { value: 'SG', label: 'SINGAPORE [SG]', description: '' },
    { value: 'SK', label: 'SLOVAKIA [SK]', description: '' },
    { value: 'SI', label: 'SLOVENIA [SI]', description: '' },
    { value: 'SB', label: 'SOLOMON ISLANDS [SB]', description: '' },
    { value: 'SO', label: 'SOMALIA [SO]', description: '' },
    { value: 'ZA', label: 'SOUTH AFRICA [ZA]', description: '' },
    { value: 'GS', label: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS [GS]', description: '' },
    { value: 'ES', label: 'SPAIN [ES]', description: '' },
    { value: 'LK', label: 'SRI LANKA [LK]', description: '' },
    { value: 'SH', label: 'ST. HELENA [SH]', description: '' },
    { value: 'PM', label: 'ST. PIERRE AND MIQUELON [PM]', description: '' },
    { value: 'SD', label: 'SUDAN [SD]', description: '' },
    { value: 'SS', label: 'SOUTH SUDAN [SS]', description: '' },
    { value: 'SR', label: 'SURINAME [SR]', description: '' },
    { value: 'SJ', label: 'SVALBARD AND JAN MAYEN ISLANDS [SJ]', description: '' },
    { value: 'SZ', label: 'SWAZILAND [SZ]', description: '' },
    { value: 'SE', label: 'SWEDEN [SE]', description: '' },
    { value: 'CH', label: 'SWITZERLAND [CH]', description: '' },
    { value: 'SY', label: 'SYRIAN ARAB REPUBLIC [SY]', description: '' },
    { value: 'TW', label: 'TAIWAN, PROVINCE OF CHINA [TW]', description: '' },
    { value: 'TJ', label: 'TAJIKISTAN [TJ]', description: '' },
    { value: 'TZ', label: 'TANZANIA, UNITED REPUBLIC OF [TZ]', description: '' },
    { value: 'TH', label: 'THAILAND [TH]', description: '' },
    { value: 'CD', label: 'THE DEMOCRATIC REPUBLIC OF THE CONGO [CD]', description: '' },
    { value: 'TL', label: 'TIMOR-LESTE [TL]', description: '' },
    { value: 'TG', label: 'TOGO [TG]', description: '' },
    { value: 'TK', label: 'TOKELAU [TK]', description: '' },
    { value: 'TO', label: 'TONGA [TO]', description: '' },
    { value: 'TT', label: 'TRINIDAD AND TOBAGO [TT]', description: '' },
    { value: 'TN', label: 'TUNISIA [TN]', description: '' },
    { value: 'TR', label: 'TURKEY [TR]', description: '' },
    { value: 'TM', label: 'TURKMENISTAN [TM]', description: '' },
    { value: 'TC', label: 'TURKS AND CAICOS ISLANDS [TC]', description: '' },
    { value: 'TV', label: 'TUVALU [TV]', description: '' },
    { value: 'UG', label: 'UGANDA [UG]', description: '' },
    { value: 'UA', label: 'UKRAINE [UA]', description: '' },
    { value: 'AE', label: 'UNITED ARAB EMIRATES [AE]', description: '' },
    { value: 'UK', label: 'UNITED KINGDOM [UK]', description: '' },
    { value: 'XI', label: 'UNITED KINGDOM (NOTHERN IRELAND) [XI]', description: '' },
    { value: 'US', label: 'UNITED STATES [US]', description: '' },
    { value: 'UM', label: 'UNITED STATES MINOR OUTLYING ISLANDS [UM]', description: '' },
    { value: 'UY', label: 'URUGUAY [UY]', description: '' },
    { value: 'UZ', label: 'UZBEKISTAN [UZ]', description: '' },
    { value: 'VU', label: 'VANUATU [VU]', description: '' },
    { value: 'VE', label: 'VENEZUELA [VE]', description: '' },
    { value: 'VN', label: 'VIET NAM [VN]', description: '' },
    { value: 'VG', label: 'VIRGIN ISLANDS (BRITISH) [VG]', description: '' },
    { value: 'VI', label: 'VIRGIN ISLANDS (U.S.) [VI]', description: '' },
    { value: 'WF', label: 'WALLIS AND FUTUNA ISLANDS [WF]', description: '' },
    { value: 'EH', label: 'WESTERN SAHARA [EH]', description: '' },
    { value: 'YE', label: 'YEMEN [YE]', description: '' },
    { value: 'ZM', label: 'ZAMBIA [ZM]', description: '' },
    { value: 'ZW', label: 'ZIMBABWE [ZW]', description: '' }
  ]
};

export const NONEUCountryTypeCodeList: Codelist = {
  name: 'NONEUCountryType Code List',
  values: [
    { value: 'AF', label: 'AFGHANISTAN [AF]', description: ''},
    { value: 'AX', label: 'ALAND ISLANDS [AX]', description: ''},
    { value: 'AL', label: 'ALBANIA [AL]', description: ''},
    { value: 'DZ', label: 'ALGERIA [DZ]', description: ''},
    { value: 'AS', label: 'AMERICAN SAMOA [AS]', description: ''},
    { value: 'AD', label: 'ANDORRA [AD]', description: ''},
    { value: 'AO', label: 'ANGOLA [AO]', description: ''},
    { value: 'AI', label: 'ANGUILLA [AI]', description: ''},
    { value: 'AQ', label: 'ANTARCTICA [AQ]', description: ''},
    { value: 'AG', label: 'ANTIGUA AND BARBUDA [AG]', description: ''},
    { value: 'AR', label: 'ARGENTINA [AR]', description: ''},
    { value: 'AM', label: 'ARMENIA [AM]', description: ''},
    { value: 'AW', label: 'ARUBA [AW]', description: ''},
    { value: 'AU', label: 'AUSTRALIA [AU]', description: ''},
    { value: 'AZ', label: 'AZERBAIJAN [AZ]', description: ''},
    { value: 'BS', label: 'BAHAMAS [BS]', description: ''},
    { value: 'BH', label: 'BAHRAIN [BH]', description: ''},
    { value: 'BD', label: 'BANGLADESH [BD]', description: ''},
    { value: 'BB', label: 'BARBADOS [BB]', description: ''},
    { value: 'BY', label: 'BELARUS [BY]', description: ''},
    { value: 'BZ', label: 'BELIZE [BZ]', description: ''},
    { value: 'BJ', label: 'BENIN [BJ]', description: ''},
    { value: 'BM', label: 'BERMUDA [BM]', description: ''},
    { value: 'BT', label: 'BHUTAN [BT]', description: ''},
    { value: 'BO', label: 'BOLIVIA [BO]', description: ''},
    { value: 'BQ', label: 'BONAIRE, SAINT EUSTATIUS AND SABA [BQ]', description: ''},
    { value: 'BA', label: 'BOSNIA AND HERZEGOWINA [BA]', description: ''},
    { value: 'BW', label: 'BOTSWANA [BW]', description: ''},
    { value: 'BV', label: 'BOUVET ISLAND [BV]', description: ''},
    { value: 'BR', label: 'BRAZIL [BR]', description: ''},
    { value: 'IO', label: 'BRITISH INDIAN OCEAN TERRITORY [IO]', description: ''},
    { value: 'BN', label: 'BRUNEI DARUSSALAM [BN]', description: ''},
    { value: 'BF', label: 'BURKINA FASO [BF]', description: ''},
    { value: 'BI', label: 'BURUNDI [BI]', description: ''},
    { value: 'KH', label: 'CAMBODIA [KH]', description: ''},
    { value: 'CM', label: 'CAMEROON [CM]', description: ''},
    { value: 'CA', label: 'CANADA [CA]', description: ''},
    { value: 'CV', label: 'CAPE VERDE [CV]', description: ''},
    { value: 'KY', label: 'CAYMAN ISLANDS [KY]', description: ''},
    { value: 'CF', label: 'CENTRAL AFRICAN REPUBLIC [CF]', description: ''},
    { value: 'TD', label: 'CHAD [TD]', description: ''},
    { value: 'CL', label: 'CHILE [CL]', description: ''},
    { value: 'CN', label: 'CHINA [CN]', description: ''},
    { value: 'CX', label: 'CHRISTMAS ISLAND [CX]', description: ''},
    { value: 'CC', label: 'COCOS (KEELING) ISLANDS [CC]', description: ''},
    { value: 'CD', label: 'COLOMBIA [CD]', description: ''},
    { value: 'KM', label: 'COMOROS [KM]', description: ''},
    { value: 'CG', label: 'CONGO [CG]', description: ''},
    { value: 'CK', label: 'COOK ISLANDS [CK]', description: ''},
    { value: 'CR', label: 'COSTA RICA [CR]', description: ''},
    { value: 'CI', label: 'COTE D\'IVOIRE [CI]', description: ''},
    { value: 'CU', label: 'CUBA [CU]', description: ''},
    { value: 'CW', label: 'CURACAO [CW]', description: ''},
    { value: 'DJ', label: 'DJIBOUTI [DJ]', description: ''},
    { value: 'DM', label: 'DOMINICA [DM]', description: ''},
    { value: 'DO', label: 'DOMINICAN REPUBLIC [DO]', description: ''},
    { value: 'EC', label: 'ECUADOR [EC]', description: ''},
    { value: 'EG', label: 'EGYPT [EG]', description: ''},
    { value: 'SV', label: 'EL SALVADOR [SV]', description: ''},
    { value: 'GQ', label: 'EQUATORIAL GUINEA [GQ]', description: ''},
    { value: 'ER', label: 'ERITREA [ER]', description: ''},
    { value: 'SZ', label: 'ESWATINI [SZ]', description: ''},
    { value: 'ET', label: 'ETHIOPIA [ET]', description: ''},
    { value: 'FK', label: 'FALKLAND ISLANDS (MALVINAS) [FK]', description: ''},
    { value: 'FO', label: 'FAROE ISLANDS [FO]', description: ''},
    { value: 'FJ', label: 'FIJI [FJ]', description: ''},
    { value: 'GF', label: 'FRENCH GUIANA [GF]', description: ''},
    { value: 'PF', label: 'FRENCH POLYNESIA [PF]', description: ''},
    { value: 'TF', label: 'FRENCH SOUTHERN TERRITORIES [TF]', description: ''},
    { value: 'GA', label: 'GABON [GA]', description: ''},
    { value: 'GM', label: 'GAMBIA [GM]', description: ''},
    { value: 'GE', label: 'GEORGIA [GE]', description: ''},
    { value: 'GH', label: 'GHANA [GH]', description: ''},
    { value: 'GI', label: 'GIBRALTAR [GI]', description: ''},
    { value: 'GL', label: 'GREENLAND [GL]', description: ''},
    { value: 'GD', label: 'GRENADA [GD]', description: ''},
    { value: 'GP', label: 'GUADELOUPE [GP]', description: ''},
    { value: 'GU', label: 'GUAM [GU]', description: ''},
    { value: 'GT', label: 'GUATEMALA [GT]', description: ''},
    { value: 'GG', label: 'GUERNSEY [GG]', description: ''},
    { value: 'GN', label: 'GUINEA [GN]', description: ''},
    { value: 'GW', label: 'GUINEA-BISSAU [GW]', description: ''},
    { value: 'GY', label: 'GUYANA [GY]', description: ''},
    { value: 'HA', label: 'HAITI [HAT]', description: ''},
    { value: 'HM', label: 'HEARD AND MC DONALD ISLANDS [HM]', description: ''},
    { value: 'VA', label: 'HOLY SEE [VA]', description: ''},
    { value: 'HN', label: 'HONDURAS [HN]', description: ''},
    { value: 'HK', label: 'HONG KONG [HK]', description: ''},
    { value: 'IN', label: 'INDIA [IN]', description: ''},
    { value: 'ID', label: 'INDONESIA [ID]', description: ''},
    { value: 'IR', label: 'IRAN (ISLAMIC REPUBLIC OF) [IR]', description: ''},
    { value: 'IQ', label: 'IRAQ [IQ]', description: ''},
    { value: 'IM', label: 'ISLE OF MAN [IM]', description: ''},
    { value: 'IL', label: 'ISRAEL [IL]', description: ''},
    { value: 'JM', label: 'JAMAICA [JM]', description: ''},
    { value: 'JP', label: 'JAPAN [JP]', description: ''},
    { value: 'JE', label: 'JERSEY [JE]', description: ''},
    { value: 'JO', label: 'JORDAN [JO]', description: ''},
    { value: 'KZ', label: 'KAZAKHSTAN [KZ]', description: ''},
    { value: 'KE', label: 'KENYA [KE]', description: ''},
    { value: 'KI', label: 'KIRIBATI [KI]', description: ''},
    { value: 'KR', label: 'KOREA (REPUBLIC OF [SOUTH] KOREA) [KR]', description: ''},
    { value: 'KP', label: 'KOREA DEMOCRATIC PEOPLES REPUBLIC OF [NORTH] KOREA) [KP]', description: ''},
    { value: 'KW', label: 'KUWAIT [KW]', description: ''},
    { value: 'KG', label: 'KYRGYZSTAN [KG]', description: ''},
    { value: 'LA', label: 'LAO PEOPLE\'S DEMOCRATIC REPUBLIC [LA]', description: ''},
    { value: 'LB', label: 'LEBANON [LB]', description: ''},
    { value: 'LS', label: 'LESOTHO [LS]', description: ''},
    { value: 'LR', label: 'LIBERIA [LR]', description: ''},
    { value: 'LY', label: 'LIBYAN ARAB JAMAHIRIYA [LY]', description: ''},
    { value: 'MO', label: 'MACAO [MO]', description: ''},
    { value: 'MG', label: 'MADAGASCAR [MG]', description: ''},
    { value: 'MW', label: 'MALAWI [MW]', description: ''},
    { value: 'MY', label: 'MALAYSIA [MY]', description: ''},
    { value: 'MV', label: 'MALDIVES [MV]', description: ''},
    { value: 'ML', label: 'MALI [ML]', description: ''},
    { value: 'MH', label: 'MARSHALL ISLANDS [MH]', description: ''},
    { value: 'MQ', label: 'MARTINIQUE [MQ]', description: ''},
    { value: 'MR', label: 'MAURITANIA [MR]', description: ''},
    { value: 'MU', label: 'MAURITIUS [MU]', description: ''},
    { value: 'YT', label: 'MAYOTTE [YT ]', description: ''},
    { value: 'MX', label: 'MEXICO [MX]', description: ''},
    { value: 'FM', label: 'MICRONESIA (FEDERATED STATES OF) [FM]', description: ''},
    { value: 'MD', label: 'MOLDOVA, REPUBLIC OF [MD]', description: ''},
    { value: 'MC', label: 'MONACO [MC]', description: ''},
    { value: 'MN', label: 'MONGOLIA [MN]', description: ''},
    { value: 'ME', label: 'MONTENEGRO [ME]', description: ''},
    { value: 'MS', label: 'MONTSERRAT [MS]', description: ''},
    { value: 'MA', label: 'MOROCCO [MA]', description: ''},
    { value: 'MZ', label: 'MOZAMBIQUE [MZ]', description: ''},
    { value: 'MM', label: 'MYANMAR [MM]', description: ''},
    { value: 'NA', label: 'NAMIBIA [NA]', description: ''},
    { value: 'NR', label: 'NAURU [NR]', description: ''},
    { value: 'NP', label: 'NEPAL [NP]', description: ''},
    { value: 'NC', label: 'NEW CALEDONIA [NC]', description: ''},
    { value: 'NZ', label: 'NEW ZEALAND [NZ]', description: ''},
    { value: 'NI', label: 'NICARAGUA [NI]', description: ''},
    { value: 'NE', label: 'NIGER [NE]', description: ''},
    { value: 'NG', label: 'NIGERIA [NG]', description: ''},
    { value: 'MU', label: 'NIUE [MU]', description: ''},
    { value: 'NF', label: 'NORFOLK ISLAND [NF]', description: ''},
    { value: 'MK', label: 'NORTH MACEDONIA [MK]', description: ''},
    { value: 'MP', label: 'NORTHERN MARIANA ISLANDS [MP]', description: ''},
    { value: 'OM', label: 'OMAN [OM]', description: ''},
    { value: 'PK', label: 'PAKISTAN [PK]', description: ''},
    { value: 'PW', label: 'PALAU [PW]', description: ''},
    { value: 'PS', label: 'PALESTINIAN TERRITORY, OCCUPIED [PS]', description: ''},
    { value: 'PA', label: 'PANAMA [PA]', description: ''},
    { value: 'PG', label: 'PAPUA NEW GUINEA [PG]', description: ''},
    { value: 'PY', label: 'PARAGUAY [PY]', description: ''},
    { value: 'PE', label: 'PERU [PE]', description: ''},
    { value: 'PH', label: 'PHILIPPINES [PH]', description: ''},
    { value: 'PN', label: 'PITCAIRN [PN]', description: ''},
    { value: 'PR', label: 'PUERTO RICO [PR]', description: ''},
    { value: 'QA', label: 'QATAR [QA]', description: ''},
    { value: 'RE', label: 'REUNION [RE]', description: ''},
    { value: 'RU', label: 'RUSSIAN FEDERATION [RU]', description: ''},
    { value: 'RW', label: 'RWANDA [RW]', description: ''},
    { value: 'BL', label: 'SAINT BARTHELEMY [BL]', description: ''},
    { value: 'SH', label: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA [SH]', description: ''},
    { value: 'KN', label: 'SAINT KITTS AND NEVIS [KN]', description: ''},
    { value: 'LC', label: 'SAINT LUCIA [LC]', description: ''},
    { value: 'SX', label: 'SAINT MARTIN (DUTCH PART) [SX]', description: ''},
    { value: 'MF', label: 'SAINT MARTIN (FRENCH PART) [MF]', description: ''},
    { value: 'PM', label: 'SAINT PIERRE AND MIQUELON [PM]', description: ''},
    { value: 'VC', label: 'SAINT VINCENT AND THE GRENADINES [VC]', description: ''},
    { value: 'WS', label: 'SAMOA [WS]', description: ''},
    { value: 'SM', label: 'SAN MARINO [SM]', description: ''},
    { value: 'ST', label: 'SAO TOME AND PRINCIPE [ST]', description: ''},
    { value: 'SA', label: 'SAUDI ARABIA [SA]', description: ''},
    { value: 'SN', label: 'SENEGAL [SN]', description: ''},
    { value: 'RS', label: 'SERBIA [RS]', description: ''},
    { value: 'SC', label: 'SEYCHELLES [SC]', description: ''},
    { value: 'SL', label: 'SIERRA LEONE [SL]', description: ''},
    { value: 'SG', label: 'SINGAPORE [SG]', description: ''},
    { value: 'SB', label: 'SOLOMON ISLANDS [SB]', description: ''},
    { value: 'SO', label: 'SOMALIA [SO]', description: ''},
    { value: 'ZA', label: 'SOUTH AFRICA [ZA]', description: ''},
    { value: 'GS', label: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS [GS]', description: ''},
    { value: 'LK', label: 'SRI LANKA [LK]', description: ''},
    { value: 'SD', label: 'SUDAN [SD]', description: ''},
    { value: 'SS', label: 'SOUTH SUDAN [SS]', description: ''},
    { value: 'SR', label: 'SURINAME [SR]', description: ''},
    { value: 'SJ', label: 'SVALBARD AND JAN MAYEN ISLANDS [SJ]', description: ''},
    { value: 'SZ', label: 'SWAZILAND [SZ]', description: ''},
    { value: 'CH', label: 'SWITZERLAND [CH]', description: ''},
    { value: 'SY', label: 'SYRIAN ARAB REPUBLIC [SY]', description: ''},
    { value: 'TW', label: 'TAIWAN, PROVINCE OF CHINA [TW]', description: ''},
    { value: 'TJ', label: 'TAJIKISTAN [TJ]', description: ''},
    { value: 'TZ', label: 'TANZANIA, UNITED REPUBLIC OF [TZ]', description: ''},
    { value: 'TH', label: 'THAILAND [TH]', description: ''},
    { value: 'CD', label: 'THE DEMOCRATIC REPUBLIC OF THE CONGO [CD]', description: ''},
    { value: 'TL', label: 'TIMOR-LESTE [TL]', description: ''},
    { value: 'TG', label: 'TOGO [TG]', description: ''},
    { value: 'TK', label: 'TOKELAU [TK]', description: ''},
    { value: 'TO', label: 'TONGA [TO]', description: ''},
    { value: 'TT', label: 'TRINIDAD AND TOBAGO [TT]', description: ''},
    { value: 'TN', label: 'TUNISIA [TN]', description: ''},
    { value: 'TR', label: 'TURKEY [TR]', description: ''},
    { value: 'TM', label: 'TURKMENISTAN [TM]', description: ''},
    { value: 'TC', label: 'TURKS AND CAICOS ISLANDS [TC]', description: ''},
    { value: 'TV', label: 'TUVALU [TV]', description: ''},
    { value: 'UG', label: 'UGANDA [UG]', description: ''},
    { value: 'UA', label: 'UKRAINE [UA]', description: ''},
    { value: 'AE', label: 'UNITED ARAB EMIRATES [AE]', description: ''},
    { value: 'UK', label: 'UNITED KINGDOM (EX. NORTHERN IRELAND) [UK]', description: ''},
    { value: 'US', label: 'UNITED STATES [US]', description: ''},
    { value: 'UM', label: 'UNITED STATES MINOR OUTLYING ISLANDS [UM]', description: ''},
    { value: 'UY', label: 'URUGUAY [UY]', description: ''},
    { value: 'UZ', label: 'UZBEKISTAN [UZ]', description: ''},
    { value: 'VU', label: 'VANUATU [VU]', description: ''},
    { value: 'VE', label: 'VENEZUELA [VE]', description: ''},
    { value: 'VN', label: 'VIET NAM [VN]', description: ''},
    { value: 'VG', label: 'VIRGIN ISLANDS (BRITISH) [VG]', description: ''},
    { value: 'VI', label: 'VIRGIN ISLANDS (U.S.) [VI]', description: ''},
    { value: 'WF', label: 'WALLIS AND FUTUNA ISLANDS [WF]', description: ''},
    { value: 'EH', label: 'WESTERN SAHARA [EH]', description: ''},
    { value: 'YE', label: 'YEMEN [YE]', description: ''},
    { value: 'ZM', label: 'ZAMBIA [ZM]', description: ''},
    { value: 'ZW', label: 'ZIMBABWE [ZW]', description: ''}
  ]
};

export const SystemOrProcedurePackCodeList: Codelist = {
  name: 'SystemOrProcedurePack Code List',
  values: [
    { value: 'PROCEDURE_PACK', label: 'PROCEDURE_PACK', description: '' },
    { value: 'SYSTEM', label: 'SYSTEM', description: '' }
  ]
};

export const LanguageEUTypeCodeList: Codelist = {
  name: 'LanguageEUType Code List',
  values: [
    { value: 'ANY', label: 'ANY [ANY]', description: '' },
    { value: 'BG',  label: 'Bulgarian [BG]', description: '' },
    { value: 'HR', label: 'Croatian [HR]', description: '' },
    { value: 'CS', label: 'Czech [CS]', description: '' },
    { value: 'DA', label: 'Danish [DA]', description: '' },
    { value: 'NL', label: 'Dutch [NL]', description: '' },
    { value: 'EN', label: 'English [EN]', description: '' },
    { value: 'ET', label: 'Estonian [ET]', description: '' },
    { value: 'FI', label: 'Finnish [FI]', description: '' },
    { value: 'FR', label: 'French [FR]', description: '' },
    { value: 'DE', label: 'German [DE]', description: '' },
    { value: 'EL', label: 'Greek [EL]', description: '' },
    { value: 'HU', label: 'Hungarian [HU]', description: '' },
    { value: 'GA', label: 'Irish [GA]', description: '' },
    { value: 'IT', label: 'Italian [IT]', description: '' },
    { value: 'LV', label: 'Latvian [LV]', description: '' },
    { value: 'LT', label: 'Lithuanian [LT]', description: '' },
    { value: 'MT', label: 'Maltese [MT]', description: '' },
    { value: 'PL', label: 'Polish [PL]', description: '' },
    { value: 'PT', label: 'Portuguese [PT]', description: '' },
    { value: 'RO', label: 'Romanian [RO]', description: '' },
    { value: 'SK', label: 'Slovak [SK]', description: '' },
    { value: 'SL', label: 'Slovenian [SL]', description: '' },
    { value: 'ES', label: 'Spanish [ES]', description: '' },
    { value: 'SV', label: 'Swedish [SV]', description: '' }
  ]
};

export const LanguageEUTypeCodeListDisabledAny: Codelist = {
  name: 'LanguageEUType Code List',
  values: [
    { value: 'ANY', label: 'ANY [ANY]', description: '', disabled: true },
    { value: 'BG',  label: 'Bulgarian [BG]', description: '' },
    { value: 'HR', label: 'Croatian [HR]', description: '' },
    { value: 'CS', label: 'Czech [CS]', description: '' },
    { value: 'DA', label: 'Danish [DA]', description: '' },
    { value: 'NL', label: 'Dutch [NL]', description: '' },
    { value: 'EN', label: 'English [EN]', description: '' },
    { value: 'ET', label: 'Estonian [ET]', description: '' },
    { value: 'FI', label: 'Finnish [FI]', description: '' },
    { value: 'FR', label: 'French [FR]', description: '' },
    { value: 'DE', label: 'German [DE]', description: '' },
    { value: 'EL', label: 'Greek [EL]', description: '' },
    { value: 'HU', label: 'Hungarian [HU]', description: '' },
    { value: 'GA', label: 'Irish [GA]', description: '' },
    { value: 'IT', label: 'Italian [IT]', description: '' },
    { value: 'LV', label: 'Latvian [LV]', description: '' },
    { value: 'LT', label: 'Lithuanian [LT]', description: '' },
    { value: 'MT', label: 'Maltese [MT]', description: '' },
    { value: 'PL', label: 'Polish [PL]', description: '' },
    { value: 'PT', label: 'Portuguese [PT]', description: '' },
    { value: 'RO', label: 'Romanian [RO]', description: '' },
    { value: 'SK', label: 'Slovak [SK]', description: '' },
    { value: 'SL', label: 'Slovenian [SL]', description: '' },
    { value: 'ES', label: 'Spanish [ES]', description: '' },
    { value: 'SV', label: 'Swedish [SV]', description: '' }
  ]
};

export const DeviceTypeCodeList: Codelist = {
  name: 'DeviceType Code List',
  values: [
    { value: 'MDRBASICDEVICE', label: 'MDRBASICDEVICE', description: '' },
    { value: 'MDRUDIDEVICE', label: 'MDRUDIDEVICE', description: '' },
    { value: 'IVDRBASICDEVICE', label: 'IVDRBASICDEVICE', description: '' },
    { value: 'IVDRUDIDEVICE', label: 'IVDRUDIDEVICE', description: '' },
    { value: 'SPPBASICDEVICE', label: 'SPPBASICDEVICE', description: '' },
    { value: 'SPPUDIDEVICE', label: 'SPPUDIDEVICE', description: '' },
    { value: 'MDEUDIBASICDEVICE', label: 'MDEUDIBASICDEVICE', description: '' },
    { value: 'MDEUDIUDIDEVICE', label: 'MDEUDIUDIDEVICE', description: '' },
    { value: 'ADEUDIBASICDEVICE', label: 'ADEUDIBASICDEVICE', description: '' },
    { value: 'ADEUDIUDIDEVICE', label: 'ADEUDIUDIDEVICE', description: '' },
    { value: 'IVDEUDIBASICDEVICE', label: 'IVDEUDIBASICDEVICE', description: '' },
    { value: 'IVDEUDIUDIDEVICE', label: 'IVDEUDIUDIDEVICE', description: '' },
    { value: 'LINKMDR', label: 'LINKMDR', description: '' },
    { value: 'LINKIVDR', label: 'LINKIVDR', description: '' },
    { value: 'LINKSPP', label: 'LINKSPP', description: '' },
    { value: 'LINKMDD', label: 'LINKMDD', description: '' },
    { value: 'LINKAIMDD', label: 'LINKAIMDD', description: '' },
    { value: 'LINKIVD', label: 'LINKIVD', description: '' },
    { value: 'REGISTERMDR', label: 'REGISTERMDR', description: '' },
    { value: 'REGISTERIVDR', label: 'REGISTERIVDR', description: '' },
    { value: 'REGISTERSPP', label: 'REGISTERSPP', description: '' },
    { value: 'REGISTERMDD', label: 'REGISTERMDD', description: '' },
    { value: 'REGISTERAIMDD', label: 'REGISTERAIMDD', description: '' },
    { value: 'REGISTERIVD', label: 'REGISTERIVD', description: '' },
    { value: 'PACKLINKMDR', label: 'PACKLINKMDR', description: '' },
    { value: 'PACKLINKIVDR', label: 'PACKLINKIVDR', description: '' },
    { value: 'PACKLINKSPP', label: 'PACKLINKSPP', description: '' },
    { value: 'REGISTERBASICAIMDD', label: 'REGISTERBASICAIMDD', description: '' },
    { value: 'REGISTERBASICIVD', label: 'REGISTERBASICIVD', description: '' },
    { value: 'REGISTERBASICIVDR', label: 'REGISTERBASICIVDR', description: '' },
    { value: 'REGISTERBASICMDD', label: 'REGISTERBASICMDD', description: '' },
    { value: 'REGISTERBASICMDR', label: 'REGISTERBASICMDR', description: '' },
    { value: 'REGISTERBASICSPP', label: 'REGISTERBASICSPP', description: '' },
    { value: 'MARKETINFOLINKMDR', label: 'MARKETINFOLINKMDR', description: '' },
    { value: 'MARKETINFOLINKIVDR', label: 'MARKETINFOLINKIVDR', description: '' }
  ]
};

export const EudamedComponentCodelists: ComponentCodeList[] = [
  { formElement: 'MDNCode', codeList: MdnEditorCodelist },
  { formElement: 'basicUDIIdentifierIssuingEntityCode', codeList: IssuingEntityCodeList },
  { formElement: 'UDIDIIdentifierIssuingEntityCode', codeList: IssuingEntityCodeList },
  { formElement: 'secondaryIdentifierIssuingEntityCode', codeList: IssuingEntityCodeList },
  { formElement: 'relatedUDILinkIssuingEntityCode', codeList: IssuingEntityCodeList },
  { formElement: 'secondaryIdentifierIssuingEntityCode', codeList: IssuingEntityCodeList },
  { formElement: 'childIdentifierIssuingEntityCode', codeList: IssuingEntityCodeList },
  { formElement: 'directMarkingDIIssuingEntityCode', codeList: IssuingEntityCodeList },
  { formElement: 'unitOfUseIdentifierIssuingEntityCode', codeList: IssuingEntityCodeList },
  { formElement: 'packageIdentifierIssuingEntityCode', codeList: IssuingEntityCodeList },
  { formElement: 'applicableLegislation', codeList: ApplicableLegislationTypeCodeList },
  { formElement: 'deviceStatusCode', codeList: DeviceStatusCodeCodeList },
  { formElement: 'typeOfSystemOrProcedurePack', codeList: SystemOrProcedurePackCodeList },
  { formElement: 'substatusCode', codeList: DeviceSubStatusCodeCodeList },
  { formElement: 'specialDevice', codeList: SpecialDeviceTypeCodeList },
  { formElement: 'productionIdentifier', codeList: ProductionIdentifierElementTypeCodeList },
  { formElement: 'substatusRecallScope', codeList: SubstatusRecallScopeCodeList },
  { formElement: 'multicomponentDeviceTypeCode', codeList: MulticomponentDeviceTypeCodeList },
  { formElement: 'packageStatusCode', codeList: DeviceStatusCodeCodeList },
  { formElement: 'relatedUDILinkType', codeList: RelatedUDILinkTypeCodeList },
  { formElement: 'medicalHumanProductSubstanceType', codeList: MedicalHumanProductSubstanceTypeCodeList },
  { formElement: 'clinicalSizePrecision', codeList: ClinicalSizePrecisionCodeList },
  { formElement: 'ARCommentLanguage', codeList: LanguageEUTypeCodeList },
  { formElement: 'CMRSubstanceCategory', codeList: CMRSubstanceCategoryCodeList },
  { formElement: 'marketInfoPlacingOnTheEUCountry', codeList: EUCountryTypeCodeList },
  { formElement: 'marketInfoMadeAvailableCountry', codeList: EUCountryTypeCodeList },
  { formElement: 'clinicalInvestigationNonEUApplicableCountries', codeList: NONEUCountryTypeCodeList },
  { formElement: 'deviceType', codeList: DeviceTypeCodeList },
  { formElement: 'additionalProductDescriptionLanguage', codeList: LanguageEUTypeCodeList },
  { formElement: 'riskClass', codeList: RiskClassTypeCodeList },
  { formElement: 'tradeNameLanguage', codeList: LanguageEUTypeCodeList },
  { formElement: 'medicinalPurposeLanguage', codeList: LanguageEUTypeCodeList },
  { formElement: 'deviceCertificateType', codeList: DeviceCertificateTypeCodeList },
  { formElement: 'storageHandlingConditionCommentLanguage', codeList: LanguageEUTypeCodeListDisabledAny },
  { formElement: 'storageHandlingConditionType', codeList: StorageHandlingConditionTypeCodeList },
  { formElement: 'criticalWarningCommentLanguage', codeList: LanguageEUTypeCodeListDisabledAny },
  { formElement: 'criticalWarningValue', codeList: CriticalWarningValueCodeList },
  { formElement: 'substatusRecalledPrecisionLanguage', codeList: LanguageEUTypeCodeList },
  { formElement: 'annexXVINonMedicalDeviceType', codeList: NonMedicalDeviceTypeCodeList },
  { formElement: 'productDesignerOrganisationCountry', codeList: CountryISOTypeCodeList },
  { formElement: 'productDesignerOrganisationNameLanguage', codeList: LanguageEUTypeCodeList },
  { formElement: 'medicalHumanProductSubstanceNameLanguage', codeList: LanguageEUTypeCodeList },
  { formElement: 'clinicalSizeType', codeList: ClinicalSizeTypeCodeList},
  { formElement: 'clinicalSizeValueUnitOfMeasure', codeList: ClinicalSizeUnitOfMeasureTypeCodeList },
  { formElement: 'clinicalSizeRangeValueUnitOfMeasure', codeList: ClinicalSizeUnitOfMeasureTypeCodeList },
  { formElement: 'CMRSubstanceNameLanguage', codeList: LanguageEUTypeCodeList },
  { formElement: 'endocrineSubstanceNameLanguage', codeList: LanguageEUTypeCodeList },
  { formElement: 'clinicalSizeTypeDescriptionLanguage', codeList: LanguageEUTypeCodeList },
  { formElement: 'clinicalSizeValueUnitOfMeasureDescriptionLanguage', codeList: LanguageEUTypeCodeList },
  { formElement: 'clinicalSizeRangeValueUnitOfMeasureDescriptionLanguage', codeList: LanguageEUTypeCodeList }
];
