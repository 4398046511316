import {Component, Input} from '@angular/core';
import {Validators} from '@angular/forms';
import {returnCodelistStyle} from 'src/app/core/constants/codelist.style.constants';
import {BaseFormFieldType} from '../base-form-field-type.class';

@Component({
    selector: 'searchable-dropdown-render',
    templateUrl: './searchable-dropdown-render.component.html',
    styleUrls: ['./searchable-dropdown-render.component.scss']
})
export class SearchableDropdownRenderingComponent extends BaseFormFieldType {
    @Input()
    errorForm: boolean;

    @Input()
    editingObject: any;

    optionalClass = ['non-empty-input', 'label'];
    Validators = Validators;
    Infinity = Infinity;

    getDropdownStyle(propertyName: string): any {
      return returnCodelistStyle(propertyName);
    }

}
