import {AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {AbstractControl, FormArray, FormGroup, Validators} from '@angular/forms';
import {faPlus, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {NzCollapsePanelComponent} from 'ng-zorro-antd/collapse';
import {FormService} from 'src/app/core/service/forms/form.service';
import FormBuildingInput from '../../form-interfaces/form-building-input.interface';
import {BaseFormFieldType} from '../base-form-field-type.class';
import {RootFormComponent} from '../root-form-component/root-form.component';

@Component({
    selector: 'repeatable-render',
    templateUrl: './repeatable-render.component.html',
    styleUrls: ['./repeatable-render.component.scss'],
    providers: [{ provide: RootFormComponent , useExisting: forwardRef(() => RootFormComponent) }],
})
export class RepeatableRenderingComponent extends BaseFormFieldType implements AfterViewInit, AfterContentChecked {
    @Input()
    rootNested: boolean;
    @Input()
    lastVisitedPath: string;
    @Input()
    editingObject: any;

    optionalClass = ['non-empty-input', 'label'];
    Validators = Validators;

    faTrash = faTrashAlt;
    faPlus = faPlus;
    faTimesCircle = faTimesCircle;

    style = '';
    lastCollapsed = false;
    lastCollapsedChild = false;

    constructor(private formService: FormService, private changeDetector: ChangeDetectorRef){
        super();
    }

    ngAfterViewInit(): void {
      setTimeout(() => {
        this.style = '';
        this.lastCollapsed = false;
        this.lastCollapsedChild = false;
      });
    }

    ngAfterContentChecked(): void {
      this.changeDetector.detectChanges();
    }

    removeSingleItem(indexOfArrayGroup: number, parentFormArray: AbstractControl): void {
      if (this.formGroup.disabled){
        return;
      }
      if (parentFormArray instanceof FormArray){
          parentFormArray.removeAt(indexOfArrayGroup);
          let i = 0;
          for (const control of parentFormArray.controls) {
            if (control instanceof FormGroup) {
              i = i + 1;
              control.get('tradeNameSequenceNumber')?.setValue(i);
            }
          }
      }
    }

    addRepeatable(formControlInput: FormBuildingInput, formGroup: FormGroup, previousCollapsePanel: NzCollapsePanelComponent | null, innerFormGroups: any): void{
      if (this.formGroup.disabled){
        return;
      }
      const arrayToAddTo = formGroup.get(formControlInput.propertyName);
      const groupToCopy = arrayToAddTo?.get('0');

      if (previousCollapsePanel) {
        previousCollapsePanel.nzActive = false;
        previousCollapsePanel.nzActiveChange.emit(previousCollapsePanel.nzActive);
        previousCollapsePanel.markForCheck();
      }

      if (groupToCopy){
          const newFormGroup = this.formService.cloneAbstractControl(groupToCopy);
          if (arrayToAddTo instanceof FormArray) {
              const len = arrayToAddTo.controls.length;
              newFormGroup.get('tradeNameSequenceNumber')?.setValue(len + 1);
              arrayToAddTo.push(newFormGroup);
          }
      }

      this.returnStyle(previousCollapsePanel, innerFormGroups);
    }

    returnStyle(collapsePanel: any, innerFormGroups: any): void{
      if (this.returnLastCollapsed(collapsePanel, innerFormGroups)){
        this.style = 'height: 107px; border-top-width: 0px;';
      }else{
        this.style = '';
      }
    }

    returnLastCollapsed(collapsePanel: any, innerFormGroups: any): boolean{
      this.lastCollapsed = !collapsePanel.nzActive && this.formBuildingInput.maxLength === undefined && innerFormGroups === this.getByName(this.formGroup, this.formBuildingInput.propertyName).controls[this.getByName(this.formGroup, this.formBuildingInput.propertyName).controls.length - 1];
      this.returnLastCollapsedChild();
      return this.lastCollapsed;
    }

    returnLastCollapsedChild(): boolean{
      this.lastCollapsedChild = !this.rootNested && this.lastCollapsed;
      return this.lastCollapsedChild;
    }
}
