export interface FilterOptions {
    [key: string]: {
        name: string;
        type: FilterInputType;
        options?: {label: string, value: string}[];
    };
}

export enum FilterInputType {
    Select = 'Select',
    Input = 'Input'
}


export const COLUMN_FILTER_OPTIONS: FilterOptions = {
    name: {
        name: 'DEVICES.column.name',
        type: FilterInputType.Input,
    },
    dicode: {
        name: 'DEVICES.column.publicId',
        type: FilterInputType.Input,
    },
    model: {
         name: 'DEVICES.column.internalId',
         type: FilterInputType.Input,
    },
    actorcode: {
         name: 'DEVICES.column.actorCode',
         type: FilterInputType.Input
    },
    status: {
        name: 'DEVICES.column.status',
        type: FilterInputType.Select,
        options: [
            {
                label: 'NEW',
                value: 'NEW'
            },
            {
                label: 'SENT',
                value: 'SENT'
            },
            {
                label: 'SUCCESS',
                value: 'SUCCESS'
            },
            {
                label: 'FAILED',
                value: 'FAILED'
            },
            {
                label: 'SUBMITTED',
                value: 'SUBMITTED'
            },
            {
                label: 'REGISTERED',
                value: 'REGISTERED'
            },
            {
                label: 'DISCARDED',
                value: 'DISCARDED'
            },
        ]
    },
    devicetype: {
         name: 'DEVICES.column.deviceType',
         type: FilterInputType.Select,
         options: [
             {
                 label: 'Basic MDR Device',
                 value: 'MDRBASICDEVICE'
             },
             {
                 label: 'MDR UDI Device',
                 value: 'MDRUDIDEVICE'
             },
             {
                 label: 'Basic IVDR Device',
                 value: 'IVDRBASICDEVICE'
             },
             {
                 label: 'IVDR UDI Device',
                 value: 'IVDRUDIDEVICE'
             },
             {
                 label: 'Basic SPP Device',
                 value: 'SPPBASICDEVICE'
             },
             {
                 label: 'SPP UDI Device',
                 value: 'SPPUDIDEVICE'
             },
             {
               label: 'Basic MDD Device',
               value: 'MDEUDIBASICDEVICE'
             },
             {
               label: 'MDD UDI Device',
               value: 'MDEUDIUDIDEVICE'
             },
             {
               label: 'Basic IVD Device',
               value: 'IVDEUDIBASICDEVICE'
             },
             {
               label: 'IVD UDI Device',
               value: 'IVDEUDIUDIDEVICE'
             },
             {
               label: 'Basic AIMDD Device',
               value: 'ADEUDIBASICDEVICE'
             },
             {
               label: 'AIMDD UDI Device',
               value: 'ADEUDIUDIDEVICE'
             }
         ]
    },
    riskclass: {
        name: 'DEVICES.column.riskClass',
        type: FilterInputType.Select,
        options: [
            {
                label: 'Class A',
                value: 'CLASS_A'
            },
            {
                label: 'Class B',
                value: 'CLASS_B'
            },
            {
                label: 'Class C',
                value: 'CLASS_C'
            },
            {
                label: 'Class D',
                value: 'CLASS_D'
            },
            {
                label: 'AIMDD',
                value: 'AIMDD'
            },
            {
                label: 'Class I',
                value: 'CLASS_I'
            },
            {
                label: 'Class IIA',
                value: 'CLASS_IIA'
            },
            {
                label: 'Class IIB',
                value: 'CLASS_IIB'
            },
            {
                label: 'Class III',
                value: 'CLASS_III'
            },
            {
                label: 'IVD Annex II List A',
                value: 'IVD_ANNEX_II_LIST_A'
            },
            {
                label: 'IVD Annex II List B',
                value: 'IVD_ANNEX_II_LIST_B'
            },
            {
                label: 'IVD Devices Self Testing',
                value: 'IVD_DEVICES_SELF_TESTING'
            },
            {
                label: 'IVD General',
                value: 'IVD_GENERAL'
            },

        ]
    },
    srn: {
        name: 'SRN',
        type: FilterInputType.Input,
    },
    relatedlegacy: {
        name: 'DEVICES.column.relatedLegacy',
        type: FilterInputType.Select,
        options: [
            {
                label: 'DEVICECONSTANTS.mdr.udi.relatedUDILink.name',
                value: 'REGULATION'
            },
        ]
    },
};
