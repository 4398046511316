import {Component, forwardRef} from '@angular/core';
import {Validators} from '@angular/forms';
import {returnCodelistStyle} from 'src/app/core/constants/codelist.style.constants';
import {BaseFormFieldType} from '../base-form-field-type.class';
import {RootFormComponent} from '../root-form-component/root-form.component';

@Component({
    selector: 'date-render',
    templateUrl: './date-render.component.html',
    styleUrls: ['./date-render.component.scss'],
    providers: [{ provide: RootFormComponent , useExisting: forwardRef(() => RootFormComponent) }],
})
export class DateRenderingComponent extends BaseFormFieldType {
    optionalClass = ['non-empty-input', 'label'];
    Validators = Validators;

    getDropdownStyle(propertyName: string): any {
      return returnCodelistStyle(propertyName);
    }
}
